import { Grid } from "@mui/material";
import React from "react";
import { OptionComponent } from "../Options";

function AdminPageMypage() {
  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={6} xs={12}>
        <OptionComponent label="회원 목표" type="personal_goal" />
      </Grid>
    </Grid>
  );
}

export default AdminPageMypage;
