import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchDefaultWithCredential,
  formatDateJoined,
  formatDateString,
  formatProgramTime,
  isMobile,
} from "../../utils";
import queryString from "query-string";
import CustomDialog from "../customs/CustomDialog";
import CustomButton from "../customs/CustomButton";
import { ProgramCardImageComponent, textColor, widthPercentage } from "../Home";
import InfoBar from "./InfoBar";
import { CustomStandardSelect } from "../customs/CustomSelect";
import { OrderByType } from "./Review";
import CustomPagination from "../customs/CustomPagination";
import { Chip } from "@mui/material";
import { patchPdfDownloaded } from "../Cart";
import { GlobalContext } from "../../globals/components/ComponentsWrapper";
import { useNotifications } from "../../globals/notification";

interface RegisteredProgramCardProps {
  programInfo: ProgramInfo;
  refresh: () => void;
  tutor?: boolean;
}

function RegisteredProgramCard({
  programInfo,
  refresh,
  tutor,
}: RegisteredProgramCardProps) {
  const history = useHistory();
  const { refreshNotification } = useContext(GlobalContext);
  return (
    <div style={{ userSelect: "none" }}>
      {programInfo.program_registered_at && (
        <Fragment>
          <div
            style={{
              color: "#959595",
              fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
            }}
          >
            {formatDateJoined(new Date(programInfo.program_registered_at), ".")}
          </div>
          <div
            style={{
              marginTop: widthPercentage(isMobile() ? 2.5 : 0.625),
              height: widthPercentage(isMobile() ? 0.25 : 0.0625),
              backgroundColor: "#E5E5EC",
            }}
          ></div>
          <div
            className="font-noto-black"
            style={{
              marginTop: widthPercentage(isMobile() ? 2.5 : 0.625),
              fontSize: widthPercentage(isMobile() ? 4.5 : 1.125),
              color: "#505050",
            }}
          >
            결제완료
          </div>
        </Fragment>
      )}
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 2.5 : 0.625),
          display: "flex",
        }}
      >
        <div
          style={{
            flex: "1",
          }}
        >
          <ProgramCardImageComponent
            index={-1}
            program_image={programInfo.program_image}
          />
        </div>
        <div
          style={{
            width: widthPercentage(isMobile() ? 2.5 : 0.625),
          }}
        ></div>
        <div
          style={{
            flex: "1",
            color: textColor,
            fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: "#505050",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {programInfo.program_header}
          </div>
          <div
            style={{
              marginTop: widthPercentage(isMobile() ? 0.5 : 0.125),
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {programInfo.program_name}
          </div>
          <div
            style={{
              marginTop: widthPercentage(isMobile() ? 2.5 : 0.625),
              display: "flex",
            }}
          >
            {programInfo.tutors.map((tutor, index) => (
              <div
                key={index}
                style={{
                  padding: `${widthPercentage(
                    isMobile() ? 1 : 0.25
                  )}px ${widthPercentage(isMobile() ? 3 : 0.75)}px`,
                  backgroundColor: "#F5F5F5",
                  marginRight: widthPercentage(isMobile() ? 2 : 0.5),
                  cursor: "pointer",
                  fontSize: widthPercentage(isMobile() ? 3 : 0.75),
                }}
                onClick={() => history.push(`/tutor/${tutor.id}`)}
              >
                {tutor.tutor_name}
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: widthPercentage(isMobile() ? 4 : 2.125),
              flex: 1,
              display: "flex",
            }}
          >
            <div
              style={{
                height: "100%",
                width: widthPercentage(isMobile() ? 1 : 0.25),
                backgroundColor: "#E5E5EC",
                marginRight: widthPercentage(isMobile() ? 2.5 : 0.625),
              }}
            ></div>
            <div
              style={{
                flex: 1,
                color: "#767676",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>{formatDateString(programInfo.start_time)}</div>
              <div
                style={{
                  marginTop: widthPercentage(isMobile() ? 1 : 0.25),
                }}
              >
                {formatProgramTime(
                  programInfo.starting_hour,
                  programInfo.starting_minute,
                  programInfo.minutes
                )}
              </div>
              {programInfo.price > 0 && (
                <div style={{ marginTop: "auto" }}>
                  <div>{programInfo.price.toLocaleString()}원</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 5 : 1.25),
        }}
      >
        {programInfo.pdf_file !== "" && (
          <div
            className="font-noto-medium"
            style={{
              backgroundColor: programInfo.pdf_downloaded
                ? "#F5F5F5"
                : "#D4D0FE",
              fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
              color: programInfo.pdf_downloaded ? "#111111" : "#4A148C",
              cursor: "pointer",
              textAlign: "center",
              padding: widthPercentage(isMobile() ? 2.5 : 0.625),
            }}
            onClick={() => {
              if (!tutor) {
                patchPdfDownloaded(programInfo.id)
                  .then(refresh)
                  .then(refreshNotification);
              }
              window.open(programInfo.pdf_file, "_blank");
            }}
          >
            안내자료 다운받기
          </div>
        )}
      </div>
    </div>
  );
}

interface TutorInfo {
  id: number;
  tutor_name: string;
}

interface ProgramInfo {
  id: number;
  program_registered_at?: string;
  program_image: string;
  program_header: string;
  program_name: string;
  tutors: TutorInfo[];
  dates: string[];
  starting_hour: number;
  starting_minute: number;
  minutes: number;
  price: number;
  pdf_downloaded: boolean;
  pdf_file: string;
  start_time: string;
}

interface RegisteredInfo {
  total_registered: number;
  total_pages: number;
  program_infos: ProgramInfo[];
}

interface RegisteredProps {
  tutor?: boolean;
}

function Registered({ tutor }: RegisteredProps) {
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search);
  const [openBought, setOpenBought] = useState<boolean>(false);
  const [registeredInfo, setRegisteredInfo] = useState<RegisteredInfo | null>(
    null
  );
  const [orderByType, setOrderByType] = useState<OrderByType>("recent");
  const [page, setPage] = useState<number>(1);
  const amount = isMobile() ? 2 : 4;
  const notifications = useNotifications();
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/registered?type=${
        tutor ? "tutor" : "user"
      }&page=${page}&amount=${amount}&order_by=${orderByType}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setRegisteredInfo);
    });
  }, [amount, orderByType, page, tutor, render]);

  useEffect(() => {
    if (parsed.newBought === "true") {
      setOpenBought(true);
    } else {
      setOpenBought(false);
    }
  }, [parsed]);

  if (!registeredInfo) return null;

  return (
    <Fragment>
      <InfoBar
        header={tutor ? "나의 세미나 진행목록" : "세미나 신청목록"}
        description={
          tutor
            ? "프로그램 진행전 안내자료를 다운받고 분량 및 일정을 꼭 확인해주세요."
            : "참석전 세미나 안내자료를 꼭 다운받고 읽은 뒤 참여해주세요."
        }
        percentage={100}
        count={{
          label: tutor ? "진행한 프로그램" : "신청한 프로그램",
          element: (
            <div
              className="font-roboto-medium d-flex"
              style={{
                fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
              }}
            >
              {registeredInfo.total_registered}
            </div>
          ),
        }}
      />
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 5.25 : 1.3125),
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomStandardSelect<OrderByType>
            value={orderByType}
            items={[
              {
                label: "최신순",
                value: "recent",
              },
              {
                label: "오래된순",
                value: "old",
              },
            ]}
            handleChange={setOrderByType}
            formStyle={{
              width: isMobile() ? "100%" : widthPercentage(15.875),
            }}
          />
        </div>
      </div>
      {!tutor && notifications.user.registered_list > 0 && (
        <div
          style={{
            marginTop: widthPercentage(isMobile() ? 5.25 : 1.3125),
            padding: `${widthPercentage(
              isMobile() ? 2.5 : 0.625
            )}px ${widthPercentage(isMobile() ? 5 : 1.25)}px`,
            border: `${widthPercentage(
              isMobile() ? 0.25 : 0.0625
            )}px solid #E5E5EC`,
          }}
        >
          <div className="d-flex align-items-center">
            <div>
              <Chip
                label={notifications.user.registered_list}
                color="secondary"
                size="small"
              />
            </div>
            <div
              style={{
                color: "#505050",
                fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                marginLeft: widthPercentage(isMobile() ? 4 : 1),
              }}
            >
              아직 다운받지 않은 세미나 안내자료가 있어요.
            </div>
          </div>
        </div>
      )}
      {(() => {
        const res = [];
        for (
          let i = 0;
          i < registeredInfo.program_infos.length;
          i += amount / 2
        ) {
          res.push(
            <div
              key={i}
              style={{
                marginTop: widthPercentage(isMobile() ? 5.25 : 1.3125),
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {registeredInfo.program_infos
                .slice(i, i + amount / 2)
                .map((program_info, index) => (
                  <div
                    style={{
                      width: isMobile() ? "100%" : widthPercentage(31.25),
                    }}
                    key={index}
                  >
                    <RegisteredProgramCard
                      programInfo={program_info}
                      refresh={() => setRender(render + 1)}
                      tutor={tutor}
                    />
                  </div>
                ))}
            </div>
          );
        }
        return res;
      })()}
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 5.25 : 1.3125),
          display: "flex",
          justifyContent: "center",
          marginBottom: widthPercentage(isMobile() ? 5.25 : 1.3125),
        }}
      >
        <CustomPagination
          count={registeredInfo.total_pages}
          page={page}
          handleChange={setPage}
        />
      </div>
      <CustomDialog open={openBought}>
        <div className="p-30">
          <p className="text-align-center font-header">
            구매가 완료되었습니다.
          </p>
          <p className="font-sub text-red text-align-center">
            지금 바로 세미나 신청목록에서
            <br />
            '안내자료'를 다운 받아주세요!
          </p>
          <div className="d-flex justify-content-center">
            <CustomButton
              onClick={() => history.replace(location.pathname)}
              outlined
            >
              확인
            </CustomButton>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

export default Registered;
