import React, { useState } from "react";
import { Grid } from "@mui/material";
import CustomButton from "./customs/CustomButton";
import CustomCheckbox from "./customs/CustomCheckbox";
import {
  CustomInstructDialog,
  PrivateInfoCollectionDialog,
} from "./customs/CustomDialog";
import CustomText from "./customs/CustomText";
import {
  fetchDefaultWithCredential,
  putDefaultFileWithCredential,
} from "../utils";
import { Policy } from "./Policies";

interface PoliciesForm extends Omit<Policy, "file" | "read"> {
  filename?: string;
  file?: File;
}

function Suggestions() {
  const initForm = {
    company_name: "",
    name_position: "",
    phone: "",
    email: "",
    content: "",
    private_information_agreement: false,
  };
  const [form, setForm] = useState<PoliciesForm>(initForm);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [doneOpen, setDoneOpen] = useState<boolean>(false);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [render, setRender] = useState<number>(0);
  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.type === "checkbox") {
      setForm({
        ...form,
        [target.name]: target.checked,
      });
    } else if (
      target.type === "text" ||
      target.type === "textarea" ||
      target.type === "email"
    ) {
      setForm({
        ...form,
        [target.name]: target.value,
      });
    } else if (target.type === "file") {
      if (target.files && target.files.length > 0) {
        setForm({
          ...form,
          filename: target.files[0].name,
          file: target.files[0],
        });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");
    fetchDefaultWithCredential("/policies", "POST", form).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          setErrorMessage(error);
        });
      } else {
        return res
          .json()
          .then(({ data, token }) => {
            if (!token || !form.file) return;
            return putDefaultFileWithCredential(
              "/file/token/" + token,
              form.file
            );
          })
          .then(() => {
            setRender(render + 1);
            setForm(initForm);
            setDoneOpen(true);
          });
      }
    });
  };

  return (
    <div className="p-50">
      <p className="font-header">제휴·제안</p>
      <p className="mt-50 font-sub">
        필로어스는 기업 및 단체, 기관 간의 협업을 통해 함께 성장하기를
        기대합니다. 제휴 및 제안은 본 시스템을 통해 접수된 건에 한하여 정식으로
        검토되며 담당자 확인 후 신속히 연락드리겠습니다.
        <br />
        *단체 및 기업 교육 문의도 아래의 정보를 작성해주세요.
      </p>
      <p className="font-sub font-bold mt-50">기본정보</p>
      <form onBlur={handleChange} onSubmit={handleSubmit} key={render}>
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm>
              <CustomText
                label="회사(기관)명"
                name="company_name"
                type="text"
                width="100%"
                style={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm>
              <CustomText
                style={{ backgroundColor: "white" }}
                label="성함/직책"
                name="name_position"
                type="text"
                width="100%"
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm>
              <CustomText
                style={{ backgroundColor: "white" }}
                label="연락처"
                name="phone"
                type="text"
                width="100%"
                maxLength={11}
              />
            </Grid>
            <Grid item xs={12} sm>
              <CustomText
                style={{ backgroundColor: "white" }}
                label="이메일"
                name="email"
                type="email"
                width="100%"
              />
            </Grid>
          </Grid>
        </Grid>
        <div className="mt-20 p-relative">
          <CustomText
            style={{ backgroundColor: "white" }}
            label="내용"
            width="100%"
            height="200px"
            type="text"
            name="content"
            multiline
          />
          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 0,
            }}
          >
            <input
              type="file"
              hidden
              accept=".doc,.docx,.pdf,image/*"
              ref={setInputRef}
              onChange={handleChange as any}
            />
            <CustomButton
              height={40}
              onClick={() => {
                if (inputRef) {
                  inputRef.click();
                }
              }}
              style={{ width: "auto" }}
            >
              {form.file ? form.file.name : "첨부 파일 업로드"}
            </CustomButton>
          </div>
        </div>
        <div className="text-align-center mt-50 d-flex justify-content-center">
          <CustomCheckbox
            checked={form.private_information_agreement}
            handleChange={(checked) => {
              form.private_information_agreement = checked;
              setForm({ ...form });
              setOpen(checked);
            }}
          >
            *개인정보 수집 및 이용 동의(보기)
          </CustomCheckbox>
        </div>
        {errorMessage && (
          <div className="mt-30 error text-align-center">{errorMessage}</div>
        )}
        <div className="mt-30 text-align-center">
          <CustomButton outlined style={{ marginLeft: "auto" }} type="submit">
            제휴제안 보내기
          </CustomButton>
        </div>
        <PrivateInfoCollectionDialog open={open} setOpen={setOpen} />
        <CustomInstructDialog
          open={doneOpen}
          handleClose={() => setDoneOpen(false)}
          header="보내주신 제휴 및 제안 전송이 완료되었습니다."
          maxWidth="md"
        />
      </form>
    </div>
  );
}

export default Suggestions;
