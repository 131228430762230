import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { v4 } from "uuid";
import { User } from "../../../globals/user";
import {
  fetchDefaultWithCredential,
  formatDashedDateTemplate,
  formatDateTemplateDateType,
} from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomCheck from "../../customs/CustomCheck";
import CustomSelect from "../../customs/CustomSelect";
import CustomText from "../../customs/CustomText";

export interface Program {
  id: number;
  created_at: string;
  meta: any;
  status?: string;
}

export interface ParticipatedUser {
  user: User;
  not_participated: string[];
  definition: boolean;
  status: string;
}

interface ProgramBookComponentProps {
  books: ProgramBook[];
  excerption_book_id: number;
  handleChange: (excerption_book_id: number) => void;
  disabled?: boolean;
}

interface ProgramBook {
  id: number;
  program_id: number;
  name: string;
  publisher: string;
  image: string;
  image_id: number;
}

function ProgramBookComponent({
  books,
  excerption_book_id,
  handleChange,
  disabled,
}: ProgramBookComponentProps) {
  const [localBookId, setLocalBookId] = useState<number>(excerption_book_id);
  useEffect(() => {
    setLocalBookId(excerption_book_id);
  }, [excerption_book_id]);

  const book = books.find((book) => book.id === localBookId);

  return (
    <div>
      <CustomSelect<number>
        label="도서 선택"
        name="excerption_book"
        items={[
          {
            label: "선택 안함",
            value: 0,
          },
          ...books.map((book) => ({
            label: `${book.name} - ${book.publisher}`,
            value: book.id,
          })),
        ]}
        value={localBookId}
        handleChange={(excerption_book_id) => {
          handleChange(excerption_book_id);
          setLocalBookId(excerption_book_id);
        }}
        fullWidth
        disabled={disabled}
      />
      {book && (
        <div className="mt-10">
          <img
            src={book.image}
            alt=""
            className="width-100"
            draggable={false}
          />
        </div>
      )}
    </div>
  );
}

interface ParticipantCheckComponentProps {
  program: Program;
  programRefresh: () => void;
}

function ParticipantCheckComponent({
  program,
  programRefresh,
}: ParticipantCheckComponentProps) {
  const [participatedUsers, setParticipatedUsers] = useState<
    ParticipatedUser[] | null
  >(null);
  const [books, setBooks] = useState<ProgramBook[] | null>(null);
  const [render, setRender] = useState<number>(0);
  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/${program.id}/users?type=all`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setParticipatedUsers);
    });
  }, [program, render]);

  useEffect(() => {
    fetchDefaultWithCredential(`/program/${program.id}/books`, "GET").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(setBooks);
      }
    );
  }, [program.id]);

  const handleEdit = () => {
    fetchDefaultWithCredential(`/program/${program.id}/participate`, "PUT", {
      excerpt_dates: program.meta.excerpt_dates || {},
      opening_questions: program.meta.opening_questions || {},
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const handleSubmit = () => {
    fetchDefaultWithCredential(`/program/${program.id}/participate`, "PUT", {
      participate_completed: program.meta.participate_completed || [],
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res
        .json()
        .then(() => setRender(render + 1))
        .then(programRefresh);
    });
  };

  if (!participatedUsers || !books) return null;
  return (
    <Grid container columnSpacing={2}>
      {((program.meta.dates || []) as string[]).map((date, index) => (
        <Grid item sm={3} xs={12} key={index}>
          <CustomCheck
            disabled={(program.meta.participate_completed || []).includes(date)}
            label={formatDashedDateTemplate(date)}
            checkItems={participatedUsers.map((participatedUser) => ({
              label: participatedUser.user.name,
              id: participatedUser.user.id,
              checked: !participatedUser.not_participated.includes(date),
            }))}
            handleChange={(checkItemId: any, checked: boolean) => {
              fetchDefaultWithCredential(
                `/program/${program.id}/users/${checkItemId}/participate/${date}`,
                "PATCH"
              ).then((res) => {
                if (!res.ok) {
                  return res.json().then(({ error }) => {
                    throw new Error(error);
                  });
                }
                return res.json().then(() => setRender(render + 1));
              });
            }}
          />
          <div className="mt-10">
            <CustomButton
              onClick={() => {
                const excerpt_dates = program.meta.excerpt_dates || {};
                const excerpt_date = excerpt_dates[date] || [];
                excerpt_date.push({
                  id: v4(),
                  book_id: 0,
                  excerpt: "",
                });
                excerpt_dates[date] = excerpt_date;
                program.meta.excerpt_dates = excerpt_dates;
                handleEdit();
              }}
              disabled={(program.meta.participate_completed || []).includes(
                date
              )}
            >
              도서 선택 추가
            </CustomButton>
          </div>
          {(((program.meta.excerpt_dates || {})[date] as any[]) || []).map(
            (excerpt, index) => (
              <Fragment key={excerpt.id}>
                <div className="mt-10">
                  <CustomButton
                    onClick={() => {
                      const excerpt_dates = program.meta.excerpt_dates || {};
                      const excerpt_date = (excerpt_dates[date] || []) as any[];
                      excerpt_dates[date] = excerpt_date.filter(
                        (obj) => obj.id !== excerpt.id
                      );
                      program.meta.excerpt_dates = excerpt_dates;
                      handleEdit();
                    }}
                    disabled={(
                      program.meta.participate_completed || []
                    ).includes(date)}
                  >
                    도서 삭제
                  </CustomButton>
                </div>
                <div className="mt-10">
                  <ProgramBookComponent
                    books={books}
                    excerption_book_id={excerpt.book_id}
                    handleChange={(book_id) => {
                      excerpt.book_id = book_id;
                      handleEdit();
                    }}
                    disabled={(
                      program.meta.participate_completed || []
                    ).includes(date)}
                  />
                </div>
                <div className="mt-10">
                  <CustomText
                    label="발췌문"
                    name="excerpts"
                    type="text"
                    width="100%"
                    multiline
                    height="100px"
                    defaultValue={excerpt.excerpt}
                    handleBlur={(e) => {
                      excerpt.excerpt = e.target.value;
                      handleEdit();
                    }}
                    disabled={(
                      program.meta.participate_completed || []
                    ).includes(date)}
                  />
                  {index === 0 && (
                    <div className="font-14">
                      *오프닝퀘스쳔이 나오게 된 도서/매체의 정확한 부분을
                      작성해주세요. 없을 경우 공란으로 남겨둡니다.
                    </div>
                  )}
                </div>
              </Fragment>
            )
          )}
          <div className="mt-10">
            <CustomText
              label="오프닝 퀘스쳔"
              name="opening_questions"
              type="text"
              width="100%"
              multiline
              height="100px"
              defaultValue={(program.meta.opening_questions || {})[date] || ""}
              handleBlur={(e) => {
                const opening_questions = program.meta.opening_questions || {};
                opening_questions[date] = e.target.value;
                program.meta.opening_questions = opening_questions;
                handleEdit();
              }}
              disabled={(program.meta.participate_completed || []).includes(
                date
              )}
            />
            {index === 0 && (
              <div className="font-14">
                *작성해주신 오프닝퀘스쳔은 멤버분들께 전달이 됩니다. 반드시
                오탈자없이 정확히 작성해주세요. (말따옴표는 기재하지 않고 질문만
                작성해주세요.)
              </div>
            )}
          </div>
          <div className="mt-10">
            <div className="font-14 font-bold">
              상태:{" "}
              {(() => {
                const participate_completed =
                  program.meta.participate_completed || [];
                if (!participate_completed.includes(date)) {
                  return "미제출함";
                }
                return "제출함";
              })()}
            </div>
            <div className="d-flex mt-10">
              <div className="mr-10">
                <CustomButton
                  onClick={() => {
                    const participate_completed = (program.meta
                      .participate_completed || []) as string[];
                    program.meta.participate_completed =
                      participate_completed.filter(
                        (participatedDate) => participatedDate !== date
                      );
                    handleSubmit();
                  }}
                  disabled={
                    !(program.meta.participate_completed || []).includes(date)
                  }
                >
                  미제출
                </CustomButton>
              </div>
              <div className="mr-10">
                <CustomButton
                  onClick={() => {
                    const participate_completed =
                      program.meta.participate_completed || [];
                    if (participate_completed.includes(date)) return;
                    participate_completed.push(date);
                    program.meta.participate_completed = participate_completed;
                    handleSubmit();
                  }}
                  disabled={(program.meta.participate_completed || []).includes(
                    date
                  )}
                >
                  제출
                </CustomButton>
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

interface TutorParticipationsProps {
  admin?: boolean;
}

function TutorParticipations({ admin }: TutorParticipationsProps) {
  const [programId, setProgramId] = useState<number>(0);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [render, setRender] = useState<number>(0);

  const program = programs.find((program) => program.id === programId);

  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/tutor/participated?type=all${admin ? "&page=admin" : ""}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setPrograms);
    });
  }, [render, admin]);

  return (
    <Fragment>
      <div className="d-flex">
        <CustomSelect<number>
          label="프로그램 선택"
          name="program"
          items={[
            {
              label: "선택 안함",
              value: 0,
              disabled: true,
            },
            ...programs.map((program) => ({
              label: `${formatDashedDateTemplate(
                program.meta.dates[0] || formatDateTemplateDateType(new Date())
              )} - ${program.meta.program_name || ""}${
                program.status === "completed" ? " (완료)" : ""
              }`,
              value: program.id,
            })),
          ]}
          value={programId}
          handleChange={setProgramId}
        />
      </div>
      {program && (
        <div className="mt-10">
          <ParticipantCheckComponent
            key={programId}
            program={program}
            programRefresh={() => setRender(render + 1)}
          />
        </div>
      )}
    </Fragment>
  );
}

export default TutorParticipations;
