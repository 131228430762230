import {
  fetchDefaultWithCredential,
  putDefaultFileWithCredential,
} from "../utils";

export function postFile(filename: string) {
  return fetchDefaultWithCredential(`/file`, "POST", {
    filename,
  }).then((res) => {
    if (!res.ok) {
      return res.json().then(({ error }) => {
        throw new Error(error);
      });
    }
    return res.json();
  });
}

export function putFile(fileId: number, file: File) {
  return putDefaultFileWithCredential(`/file/${fileId}`, file).then((res) => {
    if (!res.ok) {
      return res.json().then(({ error }) => {
        throw new Error(error);
      });
    }
    return res.json();
  });
}
