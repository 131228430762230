import Signup from "../components/layouts/Signup";

const Unsigned = [
  {
    exact: true,
    path: "/signup",
    component: Signup,
  },
];

export default Unsigned;
