import React, { ReactNode } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { RadioItem } from "../../interfaces";
import { widthPercentage } from "../Home";
import { isMobile } from "../../utils";

interface PropType<T> {
  name: string;
  label: ReactNode;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  row?: boolean;
  value: T;
  radios: RadioItem<T>[];
}

function RadioForm<T>({
  name,
  label,
  handleChange,
  row,
  value,
  radios,
}: PropType<T>) {
  return (
    <FormControl component="fieldset">
      {label && <p className="font-14 font-bold">{label}</p>}
      <RadioGroup row={row} name={name} value={value} onChange={handleChange}>
        {radios.map((radio, index) => (
          <FormControlLabel
            style={{
              margin: 0,
              marginRight: widthPercentage(isMobile() ? 5 : 1.25),
            }}
            value={radio.value}
            control={
              <Radio
                color="secondary"
                style={{ padding: 0 }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    width: widthPercentage(isMobile() ? 6 : 1.5),
                    height: widthPercentage(isMobile() ? 6 : 1.5),
                  },
                }}
              />
            }
            label={
              <div
                className="font-noto-regular"
                style={{
                  marginLeft: widthPercentage(isMobile() ? 2.25 : 0.5625),
                  fontSize: widthPercentage(isMobile() ? 4 : 1),
                  color:
                    value === radio.value
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(80, 80, 80, 0.87)",
                }}
              >
                {radio.label}
              </div>
            }
            key={index}
            disabled={radio.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioForm;
