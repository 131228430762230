import { Card, Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { v4 } from "uuid";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  graphColors,
} from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomCheckbox from "../../customs/CustomCheckbox";
import CustomDialog from "../../customs/CustomDialog";
import CustomSelect from "../../customs/CustomSelect";
import CustomText from "../../customs/CustomText";
import SortComponent from "./helpers/SortComponent";
import { UserListView } from "./helpers/UserList";
import { CustomDateTimePicker } from "../../customs/CustomDatePicker";
import { User } from "../../../globals/user";

interface PopupTemplateOption {
  id: string;
  value: string;
}

type PopupTemplateQuestionType = "multiple" | "subjective";

export interface PopupTemplateQuestion {
  id: string;
  question: string;
  type: PopupTemplateQuestionType;
  options: PopupTemplateOption[];
  etc: number;
  min_options: number;
  max_options: number;
  min_subjective_length: number;
}

type PopupBenefitType = "none" | "coupon" | "point";

export interface PopupTemplate {
  id: string;
  title: string;
  description: string;
  questions: PopupTemplateQuestion[];
  activated_at: string | null;
  coupon_id: number;
  point: number;
  max_close_number: number;
  benefit_type: PopupBenefitType;
  coupon_name?: string;
  activate_starts?: string;
  order_by?: string;
}

interface PopupCondition {
  name: string;
  value: string;
  templates: PopupTemplate[];
}

interface PopupInfo {
  conditions: PopupCondition[];
}

interface ConditionRefreshProps {
  condition: PopupCondition;
  refresh: () => void;
  coupons: RawCoupon[];
}

interface PopupTemplateComponentProps {
  template: PopupTemplate;
  handleEdit: () => void;
  templates: PopupTemplate[];
  coupons: RawCoupon[];
  condition: string;
}

interface TemplateSummaryComponentProps {
  templateId: string;
  condition: string;
}

type TemplateSummaryQuestion =
  | TemplateSummaryQuestionMultiple
  | TemplateSummaryQuestionSubjective;

interface TemplateSummaryQuestionMultipleOption {
  option_id: string;
  option_value: string;
  count: number;
}

interface TemplateSummaryQuestionMultiple {
  question: string;
  type: "multiple";
  one_option_only: boolean;
  options: TemplateSummaryQuestionMultipleOption[];
  etcs: string[];
}

interface TemplateSummaryQuestionSubjective {
  question: string;
  type: "subjective";
  subjectives: string[];
}

interface TemplateSummary {
  questions: TemplateSummaryQuestion[];
  count: number;
  done_users: User[];
}

interface QuestionComponentProps {
  question: TemplateSummaryQuestion;
}

function QuestionComponent({ question }: QuestionComponentProps) {
  const [openEtc, setOpenEtc] = useState<boolean>(false);
  return (
    <div>
      <p className="font-header">{question.question}</p>
      {question.type === "multiple" &&
        (() => {
          const labels = question.options.map((option) => option.option_value);
          const data = {
            labels: labels.map((label) =>
              label.length > 10 ? label.slice(0, 10) + "..." : label
            ),
            datasets: [
              {
                label: "응답 개수",
                data: question.options.map((option) => option.count),
                backgroundColor: question.one_option_only
                  ? graphColors
                  : "#0062ff",
              },
            ],
          };
          if (question.etcs.length > 0) {
            data.labels.push("기타");
            data.datasets[0].data.push(question.etcs.length);
          }
          const sum = data.datasets[0].data.reduce(
            (prev, curr) => prev + curr,
            0
          );
          console.log(data);
          return (
            <Fragment>
              {question.etcs.length > 0 && (
                <div>
                  <CustomButton onClick={() => setOpenEtc(true)}>
                    기타 보기
                  </CustomButton>
                </div>
              )}
              {question.one_option_only && (
                <div>
                  <Pie
                    data={data}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            title: (context: any) => {
                              return labels[context[0].dataIndex];
                            },
                            label: (context: any) => {
                              return context.dataset.label + ": " + context.raw;
                            },
                            footer: (context: any) => {
                              return `${Math.ceil(
                                (context[0].raw / sum) * 100
                              )}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
              {!question.one_option_only && (
                <div>
                  <Bar
                    data={data}
                    options={{
                      scales: {
                        y: {
                          ticks: {
                            precision: 0,
                          },
                        },
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            title: (context: any) => {
                              return labels[context[0].dataIndex];
                            },
                            footer: (context: any) => {
                              return `${Math.ceil(
                                (context[0].raw / sum) * 100
                              )}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </Fragment>
          );
        })()}
      {question.type === "subjective" && (
        <Card
          variant="outlined"
          className="p-10"
          style={{ maxHeight: "30vh", overflow: "auto" }}
        >
          {question.subjectives.map((subjective, index) => (
            <div className="p-10" key={index}>
              <Card variant="outlined" className="p-10">
                <div className="font-sub">{subjective}</div>
              </Card>
            </div>
          ))}
        </Card>
      )}
      {question.type === "multiple" && question.etcs.length > 0 && (
        <CustomDialog open={openEtc}>
          <Card
            variant="outlined"
            className="p-10"
            style={{ maxHeight: "75vh", overflow: "auto" }}
          >
            {question.etcs.map((etc, index) => (
              <div className="p-10" key={index}>
                <Card variant="outlined" className="p-10">
                  <div className="font-sub">{etc}</div>
                </Card>
              </div>
            ))}
          </Card>
          <div className="d-flex justify-content-flex-end mt-10">
            <CustomButton onClick={() => setOpenEtc(false)}>닫기</CustomButton>
          </div>
        </CustomDialog>
      )}
    </div>
  );
}

interface IndividualComponentProps {
  templateId: string;
  users: User[];
  condition: string;
  currentProgramId: number;
}

interface PopupTemplateAnswer {
  option_id: string;
  option_value?: string;
  etc?: string;
  subjective?: string;
}

interface TemplateAnswer {
  question: string;
  type: PopupTemplateQuestionType;
  answers: PopupTemplateAnswer[];
}

function IndividualComponent({
  templateId,
  users,
  condition,
  currentProgramId,
}: IndividualComponentProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [templateAnswers, setTemplateAnswers] = useState<TemplateAnswer[]>([]);
  useEffect(() => {
    if (currentUser) {
      let url = `/popups/template/${templateId}/user/${currentUser.id!}/answers`;
      if (condition === "program_satisfaction") {
        if (currentProgramId === 0) return;
        url += `?program_id=${currentProgramId}`;
      }
      fetchDefaultWithCredential(url, "GET").then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(setTemplateAnswers);
      });
    }
  }, [templateId, currentUser, condition, currentProgramId]);
  return (
    <div>
      <div className="height-300" style={{ overflow: "auto" }}>
        <SortComponent<User>
          currentDataObject={currentUser}
          setCurrentDataObject={setCurrentUser}
          label="답변한 고객"
          data={users}
          listItemComponent={(user) => (
            <UserListView user={user} showNew={false} />
          )}
          abcSortAttributeFunc={(user) => user.name}
        />
      </div>
      {currentUser && (
        <div>
          {templateAnswers.map((templateAnswer, index) => (
            <div className="mt-15" key={index}>
              <div className="font-18 font-bold">
                질문: {templateAnswer.question} (
                {templateAnswer.type === "multiple" && "객관식"}
                {templateAnswer.type === "subjective" && "주관식"})
              </div>
              <div className="mt-10 font-14">
                {templateAnswer.type === "multiple" && (
                  <Fragment>
                    {templateAnswer.answers.map((answer, index) => (
                      <div className="p-10" key={index}>
                        {answer.option_id !== "etc" && answer.option_value}
                        {answer.option_id === "etc" && `기타: ${answer.etc}`}
                      </div>
                    ))}
                  </Fragment>
                )}
                {templateAnswer.type === "subjective" && (
                  <Fragment>
                    {templateAnswer.answers.map((answer, index) => (
                      <div className="p-10" key={index}>
                        {answer.subjective}
                      </div>
                    ))}
                  </Fragment>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

type TypeSelect = "summary" | "individual";

interface ProgramSatisfactionComponentProps {
  templateId: string;
  currentProgramId: number;
  setCurrentProgramId: (currentProgramId: number) => void;
}

function ProgramSatisfactionComponent({
  templateId,
  currentProgramId,
  setCurrentProgramId,
}: ProgramSatisfactionComponentProps) {
  const [availablePrograms, setAvailablePrograms] = useState<
    {
      id: number;
      program_header: string;
      program_name: string;
    }[]
  >([]);

  useEffect(() => {
    // query program list with names if condition is program satisfaction
    // program id first, then with that program id, send in same format
    fetchDefaultWithCredential(
      `/popups/template/${templateId}/programs`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setAvailablePrograms);
    });
  }, [templateId]);

  return (
    <CustomSelect
      label="프로그램 종류"
      name="program_type"
      items={[
        {
          label: "프로그램 선택",
          value: 0,
          disabled: true,
        },
        ...availablePrograms.map((availableProgram) => ({
          label: `${availableProgram.program_header} - ${availableProgram.program_name}`,
          value: availableProgram.id,
        })),
      ]}
      value={currentProgramId}
      handleChange={(value) => setCurrentProgramId(value as number)}
    />
  );
}

function TemplateSummaryComponent({
  templateId,
  condition,
}: TemplateSummaryComponentProps) {
  const [summary, setSummary] = useState<TemplateSummary | null>(null);
  const [typeSelect, setTypeSelect] = useState<TypeSelect>("summary");
  const [currentProgramId, setCurrentProgramId] = useState<number>(0);
  useEffect(() => {
    let url = `/popups/template/${templateId}/summary`;
    if (condition === "program_satisfaction") {
      if (currentProgramId === 0) return;
      url += `?program_id=${currentProgramId}`;
    }
    fetchDefaultWithCredential(url, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setSummary);
    });
  }, [templateId, condition, currentProgramId]);

  return (
    <div>
      {condition === "program_satisfaction" && (
        <ProgramSatisfactionComponent
          templateId={templateId}
          currentProgramId={currentProgramId}
          setCurrentProgramId={setCurrentProgramId}
        />
      )}
      {summary && (
        <Fragment>
          <div className="d-flex justify-content-space-between">
            <div style={{ flexGrow: 1 }}>
              <CustomSelect
                label="종류"
                name="type_select"
                items={[
                  {
                    label: "개요",
                    value: "summary",
                  },
                  {
                    label: "개별",
                    value: "individual",
                  },
                ]}
                value={typeSelect}
                handleChange={(value) => setTypeSelect(value as TypeSelect)}
              />
            </div>
            <p className="ml-30 font-sub">응답 {summary.count}개</p>
          </div>
          <div className="mt-10">
            {typeSelect === "summary" &&
              summary.questions.map((question, index) => (
                <QuestionComponent key={index} question={question} />
              ))}
            {typeSelect === "individual" && (
              <IndividualComponent
                templateId={templateId}
                users={summary.done_users}
                currentProgramId={currentProgramId}
                condition={condition}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

function PopupTemplateComponent({
  template,
  handleEdit,
  templates,
  coupons,
  condition,
}: PopupTemplateComponentProps) {
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <Fragment>
      <div>
        <CustomCheckbox
          border={false}
          checked={template.order_by !== undefined}
          handleChange={(checked) => {
            if (checked) {
              template.order_by = new Date().toISOString();
            } else {
              delete template.order_by;
            }
            handleEdit();
          }}
        >
          순서{" "}
          {(() => {
            if (!template.order_by) return "";
            const orderBys = templates
              .filter((template) => template !== null)
              .map((template) => template.order_by);
            orderBys.sort();
            const index = orderBys.findIndex(
              (order_by) => order_by === template.order_by
            );
            if (index === -1) return "";
            return `(${index + 1})`;
          })()}
        </CustomCheckbox>
      </div>
      <div className="mt-10 d-flex justify-content-space-between">
        <div>
          <CustomCheckbox
            border={false}
            checked={template.activated_at !== null}
            handleChange={(checked) => {
              if (checked) {
                template.activated_at = new Date().toISOString();
              } else {
                template.activated_at = null;
              }
              handleEdit();
            }}
          >
            활성화{" "}
            {(() => {
              if (!template.activated_at) return "";
              const activatedAts = templates
                .filter((template) => template !== null)
                .map((template) => template.activated_at);
              activatedAts.sort();
              const index = activatedAts.findIndex(
                (activatedAt) => activatedAt === template.activated_at
              );
              if (index === -1) return "";
              return `(${index + 1})`;
            })()}
          </CustomCheckbox>
        </div>
        <div>
          <CustomButton onClick={() => setOpenDetail(true)}>
            응답 지표보기
          </CustomButton>
        </div>
      </div>
      <div className="mt-10">
        <CustomDateTimePicker
          label="활성화 시점"
          value={(() => {
            if (template.activate_starts) {
              return new Date(template.activate_starts);
            }
            const date = new Date();
            date.setSeconds(0, 0);
            return date;
          })()}
          handleChange={(date) => {
            date.setSeconds(0, 0);
            template.activate_starts = date.toISOString();
            handleEdit();
          }}
          fullWidth
        />
      </div>
      <div className="mb-10">
        <CustomText
          label="제목"
          name="title"
          type="text"
          width="100%"
          defaultValue={template.title}
          handleBlur={(e) => {
            template.title = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mb-10">
        <CustomText
          label="세부 설명"
          name="description"
          type="text"
          width="100%"
          height="100px"
          multiline
          defaultValue={template.description ?? ""}
          handleBlur={(e) => {
            template.description = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomText
          label="최대 닫기 회수"
          name="max_close_number"
          type="number"
          width="100%"
          defaultValue={template.max_close_number}
          handleBlur={(e) => {
            template.max_close_number = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomSelect
          label="혜택 종류"
          name="benefit_type"
          items={[
            {
              label: "적용안함",
              value: "none",
            },
            {
              label: "쿠폰",
              value: "coupon",
            },
            {
              label: "적립금",
              value: "point",
            },
          ]}
          value={template.benefit_type}
          handleChange={(value) => {
            template.benefit_type = value as PopupBenefitType;
            handleEdit();
          }}
        />
      </div>
      {template.benefit_type === "point" && (
        <div className="mt-10">
          <CustomText
            label="지급할 적립금"
            name="point"
            type="number"
            width="100%"
            defaultValue={template.point}
            handleBlur={(e) => {
              template.point = parseInt(e.target.value) || 0;
              handleEdit();
            }}
          />
        </div>
      )}
      {template.benefit_type === "coupon" && (
        <div className="mt-10">
          <SortComponent
            currentDataObject={null}
            setCurrentDataObject={() => null}
            label="등록 쿠폰"
            data={coupons}
            listItemComponent={(coupon: RawCoupon) => (
              <div className="width-100-percentage">
                <div className="d-flex justify-content-space-between align-items-center">
                  <div>
                    <div className="font-12 font-bold">{coupon.meta.title}</div>
                    <div className="mt-5 font-10 font-bold">
                      {coupon.meta.value.toLocaleString()}
                      {coupon.meta.unit === "percentage" ? "%" : "원"}
                    </div>
                    <div className="mt-5 font-10 font-bold">
                      {formatDateTemplate(coupon.meta.expires_at)}까지
                    </div>
                  </div>
                  <div>
                    <CustomButton
                      onClick={() => {
                        if (template.coupon_id === coupon.id) {
                          template.coupon_id = 0;
                        } else {
                          template.coupon_id = coupon.id;
                        }
                        handleEdit();
                      }}
                      outlined={coupon.id !== template.coupon_id}
                    >
                      {coupon.id === template.coupon_id ? "연동됨" : "연동안됨"}
                    </CustomButton>
                  </div>
                </div>
              </div>
            )}
            abcSortAttributeFunc={(coupon) => coupon.meta.title}
            defaultSortType="recent"
          />
        </div>
      )}
      <div className="mt-10">
        <Card variant="outlined" className="p-15">
          <div className="d-flex justify-content-flex-end">
            <CustomButton
              className="background-red"
              onClick={() => {
                template.questions.push({
                  id: v4(),
                  question: "",
                  etc: 0,
                  options: [],
                  max_options: 0,
                  min_options: 0,
                  type: "multiple",
                  min_subjective_length: 0,
                });
                handleEdit();
              }}
            >
              질문 추가
            </CustomButton>
          </div>
          {template.questions.map((question, index) => (
            <Fragment key={question.id}>
              <Grid
                container
                columnSpacing={1}
                key={question.id}
                className="mt-10"
              >
                <Grid item xs>
                  <CustomText
                    label={"질문" + (index + 1)}
                    name="question"
                    type="text"
                    width="100%"
                    defaultValue={question.question}
                    handleBlur={(e) => {
                      question.question = e.target.value;
                      handleEdit();
                    }}
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    onClick={() => {
                      template.questions = template.questions.filter(
                        (currentQuestion) => currentQuestion.id !== question.id
                      );
                      handleEdit();
                    }}
                  >
                    질문 삭제
                  </CustomButton>
                </Grid>
              </Grid>
              <div className="mt-10">
                <CustomSelect
                  label="질문 종류"
                  name="question_type"
                  items={[
                    {
                      label: "객관식",
                      value: "multiple",
                    },
                    {
                      label: "주관식",
                      value: "subjective",
                    },
                  ]}
                  value={question.type ?? "multiple"}
                  handleChange={(value) => {
                    question.type = value as PopupTemplateQuestionType;
                    handleEdit();
                  }}
                />
              </div>
              {question.type === "multiple" && (
                <div className="mt-10">
                  <Card variant="outlined" className="p-15">
                    <div className="d-flex justify-content-flex-end">
                      <CustomButton
                        className="background-blue"
                        onClick={() => {
                          question.options.push({
                            id: v4(),
                            value: "",
                          });
                          handleEdit();
                        }}
                      >
                        선택지 추가
                      </CustomButton>
                    </div>
                    {question.options.map((option, index) => (
                      <Fragment key={option.id}>
                        <Grid container columnSpacing={1} className="mt-10">
                          <Grid item xs>
                            <CustomText
                              label={"선택지" + (index + 1)}
                              name="option"
                              type="text"
                              width="100%"
                              defaultValue={option.value}
                              handleBlur={(e) => {
                                option.value = e.target.value;
                                handleEdit();
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <CustomButton
                              onClick={() => {
                                question.options = question.options.filter(
                                  (currentOption) =>
                                    currentOption.id !== option.id
                                );
                                handleEdit();
                              }}
                            >
                              선택지 삭제
                            </CustomButton>
                          </Grid>
                        </Grid>
                      </Fragment>
                    ))}
                    <div className="mt-10">
                      <CustomText
                        label="기타 허용 최소 글자수(0일 경우 기타x)"
                        name="etc"
                        type="number"
                        width="100%"
                        defaultValue={question.etc}
                        handleBlur={(e) => {
                          question.etc = parseInt(e.target.value) || 0;
                          handleEdit();
                        }}
                      />
                    </div>
                    <div className="mt-10">
                      <CustomText
                        label="최소 선택지"
                        name="min_options"
                        type="number"
                        width="100%"
                        defaultValue={question.min_options}
                        handleBlur={(e) => {
                          question.min_options = parseInt(e.target.value) || 0;
                          handleEdit();
                        }}
                      />
                    </div>
                    <div className="mt-10">
                      <CustomText
                        label="최대 선택지"
                        name="max_options"
                        type="number"
                        width="100%"
                        defaultValue={question.max_options}
                        handleBlur={(e) => {
                          question.max_options = parseInt(e.target.value) || 0;
                          handleEdit();
                        }}
                      />
                    </div>
                  </Card>
                </div>
              )}
              {question.type === "subjective" && (
                <div className="mt-10">
                  <CustomText
                    label="주관식 최소 길이"
                    name="min_subjective_length"
                    type="number"
                    width="100%"
                    defaultValue={question.min_subjective_length}
                    handleBlur={(e) => {
                      question.min_subjective_length =
                        parseInt(e.target.value) || 0;
                      handleEdit();
                    }}
                  />
                </div>
              )}
            </Fragment>
          ))}
        </Card>
      </div>
      <CustomDialog open={openDetail} fullWidth>
        <div style={{ maxHeight: "80vh", overflow: "auto" }}>
          <TemplateSummaryComponent
            templateId={template.id}
            condition={condition}
          />
        </div>
        <div className="d-flex justify-content-flex-end mt-15">
          <div>
            <CustomButton onClick={() => setOpenDetail(false)}>
              닫기
            </CustomButton>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

function PopupConditionComponent({
  condition,
  refresh,
  coupons,
}: ConditionRefreshProps) {
  const handleEditCondition = () => {
    fetchDefaultWithCredential(
      `/popups/${condition.name}`,
      "PUT",
      condition
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refresh);
    });
  };

  interface PopupTemplateCardComponentProps {
    template: PopupTemplate;
  }

  const PopupTemplateCardComponent = ({
    template,
  }: PopupTemplateCardComponentProps) => {
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    return (
      <Card variant="outlined" className="p-10">
        <CustomButton onClick={() => setDeleteOpen(true)}>삭제</CustomButton>
        <div className="mt-10">
          <PopupTemplateComponent
            template={template}
            handleEdit={handleEditCondition}
            templates={condition.templates}
            coupons={coupons}
            condition={condition.name}
          />
        </div>
        <CustomDialog open={deleteOpen}>
          <p className="font-header">정말로 삭제하시겠습니까?</p>
          <div className="mt-10 d-flex justify-content-flex-end">
            <div>
              <CustomButton
                onClick={() => {
                  condition.templates = condition.templates.filter(
                    (currentTemplate) => currentTemplate.id !== template.id
                  );
                  handleEditCondition();
                  setDeleteOpen(false);
                }}
              >
                삭제
              </CustomButton>
            </div>
            <div className="ml-10">
              <CustomButton onClick={() => setDeleteOpen(false)}>
                닫기
              </CustomButton>
            </div>
          </div>
        </CustomDialog>
      </Card>
    );
  };

  return (
    <Fragment>
      <Card className="p-30" variant="outlined">
        <p className="font-header">질문지</p>
        <div className="d-flex justify-content-flex-end">
          <div>
            <CustomButton
              onClick={() => {
                condition.templates.push({
                  id: v4(),
                  title: "",
                  questions: [],
                  activated_at: null,
                  point: 0,
                  coupon_id: 0,
                  max_close_number: 0,
                  benefit_type: "none",
                  description: "",
                  activate_starts: (() => {
                    const date = new Date();
                    date.setSeconds(0, 0);
                    return date.toISOString();
                  })(),
                });
                handleEditCondition();
              }}
            >
              추가
            </CustomButton>
          </div>
        </div>
        {condition.templates
          .sort((a, b) => {
            if (!a.order_by && !b.order_by) return 0;
            if (!a.order_by) return 1;
            if (!b.order_by) return -1;
            return (
              new Date(a.order_by).getTime() - new Date(b.order_by).getTime()
            );
          })
          .map((template) => (
            <div className="mt-10" key={template.id}>
              <PopupTemplateCardComponent template={template} />
            </div>
          ))}
      </Card>
    </Fragment>
  );
}

interface RawCoupon {
  id: number;
  created_at: string;
  meta: {
    at_least_to_apply: number;
    expires_at: string;
    title: string;
    type: "default" | "code" | "package" | "select";
    unit: "value" | "percentage";
    value: number;
  };
}

function AdminPagesPopup() {
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);
  const [userCoupons, setUserCoupons] = useState<RawCoupon[]>([]);
  const [currentCondition, setCurrentCondition] = useState<number>(-1);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential("/popups", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setPopupInfo);
    });
    fetchDefaultWithCredential("/coupons/user", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setUserCoupons);
    });
  }, [render]);

  const filteredCoupons = userCoupons.filter(
    (userCoupon) => new Date(userCoupon.meta.expires_at) > new Date()
  );

  if (!popupInfo) return null;
  return (
    <Grid container columnSpacing={2}>
      <Grid item sm xs={12}>
        <Card className="p-30" variant="outlined">
          <p className="font-header">조건</p>
          {popupInfo.conditions.map((condition, index) => (
            <Card
              className="p-30 mb-15 cursor-pointer"
              key={index}
              style={{
                backgroundColor:
                  index === currentCondition ? "lightgray" : "inherit",
              }}
              onClick={() => setCurrentCondition(index)}
            >
              {condition.value}
            </Card>
          ))}
        </Card>
      </Grid>
      <Grid item sm xs={12}>
        {currentCondition !== -1 && (
          <PopupConditionComponent
            condition={popupInfo.conditions[currentCondition]}
            refresh={() => setRender(render + 1)}
            coupons={filteredCoupons}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default AdminPagesPopup;
