import React, { ReactNode } from "react";
import { responsivePixel } from "../Home";

interface PropType {
  children: ReactNode;
  style?: object;
  className?: string;
}

function CustomBlueInfo({ children, style, className }: PropType) {
  return (
    <div
      className={[
        "font-12 text-white font-bold p-4 background-blue",
        className,
      ].join(" ")}
      style={{
        borderRadius: responsivePixel(4),
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default CustomBlueInfo;
