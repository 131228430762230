import React, { Fragment, useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Feedbacks } from "../../interfaces";
import CustomText from "../customs/CustomText";
import CustomButton from "../customs/CustomButton";
import CustomSelect from "../customs/CustomSelect";
import CustomCheckbox from "../customs/CustomCheckbox";
import CustomDialog, { CustomInstructDialog } from "../customs/CustomDialog";
import { fetchDefaultWithCredential } from "../../utils";
import { useHistory, useLocation } from "react-router";
import HeaderBanner from "../HeaderBanner";
import "./Sign.scss";
import { usePrivacyAgreement } from "../Policies";
import { useCertification } from "../Pay";
import { SnackbarContext } from "../../globals/components/ComponentsWrapper";
export const jobList = [
  {
    label: "선택",
    value: "",
    disabled: true,
  },
  {
    label: "직장인",
    value: "직장인",
  },
  {
    label: "학생",
    value: "학생",
  },
  {
    label: "학부모",
    value: "학부모",
  },
  {
    label: "주부",
    value: "주부",
  },
  {
    label: "취업준비생",
    value: "취업준비생",
  },
  {
    label: "개인사업자",
    value: "개인사업자",
  },
  {
    label: "프리랜서",
    value: "프리랜서",
  },
  {
    label: "교육관련자",
    value: "교육관련자",
  },
  {
    label: "기타 (직접 입력)",
    value: "기타 (직접 입력)",
  },
];

export const howPhilousList = [
  {
    label: "선택",
    value: "",
    disabled: true,
  },
  { label: "유튜브", value: "유튜브" },
  { label: "인스타그램", value: "인스타그램" },
  { label: "블로그/카페", value: "블로그/카페" },
  { label: "페이스북", value: "페이스북" },
  { label: "조니토크 방송", value: "조니토크 방송" },
  {
    label: "신문/뉴스기사",
    value: "신문/뉴스기사",
  },
  { label: "지인 추천", value: "지인 추천" },
  { label: "기타 (직접 입력)", value: "기타 (직접 입력)" },
];

interface SignupForm {
  password: string;
  confirm_password: string;
  private_info: boolean;
  marketing_info: boolean;
  email: string;
  name: string;
  phone: string;
  gender: string;
  date_of_birth: string;
  job: string;
  job_detail?: string;
  how_philous: string;
  how_philous_detail?: string;
}

export const formatPhone = (phoneNumber: string) => {
  const phoneNumberLength = phoneNumber.length;
  // check for greater than 3, less than 8
  if (phoneNumberLength > 3 && phoneNumberLength < 8) {
    return (
      phoneNumber.substring(0, 3) +
      "-" +
      phoneNumber.substring(3, phoneNumberLength)
    );
  }
  // check for greater than 7
  if (phoneNumberLength > 7) {
    return (
      phoneNumber.substring(0, 3) +
      "-" +
      phoneNumber.substring(3, 7) +
      "-" +
      phoneNumber.substring(7, 11)
    );
  }
  return phoneNumber;
};

interface SignupFormComponentProps {
  noAuthorize?: boolean;
  existingForm?: any;
}

export function SignupFormComponent({
  noAuthorize,
  existingForm,
}: SignupFormComponentProps) {
  const [form, setForm] = useState<SignupForm>({
    email: "",
    password: "",
    confirm_password: "",
    name: "",
    phone: "",
    gender: "",
    private_info: false,
    marketing_info: true,
    date_of_birth: "",
    job: "",
    how_philous: "",
  });
  const [feedbacks, setFeedbacks] = useState<Feedbacks>({});
  const [openSignupCompletion, setOpenSignupCompletion] =
    useState<boolean>(false);
  const [privateInfoDialog, setPrivateInfoDialog] = useState<boolean>(false);
  const [agreementDialog, setAgreementDialog] = useState<boolean>(false);
  const history = useHistory();
  const { handleCertify } = useCertification();
  const [authorized, setAuthorized] = useState<boolean>(
    noAuthorize ? true : false
  );
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  interface RecommendVerified {
    verified: boolean;
    name?: string;
  }

  const [recommendVerified, setRecommendVerified] =
    useState<RecommendVerified | null>(null);
  const location = useLocation();

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    delete feedbacks[target.name];
    setFeedbacks({
      ...feedbacks,
    });
    setForm({
      ...form,
      [target.name]: target.value,
    });
  };

  const certify = () => {
    handleCertify(
      "signup",
      ({ date_of_birth, name, gender, phone }) => {
        setForm({
          ...form,
          date_of_birth,
          name,
          gender,
          phone,
        });
        setAuthorized(true);
      },
      () => {
        setAuthorized(false);
      }
    );
  };

  useEffect(() => {
    if (!location.state || noAuthorize) return;
    const { mobile_verified } = location.state as any;
    if (!mobile_verified) {
      showWarningSnackbar("인증에 실패하셨습니다");
      setAuthorized(false);
    } else {
      showSuccessSnackbar("인증에 성공 하셨습니다");
      setAuthorized(true);
      const { gender, date_of_birth, phone, name } = location.state as any;
      setForm((form) => ({
        ...form,
        gender,
        phone,
        name,
        date_of_birth,
      }));
    }
  }, [location, noAuthorize, showSuccessSnackbar, showWarningSnackbar]);

  useEffect(() => {
    if (existingForm) {
      setForm((form) => ({
        ...form,
        ...existingForm,
      }));
    }
  }, [existingForm]);

  const privacyAgreement = usePrivacyAgreement();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchDefaultWithCredential(`/auth/signup`, "POST", form)
      .then((res) => {
        if (!res.ok) {
          res.json().then((data) => {
            let tempFeed = {};
            for (const [field, message] of Object.entries(data)) {
              if (message) {
                tempFeed = {
                  ...tempFeed,
                  [field]: {
                    stauts: false,
                    message: message,
                  },
                };
              }
            }
            setFeedbacks(tempFeed);
            return;
          });
        } else {
          setOpenSignupCompletion(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleVerifyRecommend = () => {
    if (form.how_philous_detail) {
      fetchDefaultWithCredential(`/recommend/verify`, "POST", {
        code: form.how_philous_detail,
      }).then((res) => {
        if (!res.ok) {
          return res.json().then(() => {
            return showWarningSnackbar("인증에 실패하였습니다");
          });
        }
        return res
          .json()
          .then(setRecommendVerified)
          .then(() => showSuccessSnackbar("추천인이 인증되었습니다"));
      });
    }
  };

  if (!privacyAgreement) return null;
  return (
    <Fragment>
      <form onBlur={handleChange} onSubmit={handleSubmit}>
        <p className="font-14">
          *해외 거주자 및 외국인은 고객문의 →{" "}
          <a
            href="https://pf.kakao.com/_xnsACT/chat"
            target="_blank"
            rel="noreferrer"
          >
            문의하기
          </a>
          를 통해 가입이 가능합니다.
        </p>
        <div>
          <div className="font-sub font-bold">*휴대전화 인증</div>
          <div className="d-flex justify-content-center">
            <CustomButton onClick={certify} disabled={authorized}>
              인증하기
            </CustomButton>
          </div>
        </div>
        {authorized && (
          <Fragment>
            <div className="mt-15">
              <CustomText
                width="100%"
                type="email"
                label="*이메일"
                name="email"
                feedback={feedbacks.email}
              />
            </div>
            <div className="mt-15">
              <CustomText
                width="100%"
                type="password"
                label="*비밀번호"
                name="password"
                placeholder="영문 대/소문자, 숫자, 특수문자 포함 8~16자리"
                feedback={feedbacks.password}
              />
            </div>
            <div className="mt-15">
              <CustomText
                width="100%"
                type="password"
                label="*비밀번호 재확인"
                name="confirm_password"
                feedback={feedbacks.confirm_password}
              />
            </div>
            <div className="mt-15">
              <CustomSelect
                name="job"
                label="*직업"
                items={jobList}
                value={form.job}
                feedback={feedbacks.job}
                handleChange={(value) => {
                  form.job = value as string;
                  setForm({ ...form });
                }}
              />
            </div>
            {form.job === "기타 (직접 입력)" && (
              <div className="mt-15">
                <CustomText
                  width="100%"
                  type="text"
                  label="*직업 직접 입력"
                  name="job_detail"
                  feedback={feedbacks.job_detail}
                />
              </div>
            )}
            <div className="mt-15">
              <CustomSelect
                name="how_philous"
                label="*오시게 된 경로"
                items={howPhilousList}
                value={form.how_philous}
                feedback={feedbacks.how_philous}
                handleChange={(value) => {
                  form.how_philous = value as string;
                  setForm({ ...form });
                }}
              />
            </div>
            {form.how_philous === "지인 추천" && (
              <div className="mt-15">
                <div className="d-flex align-items-flex-end">
                  <div style={{ flexGrow: 1 }}>
                    <CustomText
                      width="100%"
                      type="text"
                      label={
                        recommendVerified?.verified ? "추천인" : "*추천인 코드"
                      }
                      name="how_philous_detail"
                      feedback={feedbacks.how_philous_detail}
                      disabled={recommendVerified?.verified ?? false}
                      defaultValue={recommendVerified?.name ?? undefined}
                      key={Number(recommendVerified?.verified)}
                    />
                  </div>
                  <div className="ml-10">
                    <CustomButton
                      className="height-50"
                      onClick={handleVerifyRecommend}
                    >
                      인증하기
                    </CustomButton>
                  </div>
                </div>
              </div>
            )}
            {form.how_philous === "기타 (직접 입력)" && (
              <div className="mt-15">
                <CustomText
                  width="100%"
                  type="text"
                  label="*오시게 된 경로 직접 입력"
                  name="how_philous_detail"
                  feedback={feedbacks.how_philous_detail}
                />
              </div>
            )}
            <div className="mt-15">
              <CustomCheckbox
                checked={form.private_info}
                handleChange={(checked) => {
                  form.private_info = checked;
                  setForm({ ...form });
                }}
                feedback={feedbacks.private_info}
              >
                <Typography style={{ fontSize: "14px" }}>
                  <span
                    style={{
                      color: "#2699fb",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setAgreementDialog(true)}
                  >
                    이용약관
                  </span>{" "}
                  및{" "}
                  <span
                    style={{
                      color: "#2699fb",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setPrivateInfoDialog(true)}
                  >
                    개인정보 처리방침 동의
                  </span>{" "}
                  (필수)
                </Typography>
              </CustomCheckbox>
            </div>
            <div className="mt-15">
              <CustomCheckbox
                checked={form.marketing_info}
                handleChange={(checked) => {
                  form.marketing_info = checked;
                  setForm({ ...form });
                }}
                feedback={feedbacks.marketing_info}
              >
                <Typography style={{ fontSize: "14px" }}>
                  마케팅 정보 수집 활용 동의 (선택)
                </Typography>
              </CustomCheckbox>
              <p className="mt-10 font-small text-blue">
                마케팅 정보 수집 활용에 동의하지 않으시면 개별 쿠폰 발급 및 회원
                혜택이 제한됩니다.
              </p>
            </div>
            <div className="mt-15">
              <CustomButton
                type="submit"
                fullWidth
                disabled={(() => {
                  if (!authorized) return true;
                  if (!form.private_info) return true;
                  if (recommendVerified && !recommendVerified.verified)
                    return true;
                  return false;
                })()}
              >
                회원가입 완료
              </CustomButton>
            </div>
          </Fragment>
        )}
      </form>
      <CustomDialog open={openSignupCompletion}>
        {noAuthorize ? (
          <div className="text-align-center">
            <p className="font-header">회원 등록이 완료되었습니다</p>
            <CustomButton onClick={() => setOpenSignupCompletion(false)}>
              확인
            </CustomButton>
          </div>
        ) : (
          <div className="text-align-center">
            <p className="font-header">
              필로어스의 회원이 되신 것을 축하드립니다!
            </p>
            <p className="font-sub">
              당신을 성장시켜 줄 다양한 프로그램을 경험해보세요!
            </p>
            <CustomButton onClick={() => history.replace("/signin")}>
              로그인 하기
            </CustomButton>
          </div>
        )}
      </CustomDialog>
      <CustomInstructDialog
        open={agreementDialog}
        handleClose={() => setAgreementDialog(false)}
        header="이용약관"
      >
        {privacyAgreement.agreement.content}
      </CustomInstructDialog>
      <CustomInstructDialog
        open={privateInfoDialog}
        handleClose={() => setPrivateInfoDialog(false)}
        header="개인정보 처리방침"
      >
        {privacyAgreement.privacy.content}
      </CustomInstructDialog>
    </Fragment>
  );
}

function Signup() {
  return (
    <Fragment>
      <HeaderBanner
        background="assets/signup-desktop.png"
        mobile="assets/signup-mobile.png"
      >
        <div className="text-align-center">
          <div className="font-header">회원가입</div>
          <p className="font-sub">
            필로어스에 오신 것을
            <br />
            환영합니다!
          </p>
        </div>
      </HeaderBanner>
      <div className="p-30">
        <div className="status-content">
          <SignupFormComponent />
        </div>
      </div>
    </Fragment>
  );
}

export default Signup;
