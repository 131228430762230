import React from "react";
import { Grid, Badge } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Notification } from "../../../globals/notification";

export interface PathMetaMetaSubField {
  id?: number;
  title: string;
  endpoint: string;
  component: (props: any) => JSX.Element | null;
  show?: boolean;
  notification?: string;
}

export interface PathMetaMeta {
  prefix: string;
  subFields: PathMetaMetaSubField[];
}

export interface PathMeta {
  title: string;
  metas: PathMetaMeta;
  role?: string[];
  id?: number;
  show?: boolean;
  showCondition?: (...args: any[]) => boolean;
}

function useBadge(notifications: Notification) {
  const getBadge = (key: string) => {
    if (!notifications[key]) return 0;
    return notifications[key].total;
  };

  return { getBadge };
}

interface MenuViewProps {
  prefix: string;
  notifications: Notification;
  pathMetas: PathMeta[];
  label: string;
}

export function getPathMetas(pathMetas: PathMeta[]) {
  pathMetas.forEach((pathMeta, i) => {
    const subFields = pathMeta.metas.subFields;
    pathMeta.id = i;
    subFields.forEach((subField, j) => {
      subField.id = j + 1;
    });
  });
  return pathMetas;
}

function useSubfield(prefix: string, pathMetas: PathMeta[]) {
  const location = useLocation();
  const path = location.pathname;
  const index = +`${prefix}/page`.length;
  for (const pathMeta of pathMetas) {
    const prefix = pathMeta.metas.prefix;
    const subFields = pathMeta.metas.subFields;
    for (const subField of subFields) {
      const id = `${prefix}${subField.endpoint}`;
      if (path.substring(index) === id) {
        return subField;
      }
    }
  }
  return null;
}

function MenuView({ prefix, notifications, pathMetas, label }: MenuViewProps) {
  const { getBadge } = useBadge(notifications);
  const currentSubfield = useSubfield(prefix, pathMetas);
  return (
    <Grid container>
      <Grid item sm={2} xs={4}>
        <div style={{ backgroundColor: "#292929" }}>
          <div
            className="font-sub user-select-none"
            style={{ padding: "12px", color: "lightgray" }}
          >
            {label}
          </div>
          {pathMetas
            .filter((pathMeta) => pathMeta.show)
            .map((pathMeta, index) => (
              <div key={index}>
                <div
                  className="font-sub user-select-none"
                  style={{ padding: "12px", color: "lightgray" }}
                >
                  {pathMeta.id}. {pathMeta.title}
                </div>
                {pathMeta.metas.subFields
                  .filter((subField) => subField.show)
                  .map((subField, index) => (
                    <RouterLink
                      to={`${prefix}/page${pathMeta.metas.prefix}${subField.endpoint}`}
                      key={index}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{
                          color:
                            subField === currentSubfield ? "white" : "gray",
                          padding: "12px",
                          paddingLeft: "30px",
                          backgroundColor:
                            subField === currentSubfield
                              ? "darkgray"
                              : undefined,
                        }}
                      >
                        {(() => {
                          const title = `${subField.id}. ${subField.title}`;
                          const notification = subField.notification;
                          if (notification) {
                            return (
                              <Badge
                                badgeContent={getBadge(notification)}
                                color="secondary"
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                {title}
                              </Badge>
                            );
                          }
                          return title;
                        })()}
                      </div>
                    </RouterLink>
                  ))}
              </div>
            ))}
        </div>
      </Grid>
      <Grid item sm={10} xs={8}>
        <div className="p-30">
          {currentSubfield && currentSubfield.show && (
            <currentSubfield.component />
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default MenuView;
