import React, { useRef } from "react";
import Swiper, { ReactIdSwiperChildren } from "react-id-swiper";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

interface PropType {
  children: ReactIdSwiperChildren;
  slides: number;
  onSlideChange?: (index: number) => void;
}

function CustomSwiper({ children, slides, onSlideChange }: PropType) {
  const swiperRef = useRef<HTMLDivElement | null>(null);
  return (
    <Swiper
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      slidesPerView={slides}
      ref={swiperRef}
      on={{
        slideChange: (swiper) => {
          if (onSlideChange) {
            onSlideChange(swiper.activeIndex);
          }
        },
      }}
    >
      {children}
    </Swiper>
  );
}

export default CustomSwiper;
