import { Avatar, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import ProgramReviewCard from "../programs/ProgramReviewCard";
import { Tutor } from "../../interfaces";
import { fetchWithCredential } from "../../utils";
import { SERVER } from "../../config";
import { useLocation } from "react-router-dom";
import CustomBlueInfo from "../customs/CustomBlueInfo";
import { Pagination } from "@mui/material";
import {
  AdminUserReview,
  mapAdminUserReviewToCardProps,
} from "../admins/pages/users/Review";
import { responsivePercentage, responsivePixel } from "../Home";

interface TutorDetailProps {
  tutor: Tutor;
  user_program_reviews: AdminUserReview[];
  total_pages: number;
}

export function useTutorProgramReviews(tutor_id: number, page: number) {
  const [tutorDetail, setTutorDetail] = useState<TutorDetailProps | null>(null);

  const pageAmount = 12;

  const refresh = useCallback(() => {
    return fetchWithCredential(
      `${SERVER}/user/tutor/${tutor_id}/program/reviews?page=${page}&amount=${pageAmount}`,
      "GET"
    )
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setTutorDetail);
  }, [tutor_id, page]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { tutorDetail, refresh };
}

function TutorDetail() {
  const location = useLocation();
  const splittedLocation = location.pathname.split("/");
  const tutorId = parseInt(splittedLocation[splittedLocation.length - 1]);
  const [page, setPage] = useState<number>(1);
  const { tutorDetail, refresh } = useTutorProgramReviews(tutorId, page);
  if (!tutorDetail) return null;
  return (
    <div className="text-align-center p-50">
      {tutorDetail.tutor.tutor_type1_label &&
      tutorDetail.tutor.tutor_type2_label ? (
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="d-flex justify-content-flex-end">
              <CustomBlueInfo className="font-14">
                {tutorDetail.tutor.tutor_type1_label}
              </CustomBlueInfo>
            </div>
          </Grid>
          <Grid item xs>
            <div className="d-flex justify-content-flex-start">
              <CustomBlueInfo className="font-14">
                {tutorDetail.tutor.tutor_type2_label}
              </CustomBlueInfo>
            </div>
          </Grid>
        </Grid>
      ) : (
        (tutorDetail.tutor.tutor_type1_label ||
          tutorDetail.tutor.tutor_type2_label) && (
          <div className="d-flex justify-content-center">
            <CustomBlueInfo className="font-14">
              {tutorDetail.tutor.tutor_type1_label ||
                tutorDetail.tutor.tutor_type2_label}
            </CustomBlueInfo>
          </div>
        )
      )}
      <div className="mt-30">
        <Avatar className="width-200 height-200 margin-center">
          {tutorDetail.tutor.tutor_avatar ? (
            <img
              src={tutorDetail.tutor.tutor_avatar}
              alt=""
              className="width-100-percentage height-100-percentage"
              style={{
                borderRadius: "50%",
              }}
            />
          ) : (
            <PersonIcon className="width-100-percentage height-100-percentage p-10 text-gray" />
          )}
        </Avatar>
      </div>
      <div className="mt-30">
        <p className="font-20 font-bold">{tutorDetail.tutor.tutor_name}</p>
      </div>
      <div className="mt-30 d-flex justify-content-center">
        <div
          className="p-30"
          style={{
            backgroundColor: "lightgray",
            borderRadius: responsivePixel(30),
          }}
        >
          <div className="text-secondary font-12">튜터 소개</div>
          <p className="font-15 font-bold">
            "{tutorDetail.tutor.introduction}"
          </p>
        </div>
      </div>
      <p className="text-align-center font-16 text-secondary">
        {tutorDetail.tutor.tutor_name} 튜터와 함께 하신 분들의 후기
      </p>
      <div
        className="margin-center"
        style={{
          width: responsivePercentage(75),
        }}
      >
        <Grid container spacing={4}>
          {tutorDetail.user_program_reviews.map(
            (user_program_review, index) => (
              <Grid item xs={12} sm={3} key={index}>
                <ProgramReviewCard
                  {...mapAdminUserReviewToCardProps(
                    user_program_review,
                    refresh
                  )}
                />
              </Grid>
            )
          )}
        </Grid>
        <div className="mt-20 d-flex justify-content-center">
          <Pagination
            page={page}
            count={tutorDetail.total_pages}
            color="standard"
            onChange={(event, value) => setPage(value)}
          />
        </div>
      </div>
    </div>
  );
}

export default TutorDetail;
