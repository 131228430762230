import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import CustomDialog from "../../../customs/CustomDialog";
import CustomButton from "../../../customs/CustomButton";
import CustomCheckbox from "../../../customs/CustomCheckbox";
import CustomText from "../../../customs/CustomText";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  formatTimeTemplate,
} from "../../../../utils";
import { putObjectFromList } from "../../../../hooks";

export interface Deactivation {
  id: number;
  created_at: string;
  content: string;
  read: boolean;
  user_id: number;
  user_name: string;
  user_email: string;
  deactivated: boolean;
}

export function useDeactivations() {
  const [deactivations, setDeactivations] = useState<Deactivation[]>([]);

  const refresh = useCallback(() => {
    return fetchDefaultWithCredential("/deactivations", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setDeactivations);
    });
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const patchDeactivationRead = (deactivationId: number, read: boolean) => {
    return fetchDefaultWithCredential(
      `/deactivations/${deactivationId}/read`,
      "PATCH",
      {
        read,
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then(refresh);
  };

  return { refresh, deactivations, patchDeactivationRead };
}

export function useUserDeletions() {
  const [userDeletions, setUserDeletions] = useState<UserDeletion[]>([]);

  useEffect(() => {
    fetchDefaultWithCredential("/user/deletions", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setUserDeletions);
    });
  }, []);

  const patchUserDeletionRead = (userDeletionId: number, read: boolean) => {
    return fetchDefaultWithCredential(
      `/user/deletions/${userDeletionId}/read`,
      "PATCH",
      {
        read,
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((data) => setUserDeletions(putObjectFromList(userDeletions, data)));
  };

  return { userDeletions, patchUserDeletionRead };
}

export interface UserDeletion {
  id: number;
  created_at: string;
  read: boolean;
  user_id: number;
  user_name: string;
  user_email: string;
}

interface UserDeletionCardComponentProps {
  userDeletion: UserDeletion;
  patchUserDeletionRead: (
    userDeletionId: number,
    read: boolean
  ) => Promise<void>;
}

export function UserDeletionCardComponent({
  userDeletion,
  patchUserDeletionRead,
}: UserDeletionCardComponentProps) {
  return (
    <Fragment>
      <Card style={{ marginBottom: "50px", padding: "15px" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <p className="mt-10 mb-10 font-sub">
              {`${formatDateTemplate(
                userDeletion.created_at!
              )} ${formatTimeTemplate(userDeletion.created_at!)}`}
            </p>
            <p className="font-15 font-bold">ID: {userDeletion.user_id}</p>
            <p className="font-15 font-bold">
              이메일: {userDeletion.user_email}
            </p>
            <p className="font-15 font-bold">이름: {userDeletion.user_name}</p>
          </Grid>
          <Grid item>
            <div className="d-flex align-items-center">
              <div>
                <CustomCheckbox
                  checked={userDeletion.read}
                  handleChange={(checked) => {
                    patchUserDeletionRead(userDeletion.id!, checked);
                  }}
                >
                  읽음표시
                </CustomCheckbox>
              </div>
            </div>
          </Grid>
        </Grid>
        <CustomText
          label="내용"
          name="content"
          type="text"
          width="100%"
          readOnly
          defaultValue="유저 삭제 한달전 알림입니다"
          style={{ backgroundColor: "white" }}
        />
      </Card>
    </Fragment>
  );
}

interface DeactivationCardComponentProps {
  refresh: () => Promise<void>;
  deactivation: Deactivation;
  patchDeactivationRead: (
    deactivationId: number,
    read: boolean
  ) => Promise<void>;
}

export function DeactivationCardComponent({
  refresh,
  deactivation,
  patchDeactivationRead,
}: DeactivationCardComponentProps) {
  const [deactivationOpen, setDeactivationOpen] = useState<boolean>(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState<boolean>(false);

  const handleDeactivate = () => {
    fetchDefaultWithCredential(
      `/user/${deactivation.user_id}/deactivate`,
      "PATCH"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res
        .json()
        .then(refresh)
        .then(() => setDeactivationOpen(false));
    });
  };

  const handleDeleteUser = () => {
    fetchDefaultWithCredential(`/user/${deactivation.user_id}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res
          .json()
          .then(refresh)
          .then(() => setDeleteUserOpen(false));
      }
    );
  };

  return (
    <Fragment>
      <Card style={{ marginBottom: "50px", padding: "15px" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <p className="mt-10 mb-10 font-sub">
              {`${formatDateTemplate(
                deactivation.created_at!
              )} ${formatTimeTemplate(deactivation.created_at!)}`}
            </p>
            <p className="font-15 font-bold">ID: {deactivation.user_id}</p>
            <p className="font-15 font-bold">
              이메일: {deactivation.user_email}
            </p>
            <p className="font-15 font-bold">이름: {deactivation.user_name}</p>
          </Grid>
          <Grid item>
            <div className="d-flex align-items-center">
              <div>
                <CustomButton onClick={() => setDeactivationOpen(true)}>
                  {deactivation.deactivated ? "휴면 해제" : "휴면 등록"}
                </CustomButton>
              </div>
              <div className="ml-10">
                <CustomCheckbox
                  checked={deactivation.read}
                  handleChange={(checked) => {
                    patchDeactivationRead(deactivation.id!, checked);
                  }}
                >
                  읽음표시
                </CustomCheckbox>
              </div>
              <div className="ml-10">
                <CustomButton onClick={() => setDeleteUserOpen(true)}>
                  탈퇴
                </CustomButton>
              </div>
            </div>
          </Grid>
        </Grid>
        <CustomText
          label="내용"
          name="content"
          type="text"
          width="100%"
          readOnly
          defaultValue={deactivation.content}
          style={{ backgroundColor: "white" }}
        />
      </Card>
      <ConfirmingDialog
        open={deactivationOpen}
        handleClose={() => setDeactivationOpen(false)}
        questionaire={
          deactivation.deactivated
            ? "정말로 휴면 해제 하시겠습니까?"
            : "정말로 휴면 등록 하시겠습니까?"
        }
        handleClick={handleDeactivate}
        label={deactivation.deactivated ? "휴면 해제" : "휴면 등록"}
      />
      <ConfirmingDialog
        open={deleteUserOpen}
        handleClose={() => setDeleteUserOpen(false)}
        questionaire="정말로 유저 회원 탈퇴를 진행 하시겠습니까?"
        handleClick={handleDeleteUser}
        label="회원 탈퇴 진행"
      />
    </Fragment>
  );
}

interface ConfirmingDialogProps {
  open: boolean;
  handleClose: () => void;
  questionaire: string;
  label: string;
  handleClick: () => void;
}

function ConfirmingDialog({
  open,
  handleClose,
  label,
  questionaire,
  handleClick,
}: ConfirmingDialogProps) {
  return (
    <CustomDialog open={open}>
      <p className="font-20 font-bold">{questionaire}</p>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <CustomButton onClick={handleClick}>{label}</CustomButton>
        </Grid>
        <Grid item style={{ marginLeft: "10px" }}>
          <CustomButton onClick={handleClose}>닫기</CustomButton>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}

function AdminPagesEditsUsersDeactivation() {
  const { refresh, deactivations, patchDeactivationRead } = useDeactivations();
  const { userDeletions, patchUserDeletionRead } = useUserDeletions();

  return (
    <div className="p-30">
      <p className="font-15 font-bold">유저 삭제</p>
      {userDeletions.map((userDeletion, index) => (
        <UserDeletionCardComponent
          key={index}
          userDeletion={userDeletion}
          patchUserDeletionRead={patchUserDeletionRead}
        />
      ))}
      <p className="font-15 font-bold">비활성화</p>
      {deactivations.map((deactivation, index) => (
        <DeactivationCardComponent
          key={index}
          refresh={refresh}
          deactivation={deactivation}
          patchDeactivationRead={patchDeactivationRead}
        />
      ))}
    </div>
  );
}

export default AdminPagesEditsUsersDeactivation;
