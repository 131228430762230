import React, { Fragment, useEffect, useState } from "react";
import {
  fetchDefaultWithCredential,
  formatDashedDate,
  isMobile,
} from "../../utils";
import { CustomStandardSelect } from "../customs/CustomSelect";
import { HomeButton, HomeDialog, textColor, widthPercentage } from "../Home";
import { OrderByType } from "./Review";
import { CustomAvatarWithLocation } from "../customs/CustomAvatar";
import CustomPagination from "../customs/CustomPagination";
import { ReactComponent as LockIcon } from "../../svg/lock_icon.svg";
import { ReactComponent as BigQuote } from "../../svg/big_quote.svg";
import { useHistory } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Hidden } from "@mui/material";
import InfoBar from "./InfoBar";
import { useUser } from "../../globals/user";
import { useNotifications } from "../../globals/notification";

interface ProgramReport {
  program_id: number;
  program_image: string;
  program_name: string;
  dates: string[];
  definition?: string;
  tutor_name: string;
  tutor_image: string;
  feedback: string;
}

interface ProgramReportMeta {
  total: number;
  program_reports: ProgramReport[];
  total_pages: number;
}

// interface ReportComponentProps {
//   program: Program;
// }

// function ReportComponent({ program }: ReportComponentProps) {
//   const { refreshNotification } = useContext(GlobalContext);
//   useEffect(() => {
//     fetchDefaultWithCredential(
//       `/program/${program.id}/reports/read`,
//       "PATCH"
//     ).then((res) => {
//       if (!res.ok) {
//         return res.json().then(({ error }) => {
//           throw new Error(error);
//         });
//       }
//       return res.json().then(refreshNotification);
//     });
//   }, [refreshNotification, program]);

//   return (
//     <Fragment>
//       <img
//         alt=""
//         draggable={false}
//         className="width-150"
//         src={program.program_image}
//       />
//       <div className="mt-10">피드백: {program.feedback}</div>
//       {program.definition && (
//         <div className="mt-10">철학: {program.definition}</div>
//       )}
//     </Fragment>
//   );
// }

function dateString(dates: string[]) {
  let startDate = formatDashedDate(new Date());
  if (dates.length > 0) {
    startDate = dates[0];
  }
  const splitted = startDate.split("-");
  const year = splitted[0];
  const month = splitted[1];
  return `${year}년 ${month}월 프로그램`;
}

interface ProgramReportComponentProps {
  programReport: ProgramReport;
}

function ProgramReportComponent({
  programReport,
}: ProgramReportComponentProps) {
  const user = useUser();
  const notifications = useNotifications();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/${programReport.program_id}/reports/read`,
      "PATCH"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
    });
  }, [programReport]);

  if (!user) return null;

  return (
    <Fragment>
      <Hidden smDown>
        <div
          style={{
            border: `${widthPercentage(
              isMobile() ? 0.25 : 0.0625
            )}px solid #E5E5EC`,
          }}
        >
          <div
            style={{
              padding: widthPercentage(isMobile() ? 10 : 2.5),
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                    color: "#CBCBCB",
                  }}
                >
                  {dateString(programReport.dates)}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 1 : 0.25),
                    color: "#767676",
                  }}
                  className="font-noto-medium"
                >
                  {programReport.program_name}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 5 : 1.25),
                    backgroundImage: `url(${programReport.program_image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: widthPercentage(16.25),
                    height: widthPercentage(22.375),
                    filter: `drop-shadow(${widthPercentage(
                      0.875
                    )}px ${widthPercentage(0.75)}px ${widthPercentage(
                      1.25
                    )}px rgba(41, 0, 159, 0.16))`,
                    position: "relative",
                  }}
                >
                  {notifications.user.reports.includes(
                    programReport.program_id
                  ) && (
                    <div
                      className="p-absolute d-flex justify-content-center align-items-center"
                      style={{
                        top: 0,
                        left: 0,
                        transform: "translate(-50%, -50%)",
                        width: widthPercentage(isMobile() ? 11 : 2.75),
                        height: widthPercentage(isMobile() ? 11 : 2.75),
                        fontSize: widthPercentage(isMobile() ? 3 : 0.75),
                        color: textColor,
                        backgroundColor: "#FFD66B",
                        borderRadius: "50%",
                      }}
                    >
                      NEW
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "11%",
                  left: "28%",
                  zIndex: -1,
                }}
              >
                <BigQuote
                  style={{
                    height: widthPercentage(5),
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: widthPercentage(4.375),
                  height: widthPercentage(22.375),
                  overflow: "auto",
                  alignSelf: "flex-end",
                }}
              >
                <div
                  style={{
                    color: "#767676",
                    fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                  }}
                  className="font-noto-medium"
                >
                  {user.name}님만의 철학
                </div>
                <div
                  style={{
                    fontSize: widthPercentage(2),
                  }}
                  className="font-noto-medium"
                >
                  {programReport.definition || (
                    <span style={{ color: "#999999" }}>
                      이번에는 기록된 사항이 없어요 :(
                    </span>
                  )}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(2),
                    fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                    color: "#767676",
                  }}
                >
                  {user.name}님의 성장을 위한 {programReport.tutor_name} 튜터의
                  한마디
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(0.75),
                  }}
                >
                  <div
                    style={{
                      width: widthPercentage(4.125),
                      height: widthPercentage(4.125),
                    }}
                  >
                    <CustomAvatarWithLocation
                      location={programReport.tutor_image}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(0.75),
                    color: "#767676",
                    fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                  }}
                >
                  {programReport.feedback}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: `${widthPercentage(
                isMobile() ? 5 : 1.25
              )}px ${widthPercentage(isMobile() ? 10 : 2.5)}px`,
              backgroundColor: "rgba(241, 241, 250, 0.6)",
            }}
          >
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", cursor: "pointer" }}>
                <div>
                  <ArrowRightIcon
                    style={{
                      color: "#757575",
                    }}
                  />
                </div>
                <div
                  style={{
                    color: "#767676",
                    fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                  }}
                  className="font-noto-medium"
                >
                  나의 바보같은 질문
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: widthPercentage(isMobile() ? 30 : 7.5),
              height: widthPercentage(isMobile() ? 42.5 : 10.625),
              filter: `drop-shadow(${widthPercentage(1)}px ${widthPercentage(
                1
              )}px ${widthPercentage(1)}px rgba(153, 153, 153, 0.5))`,
              position: "relative",
              backgroundImage: `url(${programReport.program_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: widthPercentage(isMobile() ? 2.5 : 0.625),
            }}
          >
            {notifications.user.reports.includes(programReport.program_id) && (
              <div
                className="p-absolute d-flex justify-content-center align-items-center"
                style={{
                  top: 0,
                  left: 0,
                  transform: "translate(-50%, -50%)",
                  width: widthPercentage(isMobile() ? 11 : 2.75),
                  height: widthPercentage(isMobile() ? 11 : 2.75),
                  fontSize: widthPercentage(isMobile() ? 3 : 0.75),
                  color: textColor,
                  backgroundColor: "#FFD66B",
                  borderRadius: "50%",
                }}
              >
                NEW
              </div>
            )}
          </div>
          <div
            style={{
              marginLeft: widthPercentage(isMobile() ? 8 : 2),
              flexGrow: 1,
            }}
          >
            <div
              style={{
                color: "#505050",
                fontSize: widthPercentage(isMobile() ? 4 : 1),
              }}
            >
              {programReport.program_name}
            </div>
            <div
              style={{
                marginTop: widthPercentage(isMobile() ? 2.5 : 0.625),
                color: "#999999",
                fontSize: widthPercentage(isMobile() ? 3.5 : 0.875),
              }}
            >
              {dateString(programReport.dates)}
            </div>
            <div
              style={{
                marginTop: widthPercentage(isMobile() ? 2.5 : 0.625),
                display: "inline-block",
                backgroundColor: "#F5F5F5",
                fontSize: widthPercentage(isMobile() ? 3 : 0.75),
                borderRadius: widthPercentage(isMobile() ? 1 : 0.25),
                padding: `${widthPercentage(
                  isMobile() ? 1 : 0.25
                )}px ${widthPercentage(isMobile() ? 2.5 : 0.625)}px`,
              }}
            >
              {programReport.tutor_name}
            </div>
          </div>
          <div
            style={{
              width: widthPercentage(isMobile() ? 10 : 2.5),
              height: widthPercentage(isMobile() ? 10 : 2.5),
            }}
            onClick={() => setOpen(true)}
          >
            <KeyboardArrowRightIcon
              style={{
                color: "#563AB0",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </div>
        <HomeDialog open={open} handleClose={() => setOpen(false)}>
          <div
            style={{
              position: "absolute",
              height: widthPercentage(isMobile() ? 75 : 18.75),
              backgroundColor: "#522C8F",
              borderLeft: `${widthPercentage(100)}px solid transparent`,
              borderBottom: `${widthPercentage(40)}px solid white`,
            }}
          ></div>
          <div
            style={{
              padding: widthPercentage(isMobile() ? 12.5 : 3.125),
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#CBCBCB",
                fontSize: widthPercentage(isMobile() ? 3.75 : 3.125),
              }}
            >
              {dateString(programReport.dates)}
            </div>
            <div
              style={{
                marginTop: widthPercentage(isMobile() ? 1 : 0.25),
                color: "white",
                fontSize: widthPercentage(isMobile() ? 4.5 : 1.125),
              }}
            >
              {programReport.program_name}
            </div>
            <div
              style={{
                marginTop: widthPercentage(isMobile() ? 4 : 1),
              }}
            >
              <img
                src={programReport.program_image}
                alt=""
                draggable={false}
                style={{
                  width: "100%",
                  filter: `drop-shadow(${widthPercentage(
                    3.5
                  )}px ${widthPercentage(3)}px ${widthPercentage(
                    5
                  )}px rgba(41, 0, 159, 0.16))`,
                }}
              />
            </div>
            <div
              className="font-noto-medium"
              style={{
                marginTop: widthPercentage(isMobile() ? 20 : 5),
              }}
            >
              <div
                style={{
                  color: "#767676",
                  fontSize: widthPercentage(isMobile() ? 4.5 : 1.125),
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    zIndex: -1,
                    left: "-5%",
                    transform: "translateY(40%)",
                  }}
                >
                  <BigQuote
                    style={{
                      height: widthPercentage(15),
                    }}
                  />
                </div>
                {user.name}님만의 철학
              </div>
              <div
                style={{
                  marginTop: widthPercentage(isMobile() ? 2 : 0.5),
                  fontSize: widthPercentage(isMobile() ? 4 : 1),
                }}
              >
                {programReport.definition || "이번에는 기록된 사항이 없어요 :("}
              </div>
            </div>
            <div
              className="font-noto-medium"
              style={{
                marginTop: widthPercentage(isMobile() ? 20 : 5),
              }}
            >
              <div
                style={{
                  color: "#767676",
                  fontSize: widthPercentage(isMobile() ? 4.5 : 1.125),
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    zIndex: -1,
                    left: "-5%",
                    transform: "translateY(40%)",
                  }}
                >
                  <BigQuote
                    style={{
                      height: widthPercentage(15),
                    }}
                  />
                </div>
                {user.name}님의 성장을 위한
                <br />
                {programReport.tutor_name} 튜터의 한마디
              </div>
              <div
                style={{
                  marginTop: widthPercentage(isMobile() ? 5 : 1.25),
                }}
              >
                <div
                  style={{
                    width: widthPercentage(isMobile() ? 17.5 : 4.375),
                    height: widthPercentage(isMobile() ? 17.5 : 4.375),
                  }}
                >
                  <CustomAvatarWithLocation
                    location={programReport.tutor_image}
                  />
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 5 : 1.25),
                    whiteSpace: "pre-line",
                    fontSize: widthPercentage(isMobile() ? 4 : 1),
                    color: "#767676",
                  }}
                >
                  {programReport.feedback}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: `${widthPercentage(
                isMobile() ? 6.25 : 1.5625
              )}px ${widthPercentage(isMobile() ? 12.5 : 3.125)}px`,
              backgroundColor: "#F1F1FA",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <ArrowRightIcon
                  style={{
                    color: "#757575",
                  }}
                />
              </div>
              <div
                style={{
                  color: "#767676",
                  fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                }}
                className="font-noto-medium"
              >
                나의 바보같은 질문
              </div>
            </div> */}
          </div>
        </HomeDialog>
      </Hidden>
    </Fragment>
  );
}

function Report() {
  const [programReportMeta, setProgramReportMeta] =
    useState<ProgramReportMeta | null>(null);
  const [page, setPage] = useState<number>(1);
  const user = useUser();
  const history = useHistory();
  const [orderByType, setOrderByType] = useState<OrderByType>("recent");

  useEffect(() => {
    fetchDefaultWithCredential(
      `/user/mypage/program/reports?page=${page}&amount=${
        isMobile() ? 4 : 2
      }&order_by=${orderByType}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setProgramReportMeta);
    });
  }, [page, orderByType]);

  if (!user || !programReportMeta) return null;
  return (
    <Fragment>
      <InfoBar
        header="독서 성장 리포트"
        description={(() => {
          if (programReportMeta.total === 0) {
            return (
              <div>
                <div>
                  필로어스 독서 성장 리포트는 멤버분의 ‘철학적 성장’을 돕기 위해
                  만들어졌습니다.
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 4 : 1),
                  }}
                >
                  세미나에 참여하신 멤버분을 관찰하고 기록한 튜터의 피드백
                  리포트로, ‘고전 독서 먼슬리 프로그램’ 50% 이상 출석시
                  제공됩니다.
                </div>
              </div>
            );
          }
          return (
            <div>
              필로어스는 {user.name}님의 성장을 기록하고, 기억합니다. ‘고전 독서
              먼슬리 프로그램’ 참가시, 프로그램 종료 후 분석이 업데이트 됩니다.
            </div>
          );
        })()}
        percentage={programReportMeta.total > 0 ? 100 : 0}
        count={
          programReportMeta.total > 0
            ? {
                label: "나의 리포트 갯수",
                element: (
                  <div
                    className="font-roboto-medium d-flex"
                    style={{
                      fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
                    }}
                  >
                    {programReportMeta.total}
                  </div>
                ),
              }
            : undefined
        }
      />
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 5.25 : 1.3125),
          marginBottom: widthPercentage(isMobile() ? 5.25 : 1.3125),
        }}
      >
        {programReportMeta.total === 0 && (
          <div
            style={{
              height: widthPercentage(isMobile() ? 150 : 37.5),
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                inset: 0,
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                WebkitBackdropFilter: `blur(${widthPercentage(
                  isMobile() ? 1.875 : 0.46875
                )}px)`,
                backdropFilter: `blur(${widthPercentage(
                  isMobile() ? 1.875 : 0.46875
                )}px)`,
                color: "white",
              }}
            >
              <div
                className="p-absolute-center d-flex align-items-center"
                style={{
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div>
                  <LockIcon />
                </div>
                <div
                  className="font-noto-black"
                  style={{
                    marginTop: widthPercentage(isMobile() ? 4 : 1),
                    fontSize: widthPercentage(isMobile() ? 4.5 : 1.125),
                  }}
                >
                  아직 {user.name}님을 분석할 자료가 부족해요!
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 5 : 1.25),
                    height: widthPercentage(isMobile() ? 0.5 : 0.125),
                    width: widthPercentage(isMobile() ? 15 : 3.75),
                    backgroundColor: "white",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 5 : 1.25),
                    fontSize: widthPercentage(isMobile() ? 3 : 0.75),
                  }}
                >
                  먼저 세미나에 참여하러 가보실까요?
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 4 : 1),
                  }}
                >
                  <HomeButton
                    width={widthPercentage(isMobile() ? 37.5 : 9.375)}
                    height={widthPercentage(isMobile() ? 10 : 2.5)}
                    handleClick={() =>
                      history.push({
                        pathname: "/program",
                      })
                    }
                  >
                    참여하러 가기
                  </HomeButton>
                </div>
              </div>
            </div>
          </div>
        )}
        {programReportMeta.total > 0 && (
          <Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomStandardSelect<OrderByType>
                value={orderByType}
                items={[
                  {
                    label: "최신순",
                    value: "recent",
                  },
                  {
                    label: "오래된순",
                    value: "old",
                  },
                ]}
                handleChange={setOrderByType}
                formStyle={{
                  width: isMobile() ? "100%" : widthPercentage(15.875),
                }}
              />
            </div>
            <div
              style={{
                marginTop: widthPercentage(isMobile() ? 10 : 2.5),
              }}
            >
              {programReportMeta.program_reports.map(
                (program_report, index) => (
                  <div
                    style={{
                      marginBottom: widthPercentage(isMobile() ? 16 : 4),
                    }}
                    key={index}
                  >
                    <ProgramReportComponent programReport={program_report} />
                  </div>
                )
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CustomPagination
                  count={programReportMeta.total_pages}
                  page={page}
                  handleChange={setPage}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: widthPercentage(isMobile() ? 8 : 2),
                fontSize: widthPercentage(isMobile() ? 3 : 0.9375),
                color: "#999999",
                marginBottom: widthPercentage(isMobile() ? 8 : 2),
              }}
            >
              <div>
                *독서 성장 리포트는 프로그램의{" "}
                <span style={{ fontWeight: "bold" }}>50%이상</span> 참여시
                제공됩니다.
              </div>
              <div style={{ marginTop: widthPercentage(isMobile() ? 4 : 1) }}>
                *{user.name}님만의 철학은 프로그램 ‘마지막 회차’에 참여시
                제공됩니다.
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Report;
