import { Hidden } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { HomeButton, HomeDialog, widthPercentage } from "../../components/Home";
import { fetchDefaultWithCredential } from "../../utils";
import { useUser } from "../user";
import { ReactComponent as PopupImage } from "../../svg/popup_image.svg";

interface UserSatisfactionDetailProps {
  userSatisfaction: UserSatisfaction;
}

function UserSatisfactionDetail({
  userSatisfaction,
}: UserSatisfactionDetailProps) {
  const user = useUser();
  const [open, setOpen] = useState<boolean>(true);
  const history = useHistory();

  const handleClose = () => {
    fetchDefaultWithCredential(
      `/user/satisfaction/${userSatisfaction.id}/closed`,
      "PATCH"
    ).then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(() => setOpen(false));
    });
  };
  if (!user) return null;
  return (
    <HomeDialog open={open} handleClose={handleClose}>
      <Hidden smDown>
        <div
          style={{
            width: widthPercentage(68.125),
            userSelect: "none",
            WebkitUserSelect: "none",
            color: "#111111",
            textAlign: "center",
            padding: `${widthPercentage(5)}px 0`,
          }}
          className="font-noto-medium"
        >
          <div style={{ fontSize: widthPercentage(1.125) }}>
            {`${userSatisfaction.program_header} - ${userSatisfaction.program_name}`}
          </div>
          <div style={{ fontSize: widthPercentage(2) }}>
            프로그램 만족도 조사 & 후기
          </div>
          <div
            style={{
              marginTop: widthPercentage(2.5),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PopupImage
              style={{
                width: widthPercentage(11.25),
                height: widthPercentage(11.25),
              }}
            />
          </div>
          <div
            style={{
              marginTop: widthPercentage(3),
              color: "#767676",
              fontSize: widthPercentage(0.9375),
            }}
          >
            "함께 철학하자!" 필로어스 멤버분들의 철학적 성장을 응원합니다.
            <br />
            <br />
            본 만족도 조사 내용은 멤버님의 자기계발과 인문학적 성장을 돕는 좋은
            세미나를 제공하기 위해서 사용되며, 약 3분 정도 소요됩니다.
            <br />
            <br />
            참여해주셔서 정말 감사합니다.
          </div>
          {!userSatisfaction.no_point && (
            <div
              style={{
                marginTop: widthPercentage(1.25),
                color: "#767676",
                fontSize: widthPercentage(0.9375),
              }}
            >
              <span style={{ color: "#4A148C" }}>500원 적립금</span>도 즉시
              드릴게요 :)
            </div>
          )}
          <div
            style={{
              marginTop: widthPercentage(3),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HomeButton
              width={widthPercentage(10)}
              handleClick={() =>
                history.push(`/user/satisfaction/${userSatisfaction.id}`)
              }
            >
              시작하기
            </HomeButton>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            color: "#111111",
            textAlign: "center",
            padding: `0 ${widthPercentage(9)}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          className="font-noto-medium"
        >
          <div>
            <div style={{ fontSize: widthPercentage(2.8125) }}>
              {`${userSatisfaction.program_header} - ${userSatisfaction.program_name}`}
            </div>
            <div
              style={{
                marginTop: widthPercentage(1),
                fontSize: widthPercentage(5),
              }}
            >
              프로그램 만족도 조사 & 후기
            </div>
            <div
              style={{
                marginTop: widthPercentage(9),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PopupImage
                style={{
                  width: widthPercentage(40),
                  height: widthPercentage(40),
                }}
              />
            </div>
            <div
              style={{
                marginTop: widthPercentage(9),
                color: "#767676",
                fontSize: widthPercentage(3.75),
                whiteSpace: "pre-line",
              }}
            >
              "함께 철학하자!" 필로어스 멤버분들의 철학적 성장을 응원합니다.
              <br />
              <br />
              본 만족도 조사 내용은 멤버님의 자기계발과 인문학적 성장을 돕는
              좋은 세미나를 제공하기 위해서 사용되며, 약 3분 정도 소요됩니다.
              <br />
              <br />
              참여해주셔서 정말 감사합니다.
            </div>
            {!userSatisfaction.no_point && (
              <div
                style={{
                  marginTop: widthPercentage(3),
                  color: "#767676",
                  fontSize: widthPercentage(3.5),
                }}
              >
                <span style={{ color: "#4A148C" }}>500원 적립금</span>도 즉시
                드릴게요 :)
              </div>
            )}
            <div
              style={{
                marginTop: widthPercentage(8),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <HomeButton
                width={widthPercentage(40)}
                height={widthPercentage(12)}
                handleClick={() =>
                  history.push(`/user/satisfaction/${userSatisfaction.id}`)
                }
              >
                시작하기
              </HomeButton>
            </div>
          </div>
        </div>
      </Hidden>
    </HomeDialog>
  );
}

function UserSatisfactionComponent() {
  const userSatisfactions = useUserSatisfactions();
  // fetch user satisfactions to see if there's things to complete
  if (!userSatisfactions) return null;
  return (
    <Fragment>
      {userSatisfactions.map((userSatisfaction, index) => (
        <UserSatisfactionDetail
          key={index}
          userSatisfaction={userSatisfaction}
        />
      ))}
    </Fragment>
  );
}

interface UserSatisfaction {
  id: number;
  program_header: string;
  program_name: string;
  no_point: boolean;
}

function useUserSatisfactions() {
  const [userSatisfactions, setUserSatisfactions] = useState<
    UserSatisfaction[] | null
  >(null);
  useEffect(() => {
    fetchDefaultWithCredential(`/user/satisfactions`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setUserSatisfactions);
  }, []);
  if (!userSatisfactions) return null;
  return userSatisfactions;
}

function UserSatisfactionDialogComponent() {
  const user = useUser();
  const location = useLocation();
  if (!user || location.pathname.startsWith("/user/satisfaction/")) return null;
  return <UserSatisfactionComponent />;
}

export default UserSatisfactionDialogComponent;
