import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  GlobalContext,
  SnackbarContext,
} from "../../../globals/components/ComponentsWrapper";
import { useUser } from "../../../globals/user";
import { fetchDefaultWithCredential, isMobile } from "../../../utils";
import CustomText from "../../customs/CustomText";
import { useCertification } from "../../Pay";
import { Button } from "@mui/material";

function Phone() {
  const user = useUser();
  const { handleCertify } = useCertification();
  const location = useLocation();
  const { refreshUser } = useContext(GlobalContext);
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  useEffect(() => {
    if (!location.state) return;
    const { mobile_verified } = location.state as any;
    if (!mobile_verified) {
      showWarningSnackbar("인증에 실패하셨습니다");
    } else {
      const { gender, date_of_birth, phone, name } = location.state as any;
      fetchDefaultWithCredential(`/user/?type=phone`, "PUT", {
        date_of_birth,
        name,
        gender,
        phone,
      }).then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return refreshUser();
      });
    }
    delete location.state;
  }, [location, showWarningSnackbar, refreshUser]);

  const certify = () => {
    handleCertify(
      "mypage",
      ({ date_of_birth, name, gender, phone }) => {
        fetchDefaultWithCredential(`/user/?type=phone`, "PUT", {
          date_of_birth,
          name,
          gender,
          phone,
        }).then((res) => {
          if (!res.ok) {
            return res.text().then((data) => {
              throw new Error(data);
            });
          }
          showSuccessSnackbar("휴대폰 번호 변경이 완료되었습니다");
          return refreshUser();
        });
      },
      () => {}
    );
  };

  if (!user) return null;

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", inset: 0, zIndex: -1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ height: "300px", backgroundColor: "#313131" }}></div>
          <div style={{ flex: 1, backgroundColor: "#F2F2F2" }}></div>
        </div>
      </div>
      <div
        style={{
          paddingTop: isMobile() ? 50 : 100,
          fontSize: 20,
          color: "white",
          fontWeight: 700,
          width: isMobile() ? "75%" : "100%",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        휴대폰 번호가 바뀌었다면 새로운 인증을 통해 번호를 수정해주세요.
      </div>
      {isMobile() && (
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <Button
            style={{
              borderRadius: 36,
              fontSize: isMobile() ? 16 : 18,
              padding: isMobile() ? "10px 30px" : "15px 50px",
              color: "white",
              backgroundColor: "#6939CD",
            }}
            onClick={certify}
          >
            새로운 휴대폰 번호 인증하기
          </Button>
        </div>
      )}
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          padding: isMobile() ? "25px 0" : "50px 0",
        }}
      >
        <div
          style={{
            borderRadius: isMobile() ? 20 : "70px 70px 30px 30px",
            backgroundColor: "white",
            padding: isMobile() ? 25 : 50,
          }}
        >
          {!isMobile() && (
            <div style={{ textAlign: "center" }}>
              <Button
                style={{
                  borderRadius: 36,
                  fontSize: isMobile() ? 16 : 18,
                  padding: isMobile() ? "10px 30px" : "15px 50px",
                  color: "white",
                  backgroundColor: "#6939CD",
                }}
                onClick={certify}
              >
                새로운 휴대폰 번호 인증하기
              </Button>
            </div>
          )}
          <div style={{ marginTop: isMobile() ? 20 : 50 }}>
            <div
              style={{
                display: "flex",
                columnGap: 30,
                flexDirection: isMobile() ? "column" : "row",
                rowGap: 15,
              }}
            >
              <div style={{ flex: 1 }}>
                <CustomText
                  width="100%"
                  type="text"
                  label="휴대전화"
                  name="phone"
                  defaultValue={user.phone}
                  readOnly
                />
              </div>
              <div style={{ flex: 1 }}>
                <CustomText
                  width="100%"
                  type="text"
                  label="이름"
                  name="name"
                  defaultValue={user.name}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: isMobile() ? 15 : 30 }}>
            <div
              style={{
                display: "flex",
                rowGap: 15,
                columnGap: 30,
                flexDirection: isMobile() ? "column" : "row",
              }}
            >
              <div style={{ flex: 1 }}>
                <CustomText
                  width="100%"
                  type="text"
                  label="*성별"
                  name="gender"
                  defaultValue={(() => {
                    if (user.gender === "male") return "남자";
                    if (user.gender === "female") return "여자";
                    return "선택 안함";
                  })()}
                  readOnly
                />
              </div>
              <div style={{ flex: 1 }}>
                <CustomText
                  width="100%"
                  type="date"
                  label="*생년월일"
                  name="date_of_birth"
                  defaultValue={user.date_of_birth}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Phone;
