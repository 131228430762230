import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useGeneric } from "../../../../../hooks";
import { fetchDefaultWithCredential } from "../../../../../utils";
import CustomButton from "../../../../customs/CustomButton";
import CustomCheckbox from "../../../../customs/CustomCheckbox";
import { ImageUploadComponent } from "../../../../customs/CustomImageUploadButton";
import CustomSelect from "../../../../customs/CustomSelect";
import CustomText from "../../../../customs/CustomText";
import RadioForm from "../../../../customs/RadioForm";
import SortComponent from "../../helpers/SortComponent";
import { Option, RawOptionComponent, useOptions } from "../../Options";

interface BookEditComponentProps {
  book: Book;
  refresh: () => void;
  reset: () => void;
  bookGenreOptions: Option[];
  bookDifficultyOptions: Option[];
}

function BookEditComponent({
  book,
  refresh,
  reset,
  bookGenreOptions,
  bookDifficultyOptions,
}: BookEditComponentProps) {
  const handleEdit = () => {
    fetchDefaultWithCredential(`/books/${book.id}`, "PUT", book.meta).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(refresh);
      }
    );
  };

  const handleDelete = () => {
    fetchDefaultWithCredential(`/books/${book.id}`, "DELETE").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(reset).then(refresh);
    });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-flex-end">
        <CustomButton onClick={handleDelete}>책 삭제</CustomButton>
      </div>
      <div className="mt-10">
        <ImageUploadComponent
          imageId={book.meta.image_id || 0}
          handleImage={(image_id) => {
            book.meta.image_id = image_id;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomText
          label="책 이름"
          name="name"
          type="text"
          width="100%"
          defaultValue={book.meta.name || ""}
          handleBlur={(e) => {
            book.meta.name = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomText
          label="출판사"
          name="publisher"
          type="text"
          width="100%"
          defaultValue={book.meta.publisher || ""}
          handleBlur={(e) => {
            book.meta.publisher = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomText
          label="저자 명"
          name="author"
          type="text"
          width="100%"
          defaultValue={book.meta.author || ""}
          handleBlur={(e) => {
            book.meta.author = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomText
          label="설명"
          name="detail"
          type="text"
          width="100%"
          multiline
          height="100px"
          defaultValue={book.meta.detail || ""}
          handleBlur={(e) => {
            book.meta.detail = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomText
          label="링크"
          name="link"
          type="text"
          width="100%"
          defaultValue={book.meta.link || ""}
          handleBlur={(e) => {
            book.meta.link = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomSelect<number>
          fullWidth
          label="책 장르 선택"
          name="book"
          items={[
            {
              label: "선택 안함",
              value: 0,
            },
            ...bookGenreOptions.map((option) => ({
              label: option.label,
              value: option.id as number,
            })),
          ]}
          value={book.meta.book_genre_option_id || 0}
          handleChange={(option_id) => {
            book.meta.book_genre_option_id = option_id;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomCheckbox
          checked={book.meta.classic || false}
          handleChange={(checked) => {
            book.meta.classic = checked;
            handleEdit();
          }}
          border={false}
        >
          고전
        </CustomCheckbox>
      </div>
      {book.meta.classic && (
        <Fragment>
          <div className="mt-10">
            <CustomCheckbox
              checked={book.meta.philous_classic || false}
              handleChange={(checked) => {
                book.meta.philous_classic = checked;
                handleEdit();
              }}
              border={false}
            >
              필로어스 리딩리스트
            </CustomCheckbox>
          </div>
          {book.meta.philous_classic && (
            <Fragment>
              <div className="mt-10">
                <CustomSelect<number>
                  fullWidth
                  label="책 난이도 선택"
                  name="book"
                  items={[
                    {
                      label: "선택 안함",
                      value: 0,
                    },
                    ...bookDifficultyOptions.map((option) => ({
                      label: option.label,
                      value: option.id as number,
                    })),
                  ]}
                  value={book.meta.book_difficulty_option_id || 0}
                  handleChange={(option_id) => {
                    book.meta.book_difficulty_option_id = option_id;
                    handleEdit();
                  }}
                />
              </div>
              <div className="mt-10">
                <div className="font-14 font-bold">포스터 이미지 선택</div>
                <div className="mt-10">
                  <ImageUploadComponent
                    imageId={book.meta.poster_image_id || 0}
                    handleImage={(image_id) => {
                      book.meta.poster_image_id = image_id;
                      handleEdit();
                    }}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

interface Book {
  id: number;
  created_at: string;
  meta: {
    image_id: number;
    name: string;
    publisher: string;
    author: string;
    detail: string;
    link: string;
    book_genre_option_id: number;
    book_difficulty_option_id: number;
    classic: boolean;
    philous_classic: boolean;
    poster_image_id: number;
  };
  image: string;
}

export function useBooks() {
  const [books, setBooks] = useState<Book[] | null>(null);
  const [render, setRender] = useState<number>(0);

  const refresh = () => setRender(render + 1);

  useEffect(() => {
    fetchDefaultWithCredential("/books", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setBooks);
    });
  }, [render]);

  return { books, refresh };
}

type Filter = "all" | "classic" | "philous_classic";

function AdminPageProgramBook() {
  const [bookId, setBookId] = useState<number>(0);
  const { books, refresh } = useBooks();
  const [filter, setFilter] = useState<Filter>("all");
  const bookGenreOptionArgs = useOptions("book_genre");
  const bookDifficultyOptionArgs = useOptions("book_difficulty");
  const {
    generic,
    putGeneric,
    refresh: refreshGeneric,
  } = useGeneric("library");

  const addBook = () => {
    fetchDefaultWithCredential("/books", "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refresh);
    });
  };

  if (
    !books ||
    !bookGenreOptionArgs.options ||
    !bookDifficultyOptionArgs.options ||
    !generic
  )
    return null;

  const book = books.find((book) => book.id === bookId);
  let refinedBooks = books;
  if (filter !== "all") {
    refinedBooks = books.filter((book) => book.meta[filter]);
  }

  return (
    <div>
      <Grid container columnSpacing={2}>
        <Grid item sm xs={12}>
          <div>
            <CustomButton onClick={addBook}>책 추가</CustomButton>
          </div>
          <div className="mt-15">
            <RadioForm<Filter>
              label="보기 방식"
              name="filter"
              value={filter}
              radios={[
                {
                  label: "전체보기",
                  value: "all",
                },
                {
                  label: "고전만 보기",
                  value: "classic",
                },
                {
                  label: "필로어스 리딩리스트만 보기",
                  value: "philous_classic",
                },
              ]}
              handleChange={(e) => setFilter(e.target.value as Filter)}
            />
          </div>
          <div className="mt-15">
            <CustomCheckbox
              checked={generic.show || false}
              handleChange={(checked) => {
                generic.show = checked;
                putGeneric().then(refreshGeneric);
              }}
              border={false}
            >
              보여지기
            </CustomCheckbox>
          </div>
          <div className="mt-10">
            <SortComponent<Book>
              currentDataObject={book ?? null}
              setCurrentDataObject={(book) => setBookId(book?.id ?? 0)}
              label="책 선택"
              data={refinedBooks}
              listItemComponent={(book) => (
                <Grid container columnSpacing={2} alignItems="center">
                  <Grid item>
                    <img src={book.image} alt="" className="height-100" />
                  </Grid>
                  <Grid item xs className="text-align-center">
                    <div className="font-18 font-bold">
                      {book.meta.name || "책 이름"}
                    </div>
                    <div className="mt-5 font-14 font-bold">
                      {book.meta.publisher || "책 출판사"}
                    </div>
                    <div className="mt-5 font-14 font-bold">
                      고전 ({book.meta.classic ? "O" : "X"})
                    </div>
                    <div className="mt-5 font-14 font-bold">
                      필로어스 리딩리스트 (
                      {book.meta.philous_classic ? "O" : "X"})
                    </div>
                  </Grid>
                </Grid>
              )}
              abcSortAttributeFunc={(book) =>
                `${book.meta.name} - ${book.meta.publisher}`
              }
            />
          </div>
        </Grid>
        <Grid item sm xs={12}>
          {book && (
            <div className="mt-10" key={book.id}>
              <BookEditComponent
                book={book}
                refresh={refresh}
                reset={() => setBookId(0)}
                bookGenreOptions={bookGenreOptionArgs.options}
                bookDifficultyOptions={bookDifficultyOptionArgs.options}
              />
            </div>
          )}
        </Grid>
        <Grid item sm xs={12}>
          <div>
            <RawOptionComponent {...bookGenreOptionArgs} label="책 장르" />
          </div>
          <div className="mt-15">
            <RawOptionComponent
              {...bookDifficultyOptionArgs}
              label="책 난이도"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminPageProgramBook;
