import { Grid } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SnackbarContext } from "../globals/components/ComponentsWrapper";
import { useUser } from "../globals/user";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  formatProgramDate,
  year,
} from "../utils";
import CustomButton from "./customs/CustomButton";
import CustomCheck from "./customs/CustomCheck";
import { CustomInstructDialog } from "./customs/CustomDialog";
import CustomSelect from "./customs/CustomSelect";
import CustomText from "./customs/CustomText";
import RadioForm from "./customs/RadioForm";
import { responsivePercentage } from "./Home";
import { usePayment } from "./Pay";
import { usePrivacyAgreement } from "./Policies";

export function defaultCouponNamingLabel(
  title: string,
  value: number,
  at_least_to_apply: number,
  expires_at: string,
  unit: string
) {
  return `[쿠폰] ${title} ${value.toLocaleString()}${
    unit === "value" ? "원" : "%"
  } | ${at_least_to_apply.toLocaleString()}원 이상시 사용가능${
    new Date(expires_at).getTime() - new Date().getTime() > 100 * year
      ? ""
      : ` | ${formatDateTemplate(expires_at)}까지`
  }`;
}

export function packageCouponNamingLabel(title: string, value: number) {
  return `[패키지쿠폰] ${title} ${value.toLocaleString()}원 | 패키지 프로그램 신청시 사용가능 | 프로그램 마감전까지`;
}

export function selectCouponNamingLabel(title: string, value: number) {
  return `[얼리버드 쿠폰] ${title} | ${value.toLocaleString()}원 할인 | 지정 기한까지`;
}

interface OrderProgram {
  id: number;
  program_image: string;
  program_header: string;
  program_name: string;
  dates: string[];
  location: string;
  price: number;
}

interface OrderPriceInfo {
  price: number;
  coupon: number;
  tutor: number;
  point: number;
  total: number;
  point_estimate: number;
}

export interface OrderInfo {
  programs: OrderProgram[];
  price_info: OrderPriceInfo;
  coupon_point: UserCouponPoint;
}

interface ProgramInfosComponentProps {
  programs: OrderProgram[];
}

interface SolidProgramInfoComponentProps {
  program: OrderProgram;
}

function SolidProgramInfoComponent({
  program,
}: SolidProgramInfoComponentProps) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <img
          src={program.program_image}
          alt=""
          draggable={false}
          className="width-100"
        />
      </Grid>
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <div className="font-14 font-bold mt-10">
              {program.program_header} - {program.program_name}
            </div>
            <div className="font-14 mt-10">
              {formatProgramDate(program.dates)}
            </div>
            <div className="font-14 mt-10">{program.location}</div>
            <div className="font-14 mt-10">
              {program.price.toLocaleString()}원
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ProgramInfosComponent({ programs }: ProgramInfosComponentProps) {
  return (
    <Fragment>
      <div className="d-flex justify-content-space-between align-items-center">
        <p className="font-sub font-bold">주문 상품 정보</p>
      </div>
      {programs.map((program, index) => (
        <div className="mt-10" key={index}>
          <SolidProgramInfoComponent program={program} />
        </div>
      ))}
    </Fragment>
  );
}

function UserInfoComponent() {
  const user = useUser();
  if (!user) return null;
  return (
    <Fragment>
      <p className="font-sub font-bold">주문자 정보</p>
      <div className="font-14">{user.name}</div>
      <div className="font-14 mt-10">{user.phone}</div>
      <div className="font-14 mt-10">{user.email}</div>
    </Fragment>
  );
}

interface OrderPriceComponentProps {
  price_info: OrderPriceInfo;
}

function OrderPriceComponent({ price_info }: OrderPriceComponentProps) {
  return (
    <Fragment>
      <p className="font-sub font-bold">최종 결제 금액</p>
      <div className="d-flex justify-content-space-between">
        <div className="font-14">상품가격</div>
        <div className="font-14">{price_info.price.toLocaleString()}원</div>
      </div>
      <div className="mt-10 d-flex justify-content-space-between">
        <div className="font-14">쿠폰 할인</div>
        <div className="font-14">-{price_info.coupon.toLocaleString()}원</div>
      </div>
      {price_info.tutor !== 0 && (
        <div className="mt-10 d-flex justify-content-space-between">
          <div className="font-14">튜터 할인</div>
          <div className="font-14">-{price_info.tutor.toLocaleString()}원</div>
        </div>
      )}
      <div className="mt-10 d-flex justify-content-space-between">
        <div className="font-14">적립금 사용</div>
        <div className="font-14">-{price_info.point.toLocaleString()}원</div>
      </div>
      <hr style={{ margin: "10px 0" }} />
      <div className="mt-10 d-flex justify-content-space-between">
        <div className="font-20">총 결제금액</div>
        <div className="font-20" style={{ color: "#5E10AC" }}>
          {price_info.total.toLocaleString()}원
        </div>
      </div>
      <div className="mt-10 d-flex justify-content-space-between">
        <div className="font-14">적립 예정 금액</div>
        <div className="font-14">
          {price_info.point_estimate.toLocaleString()}원
        </div>
      </div>
    </Fragment>
  );
}

interface CouponPointComponentProps {
  userCouponPoint: UserCouponPoint;
  priceInfo: OrderPriceInfo;
  toApply: CouponApplyProps;
  setToApply: (toApply: CouponApplyProps) => void;
}

interface CouponApply {
  user_coupon: number;
  package_coupon: number;
  select_coupon: string;
}

export interface CouponApplyProps {
  coupon: CouponApply;
  code: string;
  point: number;
}

function CouponPointComponent({
  userCouponPoint,
  priceInfo,
  toApply,
  setToApply,
}: CouponPointComponentProps) {
  const [codeForm, setCodeForm] = useState<string>("");
  const disabledCondition =
    userCouponPoint.point < 5000 || priceInfo.total < 40000;
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const location = useLocation();

  const checkValidCode = () => {
    fetchDefaultWithCredential("/coupons/code/validity", "POST", {
      code: codeForm,
      total: priceInfo.total,
      program_ids: location.state,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          showWarningSnackbar(error);
        });
      }
      return res.json().then(() => {
        showSuccessSnackbar("쿠폰이 확인 되었습니다");
        toApply.code = codeForm;
        setToApply({ ...toApply });
      });
    });
  };

  return (
    <Fragment>
      <p className="font-sub font-bold">쿠폰 / 적립금</p>
      <Grid container spacing={4} alignItems="center">
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <p className="font-14 font-bold">보유 쿠폰</p>
            <CustomSelect
              fullWidth
              name="user_coupon"
              label=""
              items={[
                {
                  label: "적용 안함",
                  value: -1,
                },
                ...userCouponPoint.coupon.user_coupons.map((userCoupon) => ({
                  label: defaultCouponNamingLabel(
                    userCoupon.title,
                    userCoupon.amount,
                    userCoupon.at_least_to_apply,
                    userCoupon.expires_at,
                    userCoupon.unit
                  ),
                  value: userCoupon.id,
                  disabled: userCoupon.disabled,
                })),
                ...(userCouponPoint.coupon.select_coupons.amount > 0
                  ? [
                      {
                        label: selectCouponNamingLabel(
                          userCouponPoint.coupon.select_coupons.title,
                          userCouponPoint.coupon.select_coupons.amount
                        ),
                        value:
                          userCouponPoint.coupon.select_coupons.select_coupon_ids.toString(),
                        disabled:
                          userCouponPoint.coupon.select_coupons.disabled,
                      },
                    ]
                  : []),
              ]}
              value={
                toApply.coupon.user_coupon !== -1
                  ? toApply.coupon.user_coupon
                  : toApply.coupon.select_coupon || -1
              }
              handleChange={(value) => {
                toApply.coupon = {
                  ...toApply.coupon,
                  select_coupon: "",
                  user_coupon: -1,
                };
                const userCoupon = userCouponPoint.coupon.user_coupons.find(
                  (coupon) => coupon.id === value
                );
                if (userCoupon) {
                  toApply.coupon.user_coupon = value as number;
                } else if (value !== -1 && value !== "") {
                  toApply.coupon.select_coupon = value as string;
                }
                setToApply({ ...toApply });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <p className="font-14 font-bold">패키지 쿠폰</p>
            <CustomSelect
              fullWidth
              name="package_coupon"
              label=""
              items={[
                {
                  label: "적용 안함",
                  value: -1,
                },
                ...userCouponPoint.coupon.package_coupons.map(
                  (packageCoupon) => ({
                    label: packageCouponNamingLabel(
                      packageCoupon.name,
                      packageCoupon.amount
                    ),
                    value: packageCoupon.id,
                    disabled: packageCoupon.disabled,
                  })
                ),
              ]}
              value={toApply.coupon.package_coupon}
              handleChange={(value) => {
                toApply.coupon.package_coupon = value as number;
                setToApply({ ...toApply });
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs>
              <CustomText
                label="쿠폰코드"
                name="code"
                type="text"
                width="100%"
                handleBlur={(e) => {
                  setCodeForm(e.target.value);
                }}
                placeholder="쿠폰 코드 입력"
              />
            </Grid>
            <Grid item>
              <CustomButton
                height={50}
                onClick={checkValidCode}
                style={{
                  backgroundColor: "#5E10AC",
                }}
              >
                코드 확인
              </CustomButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs>
              <CustomText
                key={toApply.point}
                label="적립금"
                name="point"
                type="number"
                width="100%"
                handleBlur={(e) => {
                  let point = parseInt(e.target.value) || 0;
                  if (point > userCouponPoint.point) {
                    point = userCouponPoint.point;
                  }
                  toApply.point = point;
                  setToApply({ ...toApply });
                }}
                defaultValue={toApply.point}
                disabled={disabledCondition}
              />
            </Grid>
            <Grid item>
              <CustomButton
                height={50}
                onClick={() => {
                  toApply.point = userCouponPoint.point;
                  setToApply({ ...toApply });
                }}
                disabled={disabledCondition}
                style={{
                  backgroundColor: "#5E10AC",
                }}
              >
                전액 사용
              </CustomButton>
            </Grid>
          </Grid>
          <div className="mt-10 font-12 font-bold">
            보유 적립금 {userCouponPoint.point}원
          </div>
          <div className="mt-10 text-secondary font-12">
            5,000원 이상 보유 및 40,000원 이상 구매시 사용 가능
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

interface PaymentMethodComponentProps {
  paymentMethod: PaymentMethod;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
}

function PaymentMethodComponent({
  paymentMethod,
  setPaymentMethod,
}: PaymentMethodComponentProps) {
  return (
    <Fragment>
      <p className="font-sub font-bold">결제수단</p>
      <RadioForm
        name="payment_method"
        label=""
        radios={[
          {
            label: "신용카드/체크카드(할부가능)",
            value: "card",
          },
        ]}
        value={paymentMethod}
        handleChange={(e) => {
          setPaymentMethod(e.target.value as PaymentMethod);
        }}
        row
      />
    </Fragment>
  );
}

interface AgreementComponentProps {
  captureAgreement: YesNo;
  setCaptureAgreement: (captureAgreement: YesNo) => void;
  videoAgreement: YesNo | "";
  setVideoAgreement: (captureAgreement: YesNo | "") => void;
  onlineAvailable: boolean;
}

function AgreementComponent({
  captureAgreement,
  setCaptureAgreement,
  videoAgreement,
  setVideoAgreement,
  onlineAvailable,
}: AgreementComponentProps) {
  return (
    <Fragment>
      <p className="font-sub font-bold">프로그램 진행시 동의사항</p>
      {onlineAvailable && (
        <RadioForm
          name="video_agreement"
          label="(온라인) 필로어스 온라인 프로그램에 참여시 상호간 원활한 커뮤니케이션을 바탕으로한 세미나를 위해 2시간 동안 반드시 화면과 마이크를 켠 상태로 참여해주셔야 합니다. 이에 동의하십니까?"
          radios={[
            {
              label: "동의합니다.",
              value: "yes",
            },
          ]}
          value={videoAgreement}
          handleChange={(e) => {
            setVideoAgreement(e.target.value as YesNo);
          }}
          row
        />
      )}
      <RadioForm
        name="capture_agreement"
        label="프로그램 진행 중 '필로어스 SNS 채널 및 유튜브'에 홍보를 목적으로 한 사진 혹은 영상이 촬영될 수 있으며, 필로어스 측의 촬영외에 모든 고객의 개별 촬영은 불가합니다. 이에 동의하십니까?"
        radios={[
          {
            label: "동의합니다. 촬영시 얼굴이 나와도 괜찮습니다.",
            value: "yes",
          },
          {
            label: "동의합니다. 촬영시 하지만 얼굴을 꼭 모자이크로 가려주세요.",
            value: "no",
          },
        ]}
        value={captureAgreement}
        handleChange={(e) => {
          setCaptureAgreement(e.target.value as YesNo);
        }}
        row
      />
    </Fragment>
  );
}

interface PurchaseAgreementComponentProps {
  purchaseAgreement: YesNo;
  setPurchaseAgreement: (purchaseAgreement: YesNo) => void;
}

function PurchaseAgreementComponent({
  purchaseAgreement,
  setPurchaseAgreement,
}: PurchaseAgreementComponentProps) {
  const [all, setAll] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [read, setRead] = useState<boolean>(false);
  const privacyAgreement = usePrivacyAgreement();
  useEffect(() => {
    if (all) {
      setPurchaseAgreement("yes");
    } else {
      setPurchaseAgreement("no");
    }
  }, [all, setPurchaseAgreement]);

  useEffect(() => {
    if (!read && purchaseAgreement === "yes") {
      setOpen(true);
      setRead(true);
    }
  }, [purchaseAgreement, read]);

  if (!privacyAgreement) return null;

  return (
    <Fragment>
      <CustomCheck
        label=""
        checkItems={[
          {
            id: true,
            label: <p className="font-bold font-14">전체 동의</p>,
            checked: all,
          },
        ]}
        handleChange={(_, checked) => {
          if (checked) {
            setAll(true);
          } else {
            setAll(false);
          }
        }}
      />
      <div className="p-15">
        <CustomCheck
          label=""
          checkItems={[
            {
              id: true,
              label: (
                <div className="font-14">구매조건 확인 및 결제 진행에 동의</div>
              ),
              checked: purchaseAgreement === "yes",
            },
          ]}
          handleChange={(_, checked) => {
            if (checked) {
              setPurchaseAgreement("yes");
            } else {
              setPurchaseAgreement("no");
            }
          }}
        />
      </div>
      <CustomInstructDialog
        open={open}
        handleClose={() => setOpen(false)}
        header="이용약관"
        maxWidth="md"
      >
        {privacyAgreement.agreement.content}
      </CustomInstructDialog>
    </Fragment>
  );
}

type CouponUnit = "value" | "percentage";

interface UserCoupon {
  id: number;
  amount: number;
  title: string;
  at_least_to_apply: number;
  expires_at: string;
  disabled: boolean;
  unit: CouponUnit;
  type: "default" | "select";
}

interface UserPackageCoupon {
  id: number;
  name: string;
  amount: number;
  disabled: boolean;
  unit: CouponUnit;
  type: "package";
}

interface UserSelectCoupon {
  amount: number;
  title: string;
  select_coupon_ids: number[];
  disabled: boolean;
}

interface CouponType {
  user_coupons: UserCoupon[];
  package_coupons: UserPackageCoupon[];
  select_coupons: UserSelectCoupon;
}

interface UserCouponPoint {
  coupon: CouponType;
  code: string;
  point: number;
}

type YesNo = "yes" | "no";
type PaymentMethod = "card";

function Order() {
  const location = useLocation() as any;
  const [orderInfo, setOrderInfo] = useState<OrderInfo | null>(null);
  const { handleBuy } = usePayment();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("card");
  const [videoAgreement, setVideoAgreement] = useState<YesNo | "">("");
  const [captureAgreement, setCaptureAgreement] = useState<YesNo>("yes");
  const [purchaseAgreement, setPurchaseAgreement] = useState<YesNo>("no");
  const [couponPassed, setCouponPassed] = useState<boolean>(true);
  const [allPassed, setAllPassed] = useState<boolean>(false);
  const { showWarningSnackbar } = useContext(SnackbarContext);
  const [toApply, setToApply] = useState<CouponApplyProps>({
    coupon: {
      user_coupon: -1,
      package_coupon: -1,
      select_coupon: "",
    },
    code: "",
    point: 0,
  });
  const onlineAvailable =
    orderInfo?.programs.map((program) => program.location).includes("온라인") ??
    false;

  useEffect(() => {
    fetchDefaultWithCredential("/order", "POST", {
      program_ids: location.state,
      coupon_point_info: toApply,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          showWarningSnackbar(error);
          setCouponPassed(false);
        });
      }
      return res
        .json()
        .then(setOrderInfo)
        .then(() => setCouponPassed(true));
    });
  }, [location, toApply, showWarningSnackbar]);

  useEffect(() => {
    setAllPassed(false);
    if (purchaseAgreement === "yes" && couponPassed) {
      if (!onlineAvailable || videoAgreement === "yes") {
        setAllPassed(true);
      }
    }
  }, [purchaseAgreement, couponPassed, onlineAvailable, videoAgreement]);

  if (!orderInfo) return null;

  return (
    <div style={{ backgroundColor: "#EBEBEB" }}>
      <div
        className="p-30 margin-center"
        style={{
          width: responsivePercentage(80),
        }}
      >
        <p className="font-header text-align-center">결제하기</p>
        <Grid container spacing={2}>
          <Grid item sm={7} xs={12}>
            <div className="p-15 background-white">
              <ProgramInfosComponent programs={orderInfo.programs} />
            </div>
            <div className="mt-15">
              <div className="p-15 background-white">
                <UserInfoComponent />
              </div>
            </div>
            <div className="mt-15">
              <div className="p-15 background-white">
                <CouponPointComponent
                  userCouponPoint={orderInfo.coupon_point}
                  priceInfo={orderInfo.price_info}
                  toApply={toApply}
                  setToApply={setToApply}
                />
              </div>
            </div>
          </Grid>
          <Grid item sm={5} xs={12}>
            <div className="p-15 background-white">
              <OrderPriceComponent price_info={orderInfo.price_info} />
            </div>
            <div className="mt-10 p-15 background-white">
              <PaymentMethodComponent
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
            </div>
            <div className="mt-10 p-15 background-white">
              <AgreementComponent
                captureAgreement={captureAgreement}
                setCaptureAgreement={setCaptureAgreement}
                onlineAvailable={onlineAvailable}
                videoAgreement={videoAgreement}
                setVideoAgreement={setVideoAgreement}
              />
            </div>
            <div className="mt-10 p-15 background-white">
              <PurchaseAgreementComponent
                purchaseAgreement={purchaseAgreement}
                setPurchaseAgreement={setPurchaseAgreement}
              />
            </div>
            <div className="mt-10">
              <CustomButton
                style={{
                  backgroundColor: "#5E10AC",
                }}
                className="height-50"
                fullWidth
                onClick={() =>
                  handleBuy(location.state, toApply, {
                    capture_agreement: captureAgreement,
                    purchase_agreement: purchaseAgreement,
                    ...(onlineAvailable
                      ? {
                          video_agreement: videoAgreement,
                        }
                      : {}),
                  })
                }
                disabled={!allPassed}
              >
                결제하기
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Order;
