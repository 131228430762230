import React, { Fragment, ReactElement, useEffect, useState } from "react";
import Swiper, { ReactIdSwiperChildren } from "react-id-swiper";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "./HomeSwiper.scss";
import { ReactComponent as LeftArrow } from "../../svg/left_arrow.svg";
import { ReactComponent as RightArrow } from "../../svg/right_arrow.svg";
import { CustomLeftRightButton, widthPercentage } from "../Home";
import { Hidden } from "@mui/material";
import { isMobile } from "../../utils";
SwiperCore.use([Navigation, Pagination, Autoplay]);

interface PropType {
  children: ReactIdSwiperChildren;
  slides: number;
  buttonWidth: number;
  buttonHeight: number;
  childWidth: number;
  offsetTop: number;
}

interface BannerSwiperProps {
  children: ReactIdSwiperChildren;
}

interface SwiperPaginationProps {
  amount: number;
  ithButtonClicked: (i: number) => void;
  currentIndex: number;
  dark?: boolean;
}

export function SwiperPagination({
  amount,
  ithButtonClicked,
  currentIndex,
  dark = false,
}: SwiperPaginationProps) {
  return (
    <Fragment>
      {(() => {
        const res = [];
        for (let i = 0; i < amount; i++) {
          res.push(
            <div
              onClick={() => ithButtonClicked(i)}
              key={i}
              style={{
                width: isMobile() ? widthPercentage(2.5) : widthPercentage(0.5),
                height: isMobile()
                  ? widthPercentage(2.5)
                  : widthPercentage(0.5),
                backgroundColor: dark
                  ? "white"
                  : i === currentIndex - 1
                  ? "#4A148C"
                  : "#E1E1E1",
                borderRadius: "50%",
                opacity: dark ? (i === currentIndex - 1 ? 1 : 0.2) : 1,
                cursor: "pointer",
                marginRight: isMobile()
                  ? widthPercentage(2)
                  : widthPercentage(0.5),
              }}
            ></div>
          );
        }
        return res;
      })()}
    </Fragment>
  );
}

export function BannerSwiper({ children }: BannerSwiperProps) {
  const [swiperRef, setSwiperRef] = useState<HTMLDivElement | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(1);

  useEffect(() => {
    if (swiperRef) {
      const swiper = (swiperRef as any).swiper;
      swiper.on("slideChange", ({ realIndex }: { realIndex: number }) =>
        setCurrentIndex(realIndex + 1)
      );
    }
  }, [swiperRef]);

  const PaginationComponent = () => (
    <div
      style={{
        display: "flex",
        marginBottom: isMobile() ? 0 : widthPercentage(2),
      }}
    >
      <SwiperPagination
        currentIndex={currentIndex}
        amount={(children as ReactElement[]).length}
        ithButtonClicked={(i) => (swiperRef as any).swiper.slideTo(i + 1)}
        dark
      />
    </div>
  );

  return (
    <Fragment>
      <div className="home-banner-swiper">
        <Swiper
          ref={setSwiperRef}
          loop
          slidesPerView={1}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
        >
          {children}
        </Swiper>
        {swiperRef && (
          <Fragment>
            <Hidden smDown>
              {/* <HomeContainer>
              </HomeContainer> */}
              <div
                style={{
                  position: "absolute",
                  left: widthPercentage(9.0625),
                  bottom: widthPercentage(3.75),
                  zIndex: 3,
                }}
              >
                <PaginationComponent />
                <CustomLeftRightButton
                  isLeftEnd={currentIndex === 1}
                  isRightEnd={
                    currentIndex === (children as ReactElement[]).length
                  }
                  onLeftClick={() => (swiperRef as any).swiper.slidePrev()}
                  onRightClick={() => (swiperRef as any).swiper.slideNext()}
                />
              </div>
            </Hidden>
            <Hidden smUp>
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  bottom: widthPercentage(75),
                  width: "100%",
                }}
              >
                <div className="d-flex justify-content-center">
                  <PaginationComponent />
                </div>
              </div>
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  left: 0,
                  bottom: widthPercentage(28.5),
                  ...(currentIndex === 1
                    ? {
                        opacity: 0.35,
                        cursor: "auto",
                      }
                    : {}),
                }}
                onClick={
                  currentIndex === 1
                    ? undefined
                    : () => (swiperRef as any).swiper.slidePrev()
                }
              >
                <CustomButton
                  state="prev"
                  buttonWidth={widthPercentage(8)}
                  buttonHeight={widthPercentage(12)}
                />
              </div>
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  right: 0,
                  bottom: widthPercentage(28.5),
                  ...(currentIndex === (children as ReactElement[]).length
                    ? {
                        opacity: 0.35,
                        cursor: "auto",
                      }
                    : {}),
                }}
                onClick={
                  currentIndex === (children as ReactElement[]).length
                    ? undefined
                    : () => (swiperRef as any).swiper.slideNext()
                }
              >
                <CustomButton
                  state="next"
                  buttonWidth={widthPercentage(8)}
                  buttonHeight={widthPercentage(12)}
                />
              </div>
            </Hidden>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

type CustomButtonState = "prev" | "next";

interface CustomButtonProps {
  state: CustomButtonState;
  buttonWidth: number;
  buttonHeight: number;
}

function CustomButton({ state, buttonWidth, buttonHeight }: CustomButtonProps) {
  return (
    <div
      style={{
        width: buttonWidth,
        height: buttonHeight,
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 10,
      }}
    >
      {state === "prev" && (
        <LeftArrow style={{ width: "100%", height: "100%" }} />
      )}
      {state === "next" && (
        <RightArrow style={{ width: "100%", height: "100%" }} />
      )}
    </div>
  );
}

function HomeSwiper({
  children,
  slides,
  buttonWidth,
  buttonHeight,
  childWidth,
  offsetTop,
}: PropType) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [buttonMargin, setButtonMargin] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(1);

  useEffect(() => {
    if (ref) {
      const swiper = (ref as any).swiper;
      swiper.on("slideChange", ({ realIndex }: { realIndex: number }) =>
        setCurrentIndex(realIndex + 1)
      );
    }
  }, [ref]);

  useEffect(() => {
    if (ref) {
      const eachWidth = ref.clientWidth / slides;
      // add + 1 for border box sizing purpose
      const margin = (eachWidth - childWidth) / 2 + 1;
      setButtonMargin(margin);
    }
  }, [ref, childWidth, slides]);

  return (
    <Fragment>
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        slidesPerView={slides}
        renderPrevButton={(e) => (
          <div
            className="home-swiper swiper-button-prev p-absolute"
            style={{
              left: buttonMargin,
              top: offsetTop,
              marginTop: 0,
              width: "auto",
              height: "auto",
            }}
          >
            <CustomButton
              state="prev"
              buttonWidth={buttonWidth}
              buttonHeight={buttonHeight}
            />
          </div>
        )}
        renderNextButton={(e) => (
          <div
            className="home-swiper swiper-button-next"
            style={{
              right: buttonMargin,
              top: offsetTop,
              marginTop: 0,
              width: "auto",
              height: "auto",
            }}
          >
            <CustomButton
              state="next"
              buttonWidth={buttonWidth}
              buttonHeight={buttonHeight}
            />
          </div>
        )}
        ref={setRef}
      >
        {children}
      </Swiper>
      {ref && (
        <Hidden smUp>
          <div
            style={{
              marginTop: widthPercentage(9),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SwiperPagination
              currentIndex={currentIndex}
              amount={(children as ReactElement[]).length}
              ithButtonClicked={(i) => (ref as any).swiper.slideTo(i)}
            />
          </div>
        </Hidden>
      )}
    </Fragment>
  );
}

export default HomeSwiper;
