import { Badge, Grid } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { v4 } from "uuid";
import { User } from "../../../../../globals/user";
import { fetchDefaultWithCredential } from "../../../../../utils";
import CustomButton from "../../../../customs/CustomButton";
import CustomSelect from "../../../../customs/CustomSelect";
import CustomText from "../../../../customs/CustomText";
import { UserSatisfactionAnswer } from "../../../../UserSatisfactionComponent";
import SortComponent from "../../helpers/SortComponent";
import { ProgramVisualizeInnerComponent } from "../../Transactions";
import { ProgramInfo } from "../../users";
import { useAdminProgramInfos } from "./CustomizedRegister";
import { useNotifications } from "../../../../../globals/notification";
import { GlobalContext } from "../../../../../globals/components/ComponentsWrapper";
import CustomCheckbox from "../../../../customs/CustomCheckbox";

interface SatisfactionVisualComponentProps {
  programId: number;
}

interface CollectionAnswer {
  user: User;
  answer: UserSatisfactionAnswer;
}

interface Collection {
  questions: TemplateQuestion[];
  answers: CollectionAnswer[];
}

interface SummaryInfoProps {
  question: TemplateQuestion;
  answers: CollectionAnswer[];
}

interface UserAnswerComponentProps {
  questions: TemplateQuestion[];
  answer: UserSatisfactionAnswer;
}

function UserAnswerComponent({ answer, questions }: UserAnswerComponentProps) {
  return (
    <div className="font-bold">
      {questions.map((question) => (
        <div className="mt-10" key={question.id}>
          <div className="font-14">{question.question}</div>
          {(() => {
            const optionMap = {} as any;
            question.options.forEach((option) => {
              optionMap[option.id] = option.value;
            });
            const answerOptions = answer[question.id];
            if (!answerOptions) return null;
            return (
              <Fragment>
                {answerOptions.map((answerOption, index) => (
                  <div className="font-12 mt-10" key={index}>
                    {(() => {
                      const value = answerOption.value;
                      if (value === "etc") {
                        return answerOption.etc || "";
                      }
                      if (answerOption.type === "subjective") {
                        return value;
                      }
                      return optionMap[value];
                    })()}
                  </div>
                ))}
              </Fragment>
            );
          })()}
        </div>
      ))}
    </div>
  );
}

function SummaryInfo({ question, answers }: SummaryInfoProps) {
  const optionMap = {} as any;
  question.options.forEach((option) => {
    optionMap[option.id] = {
      label: option.value,
      count: 0,
    };
  });
  const etcs = [] as any[];
  const subjectives = [] as any[];
  let sum = 0;
  answers.forEach((answer) => {
    const userAnswers = answer.answer[question.id];
    if (!userAnswers) return;
    userAnswers.forEach((userAnswer) => {
      // if type is multiple, we increment
      if (userAnswer.type === "multiple") {
        // if it's not etc, then just increment
        if (userAnswer.value !== "etc") {
          if (optionMap[userAnswer.value]) {
            optionMap[userAnswer.value].count++;
          }
        } else {
          // if etc, then set up values
          if (userAnswer.etc) {
            etcs.push({
              user: answer.user,
              value: userAnswer.etc,
            });
          }
        }
        sum++;
      } else {
        subjectives.push({
          user: answer.user,
          value: userAnswer.value,
        });
      }
    });
  });
  const values = Object.values(optionMap) as any[];
  const labels = values.map((value) =>
    value.label.length > 10 ? value.label.slice(0, 10) + "..." : value.label
  );
  const datasets = [
    {
      backgroundColor: "#0062ff",
      label: "응답 개수",
      data: values.map((value) => value.count),
    },
  ];
  // deal with etc
  if (question.etc > 0) {
    labels.push("기타");
    datasets[0].data.push(etcs.length);
  }
  const data = {
    labels,
    datasets,
  };
  if (question.type === "subjective") {
    if (subjectives.length === 0) return null;
    return (
      <div className="font-14">
        <div
          className="mt-10 outline-with-border overflow-auto"
          style={{ maxHeight: 300 }}
        >
          {subjectives.map((subjective, index) => (
            <div className="p-10" key={index}>
              {subjective.user.name} - {subjective.value}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <div>
        <Bar
          data={data}
          width="100%"
          height="auto"
          options={{
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  precision: 0,
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: (context: any) => {
                    return labels[context[0].dataIndex];
                  },
                  footer: (context: any) => {
                    return `${Math.ceil((context[0].raw / sum) * 100)}%`;
                  },
                },
              },
            },
          }}
        />
      </div>
      {etcs.length > 0 && (
        <div className="mt-10 font-14">
          <div className="font-bold">기타</div>
          <div
            className="mt-10 outline-with-border overflow-auto"
            style={{ maxHeight: 300 }}
          >
            {etcs.map((etc, index) => (
              <div className="p-10" key={index}>
                {etc.user.name} - {etc.value}
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
}

function SatisfactionVisualComponent({
  programId,
}: SatisfactionVisualComponentProps) {
  const [collections, setCollections] = useState<Collection | null>(null);
  const [currentUser, setCurrentUser] = useState<number>(0);
  const { refreshNotification } = useContext(GlobalContext);
  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/${programId}/satisfactions/summary`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          if (res.status !== 400) throw new Error(error);
          return;
        });
      }
      return res.json().then(setCollections);
    });
    fetchDefaultWithCredential(
      `/program/${programId}/satisfactions/notifications`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          if (res.status !== 400) throw new Error(error);
          return;
        });
      }
      return res.json().then(refreshNotification);
    });
  }, [programId, refreshNotification]);
  if (!collections) return null;

  const answer = collections.answers.find(
    (answer) => answer.user.id === currentUser
  );

  return (
    <Fragment>
      <div
        className="mt-30 mb-30"
        style={{ height: 2, backgroundColor: "lightgray" }}
      ></div>
      <div className="font-18 font-bold">개요 보기</div>
      <div className="mt-10 font-14 font-bold">
        총 응답: {collections.answers.length}
      </div>
      {collections.questions.map((question) => (
        <div className="mt-10 font-14 font-bold" key={question.id}>
          <div>{question.question}</div>
          <div className="mt-10">
            <SummaryInfo question={question} answers={collections.answers} />
          </div>
        </div>
      ))}
      <div
        className="mt-30 mb-30"
        style={{ height: 2, backgroundColor: "lightgray" }}
      ></div>
      <div className="font-14 font-bold">
        <div className="font-18">개별 보기</div>
        <div className="mt-10">
          <CustomSelect<number>
            fullWidth
            label="참가자 선택"
            name="user"
            items={[
              {
                label: "참가자 선택 안함",
                value: 0,
              },
              ...collections.answers.map((answer) => ({
                label: answer.user.name,
                value: answer.user.id,
              })),
            ]}
            value={currentUser}
            handleChange={setCurrentUser}
          />
        </div>
        {answer && (
          <div className="mt-10">
            <UserAnswerComponent
              questions={collections.questions}
              answer={answer.answer}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

function ProgramComponent() {
  const { programs, refresh } = useAdminProgramInfos();
  const [currentProgram, setCurrentProgram] = useState<ProgramInfo | null>(
    null
  );
  const [satisfactionTemplateId, setSatisfactionTemplateId] =
    useState<number>(-1);
  const { satisfactionTemplates } = useContext(TemplateContext);
  const notifications = useNotifications();

  useEffect(() => {
    if (!currentProgram) return;
    fetchDefaultWithCredential(
      `/program/${currentProgram.id}/satisfaction`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setSatisfactionTemplateId);
    });
  }, [currentProgram]);

  if (!programs) return null;
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs>
        <SortComponent<ProgramInfo>
          currentDataObject={currentProgram}
          setCurrentDataObject={setCurrentProgram}
          label="프로그램"
          data={programs}
          listItemComponent={(program) => (
            <div>
              <Badge
                badgeContent={
                  notifications.admin.satisfactions[program.id] || 0
                }
                color="secondary"
              >
                <ProgramVisualizeInnerComponent {...program} />
              </Badge>
            </div>
          )}
          abcSortAttributeFunc={(program) => program.program_name}
        />
      </Grid>
      <Grid item xs>
        {currentProgram && satisfactionTemplateId !== -1 && (
          <Fragment>
            <div>
              <CustomSelect<number>
                fullWidth
                label="템플릿 선택"
                name="template"
                items={[
                  {
                    label: "템플릿 선택 안함",
                    value: 0,
                  },
                  ...satisfactionTemplates.map((satisfactionTemplate) => ({
                    label: satisfactionTemplate.meta.name || "새 템플릿",
                    value: satisfactionTemplate.id,
                  })),
                ]}
                value={satisfactionTemplateId}
                handleChange={(value) => {
                  fetchDefaultWithCredential(
                    `/program/${currentProgram.id}/satisfaction_template/${value}`,
                    "POST"
                  ).then((res) => {
                    if (!res.ok) {
                      return res.json().then(({ error }) => {
                        throw new Error(error);
                      });
                    }
                    return res.json().then(refresh);
                  });
                }}
              />
            </div>
            <div className="mt-10" key={currentProgram.id}>
              <SatisfactionVisualComponent programId={currentProgram.id} />
            </div>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
}

interface TemplateDetailComponentProps {
  satisfactionTemplateId: number;
}

function TemplateDetailComponent({
  satisfactionTemplateId,
}: TemplateDetailComponentProps) {
  const { refresh, satisfactionTemplates } = useContext(TemplateContext);
  const satisfactionTemplate = satisfactionTemplates.find(
    (satisfactionTemplate) => satisfactionTemplate.id === satisfactionTemplateId
  );

  const handleEdit = () => {
    if (!satisfactionTemplate) return;
    return fetchDefaultWithCredential(
      `/satisfaction_templates/${satisfactionTemplateId}`,
      "PUT",
      satisfactionTemplate.meta
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refresh);
    });
  };

  if (!satisfactionTemplate) return null;
  return (
    <div>
      <div>
        <CustomText
          label="템플릿 이름"
          name="name"
          type="text"
          width="100%"
          defaultValue={satisfactionTemplate.meta.name || ""}
          handleBlur={(e) => {
            satisfactionTemplate.meta.name = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="mt-10">
        <CustomCheckbox
          border={false}
          checked={!satisfactionTemplate.meta.no_point}
          handleChange={(checked) => {
            satisfactionTemplate.meta.no_point = !checked;
            handleEdit();
          }}
        >
          적립금 부여
        </CustomCheckbox>
      </div>
      <div className="mt-10">
        <CustomButton
          className="background-red"
          onClick={() => {
            const questions = satisfactionTemplate.meta.questions ?? [];
            satisfactionTemplate.meta.questions = [
              ...questions,
              {
                id: v4(),
                question: "",
                etc: 0,
                options: [],
                max_options: 0,
                min_options: 0,
                type: "multiple",
                min_subjective_length: 0,
              },
            ];
            handleEdit();
          }}
        >
          질문 추가
        </CustomButton>
      </div>
      {(satisfactionTemplate.meta.questions ?? []).map((question, index) => (
        <div className="mt-10" key={question.id}>
          <div>
            <CustomButton
              onClick={() => {
                satisfactionTemplate.meta.questions = (
                  satisfactionTemplate.meta.questions ?? []
                ).filter(
                  (currentQuestion) => currentQuestion.id !== question.id
                );
                handleEdit();
              }}
            >
              질문 삭제
            </CustomButton>
          </div>
          <div className="mt-10">
            <CustomText
              label={"질문" + (index + 1)}
              name="question"
              type="text"
              width="100%"
              defaultValue={question.question}
              handleBlur={(e) => {
                question.question = e.target.value;
                handleEdit();
              }}
            />
          </div>
          <div className="mt-10">
            <CustomSelect<TemplateQuestionType>
              fullWidth
              label="질문 종류"
              name="question_type"
              items={[
                {
                  label: "객관식",
                  value: "multiple",
                },
                {
                  label: "주관식",
                  value: "subjective",
                },
              ]}
              value={question.type ?? "multiple"}
              handleChange={(value) => {
                question.type = value;
                handleEdit();
              }}
            />
          </div>
          {question.type === "multiple" && (
            <div className="mt-10">
              <div>
                <CustomButton
                  className="background-blue"
                  onClick={() => {
                    question.options.push({
                      id: v4(),
                      value: "",
                    });
                    handleEdit();
                  }}
                >
                  선택지 추가
                </CustomButton>
              </div>
              {question.options.map((option, index) => (
                <div className="mt-10" key={option.id}>
                  <Grid container columnSpacing={1} alignItems="flex-end">
                    <Grid item xs>
                      <CustomText
                        label={"선택지" + (index + 1)}
                        name="option"
                        type="text"
                        width="100%"
                        defaultValue={option.value}
                        handleBlur={(e) => {
                          option.value = e.target.value;
                          handleEdit();
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomButton
                        onClick={() => {
                          question.options = question.options.filter(
                            (currentOption) => currentOption.id !== option.id
                          );
                          handleEdit();
                        }}
                      >
                        선택지 삭제
                      </CustomButton>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <div className="mt-10">
                <CustomText
                  label="기타 허용 최소 글자수(0일 경우 기타x)"
                  name="etc"
                  type="number"
                  width="100%"
                  defaultValue={question.etc}
                  handleBlur={(e) => {
                    question.etc = parseInt(e.target.value) || 0;
                    handleEdit();
                  }}
                />
              </div>
              <div className="mt-10">
                <CustomText
                  label="최소 선택지"
                  name="min_options"
                  type="number"
                  width="100%"
                  defaultValue={question.min_options}
                  handleBlur={(e) => {
                    question.min_options = parseInt(e.target.value) || 0;
                    handleEdit();
                  }}
                />
              </div>
              <div className="mt-10">
                <CustomText
                  label="최대 선택지"
                  name="max_options"
                  type="number"
                  width="100%"
                  defaultValue={question.max_options}
                  handleBlur={(e) => {
                    question.max_options = parseInt(e.target.value) || 0;
                    handleEdit();
                  }}
                />
              </div>
            </div>
          )}
          {question.type === "subjective" && (
            <div className="mt-10">
              <CustomText
                label="주관식 최소 길이"
                name="min_subjective_length"
                type="number"
                width="100%"
                defaultValue={question.min_subjective_length}
                handleBlur={(e) => {
                  question.min_subjective_length =
                    parseInt(e.target.value) || 0;
                  handleEdit();
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

type TemplateQuestionType = "multiple" | "subjective";

interface TemplateOption {
  id: string;
  value: string;
}

interface TemplateQuestion {
  id: string;
  question: string;
  type: TemplateQuestionType;
  options: TemplateOption[];
  etc: number;
  min_options: number;
  max_options: number;
  min_subjective_length: number;
}

interface SatisfactionTemplateMeta {
  name?: string;
  questions?: TemplateQuestion[];
  program_ids?: number[];
  no_point?: boolean;
}

interface SatisfactionTemplate {
  id: number;
  meta: SatisfactionTemplateMeta;
}

function useSatisfactionTemplates() {
  const [satisfactionTemplates, setSatisfactionTemplates] = useState<
    SatisfactionTemplate[] | null
  >(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential("/satisfaction_templates", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setSatisfactionTemplates);
    });
  }, [render]);

  return {
    satisfactionTemplates,
    refresh: () => setRender((render) => render + 1),
  };
}

interface TemplateContextProps {
  refresh: () => void;
  satisfactionTemplates: SatisfactionTemplate[];
}

const TemplateContext = React.createContext({} as TemplateContextProps);

function TemplateComponent() {
  const [satisfactionTemplateId, setSatisfactionTemplateId] =
    useState<number>(0);
  const { refresh, satisfactionTemplates } = useContext(TemplateContext);

  const createTemplate = () => {
    return fetchDefaultWithCredential("/satisfaction_templates", "POST").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(refresh);
      }
    );
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs>
        <div>
          <CustomButton onClick={createTemplate}>템플릿 추가</CustomButton>
        </div>
        <div className="mt-10">
          <CustomSelect<number>
            fullWidth
            label="템플릿 선택"
            name="template"
            items={[
              {
                label: "템플릿 선택",
                value: 0,
              },
              ...satisfactionTemplates.map((satisfactionTemplate) => ({
                label: satisfactionTemplate.meta.name || "새 템플릿",
                value: satisfactionTemplate.id,
              })),
            ]}
            value={satisfactionTemplateId}
            handleChange={setSatisfactionTemplateId}
          />
        </div>
      </Grid>
      <Grid item xs>
        <TemplateDetailComponent
          key={satisfactionTemplateId}
          satisfactionTemplateId={satisfactionTemplateId}
        />
      </Grid>
    </Grid>
  );
}

function AdminPageProgramSatisfaction() {
  const { satisfactionTemplates, refresh } = useSatisfactionTemplates();
  if (!satisfactionTemplates) return null;
  return (
    <TemplateContext.Provider
      value={{
        satisfactionTemplates,
        refresh,
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item sm xs={12}>
          <div className="font-14 font-bold">템플릿</div>
          <div className="mt-10">
            <TemplateComponent />
          </div>
        </Grid>
        <Grid item sm xs={12}>
          <div className="font-14 font-bold">프로그램</div>
          <div className="mt-10">
            <ProgramComponent />
          </div>
        </Grid>
      </Grid>
    </TemplateContext.Provider>
  );
}

export default AdminPageProgramSatisfaction;
