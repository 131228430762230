import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { putObjectFromList, removeObjectFromList } from "../../../hooks";
import { defaultSet } from "../../../interfaces";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  formatTimeTemplate,
} from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomCheckbox from "../../customs/CustomCheckbox";
import CustomDialog from "../../customs/CustomDialog";
import CustomText from "../../customs/CustomText";

export interface Feedback extends defaultSet {
  content: string;
  read: boolean;
  user_name: string;
}

export function useFeedbacks() {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);

  useEffect(() => {
    fetchDefaultWithCredential("/feedbacks", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setFeedbacks);
    });
  }, []);

  const patchFeedbackRead = (feedbackId: number, read: boolean) => {
    return fetchDefaultWithCredential(
      `/feedbacks/${feedbackId}/read`,
      "PATCH",
      {
        read,
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((data) => setFeedbacks(putObjectFromList(feedbacks, data)));
  };

  const deleteFeedback = (feedbackId: number) => {
    return fetchDefaultWithCredential(
      `/feedbacks/${feedbackId}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res
        .json()
        .then((data) => setFeedbacks(removeObjectFromList(feedbacks, data.id)));
    });
  };

  return { feedbacks, deleteFeedback, patchFeedbackRead };
}

interface FeedbackCardComponentProps {
  feedback: Feedback;
  setDeleteId: (id: number) => void;
  patchFeedbackRead: (feedbackId: number, read: boolean) => Promise<void>;
}

export function FeedbackCardComponent({
  feedback,
  setDeleteId,
  patchFeedbackRead,
}: FeedbackCardComponentProps) {
  return (
    <Card style={{ marginBottom: "50px", padding: "15px" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <p className="mt-10 mb-10 font-sub">
            {`${formatDateTemplate(feedback.created_at!)} ${formatTimeTemplate(
              feedback.created_at!
            )}`}
          </p>
        </Grid>
        <Grid item>
          <div className="d-flex align-items-center">
            <CustomCheckbox
              checked={feedback.read}
              handleChange={(checked) => {
                patchFeedbackRead(feedback.id!, checked);
              }}
            >
              읽음표시
            </CustomCheckbox>
            <div className="ml-10">
              <CustomButton onClick={() => setDeleteId(feedback.id!)}>
                삭제
              </CustomButton>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>작성자: {feedback.user_name}</div>
      <div className="mt-10">
        <CustomText
          label="내용"
          name="content"
          type="text"
          width="100%"
          readOnly
          defaultValue={feedback.content}
          style={{ backgroundColor: "white" }}
        />
      </div>
    </Card>
  );
}

function AdminPageFeedbacks() {
  const { feedbacks, deleteFeedback, patchFeedbackRead } = useFeedbacks();
  const [deleteId, setDeleteId] = useState<number>(0);

  return (
    <div className="p-30">
      <p className="font-15 font-bold">피드백</p>
      {feedbacks.map((feedback, index) => (
        <FeedbackCardComponent
          key={index}
          feedback={feedback}
          setDeleteId={setDeleteId}
          patchFeedbackRead={patchFeedbackRead}
        />
      ))}
      <CustomDialog open={deleteId !== 0}>
        <p className="font-header">정말로 삭제하시겠습니까?</p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton
              onClick={() => {
                deleteFeedback(deleteId);
                setDeleteId(0);
              }}
            >
              삭제
            </CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setDeleteId(0)}>닫기</CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
    </div>
  );
}

export default AdminPageFeedbacks;
