import React, { useState } from "react";
import { Grid } from "@mui/material";
import UserList from "../helpers/UserList";
import { fetchDefaultWithCredential } from "../../../../utils";
import { User } from "../../../../globals/user";

export type TutorStatus = "active" | "pending";

export interface AdminUserReview {
  program_id: number;
  program_image: string;
  program_header: string;
  program_name: string;
  program_description: string;
  tutor_names: string[];
  tutor_statuses: TutorStatus[];
  program_review_id: number;
  program_review_date: string;
  program_review_detail: string;
  program_reviewed_user_name: string;
  program_review_active: boolean;
  program_review_best: boolean;
  votes: number;
  voted: boolean;
  program_tutor_ids: number[];
}

export function patchVote(programReviewId: number) {
  return fetchDefaultWithCredential(
    `/program/review/${programReviewId}/vote`,
    "PATCH"
  ).then((res) => {
    if (!res.ok) {
      return res.text().then((data) => {
        throw new Error(data);
      });
    }
    return res.json();
  });
}

export function mapAdminUserReviewToCardProps(
  adminUserReview: AdminUserReview,
  refresh: () => void
) {
  const object: any = {
    program: {
      id: adminUserReview.program_id,
      programImage: adminUserReview.program_image,
      programHeader: adminUserReview.program_header,
      programName: adminUserReview.program_name,
      tutorNames: adminUserReview.tutor_names,
      tutorIds: adminUserReview.program_tutor_ids,
    },
  };
  if (adminUserReview.program_review_id) {
    object.review = {
      id: adminUserReview.program_review_id,
      reviewDate: adminUserReview.program_review_date,
      review: adminUserReview.program_review_detail,
      reviewedUserName: adminUserReview.program_reviewed_user_name,
      votes: adminUserReview.votes,
      voted: adminUserReview.voted,
      handleVote: () =>
        patchVote(adminUserReview.program_review_id).then(refresh),
    };
  }
  return object;
}

function AdminPagesEditsUsersReview() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  return (
    <Grid container>
      <Grid item xs={6}>
        <UserList currentUser={currentUser} setCurrentUser={setCurrentUser} />
      </Grid>
      {currentUser && <Grid item xs={6}></Grid>}
    </Grid>
  );
}

export default AdminPagesEditsUsersReview;
