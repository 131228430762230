import React from "react";
import { User } from "../../globals/user";
import { ReactComponent as HumanIcon } from "../../svg/human_icon.svg";

interface PropType {
  user: User;
}

interface CustomAvatarWithLocationProps {
  location: string;
}

export function CustomAvatarWithLocation({
  location,
}: CustomAvatarWithLocationProps) {
  if (location === "") {
    return (
      <div
        style={{
          backgroundImage: `url(${location})`,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      >
        <HumanIcon
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundImage: `url(${location})`,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
      }}
    ></div>
  );
}

function CustomAvatar({ user }: PropType) {
  return (
    <CustomAvatarWithLocation location={user.user_avatar?.location ?? ""} />
  );
}

export default CustomAvatar;
