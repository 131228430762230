import React, { Fragment, useEffect, useState } from "react";
import { defaultSet, TextProps } from "../interfaces";
import { fetchDefaultWithCredential } from "../utils";

import { VisualComponent } from "./FAQ";

export interface CustomFile extends defaultSet {
  filename: string;
  location: string;
}

export interface Policy extends defaultSet {
  company_name: string;
  name_position: string;
  phone: string;
  email: string;
  content: string;
  private_information_agreement: boolean;
  file?: CustomFile;
  read: boolean;
}

export interface PrivacyAgreementProps {
  privacy: TextProps;
  agreement: TextProps;
}

export function usePrivacyAgreement() {
  const [privacyAgreement, setPrivacyAgreement] =
    useState<PrivacyAgreementProps | null>(null);

  useEffect(() => {
    fetchDefaultWithCredential("/privacy-agreement", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      } else {
        return res.json().then(setPrivacyAgreement);
      }
    });
  }, []);

  return privacyAgreement;
}

function Policies() {
  const privacyAgreement = usePrivacyAgreement();

  return (
    <div className="p-50">
      {privacyAgreement && (
        <Fragment>
          <div>
            <VisualComponent
              header="이용약관"
              subheader={privacyAgreement.agreement.content}
            />
          </div>
          <div className="mt-50">
            <VisualComponent
              header="개인정보 처리방침"
              subheader={privacyAgreement.privacy.content}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default Policies;
