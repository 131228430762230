import React from "react";

function NaverBlogIcon(props: any) {
  return (
    <svg viewBox="0 -20 512 511" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M67.18 48.395h-.102c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10h.102c5.523 0 10-4.477 10-10 0-5.524-4.477-10-10-10zm0 0M116.71 48.395h-.1c-5.524 0-10 4.476-10 10 0 5.523 4.476 10 10 10h.1c5.524 0 10-4.477 10-10 0-5.524-4.476-10-10-10zm0 0M166.242 48.395h-.101c-5.524 0-10 4.476-10 10 0 5.523 4.476 10 10 10h.101c5.524 0 10-4.477 10-10 0-5.524-4.476-10-10-10zm0 0M444.309 48.395h-192.93c-5.524 0-10 4.476-10 10 0 5.52 4.476 10 10 10h192.93c5.523 0 10-4.48 10-10 0-5.524-4.477-10-10-10zm0 0M262.89 452.438a10.1 10.1 0 00-7.07 2.93c-1.86 1.859-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07c1.871 1.86 4.442 2.93 7.07 2.93s5.212-1.07 7.07-2.93c1.868-1.86 2.93-4.442 2.93-7.07s-1.062-5.211-2.93-7.07a10.08 10.08 0 00-7.07-2.93zm0 0" />
      <path d="M480.23.5H31.77C14.254.5 0 14.754 0 32.27v408.398c0 17.52 14.254 31.77 31.77 31.77H221c5.523 0 10-4.477 10-10s-4.477-10-10-10H31.77c-6.489 0-11.77-5.278-11.77-11.77V116.289h472v324.379c0 6.488-5.281 11.77-11.77 11.77H303.668c-5.523 0-10 4.476-10 10s4.477 10 10 10H480.23c17.516 0 31.77-14.25 31.77-31.77V32.27C512 14.754 497.746.5 480.23.5zM20 96.285V32.27c0-6.489 5.281-11.77 11.77-11.77h448.46c6.489 0 11.77 5.281 11.77 11.77v64.02H20zm0 0" />
      <path d="M178.04 230.5c0-19.852-16.15-36-36-36H108.37c-5.523 0-10 4.477-10 10v104c0 5.523 4.477 10 10 10h33.668c19.852 0 36-16.148 36-36 0-10.215-4.281-19.441-11.14-26 6.859-6.559 11.14-15.785 11.14-26zm-20 52c0 8.82-7.18 16-16 16H118.37v-32h23.668c8.82 0 16 7.176 16 16zm-39.669-36v-32h23.668c8.824 0 16 7.18 16 16s-7.176 16-16 16zm0 0M206.648 194.5c-5.523 0-10 4.477-10 10v80.871c0 18.27 14.864 33.129 33.13 33.129 5.523 0 10-4.477 10-10s-4.477-10-10-10c-7.239 0-13.13-5.89-13.13-13.129V204.5c0-5.523-4.476-10-10-10zm0 0M324.867 282.29c0-19.97-16.242-36.216-36.21-36.216s-36.212 16.246-36.212 36.215 16.246 36.211 36.211 36.211c19.969 0 36.211-16.242 36.211-36.21zm-52.422 0c0-8.942 7.274-16.216 16.211-16.216s16.211 7.274 16.211 16.215c0 8.938-7.27 16.211-16.21 16.211s-16.212-7.273-16.212-16.21zm0 0M377.414 346.5c-8.937 0-16.21-7.273-16.21-16.21 0-5.524-4.477-10-10-10s-10 4.476-10 10c0 19.968 16.245 36.21 36.21 36.21 19.969 0 36.215-16.242 36.215-36.21v-48c0-19.97-16.246-36.216-36.215-36.216-19.965 0-36.21 16.246-36.21 36.215s16.245 36.211 36.21 36.211c5.828 0 11.332-1.39 16.215-3.848v15.637c0 8.938-7.274 16.211-16.215 16.211zm0-48c-8.937 0-16.21-7.273-16.21-16.21 0-8.942 7.273-16.216 16.21-16.216 8.941 0 16.215 7.274 16.215 16.215 0 8.938-7.274 16.211-16.215 16.211zm0 0M108.371 416.5H403.63c5.523 0 10-4.477 10-10s-4.477-10-10-10H108.37c-5.52 0-10 4.477-10 10s4.48 10 10 10zm0 0" />
    </svg>
  );
}

export default NaverBlogIcon;
