import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { defaultSet } from "../../../interfaces";
import { fetchDefaultWithCredential } from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomText from "../../customs/CustomText";

export interface TutorType extends defaultSet {
  label: string;
}

export function useTutorTypes() {
  const [tutorTypes, setTutorTypes] = useState<TutorType[]>([]);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(`/tutor/types`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setTutorTypes);
  }, [render]);

  const refresh = () => {
    setRender(render + 1);
  };

  function postTutorType() {
    return fetchDefaultWithCredential(`/tutor/types`, "POST")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(refresh);
  }

  function putTutorType(tutorTypeId: number, label: string) {
    return fetchDefaultWithCredential(`/tutor/types/${tutorTypeId}`, "PUT", {
      label,
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(refresh);
  }

  function deleteTutorType(tutorTypeId: number) {
    return fetchDefaultWithCredential(`/tutor/types/${tutorTypeId}`, "DELETE")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(refresh);
  }

  return { tutorTypes, postTutorType, putTutorType, deleteTutorType };
}

function AdminPageTutorTypes() {
  const { tutorTypes, postTutorType, putTutorType, deleteTutorType } =
    useTutorTypes();

  return (
    <Card className="p-30">
      <div className="d-flex justify-content-space-between">
        <p className="font-bold">튜터 유형</p>
        <div className="mt-10">
          <CustomButton onClick={postTutorType}>추가</CustomButton>
        </div>
      </div>
      {tutorTypes.map((tutorType, index) => (
        <Grid
          container
          className="mt-10"
          key={tutorType.id!}
          alignItems="center"
        >
          <Grid item>
            <CustomText
              type="text"
              label={`유형 ${index + 1}`}
              name="option"
              defaultValue={tutorType.label}
              handleBlur={(e) => putTutorType(tutorType.id!, e.target.value)}
            />
          </Grid>
          <Grid item className="ml-10">
            <CustomButton onClick={() => deleteTutorType(tutorType.id!)}>
              제거
            </CustomButton>
          </Grid>
        </Grid>
      ))}
    </Card>
  );
}

export default AdminPageTutorTypes;
