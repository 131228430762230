import React, { ReactNode } from "react";
import { Button } from "@mui/material";

interface PropType {
  children: ReactNode;
  width?: number;
  height?: number;
  fullWidth?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: any;
  disabled?: boolean;
  outlined?: boolean;
  style?: object;
  className?: string;
  disableMinwidth?: boolean;
}

function CustomButton({
  width,
  height,
  children,
  fullWidth,
  type,
  onClick,
  disabled,
  outlined,
  style,
  className,
  disableMinwidth,
}: PropType) {
  return (
    <Button
      type={type}
      style={{
        width: fullWidth ? "100%" : width,
        height,
        borderRadius: "4px",
        backgroundColor: "#292929",
        color: disabled ? "rgba(255,255,255,0.3)" : "#ffffff",
        fontSize: "10px",
        fontWeight: "bold",
        textTransform: "none",
        padding: "10px",
        ...(!disableMinwidth ? {} : { minWidth: 0 }),
        ...(!outlined
          ? {}
          : {
              border: "solid 2px #959595",
              color: "#292929",
              backgroundColor: "transparent",
            }),
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
