import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { Fragment, ReactNode } from "react";

interface CheckItem {
  label: ReactNode;
  checked: boolean;
  name?: string;
  id: any;
}

interface CustomCheckProps {
  label: string;
  checkItems: CheckItem[];
  handleChange: (checkItemId: any, checked: boolean) => void;
  disabled?: boolean;
}

function CustomCheck({
  label,
  checkItems,
  handleChange,
  disabled,
}: CustomCheckProps) {
  return (
    <FormControl component="fieldset" disabled={disabled}>
      {label !== "" && <p className="font-14 font-bold">{label}</p>}
      <FormGroup>
        {checkItems.map((checkItem, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkItem.checked}
                onChange={(e) => {
                  handleChange(checkItem.id, e.target.checked);
                }}
                name={checkItem.name}
              />
            }
            label={<Fragment>{checkItem.label}</Fragment>}
            key={index}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default CustomCheck;
