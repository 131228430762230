import { Checkbox, FormControlLabel } from "@mui/material";
import React, { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { StatusProps } from "../../interfaces";
import CustomPopper from "./CustomPopper";

interface PropType {
  handleChange: (value: boolean) => void;
  checked?: boolean;
  feedback?: StatusProps;
  children?: ReactNode;
  style?: object;
  border?: boolean;
  disabled?: boolean;
  labelPlacement?: "start" | "top" | "bottom" | "end";
}

function CustomCheckbox({
  checked,
  handleChange,
  feedback,
  children,
  style,
  border = true,
  disabled,
  labelPlacement,
}: PropType) {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
    }
  }, [ref]);

  return (
    <Fragment>
      <CustomPopper feedback={feedback} anchorEl={anchorEl} />
      <div
        className={["p-relative", border ? "outline-with-border" : ""].join(
          " "
        )}
        style={{
          padding: border ? "5px 20px" : undefined,
          backgroundColor: (() => {
            if (feedback && !feedback.status) return "#ffd0d5";
            return "inherit";
          })(),
          ...style,
        }}
        ref={ref}
      >
        <FormControlLabel
          style={{ marginLeft: 0, marginRight: 0 }}
          disabled={disabled}
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => handleChange(e.target.checked)}
              color="default"
              className="width-20 height-20"
            />
          }
          label={<div className="ml-10 font-16">{children}</div>}
          labelPlacement={labelPlacement}
        />
      </div>
      {feedback && !feedback.status && feedback.message && (
        <div className="text-red font-14">{feedback.message}</div>
      )}
    </Fragment>
  );
}

export default CustomCheckbox;
