import { Avatar, Button } from "@mui/material";
import React from "react";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTutors } from "../../hooks";
import { formatMonthsToYear, isMobile, month } from "../../utils";
import { Tutor } from "../../interfaces";
import CustomBlueInfo from "../customs/CustomBlueInfo";
import CustomButton from "../customs/CustomButton";
import CustomSwiper from "../customs/CustomSwiper";
import { responsivePixel } from "../Home";
import queryString from "query-string";

interface TutorCardProps {
  tutor: Tutor;
}

function TutorCard({ tutor }: TutorCardProps) {
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const openTutorInterview = () => {
    parsed.tutor_interview_id = tutor.id!.toString();
    location.search = queryString.stringify(parsed);
    history.replace({ search: location.search });
  };

  return (
    <div>
      <div
        className="p-30 p-relative height-350"
        style={{
          backgroundColor: "#e6e6e6",
        }}
      >
        {new Date().getTime() - new Date(tutor.created_at!).getTime() <=
          3 * month && (
          <CustomButton
            className="background-red p-absolute p-5"
            style={{ top: 0, left: 0 }}
          >
            New
          </CustomButton>
        )}
        <div className="d-flex justify-content-space-evenly">
          {tutor.tutor_type1_label && (
            <CustomBlueInfo className="font-8 p-5 d-flex align-items-center">
              {tutor.tutor_type1_label}
            </CustomBlueInfo>
          )}
          {tutor.tutor_type2_label && (
            <CustomBlueInfo className="font-8 p-5 d-flex align-items-center">
              {tutor.tutor_type2_label}
            </CustomBlueInfo>
          )}
        </div>
        <Avatar className="mt-10 width-100 height-100 margin-center">
          {tutor.tutor_avatar ? (
            <img
              src={tutor.tutor_avatar}
              alt=""
              className="width-100-percentage height-100-percentage"
              style={{
                borderRadius: "50%",
              }}
            />
          ) : (
            <PersonIcon className="width-100-percentage height-100-percentage p-10 text-gray" />
          )}
        </Avatar>
        <div className="font-16 font-bold mt-10">{tutor.tutor_name}</div>
        <div className="font-12 font-bold mt-10">튜터 경력</div>
        <div className="mt-10 d-flex justify-content-center mt-10">
          <div
            className="background-white font-10 p-10"
            style={{
              borderRadius: responsivePixel(6),
            }}
          >
            {formatMonthsToYear(tutor.months)}
          </div>
          <div
            className="background-white font-10 p-10 ml-10"
            style={{
              borderRadius: responsivePixel(6),
            }}
          >
            {tutor.counts}회 진행
          </div>
        </div>
      </div>
      <div
        className="height-150"
        style={{
          backgroundColor: "#959595",
        }}
      >
        <div className="d-flex justify-content-center align-items-center height-100-percentage">
          <div>
            <CustomButton
              className="width-50 height-50 min-width-0"
              onClick={openTutorInterview}
              style={{
                borderRadius: "50%",
              }}
            >
              <ArticleIcon />
            </CustomButton>
            <p className="text-white font-10 font-bold text-align-center">
              튜터 인터뷰
            </p>
          </div>
          <div className="ml-20">
            <Link to={"/tutor/" + tutor.id!} className="text-decoration-none">
              <CustomButton
                className="width-50 height-50 min-width-0"
                style={{
                  borderRadius: "50%",
                }}
              >
                <PersonIcon />
              </CustomButton>
            </Link>
            <p className="text-white font-10 font-bold text-align-center">
              튜터 홈
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function TutorProfile() {
  const { tutors, loaded } = useTutors();
  if (!loaded) return null;
  return (
    <div className="p-50 text-align-center">
      <img
        src="/philous_logo_letter.png"
        alt=""
        className="width-150 height-150"
      />
      <div>
        <p className="font-header">필로어스 튜터 프로필</p>
        <p className="font-sub text-secondary">
          필로어스 프로그램을 함께 해주실 튜터분들을 소개합니다.
        </p>
      </div>
      <div className="mt-50">
        <CustomSwiper slides={isMobile() ? 1 : 4}>
          {tutors
            .filter((tutor) => tutor.status === "active")
            .map((tutor, index) => (
              <div key={index}>
                <div className="width-250 margin-center">
                  <TutorCard tutor={tutor} />
                </div>
              </div>
            ))}
        </CustomSwiper>
      </div>
      <div className="mt-50">
        <p className="font-header">튜터 모집</p>
        <p className="font-sub text-secondary">
          당신도 필로어스의 튜터가 될 수 있어요!
          <br />
          필로어스와 함께 하지 않으실래요?
        </p>
      </div>
      <div className="mt-30">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            window.open(
              "https://www.notion.so/philous/453ad510321942378c600eee24d29ab8",
              "_blank"
            )
          }
        >
          지원하기
        </Button>
      </div>
    </div>
  );
}

export default TutorProfile;
