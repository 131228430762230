import {
  Card,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { useUsers } from "../../../../hooks";
import {
  fetchDefaultWithCredential,
  getAge,
  graphColors,
} from "../../../../utils";
import CustomAvatar from "../../../customs/CustomAvatar";
import CustomSelect from "../../../customs/CustomSelect";
import { User } from "../../../../globals/user";

interface ShowBarChartPropType {
  data: any;
}

function ShowBarChart({ data }: ShowBarChartPropType) {
  return (
    <Bar
      data={data}
      options={{
        scales: {
          y: {
            stacked: true,
          },
          x: {
            stacked: true,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer: (context: any[]) => {
                return (
                  "총 인원: " +
                  context.reduce((prev, curr) => prev + curr.raw, 0)
                );
              },
            },
          },
        },
      }}
      height={100}
    />
  );
}

type CategoryType = "gender" | "age";

function getYearMonth(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}`;
}

function refineDataByCategory(
  category: CategoryType,
  activated: boolean,
  users: User[]
) {
  let filteredUser = [] as User[];
  if (activated) {
    filteredUser = users.filter((user) => {
      const today = new Date().getTime();
      const yearBefore = today - 1000 * 60 * 60 * 24 * 365;
      const userCreatedAt = new Date(user.created_at!).getTime();
      return yearBefore <= userCreatedAt && userCreatedAt <= today;
    });
  } else {
    filteredUser = users.filter((user) => {
      return user.deactivated !== null;
    });
  }
  if (category === "gender") {
    const labels: string[] = [];
    const maleCounts: number[] = [];
    const femaleCounts: number[] = [];
    const male = filteredUser.filter((user) => user.gender === "male");
    const female = filteredUser.filter((user) => user.gender === "female");
    // get all labels from today to last 12 months trailings
    for (let i = 1; i <= 12; i++) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      currentDate.setDate(1);
      currentDate.setMonth(currentDate.getMonth() - i + 1);
      const dateKey = getYearMonth(currentDate);
      labels.splice(0, 0, dateKey);
      // now, insert male, female count of targeted created at
      maleCounts.splice(
        0,
        0,
        male.filter(
          (maleUser) => getYearMonth(new Date(maleUser.created_at)) === dateKey
        ).length
      );
      femaleCounts.splice(
        0,
        0,
        female.filter(
          (femaleUser) =>
            getYearMonth(new Date(femaleUser.created_at)) === dateKey
        ).length
      );
    }

    const data = {
      labels,
      datasets: [
        {
          label: "남성",
          data: maleCounts,
          backgroundColor: "#0062ff",
        },
        {
          label: "여성",
          data: femaleCounts,
          backgroundColor: "#57b8ff",
        },
      ],
    };
    return data;
  } else if (category === "age") {
    const labels: string[] = [];
    const age10sCounts: number[] = [];
    const age20sCounts: number[] = [];
    const age30sCounts: number[] = [];
    const age40sCounts: number[] = [];
    const age50sCounts: number[] = [];
    const age60sCounts: number[] = [];
    const age10s = filteredUser.filter(
      (user) => getAge(user.date_of_birth) < 20
    );
    const age20s = filteredUser.filter(
      (user) =>
        getAge(user.date_of_birth) >= 20 && getAge(user.date_of_birth) < 30
    );
    const age30s = filteredUser.filter(
      (user) =>
        getAge(user.date_of_birth) >= 30 && getAge(user.date_of_birth) < 40
    );
    const age40s = filteredUser.filter(
      (user) =>
        getAge(user.date_of_birth) >= 40 && getAge(user.date_of_birth) < 50
    );
    const age50s = filteredUser.filter(
      (user) =>
        getAge(user.date_of_birth) >= 50 && getAge(user.date_of_birth) < 60
    );
    const age60s = filteredUser.filter(
      (user) => getAge(user.date_of_birth) >= 60
    );

    // get all labels from today to last 12 months trailings
    for (let i = 1; i <= 12; i++) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      currentDate.setMonth(currentDate.getMonth() - i + 1);
      const dateKey = getYearMonth(currentDate);
      labels.splice(0, 0, dateKey);
      // now, insert male, female count of targeted created at
      age10sCounts.splice(
        0,
        0,
        age10s.filter(
          (user) => getYearMonth(new Date(user.created_at)) === dateKey
        ).length
      );
      age20sCounts.splice(
        0,
        0,
        age20s.filter(
          (user) => getYearMonth(new Date(user.created_at)) === dateKey
        ).length
      );
      age30sCounts.splice(
        0,
        0,
        age30s.filter(
          (user) => getYearMonth(new Date(user.created_at)) === dateKey
        ).length
      );
      age40sCounts.splice(
        0,
        0,
        age40s.filter(
          (user) => getYearMonth(new Date(user.created_at)) === dateKey
        ).length
      );
      age50sCounts.splice(
        0,
        0,
        age50s.filter(
          (user) => getYearMonth(new Date(user.created_at)) === dateKey
        ).length
      );
      age60sCounts.splice(
        0,
        0,
        age60s.filter(
          (user) => getYearMonth(new Date(user.created_at)) === dateKey
        ).length
      );
    }

    const data = {
      labels,
      datasets: [
        {
          label: "10대",
          data: age10sCounts,
          backgroundColor: "red",
        },
        {
          label: "20대",
          data: age20sCounts,
          backgroundColor: "orange",
        },
        {
          label: "30대",
          data: age30sCounts,
          backgroundColor: "yellow",
        },
        {
          label: "40대",
          data: age40sCounts,
          backgroundColor: "green",
        },
        {
          label: "50대",
          data: age50sCounts,
          backgroundColor: "blue",
        },
        {
          label: "60대",
          data: age60sCounts,
          backgroundColor: "purple",
        },
      ],
    };
    data.datasets.forEach((dataset, i) => {
      dataset.backgroundColor = graphColors[i];
    });
    return data;
  }
}

interface HowPhilousInfo {
  id: number;
  created_at: string;
  name: string;
  how_philous: string;
  how_philous_detail: string;
}

function HowPhilousChartComponent() {
  const [howPhilousInfos, setHowPhilousInfo] = useState<
    HowPhilousInfo[] | null
  >(null);

  useEffect(() => {
    fetchDefaultWithCredential(`/user/how_philous/list`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setHowPhilousInfo);
    });
  }, []);

  if (!howPhilousInfos) return null;

  const howPhilousMap = {} as any;
  const howPhilousSet = new Set<string>();
  const details = [] as HowPhilousInfo[];
  for (const howPhilousInfo of howPhilousInfos) {
    if (howPhilousInfo.how_philous === "") continue;
    const yearMonth = getYearMonth(new Date(howPhilousInfo.created_at));
    if (!(yearMonth in howPhilousMap)) {
      howPhilousMap[yearMonth] = {};
    }
    if (!(howPhilousInfo.how_philous in howPhilousMap[yearMonth])) {
      howPhilousMap[yearMonth][howPhilousInfo.how_philous] = 0;
    }
    howPhilousMap[yearMonth][howPhilousInfo.how_philous]++;
    if (
      howPhilousInfo.how_philous === "기타 (직접 입력)" &&
      howPhilousInfo.how_philous_detail !== ""
    ) {
      details.push(howPhilousInfo);
    }
    howPhilousSet.add(howPhilousInfo.how_philous);
  }

  // now, we have keys set up, we can set template for dataset
  const datasets = [] as any[];
  const labels = [] as string[];
  const labelIndexMap = {} as any;
  for (let i = 1; i <= 12; i++) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setMonth(currentDate.getMonth() - 12 + i);
    const dateKey = getYearMonth(currentDate);
    labels.push(dateKey);
    labelIndexMap[dateKey] = i - 1;
  }
  let i = 0;
  const howPhilousIndexMap = {} as any;
  for (const howPhilous of Array.from(howPhilousSet)) {
    datasets.push({
      label: howPhilous,
      data: new Array(12).fill(0),
      backgroundColor: graphColors[i],
    });
    howPhilousIndexMap[howPhilous] = i;
    i++;
  }

  for (const yearMonth in howPhilousMap) {
    // key will be year month, we know where mapped
    if (!(yearMonth in labelIndexMap)) continue;
    const index = labelIndexMap[yearMonth];
    for (const howPhilous in howPhilousMap[yearMonth]) {
      // now it's key of how philous, we can use previous index map to map
      if (!(howPhilous in howPhilousIndexMap)) continue;
      datasets[howPhilousIndexMap[howPhilous]].data[index] =
        howPhilousMap[yearMonth][howPhilous];
    }
  }

  const data = {
    labels,
    datasets,
  };

  return (
    <div>
      <div className="font-18 font-bold">오시게 된 경로 차트</div>
      <div className="mt-10">
        <div className="mt-10">
          <ShowBarChart data={data} />
        </div>
        <div className="mt-10">
          <div className="font-14 font-bold text-align-center">기타 응답</div>
          <div className="mt-10 height-300 overflow-auto outline-with-border p-10">
            {details.map((detail, index) => (
              <div className="outline-with-border p-10 mt-10" key={index}>
                {getYearMonth(new Date(detail.created_at))} | {detail.id}.{" "}
                {detail.name}: {detail.how_philous_detail}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function AdminPageUsersSignup() {
  const { users } = useUsers();
  const [category, setCategory] = useState<CategoryType>("gender");

  const deactivated = users
    .filter((user) => user.deactivated)
    .sort(
      (a, b) =>
        new Date(a.deactivated!).getTime() - new Date(b.deactivated!).getTime()
    );

  return (
    <div>
      <div style={{ width: "30%" }}>
        <CustomSelect
          name="category"
          label="프로그램 유형"
          items={[
            {
              label: "성별",
              value: "gender",
            },
            {
              label: "나이",
              value: "age",
            },
          ]}
          value={category}
          handleChange={(value) => setCategory(value as CategoryType)}
        />
      </div>
      <div className="mt-30">
        <Typography style={{ fontWeight: 600, fontSize: "18px" }}>
          신규 가입 현황
        </Typography>
        <div style={{ marginTop: "15px" }}>
          <ShowBarChart data={refineDataByCategory(category, true, users)} />
        </div>
      </div>
      <div className="mt-20">
        <Card style={{ padding: "20px" }}>
          <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
            새로운 고객
          </Typography>
          <div
            className="mt-10"
            style={{
              overflow: "auto",
            }}
          >
            <Grid container style={{ flexWrap: "nowrap", overflow: "scroll" }}>
              {users.map((user, index) => (
                <Grid item key={index}>
                  <ListItem>
                    <ListItemAvatar>
                      <div className="width-40 height-40">
                        <CustomAvatar user={user} />
                      </div>
                    </ListItemAvatar>
                    <ListItemText primary={user.name} secondary={user.email} />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </div>
        </Card>
      </div>
      <div className="mt-30">
        <Typography style={{ fontWeight: 600, fontSize: "18px" }}>
          휴면 고객 현황
        </Typography>
        <div style={{ marginTop: "15px" }}>
          <ShowBarChart data={refineDataByCategory(category, false, users)} />
        </div>
      </div>
      <div className="mt-20">
        <Card style={{ padding: "20px", filter: "grayscale(75%)" }}>
          <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
            휴면 고객
          </Typography>

          <div
            className="mt-10"
            style={{
              overflow: "auto",
            }}
          >
            <Grid container style={{ flexWrap: "nowrap", overflow: "scroll" }}>
              {deactivated.map((deactivatedUser, index) => (
                <Grid item key={index}>
                  <ListItem>
                    <ListItemAvatar>
                      <div className="width-40 height-40">
                        <CustomAvatar user={deactivatedUser} />
                      </div>
                    </ListItemAvatar>
                    <ListItemText
                      primary={deactivatedUser.name}
                      secondary={deactivatedUser.email}
                    />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </div>
        </Card>
      </div>
      <div className="mt-30">
        <HowPhilousChartComponent />
      </div>
    </div>
  );
}

export default AdminPageUsersSignup;
