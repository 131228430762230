import { Box, Chip, Grid, Hidden, Popover, Typography } from "@mui/material";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { fetchDefaultWithCredential, isMobile } from "../utils";
import { useHistory, useLocation } from "react-router-dom";
import Info from "./Mypage/Profile/Info";
import Phone from "./Mypage/Profile/Phone";
import Password from "./Mypage/Profile/Password";
import { HomeContainer, textColor, widthPercentage } from "./Home";
import { ReactComponent as PencilIcon } from "../svg/pencil_icon.svg";
import { ReactComponent as TrophyIcon } from "../svg/trophy_icon.svg";
import { ReactComponent as DownloadIcon } from "../svg/download_icon.svg";
import { ReactComponent as RightArrow } from "../svg/right_arrow.svg";
import { ReactComponent as LeftArrow } from "../svg/left_arrow.svg";
import { ReactComponent as InfoIcon } from "../svg/info_icon.svg";
import { ReactComponent as PersonPlusIcon } from "../svg/person_plus_icon.svg";
import { ReactComponent as PresentIcon } from "../svg/present_icon.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useOptions } from "./admins/pages/Options";
import CustomAvatar from "./customs/CustomAvatar";
import Review from "./programs/Review";
import { couponLabeling } from "./Cart";
import Registered from "./programs/Registered";
import Report from "./programs/Report";
import { useUser } from "../globals/user";
import { useNotifications } from "../globals/notification";
import Library from "./programs/Library";

interface DoughnutComponentProps {
  labels: string[];
  data: number[];
}

function calculateRatio(dataNumber: number, dataSum: number) {
  const ratio = Math.round((dataNumber / dataSum) * 100);
  return `${ratio}%`;
}

export function DoughnutComponent({ labels, data }: DoughnutComponentProps) {
  const colorArray = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "darkblue",
    "purple",
  ];

  const dataSum = data.reduce((acc, curr) => acc + curr, 0);

  interface RectangleProps {
    color: string;
  }

  const Rectangle = ({ color }: RectangleProps) => (
    <div
      style={{
        width: "12px",
        height: "12px",
        borderRadius: "2px",
        backgroundColor: color,
      }}
    ></div>
  );

  return (
    <Fragment>
      {/* <Doughnut
        options={{ maintainAspectRatio: true }}
        legend={{ display: false }}
        data={{
          labels,
          datasets: [
            {
              data,
              backgroundColor: colorArray,
              borderColor: colorArray,
              borderWidth: 1,
            },
          ],
        }}
      /> */}
      <div className="mt-30">
        <Grid container direction="column">
          {data.map((dataNumber, index) => (
            <Grid item container key={index} alignItems="center">
              <Rectangle color={colorArray[index]} />
              <Typography style={{ marginLeft: "20px", fontSize: "8px" }}>
                {labels[index]}
              </Typography>
              <Typography
                style={{
                  marginLeft: "auto",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {calculateRatio(dataNumber, dataSum)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </Fragment>
  );
}

interface ProgramReport {
  program_name: string;
  poster_location: string;
  program_options: string[];
  reviewed: boolean;
  to_audiences: string;
  from_tutor?: string;
  read?: string;
}

interface ReportDetail {
  label: string;
  data: number;
}

export interface ReadingReport {
  genres: ReportDetail[];
  difficulties: ReportDetail[];
  growth_hours: number;
  books_fulfilled: number;
  growth_months_from_start: number;
}

interface LibraryTemplate {
  poster_location: string;
  name: string;
}

export interface UserLibrary {
  programs: LibraryTemplate[];
  pre_seminars: LibraryTemplate[];
  events: LibraryTemplate[];
}

export const libraryTemplateShows = (templates: LibraryTemplate[]) => {
  const res = [];
  for (let i = 0; i < templates.length / 5; i++) {
    const temp = [];
    for (let j = 0; j < 5; j++) {
      const currIndex = i * 5 + (j % 5);
      if (currIndex === templates.length) break;
      temp.push(
        <Grid item style={{ width: "300px" }} key={j}>
          <img
            src={templates[currIndex].poster_location}
            style={{
              width: "100%",
              height: "300px",
              backgroundColor: "lightblue",
            }}
            alt=""
          />
          <p className="font-15 text-white font-bold">
            {templates[currIndex].name}
          </p>
        </Grid>
      );
    }
    res.push(
      <Grid
        container
        spacing={2}
        style={{ marginTop: i === 0 ? 0 : 50 }}
        key={i}
      >
        {temp}
      </Grid>
    );
  }
  return res;
};

export interface UserProgramReports {
  status: "no-program" | "in-progress" | "done";
  program_reports: ProgramReport[];
}

interface RecommendRewardInfo {
  coupon_title?: string;
  point?: number;
}

interface RecommendInfo {
  code: string;
  recommendee: RecommendRewardInfo;
  recommender: RecommendRewardInfo;
}

function InfoComponent() {
  const [recommendInfo, setRecommendInfo] = useState<RecommendInfo | null>(
    null
  );
  const user = useUser();
  const { options: personalGoalOptions } = useOptions("personal_goal");
  const history = useHistory();
  const [recommendRef, setRecommendRef] = useState<HTMLDivElement | null>(null);
  const [recommendOpen, setRecommendOpen] = useState<boolean>(false);
  const [couponRef, setCouponRef] = useState<HTMLDivElement | null>(null);
  const [couponOpen, setCouponOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchDefaultWithCredential(`/recommend/mypage/info`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(setRecommendInfo);
    });
  }, []);

  if (!user || !recommendInfo || !personalGoalOptions) return null;
  return (
    <div
      style={{
        padding: `${widthPercentage(isMobile() ? 9 : 2.5)}px ${widthPercentage(
          isMobile() ? 4 : 3
        )}px`,
        backgroundColor: "#F1F1FA",
        height: widthPercentage(isMobile() ? 97 : 14.125),
      }}
    >
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <div
          style={{
            position: "absolute",
            top: widthPercentage(isMobile() ? 7 : 0),
            left: 0,
          }}
        >
          <div style={{ display: isMobile() ? "block" : "flex" }}>
            <div
              style={{
                width: widthPercentage(isMobile() ? 15 : 5.375),
                height: widthPercentage(isMobile() ? 15 : 5.375),
              }}
            >
              <CustomAvatar user={user} />
            </div>
            <div
              style={{
                marginLeft: isMobile() ? 0 : widthPercentage(1.5),
                marginTop: isMobile() ? 2.5 : 0,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="font-noto-medium"
                  style={{
                    fontSize: widthPercentage(isMobile() ? 6.75 : 2),
                  }}
                >
                  반가워요! {user.name}님
                </div>
                <div
                  style={{ marginLeft: widthPercentage(isMobile() ? 3 : 0.75) }}
                >
                  <div
                    style={{
                      padding: `${widthPercentage(
                        isMobile() ? 0.75 : 0.1875
                      )}px ${widthPercentage(isMobile() ? 4 : 1)}px`,
                      border: `${widthPercentage(
                        isMobile() ? 0.25 : 0.0625
                      )}px solid #999999`,
                      borderRadius: widthPercentage(isMobile() ? 25 : 6.25),
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: widthPercentage(isMobile() ? 4 : 1),
                        height: widthPercentage(isMobile() ? 4 : 1),
                        display: "flex",
                      }}
                    >
                      <PencilIcon style={{ width: "100%", height: "100%" }} />
                    </div>
                    <div
                      onClick={() => history.push("/mypage/profile/info")}
                      style={{
                        marginLeft: widthPercentage(isMobile() ? 0.5 : 0.125),
                        color: "#767676",
                        fontSize: widthPercentage(isMobile() ? 3.5 : 0.875),
                        cursor: "pointer",
                      }}
                    >
                      수정
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  color: "#505050",
                  fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                }}
              >
                {user.email}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: widthPercentage(isMobile() ? 23.75 : 0),
          }}
        >
          <div
            onClick={() => history.push("/mypage/profile/info")}
            style={{
              borderRadius: widthPercentage(isMobile() ? 25 : 6.25),
              backgroundColor: "white",
              padding: `${widthPercentage(
                isMobile() ? 2.75 : 0.6875
              )}px ${widthPercentage(isMobile() ? 5.25 : 1.3125)}px`,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                width: widthPercentage(isMobile() ? 4 : 1),
                height: widthPercentage(isMobile() ? 4 : 1),
                display: "flex",
              }}
            >
              <TrophyIcon
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div
              style={{
                marginLeft: widthPercentage(isMobile() ? 1.5 : 0.375),
                fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
                color: "#505050",
              }}
            >
              {(() => {
                const option = personalGoalOptions.find(
                  (personalGoalOption) =>
                    personalGoalOption.id === user.meta.personal_goal_option_id
                );
                if (!option) return "나만의 목표를 선택해보세요";
                return option.label;
              })()}
            </div>
            <div
              style={{
                marginLeft: widthPercentage(isMobile() ? 1 : 0.25),
                width: widthPercentage(isMobile() ? 4 : 1),
                height: widthPercentage(isMobile() ? 4 : 1),
                display: "flex",
              }}
            >
              <KeyboardArrowDownIcon
                style={{
                  width: "100%",
                  height: "100%",
                  color: "#505050",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() =>
              fetchDefaultWithCredential("/readinglist", "GET")
                .then((res) => {
                  if (!res.ok) {
                    return res.json().then(({ error }) => {
                      throw new Error(error);
                    });
                  }
                  return res.blob();
                })
                .then((blob) => {
                  const url = URL.createObjectURL(blob);
                  setTimeout(() => {
                    const open = window.open();
                    if (open) {
                      open.location.href = url;
                    }
                  });
                })
            }
          >
            <div
              style={{
                marginLeft: widthPercentage(isMobile() ? 1.5 : 0.5625),
                color: "#505050",
                fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
              }}
            >
              필로어스 인문고전 목록 PDF로 다운받기
            </div>
            <div
              style={{
                marginLeft: widthPercentage(isMobile() ? 1.5 : 0.375),
                width: widthPercentage(isMobile() ? 5.5 : 1.375),
                height: widthPercentage(isMobile() ? 5.5 : 1.375),
                display: "flex",
              }}
            >
              <DownloadIcon
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: isMobile() ? 0 : undefined,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile() ? "space-evenly" : undefined,
            }}
          >
            <div
              style={{
                marginRight: widthPercentage(isMobile() ? 0 : 1.75),
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile() ? "center" : "flex-end",
                justifyContent: isMobile() ? "center" : undefined,
              }}
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                ref={setRecommendRef}
                onClick={() => setRecommendOpen(!recommendOpen)}
              >
                <div
                  style={{
                    color: "#767676",
                    fontSize: widthPercentage(isMobile() ? 3.5 : 0.9375),
                  }}
                >
                  내 추천인 코드
                </div>
                <div
                  style={{
                    marginLeft: widthPercentage(isMobile() ? 1 : 0.125),
                    width: widthPercentage(isMobile() ? 4 : 1),
                    height: widthPercentage(isMobile() ? 4 : 1),
                    display: "flex",
                  }}
                >
                  <InfoIcon
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
              <Popover
                open={recommendOpen}
                anchorEl={recommendRef}
                onClose={() => setRecommendOpen(false)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div
                  style={{
                    border: `${widthPercentage(
                      isMobile() ? 0.25 : 0.0625
                    )}px solid #C4C4C4`,
                    padding: widthPercentage(isMobile() ? 7 : 1.75),
                    fontSize: widthPercentage(isMobile() ? 3.5 : 0.875),
                    color: "#767676",
                    backgroundColor: "white",
                    userSelect: "none",
                  }}
                >
                  <span className="font-noto-black">
                    내 추천인 코드: {recommendInfo.code}
                  </span>
                  <div
                    style={{
                      marginTop: widthPercentage(isMobile() ? 1 : 0.25),
                      height: widthPercentage(isMobile() ? 0.25 : 0.0625),
                      backgroundColor: "#767676",
                    }}
                  ></div>
                  <div
                    style={{
                      marginTop: widthPercentage(isMobile() ? 8 : 2),
                      display: "flex",
                    }}
                  >
                    <div>
                      <PersonPlusIcon
                        style={{
                          width: widthPercentage(isMobile() ? 6 : 1.5),
                          height: widthPercentage(isMobile() ? 6 : 1.5),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: widthPercentage(isMobile() ? 1 : 0.25),
                      }}
                    >
                      <div>
                        필로어스를 추천해주고 싶은 지인에게 내 코드넘버를
                        알려주세요!
                      </div>
                      <div
                        style={{
                          marginTop: widthPercentage(isMobile() ? 4 : 1),
                        }}
                      >
                        회원가입시 추천인 코드를 입력한 지인에게는{" "}
                        <span className="font-noto-black">
                          {(() => {
                            const recommenderInfo = recommendInfo.recommender;
                            if (recommenderInfo.coupon_title) {
                              return recommenderInfo.coupon_title;
                            }
                            if (recommenderInfo.point) {
                              return `${recommenderInfo.point}원 적립금`;
                            }
                            return "";
                          })()}
                        </span>
                        <br />
                        나에게는{" "}
                        <span className="font-noto-black">
                          {(() => {
                            const recommendeeInfo = recommendInfo.recommendee;
                            if (recommendeeInfo.coupon_title) {
                              return recommendeeInfo.coupon_title;
                            }
                            if (recommendeeInfo.point) {
                              return `${recommendeeInfo.point}원 적립금`;
                            }
                            return "";
                          })()}{" "}
                          혜택
                        </span>
                        이 주어집니다.
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
              <div
                className="font-noto-black"
                style={{
                  marginTop: widthPercentage(isMobile() ? 0.5 : 0.25),
                  fontSize: widthPercentage(isMobile() ? 5 : 2.25),
                  color: "#4A148C",
                }}
              >
                {recommendInfo.code}
              </div>
            </div>
            <div
              style={{
                height: widthPercentage(isMobile() ? 4 : 2.375),
                width: widthPercentage(isMobile() ? 0.25 : 0.0625),
                backgroundColor: "white",
                marginRight: widthPercentage(isMobile() ? 0 : 4.375),
              }}
            ></div>
            <div
              style={{
                marginRight: widthPercentage(isMobile() ? 0 : 1.75),
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile() ? "center" : "flex-end",
                justifyContent: isMobile() ? "center" : undefined,
              }}
            >
              <div
                style={{
                  color: "#767676",
                  fontSize: widthPercentage(isMobile() ? 3.5 : 0.9375),
                }}
              >
                내 적립금
              </div>
              <div
                className="font-noto-black"
                style={{
                  marginTop: widthPercentage(isMobile() ? 0.5 : 0.25),
                  fontSize: widthPercentage(isMobile() ? 5 : 2.25),
                  color: "#4A148C",
                }}
              >
                {user.point.toLocaleString()}
              </div>
            </div>
            <div
              style={{
                height: widthPercentage(isMobile() ? 4 : 2.375),
                width: widthPercentage(isMobile() ? 0.25 : 0.0625),
                backgroundColor: "white",
                marginRight: widthPercentage(isMobile() ? 0 : 4.375),
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile() ? "center" : "flex-end",
                justifyContent: isMobile() ? "center" : undefined,
              }}
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                ref={setCouponRef}
                onClick={() =>
                  user.coupons.length > 0 && setCouponOpen(!couponOpen)
                }
              >
                <div
                  style={{
                    color: "#767676",
                    fontSize: widthPercentage(isMobile() ? 3.5 : 0.9375),
                  }}
                >
                  내 쿠폰
                </div>
                <div
                  style={{
                    marginLeft: widthPercentage(isMobile() ? 1 : 0.125),
                    width: widthPercentage(isMobile() ? 4 : 1),
                    height: widthPercentage(isMobile() ? 4 : 1),
                    display: "flex",
                  }}
                >
                  <KeyboardArrowDownIcon
                    style={{
                      width: "100%",
                      height: "100%",
                      color: "#767676",
                    }}
                  />
                </div>
              </div>
              <Popover
                open={couponOpen}
                anchorEl={couponRef}
                onClose={() => setCouponOpen(false)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div
                  style={{
                    border: `${widthPercentage(
                      isMobile() ? 0.25 : 0.0625
                    )}px solid #C4C4C4`,
                    padding: widthPercentage(isMobile() ? 7 : 1.75),
                    fontSize: widthPercentage(isMobile() ? 3.5 : 0.875),
                    color: "#767676",
                    backgroundColor: "white",
                    maxHeight: widthPercentage(isMobile() ? 50 : 12.5),
                    overflow: "auto",
                    userSelect: "none",
                  }}
                >
                  <span className="font-noto-black">
                    보유한 쿠폰 [{user.coupons.length}개]
                  </span>
                  <div
                    style={{
                      marginTop: widthPercentage(isMobile() ? 1 : 0.25),
                      height: widthPercentage(isMobile() ? 0.25 : 0.0625),
                      backgroundColor: "#767676",
                    }}
                  ></div>
                  <div
                    style={{
                      marginTop: widthPercentage(isMobile() ? 8 : 2),
                      display: "flex",
                    }}
                  >
                    <div>
                      <PresentIcon
                        style={{
                          width: widthPercentage(isMobile() ? 6 : 1.5),
                          height: widthPercentage(isMobile() ? 6 : 1.5),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: widthPercentage(isMobile() ? 1 : 0.25),
                      }}
                    >
                      {user.coupons.map((coupon, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: widthPercentage(isMobile() ? 4 : 1),
                          }}
                        >
                          {couponLabeling(coupon)}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover>
              <div
                className="font-noto-black"
                style={{
                  marginTop: widthPercentage(isMobile() ? 0.5 : 0.25),
                  fontSize: widthPercentage(isMobile() ? 5 : 2.25),
                  color: "#4A148C",
                }}
              >
                {user.coupons.length}개
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface MenuTabProps {
  menu: SubMenuComponentProps;
}

function MenuTab({ menu }: MenuTabProps) {
  const history = useHistory();
  const { subUrl } = useContext(MyPageContext);
  const currTab = subUrl === menu.link;
  return (
    <Fragment>
      <div
        className="font-noto-medium"
        style={{
          padding: `${widthPercentage(
            isMobile() ? 3.25 : 0.8125
          )}px ${widthPercentage(isMobile() ? 2 : 0.5)}px`,
          color: currTab ? textColor : "#505050",
          fontSize: widthPercentage(isMobile() ? 4.5 : 1.125),
          cursor: menu.link ? "pointer" : "default",
          backgroundColor: currTab ? "#F1F1FA" : "inherit",
          fontWeight: currTab ? 700 : 500,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => {
          if (!menu.link) return;
          history.push("/mypage" + menu.link);
        }}
      >
        <div className="d-flex align-items-center">
          <div>{menu.title}</div>
          {Boolean(menu.notification) && (
            <div
              style={{
                marginLeft: widthPercentage(isMobile() ? 1.25 : 0.3125),
              }}
            >
              <Chip label={menu.notification} color="secondary" size="small" />
            </div>
          )}
        </div>
        {isMobile() && menu.link && (
          <Box
            style={{
              width: widthPercentage(4),
              height: widthPercentage(4),
              display: "flex",
            }}
            sx={{
              "& path": {
                stroke: "#505050",
              },
            }}
          >
            <RightArrow style={{ width: "100%", height: "100%" }} />
          </Box>
        )}
      </div>
      {menu.menus.map((menu, index) => (
        <div
          style={{
            paddingLeft: widthPercentage(isMobile() ? 2 : 0.5),
            backgroundColor: currTab ? "#F1F1FA" : "inherit",
          }}
          key={index}
        >
          <MenuTab menu={menu} />
        </div>
      ))}
    </Fragment>
  );
}

interface SubMenuComponentProps {
  title: string;
  menus: SubMenuComponentProps[];
  link?: string;
  notification?: number;
}

function SubMenuComponent({ title, menus }: SubMenuComponentProps) {
  return (
    <Fragment>
      <div
        style={{
          color: "#999999",
          fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
        }}
      >
        {title}
      </div>
      <div style={{ marginTop: widthPercentage(isMobile() ? 2.5 : 0.625) }}>
        {menus.map((menu, index) => (
          <div key={index}>
            <MenuTab menu={menu} />
          </div>
        ))}
      </div>
    </Fragment>
  );
}

function MenuComponent() {
  const notifications = useNotifications();
  return (
    <Fragment>
      <div>
        <SubMenuComponent
          title="나의 프로그램 정보"
          menus={[
            "/program/library",
            "/program/reviews",
            "/program/reports",
            "/program/registered",
          ].map((link) => ({
            title: linkComponentMap[link].name,
            link,
            menus: [],
            notification: (() => {
              const notification = linkComponentMap[link].notification;
              if (!notification) return undefined;
              return notification(notifications.user);
            })(),
          }))}
        />
      </div>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 14.25 : 2.5),
        }}
      >
        <SubMenuComponent
          title="나의 계정 정보"
          menus={[
            {
              title: "프로필 수정",
              menus: [
                "/profile/info",
                "/profile/phone",
                "/profile/password",
              ].map((link) => ({
                title: linkComponentMap[link].name,
                link,
                menus: [],
              })),
            },
          ]}
        />
      </div>
    </Fragment>
  );
}

interface LinkComponentMap {
  [link: string]: {
    name: string;
    component: () => JSX.Element | null;
    notification?: (notification: any) => number;
    full?: boolean;
  };
}

const linkComponentMap: LinkComponentMap = {
  // {
  //   title: "메인 보드",
  //   menus: [],
  // },
  // {
  //   title: "나의 서재",
  //   menus: [],
  // },
  "/program/reports": {
    name: "독서 성장 리포트",
    component: Report,
    notification: (notification) => notification?.reports.length ?? 0,
  },
  "/program/library": {
    name: "나의 서재",
    component: () => <Library />,
  },
  "/program/reviews": {
    name: "나의 후기",
    component: Review,
  },
  "/program/registered": {
    name: "세미나 신청목록",
    component: () => <Registered />,
    notification: (notification) => notification?.registered_list ?? 0,
  },
  "/profile/info": {
    name: "프로필 정보",
    component: Info,
    full: true,
  },
  "/profile/phone": {
    name: "휴대번호 변경",
    component: Phone,
    full: true,
  },
  "/profile/password": {
    name: "비밀번호",
    component: Password,
    full: true,
  },
};

function MainComponent() {
  const { subUrl } = useContext(MyPageContext);
  const linkMap = linkComponentMap[subUrl];
  if (!linkMap) return null;
  const Component = linkMap.component;
  return <Component />;
}

interface MyPageContextProps {
  subUrl: string;
}

const MyPageContext = React.createContext({} as MyPageContextProps);

function MyPage() {
  const location = useLocation();
  const history = useHistory();
  const subUrl = location.pathname.substring("/mypage".length);
  const [loaded, setLoaded] = useState<boolean>(false);
  const user = useUser();

  useEffect(() => {
    if (!isMobile() && subUrl === "") {
      history.replace("/mypage/program/library");
    }
    setLoaded(true);
  }, [subUrl, history]);

  if (!loaded) return null;

  // check if no user, then redirect to signin
  if (!user) {
    history.replace("/signin", { redirect: location.pathname });
    return null;
  }

  const linkMapped = linkComponentMap[subUrl];
  let mobileView = false;
  if (isMobile() && linkMapped) {
    mobileView = true;
  }

  return (
    <MyPageContext.Provider
      value={{
        subUrl,
      }}
    >
      <div
        className="font-noto-regular"
        style={{
          color: textColor,
          userSelect: "none",
        }}
      >
        <div style={{ marginTop: widthPercentage(isMobile() ? 11 : 2.5) }}>
          <HomeContainer>
            {mobileView && (
              <div
                className="font-noto-medium p-relative"
                style={{
                  padding: `${widthPercentage(2.5)}px 0px`,
                  fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
                  textAlign: "center",
                }}
              >
                <Box
                  onClick={() => history.push("/mypage")}
                  style={{
                    width: widthPercentage(6),
                    height: widthPercentage(6),
                    display: "flex",
                    position: "absolute",
                    left: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  sx={{
                    "& path": {
                      stroke: "#111111",
                    },
                  }}
                >
                  <LeftArrow
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>
                {linkMapped.name}
              </div>
            )}
            {!mobileView && (
              <div
                className="font-noto-medium"
                style={{
                  fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
                }}
              >
                마이페이지
              </div>
            )}
          </HomeContainer>
          {mobileView && (
            <div
              style={{
                marginTop: widthPercentage(5),
              }}
            >
              <HomeContainer full={linkMapped.full}>
                <MainComponent />
              </HomeContainer>
            </div>
          )}
          {!mobileView && (
            <Fragment>
              <div
                style={{
                  marginTop: widthPercentage(isMobile() ? 5 : 2.5),
                }}
              >
                <Box
                  sx={{
                    "& *": {
                      fontFamily: "NotoSansKR-Regular",
                    },
                  }}
                >
                  <Hidden smDown>
                    <HomeContainer>
                      <InfoComponent />
                    </HomeContainer>
                  </Hidden>
                  <Hidden smUp>
                    <InfoComponent />
                  </Hidden>
                </Box>
              </div>
              <HomeContainer>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 11 : 2.5),
                    display: isMobile() ? "block" : "flex",
                    marginBottom: widthPercentage(isMobile() ? 11 : 0),
                  }}
                >
                  <div
                    style={{
                      width: isMobile() ? undefined : widthPercentage(15.875),
                    }}
                  >
                    <MenuComponent />
                  </div>
                  <Hidden smDown>
                    <div
                      style={{
                        marginLeft: widthPercentage(0.625),
                        width: "100%",
                      }}
                    >
                      <MainComponent />
                    </div>
                  </Hidden>
                </div>
              </HomeContainer>
            </Fragment>
          )}
        </div>
      </div>
    </MyPageContext.Provider>
  );
}

export default MyPage;
