import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  fetchDefaultWithCredential,
  formatProgramDate,
  formatProgramTime,
  formClass,
  isMobile,
} from "../utils";
import { GenericBannerComponent } from "./HeaderBanner";
import { Button, Grid } from "@mui/material";
import CustomButton from "./customs/CustomButton";
import { ReactComponent as Cart } from "../svg/cart.svg";
import { ReactComponent as EmptyCart } from "../svg/empty_cart.svg";
import "./Program.scss";
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useProgramWait } from "./ProgramDetail";
import {
  GlobalContext,
  SnackbarContext,
} from "../globals/components/ComponentsWrapper";
import { widthPercentage } from "./Home";
export type ProgramStatus = "all" | "progress" | "almost" | "done";

export interface ProgramProps {
  id: number;
  program_image: string;
  program_status: ProgramStatus;
  program_header: string;
  program_name: string;
  program_description: string;
  location: string;
  cart: boolean;
  minutes: number;
  dates: string[];
  starting_hour: number;
  starting_minute: number;
  bought: boolean;
  program_label: string;
  waiting_available: boolean;
  waiting: boolean;
  custom_date: boolean;
  custom_date_start: string;
  custom_date_end: string;
}

interface GroupedProgram {
  group_name: string;
  group_description: string;
  programs: ProgramProps[];
}

export function useGroupedPrograms() {
  const [groupedPrograms, setGroupedPrograms] = useState<GroupedProgram[]>([]);

  const refreshGroupedPrograms = useCallback(() => {
    fetchDefaultWithCredential("/program/list", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setGroupedPrograms);
    });
  }, []);

  useEffect(() => {
    refreshGroupedPrograms();
  }, [refreshGroupedPrograms]);

  return { groupedPrograms, refreshGroupedPrograms };
}

export function ProgramStatusLabel(status: ProgramStatus) {
  let label = "";
  if (status === "all") {
    label = "ALL";
  } else if (status === "progress") {
    label = "모집중";
  } else if (status === "almost") {
    label = "마감임박";
  } else if (status === "done") {
    label = "마감";
  }
  return label;
}

interface ProgramStatusComponentProps {
  status: ProgramStatus;
}

export function ProgramStatusComponent({
  status,
}: ProgramStatusComponentProps) {
  let style: any = {};
  if (status === "progress") {
    style = {
      ...style,
      color: "#fff",
      backgroundColor: "#2699fb",
    };
  } else if (status === "almost") {
    style = {
      ...style,
      color: "#fff",
      backgroundColor: "#ff5a6e",
    };
  } else if (status === "done") {
    style = {
      ...style,
      color: "#fff",
      backgroundColor: "#000",
    };
  }
  return (
    <div
      style={style}
      className="text-align-center width-50 font-10 font-bold p-5 height-25"
    >
      {ProgramStatusLabel(status)}
    </div>
  );
}

interface ProgramComponentProps {
  program: ProgramProps;
  refreshGroupedPrograms: () => void;
}

export function ProgramComponent({
  program,
  refreshGroupedPrograms,
}: ProgramComponentProps) {
  const history = useHistory();
  const { patchProgramWait, WaitDialog } = useProgramWait();
  const { signinWrapper } = useContext(GlobalContext);
  const { showSuccessSnackbar, showWarningSnackbar } =
    useContext(SnackbarContext);

  const patchProgramCart = () => {
    fetchDefaultWithCredential(`/program/${program.id}/cart`, "PATCH").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => {
          if (!program.cart) {
            showSuccessSnackbar("장바구니에 담겼습니다");
          } else {
            showWarningSnackbar("장바구니에서 제거되었습니다");
          }
          refreshGroupedPrograms();
        });
      }
    );
  };

  return (
    <div className="text-align-center user-select-none p-relative">
      <img
        src={program.program_image}
        alt=""
        style={{ width: "100%", borderRadius: 10 }}
        draggable={false}
        className="cursor-pointer"
        onClick={() => history.push(`/program/${program.id}`)}
      />
      {program.waiting_available && (
        <div className="p-absolute" style={{ top: 5, left: 5 }}>
          <CustomButton
            disabled
            style={{
              backgroundColor: "#6300EB",
              color: "white",
            }}
          >
            대기신청
          </CustomButton>
        </div>
      )}
      <div className="mt-5">
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <ProgramStatusComponent status={program.program_status} />
          </Grid>
          {program.program_label !== "" && (
            <Grid item>
              <div
                style={{
                  border: "1px solid #3f337b",
                  color: "#3f337b",
                }}
                className="text-align-center font-10 font-bold p-5 height-25"
              >
                {program.program_label}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <div
        className="font-bold font-20 whitespace-preline mt-5 cursor-pointer"
        onClick={() => history.push(`/program/${program.id}`)}
      >
        {program.program_name}
      </div>
      <div className="font-bold font-15 whitespace-preline mt-5">
        {program.program_header}
      </div>
      <div className="font-12 font-bold whitespace-preline mt-5">
        {program.program_description}
      </div>
      <div className="font-12 font-bold mt-5" style={{ color: "#6939CD" }}>
        {program.location}
      </div>
      <div className="font-12 font-bold mt-5" style={{ color: "#6939CD" }}>
        {(() => {
          let dates = program.dates;
          // check if we should add more onto dates or not
          if (program.custom_date) {
            dates = [
              ...dates,
              program.custom_date_start,
              program.custom_date_end,
            ];
          }
          return formatProgramDate(dates);
        })()}
        <br />
        {formatProgramTime(
          program.starting_hour,
          program.starting_minute,
          program.minutes
        )}
      </div>
      {!program.bought && program.program_status !== "done" && (
        <CustomButton
          outlined
          onClick={() => signinWrapper(patchProgramCart)}
          className="mt-10"
        >
          <div className="width-15 height-15">
            {program.cart ? (
              <Cart style={{ width: "100%", height: "auto" }} />
            ) : (
              <EmptyCart style={{ width: "100%", height: "auto" }} />
            )}
          </div>
        </CustomButton>
      )}
      {program.waiting_available && (
        <CustomButton
          outlined
          className="mt-10"
          onClick={() => {
            signinWrapper(() =>
              patchProgramWait(program.id).then(refreshGroupedPrograms)
            );
          }}
        >
          <div className="width-15 height-15">
            {program.waiting ? (
              <NotificationsIcon style={{ width: "100%", height: "auto" }} />
            ) : (
              <NotificationsNoneIcon
                style={{ width: "100%", height: "auto" }}
              />
            )}
          </div>
        </CustomButton>
      )}
      <WaitDialog />
    </div>
  );
}

interface ProgramVisualizeComponentProps {
  groupedProgram: GroupedProgram;
  refreshGroupedPrograms: () => void;
}

function ProgramVisualizeComponent({
  groupedProgram,
  refreshGroupedPrograms,
}: ProgramVisualizeComponentProps) {
  const [divContainer, setDivContainer] = useState<HTMLDivElement | null>(null);
  const divContainerWidth = divContainer?.clientWidth ?? 0;
  return (
    <div ref={setDivContainer}>
      <div
        className={formClass([
          "font-32 font-bold",
          groupedProgram.group_description === "" ? "mb-20" : null,
        ])}
      >
        {groupedProgram.group_name}
      </div>
      {groupedProgram.group_description !== "" && (
        <Fragment>
          <div className="font-sub text-secondary">
            {groupedProgram.group_description}
          </div>
          <div
            className="height-1 mb-30"
            style={{ backgroundColor: "lightgray" }}
          ></div>
        </Fragment>
      )}
      {(() => {
        const components = [];
        const times = isMobile() ? 2 : 4;
        for (let i = 0; i < groupedProgram.programs.length; i += times) {
          const innerComponents = [];
          for (let j = 0; j < times; j++) {
            const index = i + j;
            if (index < groupedProgram.programs.length) {
              innerComponents.push(
                <div
                  style={{
                    width: isMobile()
                      ? (divContainerWidth * 0.9) / times
                      : (divContainerWidth * 0.6) / times,
                  }}
                  key={j}
                >
                  <ProgramComponent
                    program={groupedProgram.programs[index]}
                    refreshGroupedPrograms={refreshGroupedPrograms}
                  />
                </div>
              );
            } else {
              innerComponents.push(
                <div
                  style={{
                    width: isMobile()
                      ? (divContainerWidth * 0.9) / times
                      : (divContainerWidth * 0.6) / times,
                  }}
                  key={j}
                />
              );
            }
          }
          components.push(
            <div className="d-flex justify-content-space-between mb-40" key={i}>
              {innerComponents}
            </div>
          );
        }
        return <Fragment>{components}</Fragment>;
      })()}
    </div>
  );
}

function Program() {
  const { groupedPrograms, refreshGroupedPrograms } = useGroupedPrograms();

  const availablePrograms = (programs: ProgramProps[]) => {
    // check program status, if it's all done, then go down
    for (const program of programs) {
      if (program.program_status !== "done") {
        return true;
      }
    }
    return false;
  };

  return (
    <Fragment>
      <GenericBannerComponent bannerType="program" />
      <div
        className="pl-50 pr-50 pb-50"
        style={{
          paddingTop: widthPercentage(isMobile() ? 21.5 : 5),
        }}
      >
        {groupedPrograms
          .sort((a, b) => {
            // sort by one which has program with passed or not
            // if custom date, then apply for custom date,
            // if not custom date, then do not apply for custom date

            if (
              availablePrograms(a.programs) &&
              availablePrograms(b.programs)
            ) {
              return 0;
            }
            if (availablePrograms(a.programs)) {
              return -1;
            }
            return 1;
          })
          .map((groupedProgram, index) => (
            <ProgramVisualizeComponent
              groupedProgram={groupedProgram}
              refreshGroupedPrograms={refreshGroupedPrograms}
              key={index}
            />
          ))}
      </div>
      <div className="mb-50 text-align-center">
        <div>
          <p className="font-header">프로그램 오픈알림</p>
          <p className="font-sub text-secondary">
            매달 새로운 프로그램이 오픈됩니다.
            <br />
            필로어스를 친구추가 해주시면
            <br />
            가장 먼저 오픈소식을 받아보실 수 있습니다.
          </p>
        </div>
        <div className="mt-30">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              window.open("https://pf.kakao.com/_xnsACT", "_blank")
            }
          >
            알림받기
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default Program;
