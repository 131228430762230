import React, { Fragment, useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CustomButton from "../customs/CustomButton";
import CustomText from "../customs/CustomText";
import { Link, useHistory, useLocation } from "react-router-dom";
import CustomDialog from "../customs/CustomDialog";
import queryString from "query-string";
import { fetchDefaultWithCredential } from "../../utils";
import CustomCheckbox from "../customs/CustomCheckbox";
import HeaderBanner from "../HeaderBanner";
import "./Sign.scss";
import { useCertification } from "../Pay";
import { responsivePixel } from "../Home";
import {
  GlobalContext,
  SnackbarContext,
} from "../../globals/components/ComponentsWrapper";
import { useUser } from "../../globals/user";

interface SigninFormProps {
  email: string;
  password: string;
  name: string;
  phone: string;
  confirm_password: string;
  signin_persistence: boolean;
}

interface SigninFeedbackProps extends SigninFormProps {
  general?: string;
}

type StatusProps =
  | "default"
  | "forgot"
  | "completed"
  | "email"
  | "password"
  | "password2";

export function SigninForm() {
  const [form, setForm] = useState<SigninFormProps>({
    email: "",
    password: "",
    name: "",
    phone: "",
    confirm_password: "",
    signin_persistence: false,
  });
  const { redirect } = useContext(SigninContext);
  const [feedback, setFeedback] = useState<SigninFeedbackProps>();
  const history = useHistory();
  const { refreshUser } = useContext(GlobalContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return fetchDefaultWithCredential(`/auth/login`, "POST", form).then(
      (res) => {
        if (!res.ok) {
          return res.json().then((data) => setFeedback(data));
        } else {
          return res.text().then((authToken) => {
            localStorage.setItem("credential", authToken);
            refreshUser().then(() => {
              if (redirect) {
                history.replace(redirect);
              }
            });
          });
        }
      }
    );
  };

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    setForm({
      ...form,
      [target.name]: target.value,
    });
    setFeedback(undefined);
  };

  return (
    <Fragment>
      <HeaderBanner background="" mobile="">
        <p className="font-header">필로어스 멤버 로그인</p>
        <p className="text-align-center">
          어서오세요. <br />
          필로어스는 당신을 기다리고 있었어요!
        </p>
      </HeaderBanner>
      <form onChange={handleChange} onSubmit={handleSubmit}>
        <div>
          <CustomText width="100%" type="email" label="이메일" name="email" />
        </div>
        <div className="mt-30">
          <CustomText
            width="100%"
            type="password"
            label="비밀번호"
            name="password"
          />
        </div>
        {feedback && (
          <div
            className="mt-30"
            style={{
              backgroundColor: "#f0142f",
              borderRadius: "4px",
              fontSize: "14px",
              textAlign: "center",
              padding: "10px",
            }}
          >
            <p
              className="text-white"
              style={{ fontSize: "14px", whiteSpace: "pre-line" }}
            >
              {feedback.general}
            </p>
          </div>
        )}
        <div className="mt-30">
          <Grid container alignItems="center">
            <Grid item xs>
              <CustomCheckbox
                checked={form.signin_persistence}
                handleChange={(checked) => {
                  form.signin_persistence = checked;
                  setForm({ ...form });
                }}
                border
              >
                <div className="font-12">로그인 유지</div>
              </CustomCheckbox>
            </Grid>
            <Grid item xs style={{ marginLeft: "10px" }}>
              <CustomButton className="font-10" type="submit" fullWidth>
                로그인 완료
              </CustomButton>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "15px" }}>
          <Grid container>
            <Grid item xs>
              <a
                href="/signin?status=forgot"
                style={{ textDecoration: "none" }}
              >
                <CustomButton className="font-10" type="button" fullWidth>
                  계정 정보를 잊으셨나요?
                </CustomButton>
              </a>
            </Grid>
            <Grid item xs style={{ marginLeft: "10px" }}>
              <a href="/signup" style={{ textDecoration: "none" }}>
                <CustomButton className="font-10" type="button" fullWidth>
                  아직 회원이 아니신가요?
                </CustomButton>
              </a>
              <div
                className="mt-10 p-5 background-blue text-white text-align-center font-bold font-10"
                style={{
                  borderRadius: responsivePixel(8),
                }}
              >
                회원 가입시 5,000원 쿠폰 발급!
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </Fragment>
  );
}

interface EmailFindForm {
  name: string;
  phone: string;
}

interface DialogMetaProps {
  open: boolean;
  name: string;
  email: string;
}

function EmailFindComponent() {
  const [dialogMeta, setDialogMeta] = useState<DialogMetaProps>({
    open: false,
    name: "",
    email: "",
  });
  const { handleCertify } = useCertification();
  const location = useLocation();
  const { showWarningSnackbar } = useContext(SnackbarContext);
  useEffect(() => {
    if (!location.state) return;
    const { mobile_verified } = location.state as any;
    if (!mobile_verified) {
      showWarningSnackbar("인증에 실패하셨습니다");
    } else {
      const { phone, name } = location.state as any;
      fetchDefaultWithCredential("/user/email/find", "POST", {
        name,
        phone,
      }).then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            showWarningSnackbar(error);
          });
        }
        return res.json().then(({ name, email }) => {
          setDialogMeta({
            open: true,
            name,
            email,
          });
        });
      });
    }
  }, [location, showWarningSnackbar]);

  const findEmail = () => {
    handleCertify(
      "email",
      (data) => {
        fetchDefaultWithCredential("/user/email/find", "POST", data).then(
          (res) => {
            if (!res.ok) {
              return res.json().then(({ error }) => {
                showWarningSnackbar(error);
              });
            }
            return res.json().then(({ name, email }) => {
              setDialogMeta({
                open: true,
                name,
                email,
              });
            });
          }
        );
      },
      () => {
        showWarningSnackbar("인증에 실패하셨습니다");
      }
    );
  };

  return (
    <Fragment>
      <p className="font-header text-align-center">
        필로어스에 등록된 계정정보를 입력해주세요.
      </p>
      <div className="mt-30 text-align-center">
        <CustomButton onClick={findEmail}>이메일 찾기</CustomButton>
      </div>
      <CustomDialog open={dialogMeta.open}>
        <div className="text-align-center">
          <p className="font-header">
            {dialogMeta.name}님의 이메일은 {dialogMeta.email} 입니다.
          </p>
          <Link to="/signin" style={{ textDecoration: "none" }}>
            <CustomButton
              outlined
              onClick={() =>
                setDialogMeta({
                  email: "",
                  name: "",
                  open: false,
                })
              }
            >
              확인
            </CustomButton>
          </Link>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

interface PasswordFindForm extends EmailFindForm {
  email: string;
}

interface Password2FindForm {
  password: string;
  confirm_password: string;
}

function Password2Component() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [form, setForm] = useState<Password2FindForm>({
    password: "",
    confirm_password: "",
  });
  const [feedback, setFeedback] = useState<Password2FindForm>();
  const [passwordConfirmed, setPasswordConfirmed] = useState<boolean>(false);

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    setForm({
      ...form,
      [target.name]: target.value,
    });
    setFeedback(undefined);
  };

  const changePassword = () => {
    fetchDefaultWithCredential(`/user/password/reset`, "POST", {
      ...form,
      token: parsed.token,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(setFeedback);
      }
      return res.json().then(() => {
        setPasswordConfirmed(true);
      });
    });
  };

  return (
    <Fragment>
      <form onBlur={handleChange}>
        <Grid container direction="column" alignItems="center">
          <p className="font-header">새로운 비밀번호를 입력해주세요.</p>
          <div style={{ marginTop: "50px", width: "360px" }}>
            <div>
              <CustomText
                width="100%"
                type="password"
                label="새로운 비밀번호"
                name="password"
                placeholder="영문 대/소문자,숫자,특수문자 포함 8~16자리"
                feedback={(() => {
                  if (!feedback) return undefined;
                  if (feedback.password !== "") {
                    return {
                      status: false,
                      message: feedback.password,
                    };
                  }
                  return { status: true };
                })()}
              />
            </div>
            <div className="mt-30">
              <CustomText
                width="100%"
                type="password"
                label="새로운 비밀번호 확인"
                name="confirm_password"
                placeholder="영문 대/소문자,숫자,특수문자 포함 8~16자리"
                feedback={(() => {
                  if (!feedback) return undefined;
                  if (feedback.confirm_password !== "") {
                    return {
                      status: false,
                      message: feedback.confirm_password,
                    };
                  }
                  return { status: true };
                })()}
              />
            </div>
            <div style={{ width: "50%", margin: "50px auto 0" }}>
              <CustomButton type="button" fullWidth onClick={changePassword}>
                설정 완료
              </CustomButton>
            </div>
          </div>
        </Grid>
      </form>
      <CustomDialog open={passwordConfirmed} maxWidth="md">
        <div className="text-align-center">
          <p className="font-header">비밀번호가 변경되었습니다</p>
          <div className="mt-30">
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <CustomButton
                outlined
                onClick={() => {
                  setPasswordConfirmed(false);
                }}
              >
                로그인 페이지로 돌아가기
              </CustomButton>
            </Link>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

function PasswordComponent() {
  const [form, setForm] = useState<PasswordFindForm>({
    email: "",
    name: "",
    phone: "",
  });
  const [feedback, setFeedback] = useState<PasswordFindForm>();
  const [passwordRequest, setPasswordRequest] = useState<boolean>(false);

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    setForm({
      ...form,
      [target.name]: target.value,
    });
    setFeedback(undefined);
  };

  const requestFindPassword = () => {
    fetchDefaultWithCredential(`/user/password/find`, "POST", form).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(setFeedback);
        }
        return res.json().then(() => {
          setPasswordRequest(true);
        });
      }
    );
  };

  return (
    <Fragment>
      <form onBlur={handleChange}>
        <p className="font-header text-align-center">
          필로어스에 등록된 계정정보를 입력해주세요.
        </p>
        <div className="mt-30">
          <div>
            <CustomText
              width="100%"
              type="text"
              label="이름"
              name="name"
              placeholder="이름을 입력해주세요."
              feedback={(() => {
                if (!feedback) return undefined;
                if (feedback.name !== "") {
                  return {
                    status: false,
                    message: feedback.name,
                  };
                }
                return { status: true };
              })()}
            />
          </div>
          <div className="mt-30">
            <CustomText
              width="100%"
              type="phone"
              label="전화번호"
              name="phone"
              placeholder="전화번호를 입력해주세요."
              feedback={(() => {
                if (!feedback) return undefined;
                if (feedback.phone !== "") {
                  return {
                    status: false,
                    message: feedback.phone,
                  };
                }
                return { status: true };
              })()}
            />
          </div>
          <div className="mt-30 p-relative">
            <CustomText
              width="100%"
              type="email"
              label="이메일 인증요청"
              name="email"
              placeholder="등록된 이메일을 입력해주세요."
              feedback={(() => {
                if (!feedback) return undefined;
                if (feedback.email !== "") {
                  return {
                    status: false,
                    message: feedback.email,
                  };
                }
                return { status: true };
              })()}
            />
            <div className="p-absolute" style={{ right: 0, bottom: 0 }}>
              <CustomButton onClick={requestFindPassword} height={50}>
                인증 요청
              </CustomButton>
            </div>
          </div>
        </div>
      </form>
      <CustomDialog open={passwordRequest} maxWidth="md">
        <div className="text-align-center">
          <p className="font-header">
            인증하신 이메일로 <br />
            비밀번호 재수정 메일을 발송했습니다.
          </p>
          <div className="mt-30">
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <CustomButton outlined onClick={() => setPasswordRequest(false)}>
                확인
              </CustomButton>
            </Link>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

function CompletedComponent() {
  return <Fragment></Fragment>;
}

function ForgotComponent() {
  return (
    <Fragment>
      <p className="font-header text-align-center">계정 정보를 잊으셨나요?</p>
      <div className="mt-30">
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Link to="/signin?status=email" style={{ textDecoration: "none" }}>
              <CustomButton>이메일 찾기</CustomButton>
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="/signin?status=password"
              style={{ textDecoration: "none" }}
            >
              <CustomButton>비밀번호 찾기</CustomButton>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}

const statusList = [
  "default",
  "forgot",
  "completed",
  "email",
  "password",
  "password2",
];

const statusMappingContent = {
  default: <SigninForm />,
  forgot: <ForgotComponent />,
  completed: <CompletedComponent />,
  email: <EmailFindComponent />,
  password: <PasswordComponent />,
  password2: <Password2Component />,
};

interface SigninContextProps {
  redirect?: string;
}

const SigninContext = React.createContext({} as SigninContextProps);

interface SigninProps {
  stay?: boolean;
}

function Signin({ stay }: SigninProps) {
  const [status, setStatus] = useState<StatusProps>("default");
  const [loaded, setLoaded] = useState<boolean>(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const user = useUser();
  const history = useHistory();
  useEffect(() => {
    /**
     * if user exists, check last signed in
     * if last signed in is not null, then move to home,
     * if it's null, then show first_signed_in
     * else, work on based on status on query string
     */
    if (parsed.status && statusList.includes(parsed.status as StatusProps)) {
      if (parsed.status === "password2") {
        if (!parsed.token) {
          setStatus("default");
          setLoaded(true);
          return;
        }
        fetchDefaultWithCredential(`/user/token/verify`, "POST", {
          token: parsed.token,
        }).then((res) => {
          if (!res.ok) {
            setStatus("default");
            setLoaded(true);
            return;
          }
          setStatus(parsed.status as StatusProps);
          setLoaded(true);
        });
      } else {
        setStatus(parsed.status as StatusProps);
        setLoaded(true);
      }
    } else {
      setStatus("default");
      setLoaded(true);
    }
  }, [parsed, user, history, location]);

  if (!loaded) return null;

  const state = location.state as any;
  let redirect = !stay ? "/" : undefined;
  if (state) {
    redirect = state.redirect;
  }

  return (
    <SigninContext.Provider value={{ redirect }}>
      <div className="p-30">
        <div className="status-content">{statusMappingContent[status]}</div>
      </div>
    </SigninContext.Provider>
  );
}

export default Signin;
