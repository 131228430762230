import TutorView from "../components/dashboard/views/TutorView";
import { RouteInfo } from "../interfaces";

const Tutor: RouteInfo[] = [
  {
    exact: false,
    path: "/tutor/management",
    component: TutorView,
  },
];

export default Tutor;
