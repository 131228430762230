import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import CustomButton from "../components/customs/CustomButton";
import { Link } from "react-router-dom";

interface NotFoundNumbeImageProps {
  style?: object;
}

function NotFoundNumbeImage({ style }: NotFoundNumbeImageProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="503.057"
      height="236.247"
      viewBox="0 0 503.057 236.247"
      style={style}
    >
      <path
        d="M0,168.512,75.169,2.065H111.1l-76,166.447H93.755V101.6h32.215v66.909h18.173V199.9H125.971v34.694H93.755V199.9H0Zm189.576-101.6c0-21.477,7.021-38,19.825-49.562C221.791,5.782,236.247,0,253.181,0c17.347,0,32.629,5.782,44.606,17.347,12.391,11.565,19,28.085,19.412,49.562V169.338c-.413,21.477-7.021,38-19.412,49.562s-27.259,17.347-44.606,17.347c-16.934-.413-31.8-6.2-43.78-17.347q-19.825-17.347-19.825-49.562V66.909Zm95.82,2.065c-.413-23.542-11.151-35.52-31.8-35.933-20.651.413-31.389,12.391-31.8,35.52v98.712c.413,23.542,11.152,35.52,31.8,35.52,20.651-.413,31.389-11.978,31.8-35.52Zm73.517,99.538L434.083,1.652h35.933l-76,166.86h58.649V101.6h32.216v66.909h18.173V199.9H484.884v34.694H452.669V199.9H358.913V168.512Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}

function NotFound() {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  if (!loaded) return null;

  return (
    <div className="p-30 text-align-center">
      <NotFoundNumbeImage style={{ width: "50%", fill: "#e6e6e6" }} />
      <Typography className="mt-30 font-16">
        이 페이지는 찾을 수 없는 페이지입니다. <br />
        필로어스의 홈으로 돌아가시겠습니까?
      </Typography>
      <div className="mt-30">
        <Link to="/" style={{ textDecoration: "none" }}>
          <CustomButton>홈으로 돌아가기</CustomButton>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
