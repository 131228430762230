import React from "react";
import MenuView, { getPathMetas, PathMeta } from "./MenuView";
import AdminPageEditsUsersLibraries from "../../admins/pages/users/Libraries";
import AdminPageUsers from "../../admins/pages/users";
import AdminPagePopup from "../../admins/pages/Popup";
import AdminPageReadingReport from "../../admins/pages/ReadingReport";
import AdminPageTutors from "../../admins/pages/Tutors";
import AdminPageOptions from "../../admins/pages/Options";
import AdminPagePolicies from "../../admins/pages/Policies";
import AdminPageFeedbacks from "../../admins/pages/Feedbacks";
import AdminPageQuestionaires from "../../admins/pages/Questionaires";
import AdminPageAgreementPrivacies from "../../admins/pages/AgreementPrivacies";
import AdminPageUsersSignup from "../../admins/pages/users/Signup";
import AdminPagePoints from "../../admins/pages/Points";
import AdminPagesEditsUsersDeactivation from "../../admins/pages/users/Deactivation";
import AdminPageTutorTypes from "../../admins/pages/TutorTypes";
import AdminPageSetup from "../../admins/pages/Setup";
import AdminPageProgram from "../../admins/pages/edits/programs/Program";
import AdminPageTemplates from "../../admins/pages/edits/programs/Templates";
import AdminPageCoupons from "../../admins/pages/Coupons";
import AdminPageTransactions from "../../admins/pages/Transactions";
import AdminPageProgramCustomizedRegister from "../../admins/pages/edits/programs/CustomizedRegister";
import AdminPagesCustomSignup from "../../admins/pages/users/CustomSignup";
import AdminPageTransactionDetails from "../../admins/pages/TransactionDetails";
import AdminPageHome from "../../admins/pages/Home";
import AdminPagesRecommender from "../../admins/pages/users/Recommender";
import AdminPageMypage from "../../admins/pages/users/Mypage";
import AdminPageParticipations from "../../admins/pages/Participations";
import AdminPagesEditsBrand from "../../admins/pages/edits/Brand";
import { useUser } from "../../../globals/user";
import { useNotifications } from "../../../globals/notification";
import AdminPagesEditsBanner from "../../admins/pages/edits/Banner";
import AdminPageProgramBook from "../../admins/pages/edits/programs/Book";
import AdminPageProgramSatisfaction from "../../admins/pages/edits/programs/Satisfaction";
import AdminPagesEditsTheme from "../../admins/pages/edits/Theme";

const initPathMetas: PathMeta[] = [
  {
    title: "메인 관리보드",
    metas: {
      prefix: "/operations",
      subFields: [
        {
          title: "관리자 설정",
          endpoint: "/setups",
          component: AdminPageSetup,
        },
      ],
    },
    role: ["admin", "operator1"],
  },
  {
    title: "프로그램 관리",
    metas: {
      prefix: "/programs",
      subFields: [
        {
          title: "이달의 프로그램",
          endpoint: "/programs",
          component: AdminPageProgram,
        },

        {
          title: "프로그램 세부사항",
          endpoint: "/program-option-details",
          component: AdminPageOptions,
        },
        {
          title: "템플릿",
          endpoint: "/templates",
          component: AdminPageTemplates,
        },
        {
          title: "프로그램 수동 등록",
          endpoint: "/customized-register",
          component: AdminPageProgramCustomizedRegister,
        },
        {
          title: "서재 관리",
          endpoint: "/books",
          component: AdminPageProgramBook,
        },
        {
          title: "만족도 조사",
          endpoint: "/satisfactions",
          component: AdminPageProgramSatisfaction,
          notification: "satisfactions",
        },
      ],
    },
  },
  {
    title: "회원 관리",
    metas: {
      prefix: "/users",
      subFields: [
        {
          title: "회원 현황 (신규/휴면)",
          endpoint: "/list",
          component: AdminPageUsersSignup,
        },
        {
          title: "회원명단 및 정보/탈퇴",
          endpoint: "/infos",
          component: AdminPageUsers,
        },

        {
          title: "회원 마이페이지",
          endpoint: "/mypage",
          component: AdminPageMypage,
        },
        {
          title: "출석체크",
          endpoint: "/participations",
          component: AdminPageParticipations,
        },
        {
          title: "회원 독서 성장리포트",
          endpoint: "/reports",
          component: AdminPageReadingReport,
          notification: "reports",
        },
        {
          title: "회원 서재",
          endpoint: "/libraries",
          component: AdminPageEditsUsersLibraries,
        },
        // {
        //   title: "회원 후기",
        //   endpoint: "/reviews",
        //   component: AdminPagesEditsUsersReview,
        // },
        {
          title: "휴면 해제/사유/탈퇴",
          endpoint: "/deactivations",
          component: AdminPagesEditsUsersDeactivation,
        },
        {
          title: "수동 회원가입",
          endpoint: "/signup",
          component: AdminPagesCustomSignup,
        },
        {
          title: "추천인 관리",
          endpoint: "/recommender",
          component: AdminPagesRecommender,
        },
      ],
    },
  },
  {
    title: "웹사이트 관리",
    metas: {
      prefix: "/websites",
      subFields: [
        {
          title: "브랜드 페이지",
          endpoint: "/brand",
          component: AdminPagesEditsBrand,
        },
        {
          title: "페이지 배너",
          endpoint: "/banner",
          component: AdminPagesEditsBanner,
        },
        {
          title: "테마 페이지",
          endpoint: "/theme",
          component: AdminPagesEditsTheme,
        },
        // {
        //   title: "프로그램 후기",
        //   endpoint: "/program/reviews",
        //   component: AdminPagesEditsProgramsReview,
        // },
        // {
        //   title: "이전의 프로그램",
        //   endpoint: "/program/previous",
        //   component: () => null,
        // },
        // {
        //   title: "앵콜 프로그램 현황",
        //   endpoint: "/program/anchors",
        //   component: () => null,
        // },
        // {
        //   title: "선호도서 요청",
        //   endpoint: "/program/book/preferences",
        //   component: () => null,
        // },
      ],
    },
  },
  {
    title: "결제 관리",
    metas: {
      prefix: "/payments",
      subFields: [
        {
          title: "신청명단/장바구니",
          endpoint: "/cart-transactions",
          component: AdminPageTransactions,
        },
        {
          title: "쿠폰",
          endpoint: "/coupons",
          component: AdminPageCoupons,
        },
        {
          title: "적립금",
          endpoint: "/points",
          component: AdminPagePoints,
        },
        {
          title: "결제·환불내역/구매취소",
          endpoint: "/transaction-details",
          component: AdminPageTransactionDetails,
        },
      ],
    },
  },
  {
    title: "튜터 관리",
    metas: {
      prefix: "/tutors",
      subFields: [
        {
          title: "튜터 프로필",
          endpoint: "/profiles",
          component: AdminPageTutors,
        },
        {
          title: "튜터 유형",
          endpoint: "/tutor/types",
          component: AdminPageTutorTypes,
        },
      ],
    },
  },
  {
    title: "디자인 관리",
    metas: {
      prefix: "/designs",
      subFields: [
        {
          title: "홈",
          endpoint: "/home",
          component: AdminPageHome,
        },
        {
          title: "팝업창",
          endpoint: "/popups",
          component: AdminPagePopup,
        },
      ],
    },
  },
  {
    title: "기타 관리",
    metas: {
      prefix: "/others",
      subFields: [
        {
          title: "피드백 문의",
          endpoint: "/feedbacks",
          component: AdminPageFeedbacks,
        },
        {
          title: "제휴 협업 문의",
          endpoint: "/policies",
          component: AdminPagePolicies,
        },
        {
          title: "자주 찾는 질문 / 환불정책",
          endpoint: "/questionaires",
          component: AdminPageQuestionaires,
        },
        {
          title: "이용약관 / 개인정보처리방침",
          endpoint: "/agreements-privacies",
          component: AdminPageAgreementPrivacies,
        },
      ],
    },
  },
];

export function useOperatorPathMetas() {
  const user = useUser();
  if (!user) return [];
  const pathMetas = getPathMetas(initPathMetas);
  for (const pathMeta of pathMetas) {
    const prefix = pathMeta.metas.prefix;
    for (const subField of pathMeta.metas.subFields) {
      const id = `${prefix}${subField.endpoint}`;
      if (user.role === "operator2") {
        if (!user.meta.accesses || !user.meta.accesses.includes(id)) {
          continue;
        }
      }
      pathMeta.show = true;
      subField.show = true;
    }
  }
  return pathMetas;
}

function OperatorView() {
  const pathMetas = useOperatorPathMetas();
  const notifications = useNotifications();
  return (
    <MenuView
      label="관리자 페이지"
      prefix="/admin/management"
      notifications={notifications.admin || {}}
      pathMetas={pathMetas}
    />
  );
}

export default OperatorView;
