import React, { Fragment, useEffect, useState } from "react";
import { useUser } from "../../globals/user";
import { fetchDefaultWithCredential, isMobile } from "../../utils";
import CustomPagination from "../customs/CustomPagination";
import { CustomStandardSelect } from "../customs/CustomSelect";
import RadioForm from "../customs/RadioForm";
import { textColor, widthPercentage } from "../Home";
import InfoBar from "./InfoBar";
import { ReactComponent as RightArrow } from "../../svg/right_arrow.svg";
import { ReactComponent as LeftArrow } from "../../svg/left_arrow.svg";
import { ReactComponent as LibraryBook } from "../../svg/library_book.svg";
import { ReactComponent as Check } from "../../svg/check.svg";
import { ReactComponent as LockIcon } from "../../svg/lock_icon.svg";
import { ReactComponent as LibraryIcon } from "../../svg/library_icon.svg";
import { ReactComponent as NoteIcon } from "../../svg/note_icon.svg";
import { ReactComponent as DifficlutyAIcon } from "../../svg/difficulty_a_icon.svg";
import { ReactComponent as DifficlutyBIcon } from "../../svg/difficulty_b_icon.svg";
import { ReactComponent as DifficlutyCIcon } from "../../svg/difficulty_c_icon.svg";
import { ReactComponent as DifficlutyDIcon } from "../../svg/difficulty_d_icon.svg";

import { Box } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { v4 } from "uuid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type ShowType = "read" | "philous_classic";
type Classify = "all" | "classic" | "recent" | "read" | "unread";

interface BookComponentBook {
  image: string;
  name: string;
  author: string;
  program_name: string;
  publisher?: string;
  completed_date?: string;
  genre?: string;
  difficulty?: string;
  read?: boolean;
  new?: boolean;
  tutor_taken?: boolean;
  taken?: boolean;
}

interface BookComponentProps {
  books: BookComponentBook[];
}

function BookComponent({ books }: BookComponentProps) {
  const [toggled, setToggled] = useState<boolean>(isMobile());
  const [index, setIndex] = useState<number>(0);
  let book = books[index];
  const unread = book.taken !== undefined && !book.taken;
  let apply: object = {
    filter: `drop-shadow(0px ${widthPercentage(
      isMobile() ? 1 : 0.25
    )}px ${widthPercentage(isMobile() ? 2.5 : 0.625)}px rgba(0, 0, 0, 0.4))`,
  };
  if (unread) {
    book = {
      ...book,
      image: "",
      new: false,
    };
    apply = {
      boxShadow: `${widthPercentage(
        isMobile() ? 3.5 : 0.875
      )}px ${widthPercentage(isMobile() ? 3 : 0.75)}px ${widthPercentage(
        isMobile() ? 6 : 1.25
      )}px rgba(41, 0, 159, 0.16)`,
      backgroundColor: "white",
    };
  }

  const difficultyMeta = (difficulty: string) => {
    if (difficulty === "A") {
      return {
        component: DifficlutyAIcon,
      };
    }
    if (difficulty === "B") {
      return {
        component: DifficlutyBIcon,
      };
    }
    if (difficulty === "C") {
      return {
        component: DifficlutyCIcon,
      };
    }
    if (difficulty === "D") {
      return {
        component: DifficlutyDIcon,
      };
    }
    return {
      component: () => null,
    };
  };

  return (
    <div
      className="font-noto-medium"
      style={{
        padding: `${widthPercentage(isMobile() ? 6 : 3)}px ${widthPercentage(
          isMobile() ? 3.75 : 1.875
        )}px ${widthPercentage(isMobile() ? 2 : 1)}px`,
        border: "1px solid #E5E5EC",
        textAlign: "center",
        width: widthPercentage(isMobile() ? 45 : 18.75),
        position: "relative",
      }}
    >
      {book.difficulty && (
        <div
          className="p-absolute"
          style={{
            left: 0,
            top: 0,
            zIndex: -1,
            width: widthPercentage(isMobile() ? 16.25 : 8.125),
          }}
        >
          {(() => {
            const meta = difficultyMeta(book.difficulty);
            return <meta.component style={{ width: "100%" }} />;
          })()}
        </div>
      )}
      {unread && (
        <div
          className="p-absolute"
          style={{
            inset: 0,
            background: `rgba(17, 17, 17, 0.3)`,
            backdropFilter: `blur(${widthPercentage(
              isMobile() ? 0.625 : 0.15625
            )}px)`,
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginTop: widthPercentage(isMobile() ? 13 : 6.25),
              width: widthPercentage(isMobile() ? 17.5 : 6.25),
              height: widthPercentage(isMobile() ? 23.1 : 8.125),
            }}
          >
            <LockIcon style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <Box
          style={{
            width: widthPercentage(isMobile() ? 30 : 11.25),
            height: widthPercentage(isMobile() ? 42.5 : 16.25),
            backgroundImage: `url(${book.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            zIndex: 0,
            ...apply,
          }}
          sx={{
            "& path": {
              stroke: "#AAAAAA",
            },
          }}
        >
          {book.tutor_taken && (
            <div
              className="p-absolute d-flex justify-content-center align-items-center"
              style={{
                top: 0,
                left: 0,
                width: widthPercentage(isMobile() ? 7.5 : 2.75),
                height: widthPercentage(isMobile() ? 7.5 : 2.75),
                fontSize: widthPercentage(isMobile() ? 2 : 0.75),
                transform: "translate(-50%, -50%)",
                color: "white",
                backgroundColor: "#7000FF",
                borderRadius: "50%",
                zIndex: 1,
              }}
            >
              진행
            </div>
          )}
          {book.new && (
            <div
              className="p-absolute d-flex justify-content-center align-items-center"
              style={{
                top: 0,
                left: 0,
                width: widthPercentage(isMobile() ? 7.5 : 2.75),
                height: widthPercentage(isMobile() ? 7.5 : 2.75),
                fontSize: widthPercentage(isMobile() ? 2 : 0.75),
                transform: "translate(-50%, -50%)",
                color: textColor,
                backgroundColor: "#FFD66B",
                borderRadius: "50%",
              }}
            >
              NEW
            </div>
          )}
          {index > 0 && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "105%",
                transform: "translateY(-50%)",
                width: widthPercentage(isMobile() ? 5 : 2.5),
                height: widthPercentage(isMobile() ? 5 : 2.5),
                zIndex: 1,
                cursor: "pointer",
              }}
              onClick={() => setIndex(index - 1)}
            >
              <LeftArrow style={{ width: "100%", height: "100%" }} />
            </div>
          )}
          {index < books.length - 1 && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "105%",
                transform: "translateY(-50%)",
                width: widthPercentage(isMobile() ? 5 : 2.5),
                height: widthPercentage(isMobile() ? 5 : 2.5),
                zIndex: 1,
                cursor: "pointer",
              }}
              onClick={() => setIndex(index + 1)}
            >
              <RightArrow style={{ width: "100%", height: "100%" }} />
            </div>
          )}
        </Box>
      </div>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 5 : 1.25),
          fontSize: widthPercentage(isMobile() ? 3.5 : 1),
          position: "relative",
          zIndex: 2,
        }}
      >
        {book.name}
      </div>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 2 : 0.5),
          fontSize: widthPercentage(isMobile() ? 3 : 0.9375),
          color: "#505050",
          position: "relative",
          zIndex: 2,
        }}
      >
        {book.author}
      </div>
      {toggled && (
        <div
          className="font-noto-regular p-relative"
          style={{
            fontSize: widthPercentage(isMobile() ? 2.5 : 0.875),
            color: "#505050",
            zIndex: 1,
          }}
        >
          <div
            style={{
              marginTop: widthPercentage(isMobile() ? 2.5 : 1),
            }}
          >
            {book.program_name}
          </div>
          <div
            style={{
              marginTop: widthPercentage(isMobile() ? 1.5 : 1),
              marginBottom: widthPercentage(isMobile() ? 1.5 : 1),
            }}
          >
            <div
              style={{
                height: widthPercentage(isMobile() ? 0.25 : 0.0625),
                backgroundColor: "#C4C4C4",
              }}
            ></div>
          </div>
          <div
            className="d-flex justify-content-space-between"
            style={{
              fontSize: widthPercentage(isMobile() ? 2.25 : 0.875),
            }}
          >
            {book.publisher && (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: widthPercentage(isMobile() ? 3 : 1.375),
                    height: widthPercentage(isMobile() ? 3 : 1.375),
                  }}
                >
                  <LibraryBook style={{ width: "100%", height: "100%" }} />
                </div>
                <div
                  style={{
                    marginLeft: widthPercentage(isMobile() ? 1 : 0.25),
                    display: "flex",
                  }}
                >
                  {book.publisher}
                </div>
              </div>
            )}
            {book.completed_date && (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: widthPercentage(isMobile() ? 3 : 1.375),
                    height: widthPercentage(isMobile() ? 3 : 1.375),
                  }}
                >
                  <Check style={{ width: "100%", height: "100%" }} />
                </div>
                <div
                  style={{
                    marginLeft: widthPercentage(isMobile() ? 1 : 0.25),
                    display: "flex",
                  }}
                >
                  {(() => {
                    const splitted = book.completed_date.split("-");
                    return `${splitted[0]}.${splitted[1]} 완독`;
                  })()}
                </div>
              </div>
            )}
            {book.genre && (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: widthPercentage(isMobile() ? 3 : 1.375),
                    height: widthPercentage(isMobile() ? 3 : 1.375),
                  }}
                >
                  <LibraryIcon style={{ width: "100%", height: "100%" }} />
                </div>
                <div
                  style={{
                    marginLeft: widthPercentage(isMobile() ? 1 : 0.25),
                    display: "flex",
                  }}
                >
                  {book.genre}
                </div>
              </div>
            )}

            {book.difficulty && (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: widthPercentage(isMobile() ? 3 : 1.375),
                    height: widthPercentage(isMobile() ? 3 : 1.375),
                  }}
                >
                  <NoteIcon style={{ width: "100%", height: "100%" }} />
                </div>
                <div
                  style={{
                    marginLeft: widthPercentage(isMobile() ? 1 : 0.25),
                    display: "flex",
                  }}
                >
                  {book.difficulty} 섹션
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!isMobile() && (
        <div
          style={{
            marginTop: widthPercentage(isMobile() ? 2 : 0.5),
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              width: widthPercentage(isMobile() ? 8 : 2),
              cursor: "pointer",
            }}
            onClick={() => setToggled(!toggled)}
          >
            {toggled && (
              <KeyboardArrowUpIcon
                style={{ width: "100%", color: "#BEBEBE" }}
              />
            )}
            {!toggled && (
              <KeyboardArrowDownIcon
                style={{ width: "100%", color: "#BEBEBE" }}
              />
            )}
          </div>
        </div>
      )}
      {toggled && (
        <div
          className="p-absolute"
          style={{
            left: 0,
            right: 0,
            bottom: 0,
            height: widthPercentage(isMobile() ? 17.5 : 6.875),
            background: `linear-gradient(180deg, rgba(217, 217, 217, 0) 34.9%, rgba(217, 217, 217, 0.5) 100%)`,
            zIndex: -1,
          }}
        ></div>
      )}
    </div>
  );
}

interface PhilousClassicBookListComponentProps {
  book_infos: PhilousClassicBookInfo[];
}

function PhilousClassicBookListComponent({
  book_infos,
}: PhilousClassicBookListComponentProps) {
  return (
    <BookListComponentTemplate
      BookComponent={BookComponent}
      book_infos={book_infos}
      bookSelector={(bookInfo) => {
        // check if bookInfo taken is false,
        // if all false, then leave,
        // if at least one is not false, then we filter all but taken one
        const found = bookInfo.metas.find((meta) => meta.taken);
        if (found) {
          bookInfo.metas = bookInfo.metas.filter((meta) => meta.taken);
        }
        return bookInfo.metas.map((meta) => ({
          author: bookInfo.author,
          name: bookInfo.name,
          ...meta,
        }));
      }}
    />
  );
}

interface BookListComponentTemplateProps<T> {
  book_infos: T[];
  BookComponent: (props: BookComponentProps) => JSX.Element;
  bookSelector: (bookInfo: T) => BookComponentBook[];
}

function BookListComponentTemplate<T>({
  book_infos,
  BookComponent,
  bookSelector,
}: BookListComponentTemplateProps<T>) {
  return (
    <div>
      {(() => {
        const rowCount = isMobile() ? 2 : 3;
        const res = [];
        for (let i = 0; i < book_infos.length; i += rowCount) {
          res.push(
            <div
              key={v4()}
              style={{
                marginBottom: widthPercentage(isMobile() ? 2 : 6),
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {(() => {
                const res = [];
                for (let j = 0; j < rowCount; j++) {
                  if (i + j >= book_infos.length) {
                    res.push(
                      <div
                        key={v4()}
                        style={{
                          width: widthPercentage(isMobile() ? 45 : 18.75),
                        }}
                      />
                    );
                  } else {
                    res.push(
                      <div
                        style={{
                          alignSelf: "baseline",
                        }}
                        key={v4()}
                      >
                        <BookComponent
                          books={bookSelector(book_infos[i + j])}
                        />
                      </div>
                    );
                  }
                }
                return res;
              })()}
            </div>
          );
        }
        return res;
      })()}
    </div>
  );
}

interface BookListComponentProps {
  book_infos: BookInfo[];
}

function BookListComponent({ book_infos }: BookListComponentProps) {
  return (
    <BookListComponentTemplate
      BookComponent={BookComponent}
      book_infos={book_infos}
      bookSelector={(bookInfo) => bookInfo.books}
    />
  );
}

interface Book {
  id: number;
  image: string;
  name: string;
  author: string;
  program_name: string;
  publisher: string;
  completed_date: string;
  tutor_taken: boolean;
}

interface BookInfo {
  books: Book[];
}

type MypageProgramLibraryInfo = LibraryInfoRead | LibraryInfoPhilousClassic;

interface LibraryInfoRead {
  type: "read";
  completed_books: number;
  total_philous_classic_books: number;
  book_infos: BookInfo[];
  page_count: number;
  show: boolean;
}

interface PhilousClassicBookInfo {
  name: string;
  author: string;
  metas: {
    image: string;
    genre: string;
    difficulty: string;
    program_name: string;
    new: boolean;
    tutor_taken: boolean;
    taken: boolean;
  }[];
}

interface LibraryInfoPhilousClassic {
  type: "philous_classic";
  completed_books: number;
  total_philous_classic_books: number;
  book_infos: PhilousClassicBookInfo[];
  page_count: number;
  show: boolean;
}

interface LibraryProps {
  admin?: boolean;
  userId?: number;
}

function Library({ admin, userId }: LibraryProps) {
  const [mypageProgramLibraryInfo, setMypageProgramLibraryInfo] =
    useState<MypageProgramLibraryInfo | null>(null);
  const user = useUser();
  const [showType, setShowType] = useState<ShowType>("read");
  const [classify, setClassify] = useState<Classify>("all");
  const [page, setPage] = useState<number>(1);
  const amount = isMobile() ? 8 : 9;

  useEffect(() => {
    fetchDefaultWithCredential(
      `/mypage/program/library${
        admin ? "/admin" : ""
      }?show=${showType}&classify=${classify}&page=${page}&amount=${amount}${
        userId ? `&user_id=${userId}` : ""
      }`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(setMypageProgramLibraryInfo);
    });
  }, [showType, classify, page, amount, admin, userId]);

  useEffect(() => {
    if (!mypageProgramLibraryInfo) return;
    if (mypageProgramLibraryInfo.completed_books === 0) {
      setShowType("philous_classic");
    }
  }, [mypageProgramLibraryInfo]);

  if (!mypageProgramLibraryInfo || !user) return null;

  if (!mypageProgramLibraryInfo.show)
    return (
      <InfoBar
        header="나의 서재"
        description={(() => {
          return "업데이트 중입니다";
        })()}
        percentage={0}
      />
    );

  return (
    <Fragment>
      <InfoBar
        header="나의 서재"
        description={(() => {
          if (showType === "read") {
            return `필로어스와 함께 철학한 ${user.name}님의 도서 목록입니다. 프로그램 종료 후 서재에 새로운 도서가 채워집니다.`;
          }
          if (showType === "philous_classic") {
            return (
              <div>
                난이도별로 선정된 필로어스 인문고전 목록입니다.
                <br />
                가볍게 시작할 수 있는 A섹션부터 가장 높은 난이도의 D섹션까지,
                차근차근 성장하는 기쁨을 맛보고 고전 100권까지 도전해보세요!
              </div>
            );
          }
          return "";
        })()}
        percentage={(() => {
          if (mypageProgramLibraryInfo.type === "read") {
            return mypageProgramLibraryInfo.completed_books > 0 ? 100 : 0;
          }
          return Math.round(
            (mypageProgramLibraryInfo.completed_books /
              mypageProgramLibraryInfo.total_philous_classic_books) *
              100
          );
        })()}
        count={(() => {
          if (mypageProgramLibraryInfo.type === "read") {
            return {
              label: "읽은 도서 수",
              element: (
                <div
                  className="font-roboto-medium d-flex"
                  style={{
                    fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
                  }}
                >
                  {mypageProgramLibraryInfo.completed_books}
                </div>
              ),
            };
          } else if (mypageProgramLibraryInfo.type === "philous_classic") {
            return {
              label: "인문고전 목록 달성 수",
              element: (
                <Fragment>
                  <div
                    className="font-roboto-medium d-flex"
                    style={{
                      fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
                    }}
                  >
                    {mypageProgramLibraryInfo.completed_books}
                  </div>
                  <div
                    className="font-roboto-regular"
                    style={{
                      marginLeft: widthPercentage(isMobile() ? 0.5 : 0.25),
                      fontSize: widthPercentage(isMobile() ? 3.75 : 1.125),
                    }}
                  >
                    /{mypageProgramLibraryInfo.total_philous_classic_books}
                  </div>
                </Fragment>
              ),
            };
          }
        })()}
      />
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 11.25 : 2.5),
          ...(isMobile()
            ? {}
            : {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }),
        }}
      >
        <div className="d-flex p-relative" style={{ zIndex: 1 }}>
          <RadioForm<any>
            name="type"
            label=""
            handleChange={(e) => {
              setShowType(e.target.value as ShowType);
              setClassify("all");
              setPage(1);
            }}
            radios={[
              ...(mypageProgramLibraryInfo.type === "read" &&
              mypageProgramLibraryInfo.completed_books === 0
                ? []
                : [
                    {
                      label: "읽은 도서 목록",
                      value: "read",
                    },
                  ]),
              {
                label: "필로어스 인문고전 목록",
                value: "philous_classic",
              },
            ]}
            row
            value={showType}
          />
        </div>
        <div
          style={{
            marginTop: widthPercentage(isMobile() ? 5.25 : 0),
          }}
        >
          <CustomStandardSelect<Classify>
            value={classify}
            items={(() => {
              const res = [
                {
                  label: "전체보기",
                  value: "all",
                },
              ] as any;
              if (showType === "read") {
                res.push({
                  label: "고전만 보기",
                  value: "classic",
                });
              } else if (showType === "philous_classic") {
                res.push(
                  {
                    label: "최신순 보기",
                    value: "recent",
                  },
                  {
                    label: "내가 읽은 고전만 보기",
                    value: "read",
                  },
                  {
                    label: "아직 읽지 않은 고전만 보기",
                    value: "unread",
                  }
                );
              }
              return res;
            })()}
            handleChange={(value) => {
              setClassify(value);
              setPage(1);
            }}
            formStyle={{
              width: isMobile() ? "100%" : widthPercentage(15.875),
            }}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 7.5 : 1.875),
        }}
      >
        {mypageProgramLibraryInfo.type === "read" && (
          <BookListComponent book_infos={mypageProgramLibraryInfo.book_infos} />
        )}
        {mypageProgramLibraryInfo.type === "philous_classic" && (
          <PhilousClassicBookListComponent
            book_infos={mypageProgramLibraryInfo.book_infos}
          />
        )}
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginBottom: widthPercentage(isMobile() ? 12 : 3) }}
      >
        <CustomPagination
          count={mypageProgramLibraryInfo.page_count}
          page={page}
          handleChange={setPage}
        />
      </div>
      {showType === "philous_classic" && (
        <div
          style={{
            color: "#999999",
            marginBottom: widthPercentage(isMobile() ? 12 : 3),
            fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
          }}
        >
          *필로어스 인문고전 100권 목록 PDF는 ‘마이페이지 상단’에서 다운받으실
          수 있습니다.
        </div>
      )}
    </Fragment>
  );
}

export default Library;
