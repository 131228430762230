import { Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useGeneric } from "../../../../hooks";
import { ImageUploadComponent } from "../../../customs/CustomImageUploadButton";
import CustomSelect from "../../../customs/CustomSelect";

type BannerType = "program" | "community" | "";

function AdminPagesEditsBanner() {
  const [type, setType] = useState<BannerType>("");
  const { generic, putGeneric, refresh } = useGeneric("banner");
  if (!generic) return null;
  const currGeneric = generic[type] || {};
  console.log(currGeneric);
  return (
    <Fragment>
      <div>
        <CustomSelect<BannerType>
          label="배너 타입"
          name="type"
          items={[
            {
              label: "선택 안함",
              value: "",
              disabled: true,
            },
            {
              label: "프로그램",
              value: "program",
            },
            {
              label: "커뮤니티",
              value: "community",
            },
          ]}
          value={type}
          handleChange={setType}
        />
      </div>
      {type && (
        <Fragment key={type}>
          <div className="mt-10">
            <Grid container columnSpacing={2}>
              <Grid item xs>
                <p className="font-18 font-bold">데스크탑</p>
                <ImageUploadComponent
                  handleImage={(image_id) => {
                    const currGeneric = generic[type] || {};
                    currGeneric.desktop_image_id = image_id;
                    generic[type] = currGeneric;
                    putGeneric().then(refresh);
                  }}
                  imageId={currGeneric.desktop_image_id || 0}
                />
              </Grid>
              <Grid item xs>
                <p className="font-18 font-bold">모바일</p>
                <ImageUploadComponent
                  handleImage={(image_id) => {
                    const currGeneric = generic[type] || {};
                    currGeneric.mobile_image_id = image_id;
                    generic[type] = currGeneric;
                    putGeneric().then(refresh);
                  }}
                  imageId={currGeneric.mobile_image_id || 0}
                />
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default AdminPagesEditsBanner;
