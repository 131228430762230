import { useContext } from "react";
import { Coupon } from "../components/admins/pages/Coupons";
import { PopupTemplate } from "../components/admins/pages/Popup";
import { Department } from "../components/admins/pages/Setup";
import { Image } from "../interfaces";
import { fetchDefaultWithCredential } from "../utils";
import { GlobalContext } from "./components/ComponentsWrapper";

export type UserRole = "admin" | "operator1" | "operator2" | "user";

type PopupCondition = "first_login" | "login" | "program_satisfaction";

interface UserPopupQuestionAnswer {
  option_id: string;
  etc?: string;
  subjective?: string;
}

interface UserPopupQuestion {
  question_id: string;
  answers: UserPopupQuestionAnswer[];
}

interface PopupProgram {
  program_header: string;
  program_name: string;
}

export interface UserPopupProgress {
  condition: PopupCondition;
  questions: UserPopupQuestion[];
  template: PopupTemplate;
  program?: PopupProgram;
  program_id?: number;
  reviewed?: boolean;
}

export interface User {
  id: number;
  created_at: string;
  updated_at: string;
  email: string;
  name: string;
  gender: string;
  date_of_birth: string;
  job: string;
  phone: string;
  last_signed_in: string;
  role: UserRole;
  deactivated?: string;
  meta: any;
  user_avatar?: Image;
  department?: Department;
  popup_progresses: UserPopupProgress[];
  tutor_id?: number;
  precept?: boolean;
  coupons: Coupon[];
  point: number;
}

export function useUser() {
  const { user } = useContext(GlobalContext);
  return user;
}

export function getUser(): Promise<User | null> {
  // this function grabs user data from server, based off jwt token stored on local storage
  if (!localStorage.getItem("credential")) {
    return new Promise((resolve) => resolve(null));
  }
  return fetchDefaultWithCredential("/user", "GET").then((res) => {
    if (!res.ok) {
      return res.text().then((text) => {
        throw new Error(text);
      });
    }
    return res.json();
  });
}
