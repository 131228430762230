import React, { useState } from "react";
import { Grid } from "@mui/material";
import UserList from "../helpers/UserList";
import { User } from "../../../../globals/user";
import Library from "../../../programs/Library";
import { widthPercentage } from "../../../Home";

function AdminPagesEditsUsersLibraries() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  return (
    <div style={{ width: widthPercentage(80) }}>
      <Grid container columnSpacing={2}>
        <Grid item sm={3} xs={12}>
          <UserList currentUser={currentUser} setCurrentUser={setCurrentUser} />
        </Grid>
        {currentUser && (
          <Grid item sm={9} xs={12}>
            <Library admin userId={currentUser.id} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default AdminPagesEditsUsersLibraries;
