import React from "react";

function PhiloTrip() {
  return (
    <div style={{ display: "flex" }}>
      <iframe
        src="https://philous.oopy.io/"
        title="필로트립"
        style={{
          border: "none",
          width: "100%",
          height: 700,
        }}
      />
    </div>
  );
}

export default PhiloTrip;
