import {
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CustomText from "../../../customs/CustomText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomButton from "../../../customs/CustomButton";

type SortType = "abc" | "old" | "recent";

interface CustomSortProps {
  key: string;
  name: string;
  sortFunc: (dataObject1: any, dataObject2: any) => any;
}

interface PropType<T> {
  label: string;
  data: T[];
  listItemComponent: (dataObject: T) => JSX.Element;
  abcSortAttributeFunc: (dataObject: T) => string;
  defaultSortType?: SortType;
  customSort?: CustomSortProps[];
  currentDataObject: T | null;
  setCurrentDataObject: (currentDataObject: T | null) => void;
  maxHeight?: string;
  showMenu?: boolean;
  mutable?: boolean;
}

function SortComponent<T>({
  label,
  data,
  listItemComponent,
  abcSortAttributeFunc,
  defaultSortType,
  customSort,
  currentDataObject,
  setCurrentDataObject,
  maxHeight = "500px",
  showMenu = true,
  mutable = false,
}: PropType<T>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [sortType, setSortType] = useState<string>(defaultSortType ?? "recent");
  const [filteredData, setFilteredData] = useState<T[]>([]);

  useEffect(() => {
    if (mutable) return;
    if (!currentDataObject) return;
    const found =
      data.find((object: any) => object.id === (currentDataObject as any).id) ||
      null;
    setCurrentDataObject(found);
  }, [data, currentDataObject, setCurrentDataObject, mutable]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setFilteredData((filteredData) => {
      filteredData.sort((data1: any, data2: any) => {
        if (sortType === "abc") {
          return abcSortAttributeFunc(data1).localeCompare(
            abcSortAttributeFunc(data2)
          );
        } else if (sortType === "old") {
          return (
            new Date(data1.created_at!).getTime() -
            new Date(data2.created_at!).getTime()
          );
        } else if (sortType === "recent") {
          return (
            new Date(data2.created_at!).getTime() -
            new Date(data1.created_at!).getTime()
          );
        }
        if (customSort) {
          for (const customSortObject of customSort) {
            if (sortType === customSortObject.key) {
              return customSortObject.sortFunc(data1, data2);
            }
          }
        }
        return 0;
      });
      return [...filteredData];
    });
  }, [sortType, abcSortAttributeFunc, customSort]);

  const sortTypeMap: { [sortTypeKey: string]: string } = {
    abc: "가나다 순",
    old: "등록 오래된 순",
    recent: "등록 최신 순",
  };

  if (customSort) {
    for (const customSortObject of customSort) {
      sortTypeMap[customSortObject.key] = customSortObject.name;
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <Grid container alignItems="center">
        <Grid item>
          <Typography style={{ fontSize: "13px", fontWeight: 600 }}>
            {label} ({filteredData.length})
          </Typography>
        </Grid>
        {showMenu && (
          <Fragment>
            <Grid item style={{ marginLeft: "auto" }}>
              <Typography style={{ fontSize: "10px" }}>
                ({sortTypeMap[sortType]})
              </Typography>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClick} size="large">
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {Object.keys(sortTypeMap).map((key, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setSortType(key as SortType);
                      handleClose();
                    }}
                  >
                    {sortTypeMap[key]}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Fragment>
        )}
      </Grid>
      <FormComponent
        data={data}
        setFilteredData={setFilteredData}
        abcSortAttributeFunc={abcSortAttributeFunc}
      />

      <div className="outline-with-border mt-10 p-5">
        <div style={{ maxHeight, overflow: "scroll" }}>
          <List disablePadding>
            {filteredData.map((dataObject, index) => (
              <ListItem
                key={index}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    dataObject === currentDataObject ? "#eaeaea" : "inherit",
                }}
                onClick={() => setCurrentDataObject(dataObject)}
              >
                {listItemComponent(dataObject)}
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </Fragment>
  );
}

interface FormComponentProps<T> {
  data: T[];
  abcSortAttributeFunc: (dataObject: T) => string;
  setFilteredData: React.Dispatch<React.SetStateAction<T[]>>;
}

function FormComponent<T>({
  data,
  abcSortAttributeFunc,
  setFilteredData,
}: FormComponentProps<T>) {
  const [searchName, setSearchName] = useState<string>("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setFilteredData(
          data.filter((dataObject) => {
            if (searchName === "") return true;
            const pattern = new RegExp(`.*${searchName}.*`);
            return pattern.test(abcSortAttributeFunc(dataObject));
          })
        );
      }}
    >
      <Grid container spacing={2} alignItems="flex-end" className="mt-10">
        <Grid item xs>
          <CustomText
            type="text"
            name="name"
            height="50px"
            width="100%"
            placeholder="이름으로 검색"
            handleChange={(e) => setSearchName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <CustomButton type="submit" height={50}>
            검색
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default SortComponent;
