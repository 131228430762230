import { Card, Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { postImageFile } from "../../../../../hooks/useImages";
import { fetchDefaultWithCredential } from "../../../../../utils";
import CustomButton from "../../../../customs/CustomButton";
import CustomImageUploadButton from "../../../../customs/CustomImageUploadButton";
import CustomText from "../../../../customs/CustomText";

export interface Template {
  id: number;
  meta: any;
}

export function useTemplates() {
  const [templates, setTemplates] = useState<Template[]>([]);

  const refreshTemplates = useCallback(() => {
    return fetchDefaultWithCredential(`/program/templates`, "GET").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(setTemplates);
      }
    );
  }, []);

  useEffect(() => {
    refreshTemplates();
  }, [refreshTemplates]);

  return { templates, refreshTemplates };
}

interface TemplateComponentProps {
  type: string;
}

function DiscountInfoComponent({ type }: TemplateComponentProps) {
  const { templates, handleAdd, handleEdit, handleDelete } =
    useContext(TemplateContext);

  const refinedTemplates = templates.filter(
    (template) => template.meta.type === type
  );

  return (
    <div
      className="p-30 height-500 outline-with-border"
      style={{ overflow: "scroll" }}
    >
      <div className="d-flex justify-content-space-between mb-10">
        <div className="font-header">할인 정보</div>
        <div>
          <CustomButton onClick={() => handleAdd(type)}>추가</CustomButton>
        </div>
      </div>
      {refinedTemplates.map((template) => (
        <Card className="mb-10 p-30" key={template.id} variant="outlined">
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDelete(template.id)}>
              삭제
            </CustomButton>
          </div>
          <div className="p-30">
            <CustomText
              label="할인 제목"
              name="title"
              type="text"
              width="100%"
              defaultValue={template.meta.title}
              handleBlur={(e) => {
                template.meta.title = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
          <div className="p-30">
            <CustomText
              label="할인 정도(원, % 포함해서)"
              name="amount"
              type="text"
              width="100%"
              defaultValue={template.meta.amount}
              handleBlur={(e) => {
                template.meta.amount = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
          <div className="p-30">
            <CustomText
              label="설명"
              name="description"
              type="text"
              width="100%"
              multiline
              height="100px"
              defaultValue={template.meta.description}
              handleBlur={(e) => {
                template.meta.description = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
        </Card>
      ))}
    </div>
  );
}

function RefundInfoComponent({ type }: TemplateComponentProps) {
  const { templates, handleAdd, handleEdit, handleDelete } =
    useContext(TemplateContext);

  const refinedTemplates = templates.filter(
    (template) => template.meta.type === type
  );

  return (
    <div
      className="p-30 height-500 outline-with-border"
      style={{ overflow: "scroll" }}
    >
      <div className="d-flex justify-content-space-between mb-10">
        <div className="font-header">환불 규정</div>
        <div>
          <CustomButton onClick={() => handleAdd(type)}>추가</CustomButton>
        </div>
      </div>
      {refinedTemplates.map((template) => (
        <Card className="mb-10 p-30" key={template.id} variant="outlined">
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDelete(template.id)}>
              삭제
            </CustomButton>
          </div>
          <div className="p-30">
            <CustomText
              label="환불 규정 제목"
              name="title"
              type="text"
              width="100%"
              defaultValue={template.meta.title}
              handleBlur={(e) => {
                template.meta.title = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
          <div className="p-30">
            <CustomText
              label="설명"
              name="description"
              type="text"
              width="100%"
              multiline
              height="100px"
              defaultValue={template.meta.description}
              handleBlur={(e) => {
                template.meta.description = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
        </Card>
      ))}
    </div>
  );
}

function FAQInfoComponent({ type }: TemplateComponentProps) {
  const { templates, handleAdd, handleEdit, handleDelete } =
    useContext(TemplateContext);

  const refinedTemplates = templates.filter(
    (template) => template.meta.type === type
  );

  return (
    <div
      className="p-30 height-500 outline-with-border"
      style={{ overflow: "scroll" }}
    >
      <div className="d-flex justify-content-space-between mb-10">
        <div className="font-header">FAQ</div>
        <div>
          <CustomButton onClick={() => handleAdd(type)}>추가</CustomButton>
        </div>
      </div>
      {refinedTemplates.map((template) => (
        <Card className="mb-10 p-30" key={template.id} variant="outlined">
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDelete(template.id)}>
              삭제
            </CustomButton>
          </div>
          <div className="p-30">
            <CustomText
              label="FAQ 제목"
              name="title"
              type="text"
              width="100%"
              defaultValue={template.meta.title}
              handleBlur={(e) => {
                template.meta.title = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
          <div className="p-30">
            <CustomText
              label="질문"
              name="question"
              type="text"
              width="100%"
              defaultValue={template.meta.question}
              handleBlur={(e) => {
                template.meta.question = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
          <div className="p-30">
            <CustomText
              label="답"
              name="answer"
              type="text"
              width="100%"
              multiline
              height="100px"
              defaultValue={template.meta.answer}
              handleBlur={(e) => {
                template.meta.answer = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
        </Card>
      ))}
    </div>
  );
}

function BenefitInfoComponent({ type }: TemplateComponentProps) {
  const { templates, handleAdd, handleEdit, handleDelete } =
    useContext(TemplateContext);

  const refinedTemplates = templates.filter(
    (template) => template.meta.type === type
  );

  return (
    <div
      className="p-30 height-500 outline-with-border"
      style={{ overflow: "scroll" }}
    >
      <div className="d-flex justify-content-space-between mb-10">
        <div className="font-header">혜택</div>
        <div>
          <CustomButton onClick={() => handleAdd(type)}>추가</CustomButton>
        </div>
      </div>
      {refinedTemplates.map((template) => (
        <Card className="mb-10 p-30" key={template.id} variant="outlined">
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDelete(template.id)}>
              삭제
            </CustomButton>
          </div>
          <div className="mt-10">
            <CustomText
              label="제목"
              name="title"
              type="text"
              width="100%"
              defaultValue={template.meta.title}
              handleBlur={(e) => {
                template.meta.title = e.target.value;
                handleEdit(template);
              }}
            />
          </div>
          <div className="mt-10 d-flex justify-content-flex-end">
            <CustomButton
              onClick={() => {
                const newObject = {
                  title: "",
                  description: "",
                };
                if (!template.meta.benefits) {
                  template.meta.benefits = [newObject];
                } else {
                  template.meta.benefits = [
                    ...template.meta.benefits,
                    newObject,
                  ];
                }
                handleEdit(template);
              }}
            >
              혜택추가
            </CustomButton>
          </div>
          {template.meta.benefits &&
            (template.meta.benefits as any[]).map((benefit, index) => (
              <Card className="mt-10 p-30" variant="outlined" key={index}>
                <CustomButton
                  onClick={() => {
                    template.meta.benefits.splice(index, 1);
                    handleEdit(template);
                  }}
                >
                  혜택삭제
                </CustomButton>
                <div className="mt-10">
                  <CustomText
                    label="제목"
                    name="title"
                    type="text"
                    width="100%"
                    defaultValue={benefit.title}
                    handleBlur={(e) => {
                      benefit.title = e.target.value;
                      handleEdit(template);
                    }}
                  />
                </div>
                <div className="mt-10">
                  <CustomText
                    label="설명"
                    name="description"
                    type="text"
                    width="100%"
                    multiline
                    height="100px"
                    defaultValue={benefit.description}
                    handleBlur={(e) => {
                      benefit.description = e.target.value;
                      handleEdit(template);
                    }}
                  />
                </div>
                <div className="mt-10">
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <img
                        src={benefit.image}
                        alt=""
                        className="width-100-percentage"
                      />
                    </Grid>
                    <Grid item>
                      <CustomImageUploadButton
                        fileChangeCallback={(file) => {
                          postImageFile(file).then((image) => {
                            benefit.image_id = image.id;
                            handleEdit(template);
                          });
                        }}
                      >
                        이미지 선택
                      </CustomImageUploadButton>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            ))}
        </Card>
      ))}
    </div>
  );
}

interface TemplateContextProps {
  templates: Template[];
  handleAdd: (type: string) => Promise<void>;
  handleEdit: (template: Template) => Promise<void>;
  handleDelete: (templateId: number) => Promise<void>;
}

const TemplateContext = React.createContext({} as TemplateContextProps);

function AdminPageTemplates() {
  const { templates, refreshTemplates } = useTemplates();

  const handleAdd = (type: string) => {
    return fetchDefaultWithCredential(`/program/templates`, "POST", {
      type,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshTemplates);
    });
  };

  const handleEdit = (template: Template) => {
    return fetchDefaultWithCredential(
      `/program/templates/${template.id}`,
      "PUT",
      template
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshTemplates);
    });
  };
  const handleDelete = (id: number) => {
    return fetchDefaultWithCredential(
      `/program/templates/${id}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshTemplates);
    });
  };

  return (
    <TemplateContext.Provider
      value={{
        templates,
        handleAdd,
        handleEdit,
        handleDelete,
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item sm={6} xs={12}>
          <DiscountInfoComponent type="discount" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <RefundInfoComponent type="refund" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FAQInfoComponent type="faq" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <BenefitInfoComponent type="benefit" />
        </Grid>
      </Grid>
    </TemplateContext.Provider>
  );
}

export default AdminPageTemplates;
