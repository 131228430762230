import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router";
import CustomButton from "./customs/CustomButton";
import queryString from "query-string";
import { useUser } from "../globals/user";

const useStyles = makeStyles({
  header: {
    backgroundColor: "lightblue",
    height: "200px",
  },
  headerText: {
    fontSize: "40px",
    fontWeight: 900,
  },
  subText: {
    marginTop: "40px",
    fontSize: "20px",
  },
});

function CompleteBuy() {
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search);
  const classes = useStyles();
  const user = useUser();

  if (!user) return null;
  if (
    !parsed.type ||
    !["adult-program", "philo-preceptorial"].includes(parsed.type as string)
  ) {
    return <p className="font-header text-align-center">타입을 설정해주세요</p>;
  }

  const isAdultProgram = parsed.type === "adult-program";

  return (
    <Fragment>
      <div className={classes.header}></div>
      <div style={{ marginTop: "70px", textAlign: "center" }}>
        <Typography className={classes.headerText}>
          신청이 완료되었습니다.
        </Typography>
        <Typography
          className={classes.subText}
          color="textSecondary"
          style={{ marginTop: "20px" }}
        >
          신청한 프로그램에 필요한 키트가 준비되어 있어요!
        </Typography>
      </div>
      <div style={{ width: "360px", margin: "70px auto 250px" }}>
        <div>
          <CustomButton
            height={40}
            fullWidth
            onClick={() => history.push("/cart?section=registered")}
          >
            프로그램 키트 다운받기 (필수)
          </CustomButton>
        </div>
        <div style={{ marginTop: "20px" }}>
          <CustomButton
            height={40}
            fullWidth
            onClick={() => history.push("/" + parsed.type)}
          >
            {isAdultProgram ? "이달의 " : ""}다른 프로그램 보러가기
          </CustomButton>
        </div>
      </div>
    </Fragment>
  );
}

export default CompleteBuy;
