import React, { useState } from "react";
import { formatDashedDate } from "../../../../utils";
import CustomSelect from "../../../customs/CustomSelect";
import CustomText from "../../../customs/CustomText";
import { responsivePercentage } from "../../../Home";
import { SignupFormComponent } from "../../../layouts/Signup";

interface FormProps {
  name: string;
  gender: string;
  phone: string;
  date_of_birth: string;
}

function AdminPagesCustomSignup() {
  const [form, setForm] = useState<FormProps>({
    name: "",
    gender: "male",
    phone: "000-0000-0000",
    date_of_birth: formatDashedDate(new Date()),
  });
  return (
    <div
      className="margin-center"
      style={{
        width: responsivePercentage(30),
      }}
    >
      <div>
        <CustomText
          type="text"
          label="이름"
          name="name"
          style={{ width: "100%" }}
          handleBlur={(e) => setForm({ ...form, name: e.target.value })}
        />
      </div>
      <div className="mt-10">
        <CustomSelect
          name="gender"
          label="성별"
          items={[
            {
              label: "남자",
              value: "male",
            },

            {
              label: "여자",
              value: "female",
            },
          ]}
          value={form.gender}
          handleChange={(value) =>
            setForm({
              ...form,
              gender: value as string,
            })
          }
        />
      </div>
      <div className="mt-10">
        <CustomText
          type="phone"
          label="휴대전화 번호"
          name="phone"
          style={{ width: "100%" }}
          readOnly
          value={form.phone}
        />
      </div>
      <div className="mt-10">
        <p className="font-14 font-bold">생년월일</p>
        <input
          type="date"
          value={form.date_of_birth}
          onChange={(e) => setForm({ ...form, date_of_birth: e.target.value })}
          className="width-100-percentage"
        />
      </div>
      <SignupFormComponent noAuthorize existingForm={form} />
    </div>
  );
}

export default AdminPagesCustomSignup;
