import OperatorView from "../components/dashboard/views/OperatorView";
import { RouteInfo } from "../interfaces";

const Operator: RouteInfo[] = [
  {
    exact: false,
    path: "/admin/management",
    component: OperatorView,
  },
];

export default Operator;
