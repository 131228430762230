import React, { ReactNode, useCallback, useEffect, useState } from "react";
import CustomDialog from "../../components/customs/CustomDialog";
import Signin from "../../components/layouts/Signin";
import ReviewDialogComponent from "./ReviewDialogComponent";
import TutorDialogComponent from "./TutorDialogComponent";
import InactiveComponent from "./InactiveComponent";
import { getUser, User } from "../user";
import { getNotification, Notification } from "../notification";
import { Environment, getEnvironment } from "../environment";
import SnackbarComponent, { SnackbarType } from "./SnackbarComponent";
import UserPopupDialogComponent from "./UserPopupDialogComponent";
import UserSatisfactionDialogComponent from "./UserSatisfactionDialogComponent";

interface ComponentsWrapperProps {
  children: ReactNode;
}

export const GlobalContext = React.createContext({} as GlobalContextProps);

export interface GlobalContextProps {
  user: User | null;
  refreshUser: () => Promise<any>;
  toggleSigninDialog: () => void;
  signinWrapper: (callbackFunc: () => any) => any;
  notifications: Notification;
  refreshNotification: () => void;
  environment: Environment;
}

export const SnackbarContext = React.createContext({} as SnackbarContextProps);

export interface SnackbarContextProps {
  showSuccessSnackbar: (text: string) => void;
  showErrorSnackbar: (text: string) => void;
  showWarningSnackbar: (text: string) => void;
}

export default function ComponentsWrapper({
  children,
}: ComponentsWrapperProps) {
  const [openDeactivated, setOpenDeactivated] = useState<boolean>(false);
  const [openSignin, setOpenSignin] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [notifications, setNotifications] = useState<Notification | null>(null);
  const [environment, setEnvironment] = useState<Environment | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string>("");
  const [snackbarType, setSnackbarType] = useState<SnackbarType>("success");

  const refreshNotification = useCallback(() => {
    return getNotification().then(setNotifications);
  }, []);

  const refreshUser = useCallback(
    () =>
      getUser().then((user) => {
        setUser(user);
        if (!user) {
          setNotifications({} as Notification);
        } else {
          refreshNotification();
          if (user.deactivated) {
            setOpenDeactivated(true);
          }
        }
      }),
    [refreshNotification]
  );

  useEffect(() => {
    if (user) {
      setOpenSignin(false);
    }
  }, [user]);

  useEffect(() => {
    if (!loaded) {
      Promise.all([
        getEnvironment().then(({ environment }) => setEnvironment(environment)),
        refreshUser(),
      ]).then(() => setLoaded(true));
    }
  }, [refreshUser, user, loaded]);

  const toggleSigninDialog = () => {
    if (!user) {
      setOpenSignin(!openSignin);
    }
  };

  const signinWrapper = (callbackFunc: () => any) => {
    if (!user) {
      toggleSigninDialog();
      return null;
    } else {
      return callbackFunc();
    }
  };

  if (!notifications || !environment) return null;

  return (
    <GlobalContext.Provider
      value={{
        user,
        refreshUser,
        toggleSigninDialog,
        signinWrapper,
        notifications,
        refreshNotification,
        environment,
      }}
    >
      <SnackbarContext.Provider
        value={{
          showWarningSnackbar(text) {
            setOpenSnackbar(true);
            setSnackbarType("warning");
            setSnackbarText(text);
          },
          showErrorSnackbar(text) {
            setOpenSnackbar(true);
            setSnackbarType("error");
            setSnackbarText(text);
          },
          showSuccessSnackbar(text) {
            setOpenSnackbar(true);
            setSnackbarType("success");
            setSnackbarText(text);
          },
        }}
      >
        {children}
        <SnackbarComponent
          open={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          text={snackbarText}
          type={snackbarType}
        />
        <CustomDialog
          open={openDeactivated}
          handleClose={() => null}
          maxWidth="md"
          fullWidth
        >
          <InactiveComponent />
        </CustomDialog>
        <CustomDialog
          open={openSignin}
          handleClose={() => setOpenSignin(false)}
          maxWidth="md"
        >
          <Signin stay />
        </CustomDialog>
        <ReviewDialogComponent />
        <TutorDialogComponent />
        <UserPopupDialogComponent />
        <UserSatisfactionDialogComponent />
      </SnackbarContext.Provider>
    </GlobalContext.Provider>
  );
}
