import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material";
import Navbar from "./components/layouts/Navbar";
import Routers from "./routers";
import Footer from "./components/layouts/Footer";
import { Provider, ErrorBoundary } from "@rollbar/react"; // <-- Provider imports 'rollbar' for us
import { ENVIRONMENT } from "./config";
import ComponentsWrapper from "./globals/components/ComponentsWrapper";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: "#292929",
      dark: "#292929",
      light: "#292929",
    },
    // secondary: {
    //   main: "#7f7f7f",
    // },
    text: {
      primary: "#292929",
      secondary: "#7f7f7f",
    },
  },
});

const rollbarConfig = {
  accessToken: "28f86cba027a41fea3ffe15596e57b80",
  environment: ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    },
  },
};

function AppComponent() {
  return (
    <div className="app">
      <div className="container">
        <CssBaseline />
        <Router>
          <ComponentsWrapper>
            <Navbar />
            <div className="content-container">
              <Routers />
            </div>
            <Footer />
          </ComponentsWrapper>
        </Router>
      </div>
    </div>
  );
}

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppComponent />
          </ThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
