import { Step, StepButton, StepConnector, StepIconProps, StepLabel, Stepper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";

interface PropType {
  desireStep?: number;
  desireQuery?: (null | string)[];
  steps: string[];
  width?: string;
  disabled: boolean;
  contents: (JSX.Element | null)[];
  dark?: boolean;
  setIndex?: (index: number) => void;
}

function CustomStepper({
  steps,
  width,
  disabled,
  contents,
  desireStep,
  desireQuery,
  dark,
  setIndex,
}: PropType) {
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: "15px",
      left: "calc(-50% + 8px)",
      right: "calc(50% + 8px)",
      zIndex: 1,
    },
    line: {
      borderColor: dark ? "white" : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  const CustomStepLabel = withStyles({
    label: {
      color: dark ? "white !important" : undefined,
    },
  })(StepLabel);

  const useQontoStepIconStyles = makeStyles((theme) => ({
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 30,
      alignItems: "center",
      justifyContent: "center",
    },
    active: {
      color: "#784af4",
      width: 30,
      height: 30,
      borderRadius: "50%",
      backgroundColor: "#e6e6e6",
      zIndex: 2,
    },
    circle: {
      width: 6,
      height: 6,
      borderRadius: "50%",
      backgroundColor: dark ? "white" : theme.palette.text.primary,
      zIndex: 2,
    },
  }));

  function QontoStepIcon(props: StepIconProps) {
    const classes = useQontoStepIconStyles();
    const { active } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        <div className={classes.circle} />
      </div>
    );
  }

  const [activeStep, setActiveStep] = useState<number>(desireStep ?? 0);

  const Content = contents[desireStep ?? activeStep];
  const location = useLocation();
  return (
    <Fragment>
      <div style={{ width, margin: "0 auto" }}>
        <Stepper
          alternativeLabel
          activeStep={desireStep ?? activeStep}
          connector={<QontoConnector />}
          style={{ backgroundColor: "inherit" }}
          nonLinear={desireStep ? false : true}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              {disabled ? (
                <CustomStepLabel
                  StepIconComponent={QontoStepIcon}
                  style={{ color: "white" }}
                >
                  <p className="font-12">{label}</p>
                </CustomStepLabel>
              ) : (
                <StepButton
                  onClick={() => {
                    setActiveStep(index);
                    if (setIndex) {
                      setIndex(index);
                    }
                  }}
                  disabled={false}
                  {...(desireQuery && {
                    component: Link,
                    to:
                      location.pathname +
                      (!desireQuery[index] ? "" : "?" + desireQuery[index]),
                  })}
                >
                  <CustomStepLabel StepIconComponent={QontoStepIcon}>
                    <p className="font-12">{label}</p>
                  </CustomStepLabel>
                </StepButton>
              )}
            </Step>
          ))}
        </Stepper>
      </div>
      {Content}
    </Fragment>
  );
}

export default CustomStepper;
