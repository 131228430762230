import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  putObjectFromList,
  removeObjectFromList,
  useUsers,
} from "../../../hooks";
import { defaultSet } from "../../../interfaces";
import {
  eligibleHierarchy,
  eligibleRoles,
  fetchDefaultWithCredential,
  formatDateTemplate,
  formatTimeTemplate,
  roleLabelMap,
} from "../../../utils";
import CustomAvatar from "../../customs/CustomAvatar";
import CustomButton from "../../customs/CustomButton";
import CustomCheck from "../../customs/CustomCheck";
import CustomDialog from "../../customs/CustomDialog";
import CustomSelect from "../../customs/CustomSelect";
import CustomText from "../../customs/CustomText";
import { useOperatorPathMetas } from "../../dashboard/views/OperatorView";
import SortComponent from "./helpers/SortComponent";
import { User, UserRole, useUser } from "../../../globals/user";

interface ListViewProps {
  user: User;
  departments?: Department[];
  handleRefresh: () => void;
}

interface UserManageForm {
  department: number;
  role: UserRole;
  accesses: string[];
}

interface HandleRefreshProps {
  handleRefresh: () => void;
}

function ListView({ user, departments, handleRefresh }: ListViewProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [openEditConfirm, setOpenEditConfirm] = useState<boolean>(false);
  const [form, setForm] = useState<UserManageForm>({
    department: user.meta.department ?? 0,
    role: user.role,
    accesses: user.meta.accesses ?? [],
  });
  const currentUser = useUser();
  const pathMetas = useOperatorPathMetas();

  const handleUpdate = () => {
    return fetchDefaultWithCredential(
      `/user/${user.id}/management`,
      "PUT",
      form
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(handleRefresh);
    });
  };

  if (!currentUser) return null;

  return (
    <div className="p-5 width-100-percentage">
      <div className="d-flex align-items-center justify-content-space-between">
        <div className="avatar">
          <div className="width-40 height-40">
            <CustomAvatar user={user} />
          </div>
        </div>
        {user.department && (
          <div className="department font-16 font-bold">
            {user.department.name}
          </div>
        )}
        <div className="name font-16 font-bold">{user.name}</div>
        <div className="email font-16 font-bold">{user.email}</div>
        <div className="phone font-16 font-bold">{user.phone}</div>
        <div className="last-signed-in font-16 font-bold">
          {user.last_signed_in
            ? `${formatDateTemplate(user.last_signed_in)} ${formatTimeTemplate(
                user.last_signed_in
              )}`
            : "마지막 로그인 날짜 없음"}
        </div>
        {eligibleHierarchy(currentUser, user) && (
          <Fragment>
            <div className="manage">
              <CustomButton onClick={() => setOpen(true)}>관리</CustomButton>
            </div>
            <CustomDialog open={open} fullWidth>
              <div>
                <CustomSelect
                  name="role"
                  label="권한"
                  items={eligibleRoles(currentUser).map((role) => ({
                    label: roleLabelMap[role],
                    value: role,
                  }))}
                  value={form.role}
                  handleChange={(value) =>
                    setForm({
                      ...form,
                      role: value as UserRole,
                    })
                  }
                />
              </div>
              {user.role === "operator2" && departments && (
                <div className="mt-30">
                  <CustomSelect
                    name="department"
                    label="부서 지정"
                    items={[
                      {
                        label: "선택 안함",
                        value: 0,
                        disabled: true,
                      },
                      ...departments.map((department) => ({
                        label: department.name,
                        value: department.id!,
                      })),
                    ]}
                    value={form.department}
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        department: value as number,
                      })
                    }
                  />
                </div>
              )}
              {user.role === "operator2" && (
                <div className="mt-30 height-300 overflow-scroll outline-with-border p-20">
                  <CustomCheck
                    label="관리자 페이지 엑세스 허용"
                    checkItems={(() => {
                      // check path metas and make id of prefix with endpoint
                      // label should be title - subtitle
                      // checked should be, if meta access has endpoint of indicated
                      const res = [];
                      for (let i = 0; i < pathMetas.length; i++) {
                        const pathMeta = pathMetas[i];
                        if (pathMeta.role && !pathMeta.role.includes(user.role))
                          continue;
                        for (
                          let j = 0;
                          j < pathMeta.metas.subFields.length;
                          j++
                        ) {
                          const subField = pathMeta.metas.subFields[j];
                          const id = pathMeta.metas.prefix + subField.endpoint;
                          const label = `${i}.${j + 1} ${pathMeta.title} - ${
                            subField.title
                          }`;
                          let checked = false;
                          checked = form.accesses.includes(id);
                          // now append list of object of it
                          res.push({
                            id,
                            label,
                            checked,
                          });
                        }
                      }
                      return res;
                    })()}
                    handleChange={(id, checked) => {
                      if (checked) {
                        form.accesses.push(id);
                      } else {
                        form.accesses = form.accesses.filter(
                          (access) => access !== id
                        );
                      }
                      setForm({ ...form });
                    }}
                  />
                </div>
              )}
              <div className="mt-10 d-flex justify-content-flex-end">
                <div>
                  <CustomButton onClick={() => setOpenEditConfirm(true)}>
                    수정
                  </CustomButton>
                </div>
                <div className="ml-10">
                  <CustomButton onClick={() => setOpen(false)}>
                    닫기
                  </CustomButton>
                </div>
              </div>
            </CustomDialog>
            <CustomDialog open={openEditConfirm} fullWidth>
              <p className="font-header">정말로 수정하시겠습니까?</p>
              <div className="mt-10 d-flex justify-content-flex-end">
                <div>
                  <CustomButton onClick={handleUpdate}>확인</CustomButton>
                </div>
                <div className="ml-10">
                  <CustomButton onClick={() => setOpenEditConfirm(false)}>
                    닫기
                  </CustomButton>
                </div>
              </div>
            </CustomDialog>
          </Fragment>
        )}
      </div>
    </div>
  );
}

function Operator1Component({ handleRefresh }: HandleRefreshProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { users: operator1Users } = useUsers("operator1");
  return (
    <SortComponent
      currentDataObject={currentUser}
      setCurrentDataObject={setCurrentUser}
      label="메인 관리자 명단"
      data={operator1Users}
      listItemComponent={(user) => (
        <ListView handleRefresh={handleRefresh} user={user} />
      )}
      abcSortAttributeFunc={(user) => user.name}
    />
  );
}

interface Operator2ComponentProps extends HandleRefreshProps {
  departments: Department[];
}

function Operator2Component({
  departments,
  handleRefresh,
}: Operator2ComponentProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { users: operator2Users } = useUsers("operator2");
  return (
    <SortComponent
      currentDataObject={currentUser}
      setCurrentDataObject={setCurrentUser}
      label="서브 관리자 명단"
      data={operator2Users}
      listItemComponent={(user) => (
        <ListView
          handleRefresh={handleRefresh}
          user={user}
          departments={departments}
        />
      )}
      abcSortAttributeFunc={(user) => user.name}
    />
  );
}

function UserComponent({ handleRefresh }: HandleRefreshProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { users } = useUsers("user");
  return (
    <SortComponent
      currentDataObject={currentUser}
      setCurrentDataObject={setCurrentUser}
      label="회원 명단"
      data={users}
      listItemComponent={(user) => (
        <ListView handleRefresh={handleRefresh} user={user} />
      )}
      abcSortAttributeFunc={(user) => user.name}
    />
  );
}

export interface Department extends defaultSet {
  name: string;
}

interface DepartmentManageProps {
  departments: Department[];
  setDepartments: (departments: Department[]) => void;
}

function DepartmentManage({
  departments,
  setDepartments,
}: DepartmentManageProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleInsert = () => {
    fetchDefaultWithCredential(`/departments`, "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then((data) => setDepartments([...departments, data]));
    });
  };

  const handleUpdate = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    department: Department
  ) => {
    const target = e.target;
    department.name = target.value;
    fetchDefaultWithCredential(
      `/departments/${department.id}`,
      "PUT",
      department
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res
        .json()
        .then((data) => setDepartments(putObjectFromList(departments, data)));
    });
  };

  const handleDelete = (department: Department) => {
    fetchDefaultWithCredential(`/departments/${department.id}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res
          .json()
          .then((data) =>
            setDepartments(removeObjectFromList(departments, data.id))
          );
      }
    );
  };

  return (
    <Fragment>
      <CustomButton onClick={() => setOpen(true)}>부서관리</CustomButton>
      <CustomDialog open={open} fullWidth>
        <div className="p-15 d-flex justify-content-space-between">
          <CustomButton onClick={handleInsert}>부서 추가</CustomButton>
          <CustomButton onClick={() => setOpen(false)}>닫기</CustomButton>
        </div>
        {departments.map((department, index) => (
          <div className="p-15" key={department.id}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs>
                <CustomText
                  label={"부서" + (index + 1)}
                  name="department"
                  type="text"
                  width="100%"
                  defaultValue={department.name}
                  handleBlur={(e) => handleUpdate(e, department)}
                />
              </Grid>
              <Grid item>
                <CustomButton
                  height={50}
                  onClick={() => handleDelete(department)}
                >
                  삭제
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        ))}
      </CustomDialog>
    </Fragment>
  );
}

function AdminPageSetup() {
  const user = useUser();
  const [departments, setDepartments] = useState<Department[]>([]);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential("/departments", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setDepartments);
    });
  }, []);

  const handleRefresh = () => {
    setRender(render + 1);
  };

  if (!user) return null;

  return (
    <Fragment>
      <div className="p-30">
        <DepartmentManage
          departments={departments}
          setDepartments={setDepartments}
        />
      </div>
      <div className="components" key={render}>
        {(user.role === "admin" || user.role === "operator1") && (
          <div className="h-300 p-30 operator1">
            <Operator1Component handleRefresh={handleRefresh} />
          </div>
        )}
        <div className="h-300 p-30 operator2">
          <Operator2Component
            departments={departments}
            handleRefresh={handleRefresh}
          />
        </div>
        <div className="h-300 p-30 user">
          <UserComponent handleRefresh={handleRefresh} />
        </div>
      </div>
    </Fragment>
  );
}

export default AdminPageSetup;
