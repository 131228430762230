import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { fetchDefaultWithCredential } from "../utils";
import { useContext } from "react";
import { SnackbarContext } from "../globals/components/ComponentsWrapper";

function handleRedirection(
  history: any,
  success: any,
  imp_uid: any,
  pathname: string,
  handleError: (err: string) => void,
  meta?: any
) {
  if (success === "false") {
    history.replace({
      pathname,
      state: {
        mobile_verified: false,
      },
    });
    history.go(0);
  } else {
    fetchDefaultWithCredential("/certification", "POST", {
      imp_uid,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          handleError(error);
        });
      }
      return res.json().then((data) => {
        history.replace({
          pathname,
          state: {
            mobile_verified: true,
            ...meta,
            ...data,
          },
        });
        history.go(0);
      });
    });
  }
}

function Redirection() {
  const history = useHistory();
  const location = useLocation();
  const splittedLocation = location.pathname.split("/");
  const type = splittedLocation[splittedLocation.length - 1];
  const parsed = queryString.parse(location.search);
  const { imp_uid, success } = parsed;
  const { showWarningSnackbar } = useContext(SnackbarContext);

  const showError = (error: string) => showWarningSnackbar(error);

  switch (type) {
    case "signup":
      handleRedirection(history, success, imp_uid, "/signup", showError);
      break;
    case "email":
      handleRedirection(
        history,
        success,
        imp_uid,
        "/signin?status=email",
        showError
      );
      break;
    case "mypage":
      handleRedirection(
        history,
        success,
        imp_uid,
        "/mypage?section=profile",
        showError,
        {
          subsection: "phone",
        }
      );
      break;
    default:
      break;
  }

  return null;
}

export default Redirection;
