import React, { Fragment, useContext, useState } from "react";
import {
  CustomInstructDialog,
  PrivateInfoCollectionDialog,
} from "./customs/CustomDialog";
import CustomText from "./customs/CustomText";
import { Grid } from "@mui/material";
import CustomButton from "./customs/CustomButton";
import { fetchDefaultWithCredential } from "../utils";
import { GlobalContext } from "../globals/components/ComponentsWrapper";

function Feedbacks() {
  const [render, setRender] = useState<number>(0);
  const [content, setContent] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [doneOpen, setDoneOpen] = useState<boolean>(false);
  const [submitFeedback, setSubmitFeedback] = useState<string>("");
  const { toggleSigninDialog } = useContext(GlobalContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchDefaultWithCredential("/feedbacks", "POST", {
      content,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          setSubmitFeedback(error);
        });
      }
      return res.json().then(() => {
        setRender(render + 1);
        setDoneOpen(true);
      });
    });
  };
  return (
    <Fragment>
      <div className="p-30">
        <p className="font-header text-align-center">
          필로어스에서의 경험은 어떠셨나요?
        </p>
        <p className="font-sub">
          사소한 부분부터 큰 부분까지, 필로어스는 모든 의견을 귀기울여 듣고
          싶습니다. 더 나은 필로어스를 위해 의견을 들려주세요!
        </p>
        <div className="mt-30">
          <form
            onChange={(e) => setContent((e.target as HTMLInputElement).value)}
            onSubmit={handleSubmit}
            key={render}
          >
            <div onClick={toggleSigninDialog}>
              <CustomText
                type="text"
                multiline
                name="content"
                width="100%"
                height="150px"
                style={{ backgroundColor: "white" }}
              />
            </div>
            {submitFeedback !== "" && (
              <div className="mt-10 error">{submitFeedback}</div>
            )}
            <Grid
              container
              style={{ marginTop: "10px" }}
              justifyContent="center"
            >
              <CustomButton outlined type="submit">
                피드백 전송하기
              </CustomButton>
            </Grid>
          </form>
        </div>
      </div>
      <PrivateInfoCollectionDialog open={open} setOpen={setOpen} />
      <CustomInstructDialog
        open={doneOpen}
        handleClose={() => setDoneOpen(false)}
        header="소중한 의견을 들려주셔서 감사합니다. 보내주신 의견은 더 나은 필로어스 경험을 제공해드리기 위해 사용됩니다."
        maxWidth="md"
      />
    </Fragment>
  );
}

export default Feedbacks;
