import { Badge } from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { GlobalContext } from "../../../globals/components/ComponentsWrapper";
import { useNotifications } from "../../../globals/notification";
import {
  fetchDefaultWithCredential,
  formatDashedDateTemplate,
  formatDateTemplateDateType,
} from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomSelect from "../../customs/CustomSelect";
import CustomText from "../../customs/CustomText";
import { ParticipatedUser, Program } from "./Participations";

interface UserSelectComponentProps {
  programId: number;
  userId: number;
  setUserId: (userId: number) => void;
  reports: any;
  setDefinition: (definition: boolean) => void;
  admin?: boolean;
  refreshRender: number;
}

interface UserReportComponentProps {
  admin?: boolean;
  programId: number;
  userId: number;
  definition: boolean;
  refresh: () => void;
}

interface UserProgram {
  id: number;
  created_at: string;
  meta: any;
}

function UserReportComponent({
  programId,
  userId,
  admin,
  definition,
  refresh,
}: UserReportComponentProps) {
  const [userProgram, setUserProgram] = useState<UserProgram | null>(null);
  const [render, setRender] = useState<number>(0);
  const { refreshNotification } = useContext(GlobalContext);

  const handleEdit = useCallback(() => {
    if (!userProgram) return;
    fetchDefaultWithCredential(
      `/program/${programId}/users/${userId}/reports`,
      "PUT",
      {
        ...userProgram.meta,
        feedback: userProgram.meta.feedback || "",
        definition: userProgram.meta.definition || "",
        status: userProgram.meta.status || "progress",
      }
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res
        .json()
        .then(() => setRender(render + 1))
        .then(refresh)
        .then(refreshNotification);
    });
  }, [programId, refreshNotification, render, userId, userProgram, refresh]);

  useEffect(() => {
    if (!userProgram || render !== 0) return;
    if (admin) {
      userProgram.meta.admin_read = true;
      handleEdit();
    } else {
      userProgram.meta.tutor_read = true;
      handleEdit();
    }
  }, [userProgram, admin, handleEdit, render]);

  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/${programId}/users/${userId}/reports`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setUserProgram);
    });
  }, [programId, userId, render]);

  if (!userProgram) return null;
  const status = userProgram.meta.status || "progress";

  return (
    <Fragment>
      <div className="font-18 font-bold">
        상태:{" "}
        {(() => {
          if (status === "requested") {
            return "요청함";
          }
          if (status === "returned") {
            return <span className="error">반려됨</span>;
          }
          if (status === "approved") {
            return "승인됨";
          }
          return "작성중";
        })()}
      </div>
      {!admin && status === "returned" && (
        <div className="mt-10">
          <div className="font-18 font-bold">
            반려 사유: {userProgram.meta.returning_reason}
          </div>
        </div>
      )}
      <div className="mt-10">
        <CustomText
          label="피드백"
          name="feedback"
          type="text"
          width="100%"
          multiline
          height="100px"
          defaultValue={userProgram.meta.feedback}
          handleBlur={(e) => {
            userProgram.meta.feedback = e.target.value;
            handleEdit();
          }}
          readOnly={status === "approved"}
        />
      </div>
      {definition && (
        <div className="mt-10">
          <CustomText
            label="철학"
            name="definition"
            type="text"
            width="100%"
            multiline
            height="100px"
            defaultValue={userProgram.meta.definition}
            handleBlur={(e) => {
              userProgram.meta.definition = e.target.value;
              handleEdit();
            }}
            readOnly={status === "approved"}
          />
        </div>
      )}
      {admin && (
        <div className="mt-10">
          <CustomText
            label="반려 사유"
            name="returning_reason"
            type="text"
            width="100%"
            multiline
            height="100px"
            defaultValue={userProgram.meta.returning_reason}
            handleBlur={(e) => {
              userProgram.meta.returning_reason = e.target.value;
              handleEdit();
            }}
            readOnly={status === "approved"}
          />
        </div>
      )}
      <div className="mt-10">
        <div className="d-flex">
          <div className="mr-10">
            <CustomButton
              disabled={status === "progress" || status === "approved"}
              onClick={() => {
                userProgram.meta.status = "progress";
                handleEdit();
              }}
            >
              작성
            </CustomButton>
          </div>
          {
            <div className="mr-10">
              {admin && (
                <CustomButton
                  disabled={status === "progress" || status === "returned"}
                  onClick={() => {
                    userProgram.meta.status = "returned";
                    userProgram.meta.tutor_read = false;
                    handleEdit();
                  }}
                >
                  반려
                </CustomButton>
              )}
              {!admin && (
                <CustomButton
                  disabled={status === "requested" || status === "approved"}
                  onClick={() => {
                    userProgram.meta.status = "requested";
                    userProgram.meta.admin_read = false;
                    handleEdit();
                  }}
                >
                  요청
                </CustomButton>
              )}
            </div>
          }
          <div className="mr-10">
            {admin && (
              <CustomButton
                disabled={status === "approved"}
                onClick={() => {
                  userProgram.meta.status = "approved";
                  userProgram.meta.tutor_read = false;
                  userProgram.meta.user_read = false;
                  handleEdit();
                }}
              >
                승인
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function UserSelectComponent({
  programId,
  userId,
  setUserId,
  reports,
  setDefinition,
  admin,
  refreshRender,
}: UserSelectComponentProps) {
  const [participatedUsers, setParticipatedUsers] = useState<
    ParticipatedUser[] | null
  >(null);
  const { refreshNotification } = useContext(GlobalContext);
  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/${programId}/users?type=more`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setParticipatedUsers);
    });
  }, [programId, refreshRender]);

  useEffect(() => {
    if (participatedUsers) {
      const participatedUser = participatedUsers.find(
        (participatedUser) => participatedUser.user.id === userId
      );
      if (participatedUser) {
        setDefinition(participatedUser.definition);
      }
    }
  }, [participatedUsers, userId, setDefinition]);

  const handleConfirmAll = () => {
    fetchDefaultWithCredential(
      `/program/${programId}/reports/readall?type=${admin ? "admin" : "tutor"}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshNotification);
    });
  };

  if (!participatedUsers) return null;

  return (
    <div className="d-flex align-items-flex-end">
      <div>
        <CustomSelect<number>
          label="참가자 선택"
          name="user"
          items={[
            {
              label: "선택 안함",
              value: 0,
              disabled: true,
            },
            ...participatedUsers.map((participatedUser) => ({
              label: (
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={
                    !(reports[programId] || []).includes(
                      participatedUser.user.id
                    )
                  }
                >
                  {participatedUser.user.name}
                  {(() => {
                    if (participatedUser.status === "approved") {
                      return " (승인됨)";
                    }
                    return "";
                  })()}
                </Badge>
              ),
              value: participatedUser.user.id as number,
            })),
          ]}
          value={userId}
          handleChange={setUserId}
        />
      </div>
      {!admin && (
        <div className="ml-30">
          <CustomButton onClick={handleConfirmAll}>모두 확인</CustomButton>
        </div>
      )}
    </div>
  );
}

interface TutorReportsProps {
  admin?: boolean;
}

function TutorReports({ admin }: TutorReportsProps) {
  const [programId, setProgramId] = useState<number>(0);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [userId, setUserId] = useState<number>(0);
  const [definition, setDefinition] = useState<boolean>(false);
  const notifications = useNotifications();
  const [refreshRender, setRefreshRender] = useState<number>(0);
  let reports: any = null;

  if (admin) {
    reports = notifications.admin.reports;
  } else {
    reports = notifications.tutor.reports;
  }
  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/tutor/participated?type=${admin ? "admin" : "monthly"}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setPrograms);
    });
  }, [admin, refreshRender]);

  if (!reports) return null;
  return (
    <Fragment>
      <div className="d-flex">
        <CustomSelect<number>
          label="프로그램 선택"
          name="program"
          items={[
            {
              label: "선택 안함",
              value: 0,
              disabled: true,
            },
            ...programs.map((program) => ({
              label: (
                <Badge
                  color="secondary"
                  badgeContent={reports[program.id]?.length ?? 0}
                >
                  {formatDashedDateTemplate(
                    program.meta.dates[0] ||
                      formatDateTemplateDateType(new Date())
                  )}{" "}
                  - {program.meta.program_name}
                  {program.status === "approved" ? " (승인됨)" : ""}
                </Badge>
              ),
              value: program.id,
            })),
          ]}
          value={programId}
          handleChange={(programId) => {
            setProgramId(programId);
            setUserId(0);
          }}
        />
      </div>
      {programId > 0 && (
        <div className="mt-10">
          <UserSelectComponent
            programId={programId}
            userId={userId}
            setUserId={setUserId}
            reports={reports}
            setDefinition={setDefinition}
            admin={admin}
            refreshRender={refreshRender}
          />
        </div>
      )}
      {programId > 0 && userId > 0 && (
        <div className="mt-10">
          <UserReportComponent
            admin={admin}
            programId={programId}
            userId={userId}
            key={userId}
            definition={definition}
            refresh={() =>
              setRefreshRender((refreshRender) => refreshRender + 1)
            }
          />
        </div>
      )}
    </Fragment>
  );
}

export default TutorReports;
