import { Divider, Grid, List, ListItem, ListItemText } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../globals/components/ComponentsWrapper";
import { User } from "../../../globals/user";
import { defaultSet } from "../../../interfaces";
import { fetchDefaultWithCredential, formatDateTemplate } from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomDialog from "../../customs/CustomDialog";
import CustomText from "../../customs/CustomText";
import UserList from "./helpers/UserList";

interface PointType extends defaultSet {
  point: number;
  type: string;
}

interface PointTypeComponentProps {
  label: string;
  pointTypes: PointType[];
  type: string;
}

function PointTypeComponent({
  pointTypes,
  type,
  label,
}: PointTypeComponentProps) {
  const [point, setPoint] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const pointType = pointTypes.find((pointType) => pointType.type === type);

  useEffect(() => {
    if (pointType) {
      setPoint(pointType.point);
    }
  }, [pointType]);

  if (!pointType) return null;
  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPoint(parseInt(e.target.value));
  };

  const handleSubmit = () => {
    if (pointType) {
      pointType.point = point;
      fetchDefaultWithCredential(
        `/point/types/${pointType.id}`,
        "PUT",
        pointType
      )
        .then((res) => {
          if (!res.ok) {
            return res.text().then((data) => {
              throw new Error(data);
            });
          }
          return res.json();
        })
        .then(() => setOpen(false));
    }
  };

  return (
    <div className={"point-type-" + type}>
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs>
          <CustomText
            label={label}
            name={type}
            type="number"
            width="100%"
            defaultValue={pointType.point}
            style={{ backgroundColor: "white" }}
            handleBlur={handleBlur}
          />
        </Grid>
        <Grid item>
          <CustomButton
            style={{ width: "50px", height: "50px" }}
            onClick={() => setOpen(true)}
          >
            등록
          </CustomButton>
        </Grid>
      </Grid>
      <CustomDialog open={open}>
        <p className="font-header">등록하시겠습니까?</p>
        <div className="d-flex justify-content-flex-end">
          <div>
            <CustomButton onClick={handleSubmit}>확인</CustomButton>
          </div>
          <div className="ml-10">
            <CustomButton onClick={() => setOpen(false)}>닫기</CustomButton>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
}

function usePointTypes() {
  const [pointTypes, setPointTypes] = useState<PointType[]>([]);
  useEffect(() => {
    fetchDefaultWithCredential(`/point/types`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setPointTypes);
  }, []);

  return pointTypes;
}

interface UserProps {
  user: User;
  render: number;
  setRender: (render: number) => void;
}

function UserPointSendingComponent({ user, render, setRender }: UserProps) {
  const [sendPoint, setSendPoint] = useState<number>(0);
  const [retrievePoint, setRetrievePoint] = useState<number>(0);
  const [openSend, setOpenSend] = useState<boolean>(false);
  const [openRetrieve, setOpenRetrieve] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const { showWarningSnackbar } = useContext(SnackbarContext);
  const handleSubmit = () => {
    const isSend = openSend;
    fetchDefaultWithCredential(`/point/user/${user.id}`, "POST", {
      reason,
      point: isSend ? sendPoint : retrievePoint,
      type: isSend ? "send" : "retrieve",
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            showWarningSnackbar(message);
          });
        }
        return res.json();
      })
      .then(() => {
        setRender(render + 1);
        setOpenSend(false);
        setOpenRetrieve(false);
      });
  };

  return (
    <div className="user-point-sending-component">
      <div>
        <CustomText
          label="사유"
          name="reason"
          type="text"
          width="100%"
          style={{ backgroundColor: "white" }}
          handleBlur={(e) => setReason(e.target.value)}
        />
      </div>
      <div className="send mt-10">
        <CustomText
          label="보낼 적립금"
          name="point-send"
          type="number"
          width="100%"
          defaultValue={0}
          style={{ backgroundColor: "white" }}
          handleBlur={(e) => setSendPoint(parseInt(e.target.value) || 0)}
        />
      </div>
      <div className="mt-10">
        <CustomButton fullWidth height={50} onClick={() => setOpenSend(true)}>
          적립금 보내기
        </CustomButton>
      </div>
      <div className="mt-10 retrieve">
        <CustomText
          label="회수할 적립금"
          name="point-retrieve"
          type="number"
          width="100%"
          defaultValue={0}
          style={{ backgroundColor: "white" }}
          handleBlur={(e) => setRetrievePoint(parseInt(e.target.value) || 0)}
        />
      </div>
      <div className="mt-10">
        <CustomButton
          fullWidth
          height={50}
          onClick={() => setOpenRetrieve(true)}
        >
          적립금 회수하기
        </CustomButton>
      </div>
      <CustomDialog open={openSend}>
        <p className="font-header">적립금 보내시겠습니까?</p>
        <div className="d-flex justify-content-flex-end">
          <div>
            <CustomButton onClick={handleSubmit}>확인</CustomButton>
          </div>
          <div className="ml-10">
            <CustomButton onClick={() => setOpenSend(false)}>닫기</CustomButton>
          </div>
        </div>
      </CustomDialog>
      <CustomDialog open={openRetrieve}>
        <p className="font-header">적립금 회수하시겠습니까?</p>
        <div className="d-flex justify-content-flex-end">
          <div>
            <CustomButton onClick={handleSubmit}>확인</CustomButton>
          </div>
          <div className="ml-10">
            <CustomButton onClick={() => setOpenRetrieve(false)}>
              닫기
            </CustomButton>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
}

interface Transaction {
  created_at: string;
  type: "send" | "retrieve";
  point: number;
  reason: string;
}

interface UserPointTransactions {
  total: number;
  transactions: Transaction[];
}

function usePointUserTransactions(user: User) {
  const [userPointTransactions, setUserPointTransactions] =
    useState<UserPointTransactions | null>(null);

  useEffect(() => {
    fetchDefaultWithCredential(`/point/user/${user.id!}/transactions`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setUserPointTransactions);
  }, [user]);

  return userPointTransactions;
}

export function typeWording(type: string) {
  if (type === "send") {
    return "수동적립";
  } else if (type === "earned") {
    return "자동적립";
  } else if (type === "retrieve") {
    return "회수";
  } else if (type === "used") {
    return "사용";
  }
  return "";
}

function UserPointShowingComponent({ user }: UserProps) {
  const userPointTransactions = usePointUserTransactions(user);

  if (!userPointTransactions) return null;
  return (
    <div className="point-container">
      <div
        className="total-point font-bold font-sub p-20 d-flex justify-content-space-between"
        style={{ backgroundColor: "lightgray" }}
      >
        <div>적립금</div>
        <div>{userPointTransactions.total}원</div>
      </div>
      <div className="point-transaction p-20" style={{ height: "75vh" }}>
        <p className="font-sub font-bold">적립금 내역</p>
        <div className="transaction">
          <List>
            {userPointTransactions.transactions.map((transaction, index) => (
              <Fragment key={index}>
                <ListItem className="user-select-none" disableGutters>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs className="font-bold font-sub2">
                      {transaction.reason}
                    </Grid>
                    <Grid item style={{ textAlign: "end" }} className="ml-10">
                      <ListItemText
                        primary={
                          <div className="font-bold font-sub2">
                            {transaction.point}원{" "}
                            {typeWording(transaction.type)}
                          </div>
                        }
                        secondary={formatDateTemplate(transaction.created_at)}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider variant="fullWidth" component="li" />
              </Fragment>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
}

function AdminPagePoints() {
  const pointTypes = usePointTypes();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [render, setRender] = useState<number>(0);

  return (
    <div className="points-container">
      <Grid container columnSpacing={2}>
        <Grid item sm={3} xs={12}>
          <p className="font-sub font-bold">프로그램 후기시</p>
          <div className="mt-30">
            <PointTypeComponent
              pointTypes={pointTypes}
              type="program_review"
              label="프로그램 후기 작성시 적립금"
            />
          </div>
        </Grid>
        <Grid item sm={3} xs={12}>
          <div className="mt-30">
            <UserList
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          </div>
        </Grid>
        <Grid item sm={3} xs={12}>
          {currentUser && (
            <div className="mt-30">
              <UserPointSendingComponent
                user={currentUser}
                render={render}
                setRender={setRender}
              />
            </div>
          )}
        </Grid>
        <Grid item sm={3} xs={12}>
          {currentUser && (
            <div className="mt-30">
              <UserPointShowingComponent
                key={render}
                user={currentUser}
                render={render}
                setRender={setRender}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminPagePoints;
