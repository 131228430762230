import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Hidden } from "@mui/material";
import { CopyRight } from "../../components/layouts/Footer";
import CustomDialog from "../../components/customs/CustomDialog";
import CustomButton from "../../components/customs/CustomButton";
import { fetchDefaultWithCredential, formatDateJoined } from "../../utils";
import {
  HomeContainer,
  HomeDialog,
  textColor,
  TutorInterviewInfo,
  widthPercentage,
} from "../../components/Home";
import queryString from "query-string";
import ErrorIcon from "@mui/icons-material/Error";

export default function TutorDialogComponent() {
  const history = useHistory();
  const location = useLocation();
  const [tutorInterviewInfo, setTutorInterviewInfo] =
    useState<TutorInterviewInfo | null>(null);
  const [unprepared, setUnprepared] = useState<boolean>(false);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    // interview popup
    if (!("tutor_interview_id" in parsed)) {
      setTutorInterviewInfo(null);
    } else {
      // check location search
      const tutorInterviewId =
        parseInt(parsed["tutor_interview_id"] as string) || 0;
      fetchDefaultWithCredential(
        `/tutor/${tutorInterviewId}/interview`,
        "GET"
      ).then((res) => {
        if (!res.ok) {
          if (res.status === 400) {
            // update router
            delete parsed.tutor_interview_id;
            location.search = queryString.stringify(parsed);
            history.replace({ search: location.search });
            setUnprepared(false);
            return;
          }
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then((tutorInterviewInfo) => {
          if (!tutorInterviewInfo) {
            setUnprepared(true);
            return;
          }
          setTutorInterviewInfo(tutorInterviewInfo);
        });
      });
    }
  }, [history, location]);

  const handleClose = () => {
    const parsed = queryString.parse(location.search);
    delete parsed.tutor_interview_id;
    location.search = queryString.stringify(parsed);
    history.replace({ search: location.search });
    setUnprepared(false);
  };

  return (
    <Fragment>
      <HomeDialog open={Boolean(tutorInterviewInfo)} handleClose={handleClose}>
        {tutorInterviewInfo &&
          (() => {
            const {
              title,
              interview_date,
              tutor_name,
              hashtags,
              text,
              interviews,
              name,
              image,
              image1,
              image2,
            } = tutorInterviewInfo;
            return (
              <Fragment>
                <Hidden smDown>
                  <div
                    className="p-relative font-noto-light"
                    style={{
                      width: widthPercentage(68.125),
                      userSelect: "none",
                      WebkitUserSelect: "none",
                    }}
                  >
                    <div
                      className="p-relative"
                      style={{
                        height: widthPercentage(28.125),
                        backgroundImage: `url(${image2})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div
                        className="p-absolute"
                        style={{
                          left: widthPercentage(2.5),
                          bottom: widthPercentage(3.75),
                        }}
                      >
                        <div
                          className="text-white whitespace-preline"
                          style={{
                            fontSize: widthPercentage(2),
                          }}
                        >
                          {title}
                        </div>
                        <div
                          style={{
                            marginTop: widthPercentage(0.25),
                            color: "#DEDEDE",
                            fontSize: widthPercentage(0.9375),
                          }}
                        >
                          {formatDateJoined(new Date(interview_date), ".")}
                        </div>
                      </div>
                    </div>
                    <div
                      className="p-relative"
                      style={{
                        padding: widthPercentage(2.5),
                      }}
                    >
                      <div
                        className="p-absolute"
                        style={{
                          width: widthPercentage(6.25),
                          height: widthPercentage(6.25),
                          border: `${widthPercentage(0.0625)}px solid #F1F1FA`,
                          borderRadius: widthPercentage(1.25),
                          top: -widthPercentage(1.5),
                          backgroundImage: `url(${image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          marginTop: widthPercentage(3.75),
                          fontSize: widthPercentage(1.125),
                          color: textColor,
                        }}
                      >
                        튜터 {tutor_name}
                      </div>
                      <div
                        style={{
                          marginTop: widthPercentage(0.75),
                        }}
                      >
                        {hashtags.map((hashtag, index) => (
                          <div
                            className="font-noto-regular"
                            style={{
                              display: "inline",
                              border: `${widthPercentage(
                                0.0625
                              )}px solid rgba(74, 20, 140, 0.4)`,
                              borderRadius: widthPercentage(3.0625),
                              padding: `${widthPercentage(
                                0.3125
                              )}px ${widthPercentage(1.25)}px`,
                              marginRight: widthPercentage(0.5),
                              fontSize: widthPercentage(0.9375),
                              color: "#4A148C",
                            }}
                            key={index}
                          >
                            #{hashtag}
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          marginTop: widthPercentage(2.5),
                          height: widthPercentage(0.0625),
                          backgroundColor: "#E5E5EC",
                        }}
                      ></div>
                      <div
                        style={{
                          marginTop: widthPercentage(3.75),
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <div
                              style={{
                                width: widthPercentage(30.5625),
                                height: widthPercentage(45.5),
                                backgroundImage: `url(${image1})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              marginLeft: widthPercentage(2),
                              marginTop: widthPercentage(10),
                            }}
                          >
                            <div
                              className="font-bebas-regular"
                              style={{
                                fontSize: widthPercentage(6.875),
                                color: "#505050",
                                lineHeight: `${widthPercentage(5.875)}px`,
                              }}
                            >
                              PhiloUs Tutor Interview
                            </div>
                            <div
                              style={{
                                marginTop: widthPercentage(2.5),
                                height: widthPercentage(0.25),
                                backgroundColor: "#505050",
                                width: widthPercentage(2.5),
                              }}
                            ></div>
                            <div
                              style={{
                                marginTop: widthPercentage(1.5),
                                color: "#767676",
                                fontSize: widthPercentage(1),
                                lineHeight: `${widthPercentage(1.4375)}px`,
                              }}
                            >
                              “{text}”
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: widthPercentage(2.5),
                        }}
                      >
                        {(() => {
                          const res = [];
                          let row = [];
                          for (let i = 1; i <= interviews.length; i++) {
                            row.push(
                              <div
                                style={{
                                  width: widthPercentage(30.5625),
                                }}
                                key={i}
                              >
                                <div className="d-flex">
                                  <div
                                    className="font-roboto-regular"
                                    style={{
                                      fontSize: widthPercentage(1.125),
                                      color: textColor,
                                      marginRight: widthPercentage(0.75),
                                    }}
                                  >
                                    Q{i}
                                  </div>
                                  <div>
                                    <div
                                      className="font-noto-regular"
                                      style={{
                                        color: "#505050",
                                        fontSize: widthPercentage(1.125),
                                      }}
                                    >
                                      {interviews[i - 1].question}
                                    </div>

                                    <div
                                      className="d-flex justify-content-flex-end"
                                      style={{
                                        marginTop: widthPercentage(1.25),
                                      }}
                                    >
                                      <div
                                        className="p-relative"
                                        style={{
                                          alignSelf: "stretch",
                                          backgroundColor: "#D9D9D9",
                                          left: -widthPercentage(1.25),
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: widthPercentage(0.0625),
                                            height: "100%",
                                          }}
                                        ></div>
                                      </div>
                                      <div
                                        style={{
                                          color: "#767676",
                                          fontSize: widthPercentage(1.125),
                                        }}
                                      >
                                        {interviews[i - 1].answer}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                            if (i % 2 === 0) {
                              res.push(
                                <div
                                  className="d-flex justify-content-space-between"
                                  style={{
                                    marginBottom:
                                      i !== interviews.length
                                        ? widthPercentage(2)
                                        : 0,
                                  }}
                                  key={i}
                                >
                                  {row}
                                </div>
                              );
                              row = [];
                            }
                          }
                          if (row.length > 0) {
                            res.push(
                              <div
                                className="d-flex justify-content-space-between"
                                key={interviews.length}
                              >
                                {row}
                              </div>
                            );
                          }
                          return res;
                        })()}
                      </div>
                    </div>
                    <div
                      style={{
                        height: widthPercentage(9.375),
                        backgroundColor: "#E6E6E6",
                        padding: widthPercentage(2.5),
                        color: "#999999",
                        fontSize: widthPercentage(0.9375),
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <div>
                        <div>
                          인터뷰 진행자: {tutor_name} {`(${name})`}
                        </div>
                        <div style={{ marginTop: widthPercentage(0.125) }}>
                          {CopyRight()}
                        </div>
                      </div>
                    </div>
                  </div>
                </Hidden>
                <Hidden smUp>
                  <div
                    className="d-flex align-items-flex-end"
                    style={{
                      height: widthPercentage(100),
                      backgroundImage: `url(${image2})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <HomeContainer>
                      <div
                        style={{
                          paddingBottom: widthPercentage(12),
                        }}
                      >
                        <div
                          className="text-white whitespace-preline"
                          style={{
                            fontSize: widthPercentage(5),
                          }}
                        >
                          {title}
                        </div>
                        <div
                          style={{
                            marginTop: widthPercentage(0.25),
                            color: "#DEDEDE",
                            fontSize: widthPercentage(3.75),
                          }}
                        >
                          {formatDateJoined(new Date(interview_date), ".")}
                        </div>
                      </div>
                    </HomeContainer>
                  </div>
                  <HomeContainer>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: -widthPercentage(5.5),
                      }}
                    >
                      <div
                        style={{
                          width: widthPercentage(25),
                          height: widthPercentage(25),
                          border: `${widthPercentage(0.25)}px solid #F1F1FA`,
                          borderRadius: widthPercentage(5),
                          backgroundImage: `url(${image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          display: "inline-block",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        marginTop: widthPercentage(4.5),
                        fontSize: widthPercentage(4.5),
                        color: textColor,
                        textAlign: "center",
                      }}
                    >
                      튜터 {tutor_name}
                    </div>
                    <div
                      style={{
                        marginTop: widthPercentage(2.5),
                        textAlign: "center",
                      }}
                    >
                      {hashtags.map((hashtag, index) => (
                        <div
                          className="font-noto-regular"
                          style={{
                            display: "inline",
                            marginRight: widthPercentage(2.5),
                            fontSize: widthPercentage(4),
                            color: "#4A148C",
                            marginBottom: widthPercentage(1),
                          }}
                          key={index}
                        >
                          #{hashtag}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        marginTop: widthPercentage(10.5),
                        height: widthPercentage(0.25),
                        backgroundColor: "#E5E5EC",
                      }}
                    ></div>
                    <div
                      className="font-bebas-regular"
                      style={{
                        marginTop: widthPercentage(11),
                        fontSize: widthPercentage(20),
                        color: "#505050",
                        lineHeight: `${widthPercentage(17.5)}px`,
                        textAlign: "start",
                      }}
                    >
                      PhiloUs
                      <br />
                      Tutor
                      <br />
                      Interview
                    </div>
                    <div
                      style={{
                        marginTop: widthPercentage(9),
                        height: widthPercentage(1),
                        backgroundColor: "#505050",
                        width: widthPercentage(10),
                      }}
                    ></div>
                    <div
                      style={{
                        marginTop: widthPercentage(5.5),
                        color: "#767676",
                        fontSize: widthPercentage(4),
                        lineHeight: `${widthPercentage(5.75)}px`,
                      }}
                    >
                      “{text}”
                    </div>
                  </HomeContainer>
                  <div
                    style={{
                      marginTop: widthPercentage(9),
                      height: widthPercentage(149),
                      backgroundImage: `url(${image1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <HomeContainer>
                    {interviews.map((interview, index) => (
                      <Fragment key={index}>
                        <div
                          style={{
                            marginTop: widthPercentage(11),
                            display: "flex",
                          }}
                        >
                          <div
                            className="font-roboto-regular"
                            style={{
                              paddingTop: widthPercentage(0.5),
                              fontSize: widthPercentage(4.5),
                              color: textColor,
                              marginRight: widthPercentage(2.5),
                            }}
                          >
                            Q{index + 1}
                          </div>
                          <div
                            className="font-noto-regular"
                            style={{
                              color: "#505050",
                              fontSize: widthPercentage(4),
                            }}
                          >
                            {interview.question}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              marginTop: widthPercentage(5.5),
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: widthPercentage(2.5),
                                alignSelf: "stretch",
                                backgroundColor: "#D9D9D9",
                                marginRight: widthPercentage(5.5),
                              }}
                            >
                              <div
                                style={{
                                  width: widthPercentage(0.25),
                                  height: "100%",
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                flexGrow: 1,
                                color: "#767676",
                                fontSize: widthPercentage(4),
                              }}
                            >
                              {interview.answer}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </HomeContainer>
                  <div
                    style={{
                      marginTop: widthPercentage(22),
                      paddingTop: widthPercentage(15),
                      paddingBottom: widthPercentage(6.5),
                      backgroundColor: "#E6E6E6",
                      fontSize: widthPercentage(3.5),
                      color: "#999999",
                    }}
                  >
                    <HomeContainer>
                      <div>
                        인터뷰 진행자: {tutor_name} {`(${name})`}
                      </div>
                      <div style={{ marginTop: widthPercentage(1) }}>
                        {CopyRight()}
                      </div>
                    </HomeContainer>
                  </div>
                </Hidden>
              </Fragment>
            );
          })()}
      </HomeDialog>
      <CustomDialog open={unprepared}>
        <div className="p-30 text-align-center">
          <div className="d-flex justify-content-center">
            <div className="height-100">
              <ErrorIcon
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
          <p className="font-header">아직 인터뷰가 완료되지 않았습니다.</p>
          <p className="font-sub text-secondary">
            튜터 인터뷰는 매달 한분씩 업데이트 되고 있습니다. 곧 관심있는
            튜터분의 인터뷰도 업데이트될 예정이니 많이 기대해주세요!
          </p>
          <div className="text-align-center">
            <CustomButton outlined onClick={handleClose}>
              닫기
            </CustomButton>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}
