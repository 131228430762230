import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { fetchDefaultWithCredential } from "../../utils";
import { HomeDialog, HomeReview } from "../../components/Home";
import { Content } from "../../components/programs/ProgramReviewCard";
import queryString from "query-string";
import { patchVote } from "../../components/admins/pages/users/Review";

interface ProgramReviewDialog extends HomeReview {
  user_id: number;
  user_image: string;
  review_image: string;
}

function ReviewDialogComponent() {
  const history = useHistory();
  const location = useLocation();
  const [programReview, setProgramReview] =
    useState<ProgramReviewDialog | null>(null);
  const [index, setIndex] = useState<number>(-1);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (!("review_id" in parsed)) {
      setProgramReview(null);
    } else {
      // check location search
      const reviewId = parseInt(parsed["review_id"] as string) || 0;
      const index = parseInt(parsed["index"] as string) || -1;
      setIndex(index);
      fetchDefaultWithCredential(`/program/review/${reviewId}`, "GET").then(
        (res) => {
          if (!res.ok) {
            return res.json().then(({ error }) => {
              throw new Error(error);
            });
          }
          return res.json().then((programReview) => {
            if (!programReview) {
              // update router
              delete parsed.review_id;
              delete parsed.index;
              location.search = queryString.stringify(parsed);
              history.replace({ search: location.search });
              return;
            }
            setProgramReview(programReview);
          });
        }
      );
    }
  }, [location, history]);

  const handleClose = () => {
    const parsed = queryString.parse(location.search);
    delete parsed.review_id;
    delete parsed.index;
    location.search = queryString.stringify(parsed);
    history.replace({ search: location.search });
  };

  return (
    <HomeDialog open={Boolean(programReview)} handleClose={handleClose}>
      {programReview && (
        <Content
          {...{
            reviewId: programReview.program_review_id,
            programImage: programReview.image,
            programHeader: programReview.program_header,
            programName: programReview.program_name,
            reviewDate: programReview.program_review_date,
            tutorNames: programReview.tutor_names,
            reviewedUserName: programReview.program_reviewed_user_name,
            userId: programReview.user_id,
            userImage: programReview.user_image,
            review: programReview.program_review_detail,
            votes: programReview.votes,
            voted: programReview.voted,
            tutorIds: programReview.tutor_ids,
            tutorStatuses: programReview.tutor_statuses,
            handleVote: () => patchVote(programReview.program_review_id),
            reviewImage: programReview.review_image,
          }}
          index={index}
        />
      )}
    </HomeDialog>
  );
}

export default ReviewDialogComponent;
