import { Checkbox, Collapse, Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CustomButton from "./customs/CustomButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  formatProgramDate,
  formatProgramTime,
} from "../utils";
import { useHistory } from "react-router";
import "./cart.scss";
import { Coupon } from "./admins/pages/Coupons";
import CustomCheckbox from "./customs/CustomCheckbox";
import {
  defaultCouponNamingLabel,
  packageCouponNamingLabel,
  selectCouponNamingLabel,
} from "./Order";
import { responsivePercentage, responsivePixel } from "./Home";

interface CartProgram {
  id: number;
  program_image: string;
  program_header: string;
  program_name: string;
  dates: string[];
  starting_hour: number;
  starting_minute: number;
  minutes: number;
  price: number;
  program_description: string;
}

export interface RegisteredProgram extends CartProgram {
  pdf_file?: string;
  program_registered_at?: string;
  pdf_downloaded: boolean;
}

interface CartProps {
  programs: CartProgram[];
  coupons: Coupon[];
  point: number;
}

type ProgramCardProps =
  | {
      program: CartProgram;
      refresh: () => void;
    }
  | {
      program: RegisteredProgram;
      programBought: boolean;
      refresh: () => void;
    };

export function patchPdfDownloaded(programId: number) {
  return fetchDefaultWithCredential(
    `/program/${programId}/pdf/downloaded`,
    "PATCH"
  ).then((res) => {
    if (!res.ok) {
      return res.json().then(({ error }) => {
        throw new Error(error);
      });
    }
    return res.json();
  });
}

export function CartProgramCard(props: ProgramCardProps) {
  const [toggleDetail, setToggleDetail] = useState<boolean>(false);
  const history = useHistory();
  const handleToggle = () => {
    setToggleDetail(!toggleDetail);
  };
  const { program } = props;

  const isRegistered = "programBought" in props;

  const patchConfirmDownload = () => {
    return patchPdfDownloaded(props.program.id).then(props.refresh);
  };

  return (
    <Fragment>
      {"programBought" in props && props.program.program_registered_at && (
        <div className="d-flex justify-content-flex-end mb-10">
          <div
            className="text-secondary"
            style={{
              fontSize: responsivePixel(14),
            }}
          >
            주문일자 {formatDateTemplate(props.program.program_registered_at)}
          </div>
        </div>
      )}
      <Grid container>
        <Grid item xs>
          <div className="d-flex">
            <img
              src={program.program_image}
              alt=""
              draggable={false}
              className="width-100-percentage cursor-pointer"
              onClick={() => history.push("/program/" + program.id)}
            />
          </div>
        </Grid>
        <Grid
          item
          xs
          style={{
            backgroundColor: "#e6e6e6",
          }}
        >
          <div className="p-15 height-100-percentage">
            <div className="p-relative height-100-percentage">
              <div className="d-flex justify-content-space-between align-items-center">
                <div>
                  <div
                    className="font-bold"
                    style={{
                      fontSize: responsivePixel(16),
                    }}
                  >
                    {program.program_header}
                  </div>
                </div>
              </div>
              <div
                className="mt-5 font-bold"
                style={{
                  fontSize: responsivePixel(14),
                }}
              >
                {program.program_name}
              </div>
              <div
                className="mt-5"
                style={{
                  fontSize: responsivePixel(10),
                }}
              >
                {formatProgramDate(program.dates)}
              </div>
              <div
                className="mt-5"
                style={{
                  fontSize: responsivePixel(10),
                }}
              >
                {formatProgramTime(
                  program.starting_hour,
                  program.starting_minute,
                  program.minutes
                )}
              </div>
              {program.price !== 0 && (
                <div
                  className="mt-5"
                  style={{
                    fontSize: responsivePixel(10),
                  }}
                >
                  {program.price.toLocaleString("en-US")}원
                </div>
              )}
              <Collapse in={toggleDetail}>
                <div
                  className="mt-5"
                  style={{
                    fontSize: responsivePixel(8),
                  }}
                >
                  {program.program_description}
                </div>
              </Collapse>
              <Grid
                container
                justifyContent="flex-end"
                style={{ position: "absolute", right: 0, bottom: 0 }}
              >
                <Grid item className="mr-5">
                  <CustomButton
                    className="height-30 p-5"
                    disableMinwidth
                    onClick={
                      !isRegistered
                        ? handleToggle
                        : () => history.push("/program/" + program.id)
                    }
                    style={{
                      backgroundColor: "#B5B5B5",
                      fontSize: responsivePixel(8),
                    }}
                  >
                    {isRegistered ? (
                      "상세 설명 보러가기"
                    ) : toggleDetail ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )}
                  </CustomButton>
                </Grid>
                <Grid item>
                  {"programBought" in props ? (
                    props.program.pdf_file && (
                      <CustomButton
                        disableMinwidth
                        onClick={() => {
                          window.open(props.program.pdf_file, "_blank");
                          patchConfirmDownload();
                        }}
                        className={[
                          "height-30",
                          "p-5",
                          !props.program.pdf_downloaded
                            ? "background-blue"
                            : null,
                        ].join(" ")}
                        style={{
                          fontSize: responsivePixel(8),
                        }}
                      >
                        키트 PDF 다운로드
                      </CustomButton>
                    )
                  ) : (
                    <CustomButton
                      disableMinwidth
                      onClick={() =>
                        history.push({
                          pathname: "/order",
                          state: [program.id],
                        })
                      }
                      className={["height-30", "p-5"].join(" ")}
                      style={{
                        fontSize: responsivePixel(8),
                        backgroundColor: "#6939CD",
                      }}
                    >
                      바로 신청하기
                    </CustomButton>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export function couponLabeling(coupon: Coupon) {
  return coupon.type === "default"
    ? defaultCouponNamingLabel(
        coupon.title,
        coupon.value,
        coupon.at_least_to_apply,
        coupon.expires_at,
        coupon.unit
      )
    : coupon.type === "package"
    ? packageCouponNamingLabel(coupon.title, coupon.value)
    : coupon.type === "select"
    ? selectCouponNamingLabel(coupon.title, coupon.value)
    : "";
}

interface CartComponentProps {
  cart: CartProps;
  refresh: () => void;
}

function CartComponent({ cart, refresh }: CartComponentProps) {
  const [checkedProgramIds, setCheckedProgramIds] = useState<number[]>([]);
  const history = useHistory();

  const handleDelete = (checkedProgramIds: number[]) => {
    fetchDefaultWithCredential("/cart", "DELETE", {
      program_ids: checkedProgramIds,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res
        .json()
        .then(refresh)
        .then(() => setCheckedProgramIds([]));
    });
  };

  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <p className="font-header">나의 장바구니</p>
        <p className="font-sub text-secondary">
          당신의 성장을 이루게 해줄 프로그램이 담겨있어요!
        </p>
      </div>
      <div className="stepper-item-container">
        <Grid
          container
          justifyContent="space-between"
          className="background-gray p-20"
          alignItems="center"
        >
          <div
            className="font-bold"
            style={{
              fontSize: responsivePixel(15),
            }}
          >
            장바구니에 담긴 프로그램
          </div>
          <div
            className="font-bold"
            style={{
              fontSize: responsivePixel(15),
            }}
          >
            {cart.programs.length}개
          </div>
        </Grid>
      </div>
      <div className="mt-30">
        {cart.programs.length > 0 ? (
          <Fragment>
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CustomCheckbox
                handleChange={(checked) => {
                  if (checked) {
                    setCheckedProgramIds(
                      cart.programs.map((program) => program.id)
                    );
                  } else {
                    setCheckedProgramIds([]);
                  }
                }}
                border={false}
              >
                전체선택
              </CustomCheckbox>
              <div>
                <CustomButton
                  onClick={() => handleDelete(checkedProgramIds)}
                  style={{
                    fontSize: responsivePixel(10),
                  }}
                  disableMinwidth
                >
                  선택삭제
                </CustomButton>
              </div>
            </div>
            <div className="mt-30">
              {cart.programs.map((program, index) => (
                <div className="mb-30" key={index}>
                  <div className="d-flex">
                    <div>
                      <Checkbox
                        color="primary"
                        onClick={(e) => {
                          const checked = (e.target as HTMLInputElement)
                            .checked;
                          // if checked, then we include, if not, then we filter
                          if (checked) {
                            setCheckedProgramIds([
                              ...checkedProgramIds,
                              program.id,
                            ]);
                          } else {
                            setCheckedProgramIds(
                              checkedProgramIds.filter(
                                (checkedProgramId) =>
                                  checkedProgramId !== program.id
                              )
                            );
                          }
                        }}
                        checked={checkedProgramIds.includes(program.id)}
                      />
                    </div>
                    <div>
                      <CartProgramCard program={program} refresh={refresh} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-align-center mb-30">
              <CustomButton
                className="height-50 width-300"
                style={{ backgroundColor: "#6939CD" }}
                onClick={() =>
                  history.push({
                    pathname: "/order",
                    state: checkedProgramIds,
                  })
                }
                disabled={checkedProgramIds.length === 0}
              >
                신청하기
              </CustomButton>
            </div>
          </Fragment>
        ) : (
          <div className="text-align-center">
            <img
              className="cart-image"
              src="assets/cart-image.svg"
              alt=""
              draggable={false}
            />
            <p className="font-header">현재 장바구니가 비어있습니다</p>
          </div>
        )}
      </div>
    </Fragment>
  );
}

function Cart() {
  const [cart, setCart] = useState<CartProps | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential("/cart", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setCart);
    });
  }, [render]);

  if (!cart) return null;

  return (
    <div
      className="margin-center"
      style={{
        width: responsivePercentage(30),
      }}
    >
      <CartComponent cart={cart} refresh={() => setRender(render + 1)} />
    </div>
  );
}

export default Cart;
