import { useCallback, useEffect, useState } from "react";
import { SERVER } from "../config";
import { User, UserRole } from "../globals/user";
import { Tutor } from "../interfaces";
import { fetchDefaultWithCredential, fetchWithCredential } from "../utils";

export function useUsers(role?: UserRole) {
  const [users, setUsers] = useState<User[]>([]);

  const refreshUsers = useCallback(() => {
    fetchWithCredential(
      `${SERVER}/user/list${role ? `?role=${role}` : ""}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setUsers);
    });
  }, [role]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  return { users };
}

export function useTutors() {
  const [tutors, setTutors] = useState<Tutor[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const refreshTutors = useCallback(() => {
    fetchWithCredential(`${SERVER}/user/tutor/list`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res
        .json()
        .then(setTutors)
        .then(() => setLoaded(true));
    });
  }, []);

  const patchTutorStatus = (tutorId: number) => {
    return fetchDefaultWithCredential(`/tutor/${tutorId}/status`, "PATCH")
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((data) => setTutors(putObjectFromList(tutors, data)));
  };

  const deleteTutor = (tutorId: number) => {
    return fetchDefaultWithCredential(`/tutor/${tutorId}`, "DELETE")
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((data) => setTutors(removeObjectFromList(tutors, data.id)));
  };

  useEffect(() => {
    refreshTutors();
  }, [refreshTutors]);

  return { tutors, refreshTutors, patchTutorStatus, deleteTutor, loaded };
}

export function deleteTutor(tutor_id: number) {
  return fetchWithCredential(`${SERVER}/user/tutor/${tutor_id}`, "DELETE").then(
    (res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json();
    }
  );
}

export function postTutor(user_id: number) {
  return fetchWithCredential(`${SERVER}/user/${user_id}/tutor`, "POST").then(
    (res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json();
    }
  );
}

export function putObjectFromList(list: any[], data: any) {
  Object.assign(
    list.find((listObject) => listObject.id === data.id!),
    data
  );
  return [...list];
}

export function removeObjectFromList(list: any[], data_id: number) {
  return list.filter((listObject) => listObject.id !== data_id);
}

export function useGeneric(genericType: string) {
  const [generic, setGeneric] = useState<any>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(`/generic/${genericType}`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setGeneric);
    });
  }, [genericType, render]);

  const putGeneric = () => {
    return fetchDefaultWithCredential(
      `/generic/${genericType}`,
      "PUT",
      generic
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json();
    });
  };
  return {
    generic,
    putGeneric,
    refresh: () => setRender((render) => render + 1),
  };
}
