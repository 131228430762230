import { useContext } from "react";
import { fetchDefaultWithCredential } from "../utils";
import { GlobalContext } from "./components/ComponentsWrapper";

export function useNotifications() {
  const { notifications } = useContext(GlobalContext);
  return notifications;
}

export function getNotification() {
  return fetchDefaultWithCredential(`/notifications`, "GET").then((res) => {
    if (!res.ok) {
      return res.json().then(({ error }) => {
        throw new Error(error);
      });
    }
    return res.json();
  });
}

export interface Notification {
  [dataName: string]: any;
}
