import React, { Fragment, ReactNode, useRef } from "react";
import { postImageFile, useImage } from "../../hooks/useImages";
import CustomButton from "./CustomButton";

interface PropType {
  children: ReactNode;
  name?: string;
  fileChangeCallback?: (file: File) => void;
}

interface CustomUploadImageProps {
  children: ReactNode;
  handleImage: (image_id: number) => void;
}

function CustomUploadImage({ children, handleImage }: CustomUploadImageProps) {
  return (
    <CustomImageUploadButton
      fileChangeCallback={(file) =>
        postImageFile(file).then((image) => handleImage(image.id))
      }
    >
      {children}
    </CustomImageUploadButton>
  );
}

interface ImageUploadComponentProps {
  handleImage: (image_id: number) => void;
  imageId: number;
}

export function ImageUploadComponent({
  handleImage,
  imageId,
}: ImageUploadComponentProps) {
  const image = useImage(imageId);
  return (
    <div>
      <div className="d-flex">
        <div>
          <CustomUploadImage handleImage={handleImage}>
            이미지 선택
          </CustomUploadImage>
        </div>
        <div className="ml-10">
          <CustomButton onClick={() => handleImage(0)}>삭제</CustomButton>
        </div>
      </div>
      {image && (
        <div className="mt-30">
          <div className="width-300">
            <img
              src={image.location}
              alt=""
              className="width-100-percentage"
              draggable={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function CustomImageUploadButton({
  children,
  name,
  fileChangeCallback,
}: PropType) {
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    if (fileChangeCallback) {
      fileChangeCallback(files[0]);
    }
  };

  return (
    <Fragment>
      <CustomButton
        onClick={() => uploadRef.current && uploadRef.current.click()}
      >
        {children}
      </CustomButton>
      <input
        name={name}
        ref={uploadRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Fragment>
  );
}

export default CustomImageUploadButton;
