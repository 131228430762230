import React, { Fragment, useEffect, useState } from "react";
import { fetchDefaultWithCredential, isMobile } from "../../utils";
import {
  ProgramReviewWriteBody,
  ProgramReviewWriteBodyProgram,
  ProgramReviewWriteHeader,
} from "../Community";
import CustomPagination from "../customs/CustomPagination";
import { CustomStandardSelect } from "../customs/CustomSelect";
import RadioForm from "../customs/RadioForm";
import {
  HomeButton,
  HomeReview,
  ProgramCard,
  textColor,
  widthPercentage,
} from "../Home";
import InfoBar from "./InfoBar";

interface MypageProgramReviewInfo {
  review_point: number;
  non_reviewed_programs: ProgramReviewWriteBodyProgram[];
  total_taken_programs: number;
  program_reviews: HomeReview[];
  page_count: number;
  current_page: number;
}

export type SearchType = "all" | "posted_review" | "non_posted_review";
export type OrderByType = "like" | "recent" | "old";

function Review() {
  const [mypageProgramReviewInfo, setMypageProgramReviewInfo] =
    useState<MypageProgramReviewInfo | null>(null);
  const [searchType, setSearchType] = useState<SearchType>("all");
  const [orderBy, setOrderBy] = useState<OrderByType>("like");
  const [page, setPage] = useState<number>(1);
  const [render, setRender] = useState<number>(0);
  const [programId, setProgramId] = useState<number>(0);
  const [reviewWriteRef, setReviewWriteRef] = useState<HTMLDivElement | null>(
    null
  );
  const [reviewWriteMiddle, setReviewWriteMiddle] = useState<number>(0);
  const amount = 8;

  useEffect(() => {
    fetchDefaultWithCredential(
      `/mypage/program/reviews?search=${searchType}&order_by=${orderBy}&page=${page}&amount=${amount}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(setMypageProgramReviewInfo);
    });
  }, [searchType, orderBy, page, render]);

  useEffect(() => {
    if (reviewWriteRef) {
      const clientRect = reviewWriteRef.getBoundingClientRect();
      const middle = (clientRect.top + clientRect.bottom) / 2;
      setReviewWriteMiddle(middle);
    }
  }, [reviewWriteRef]);

  if (!mypageProgramReviewInfo) return null;
  return (
    <Fragment>
      <InfoBar
        header="나의 후기"
        description={`책과 세미나에 대한 나만의 이야기를 기록해보세요! 후기를 쓰시고 ${mypageProgramReviewInfo.review_point.toLocaleString()}원 적립금도 받아가세요.`}
        percentage={
          Math.round(
            ((mypageProgramReviewInfo.total_taken_programs -
              mypageProgramReviewInfo.non_reviewed_programs.length) *
              100) /
              mypageProgramReviewInfo.total_taken_programs
          ) || 0
        }
        count={{
          label: "기록한 후기 수",
          element: (
            <Fragment>
              <div
                className="font-roboto-medium d-flex"
                style={{
                  fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
                }}
              >
                {mypageProgramReviewInfo.total_taken_programs -
                  mypageProgramReviewInfo.non_reviewed_programs.length}
              </div>
              <div
                className="font-roboto-regular"
                style={{
                  marginLeft: widthPercentage(isMobile() ? 0.5 : 0.25),
                  fontSize: widthPercentage(isMobile() ? 3.75 : 1.125),
                }}
              >
                /{mypageProgramReviewInfo.total_taken_programs}
              </div>
            </Fragment>
          ),
        }}
      />
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 11.25 : 2.5),
          ...(isMobile()
            ? {}
            : {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }),
        }}
      >
        <div className="d-flex">
          <RadioForm
            name="type"
            label=""
            handleChange={(e) => {
              setSearchType(e.target.value as SearchType);
              setPage(1);
            }}
            radios={[
              {
                label: "전체",
                value: "all",
              },
              {
                label: "작성 후기",
                value: "posted_review",
              },
              {
                label: "미작성 후기",
                value: "non_posted_review",
              },
            ]}
            row
            value={searchType}
          />
        </div>
        <div
          style={{
            marginTop: widthPercentage(isMobile() ? 5.25 : 0),
          }}
        >
          <CustomStandardSelect<OrderByType>
            value={orderBy}
            items={[
              {
                label: "좋아요순",
                value: "like",
              },
              {
                label: "최신순",
                value: "recent",
              },
              {
                label: "오래된순",
                value: "old",
              },
            ]}
            handleChange={setOrderBy}
            formStyle={{
              width: isMobile() ? "100%" : widthPercentage(15.875),
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: widthPercentage(isMobile() ? 9 : 1.4375) }}>
        {(() => {
          const res = [];
          const width = widthPercentage(isMobile() ? 45 : 15.875);
          const showNumber = isMobile() ? 2 : 4;
          const refinedReviews = [
            ...mypageProgramReviewInfo.program_reviews,
          ] as (HomeReview | null)[];
          while (refinedReviews.length % showNumber !== 0) {
            refinedReviews.push(null);
          }
          for (let i = 0; i < refinedReviews.length; i += showNumber) {
            const sliced = refinedReviews.slice(i, i + showNumber);
            res.push(
              <div
                key={i}
                className="d-flex justify-content-space-between"
                style={{ marginBottom: widthPercentage(isMobile() ? 4 : 2.5) }}
              >
                {sliced.map((review, index) => (
                  <div key={index}>
                    {review && (
                      <ProgramCard
                        program={{
                          id: review.program_id,
                          image: review.image,
                          program_name: review.program_name,
                          program_description: review.program_description,
                        }}
                        index={0}
                        width={width}
                        review={review}
                        blur={review.program_review_id === 0}
                        blurText={review.program_name}
                        customDescriptionBox={
                          review.program_review_id === 0 ? (
                            <div
                              style={{
                                height: widthPercentage(
                                  isMobile() ? 29.25 : 5.78125
                                ),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <HomeButton
                                width={widthPercentage(
                                  isMobile() ? 32.75 : 9.5
                                )}
                                height={widthPercentage(isMobile() ? 9 : 2.75)}
                                handleClick={() => {
                                  setProgramId(review.program_id);
                                  window.scrollTo({
                                    left: 0,
                                    top: reviewWriteMiddle,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                후기쓰러 가기
                              </HomeButton>
                            </div>
                          ) : undefined
                        }
                        disablePointer={review.program_review_id === 0}
                        vote
                      />
                    )}
                    {!review && (
                      <div
                        style={{
                          width,
                        }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            );
          }
          return res;
        })()}
      </div>
      <div className="d-flex justify-content-center">
        <CustomPagination
          count={mypageProgramReviewInfo.page_count}
          page={page}
          handleChange={setPage}
        />
      </div>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 22 : 7.5),
          color: textColor,
          marginBottom: widthPercentage(isMobile() ? 33 : 11.25),
        }}
        ref={setReviewWriteRef}
      >
        <ProgramReviewWriteHeader />
        <div
          style={{
            marginTop: widthPercentage(isMobile() ? 10 : 2.5),
            width: "100%",
          }}
        >
          <ProgramReviewWriteBody
            programs={mypageProgramReviewInfo.non_reviewed_programs}
            refresh={() => {
              setRender(render + 1);
            }}
            selectWidth={widthPercentage(13.4375)}
            margin={widthPercentage(0.625)}
            setProgramId={setProgramId}
            programId={programId}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Review;
