import { Box, Grid, Hidden, Input } from "@mui/material";
import React, { useState, Fragment, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  formClass,
  isMobile,
} from "../../utils";
import CustomButton from "../customs/CustomButton";
import {
  getBackgroundShadowColors,
  HomeButton,
  HomeContainer,
  textColor,
  widthPercentage,
} from "../Home";
import "./ProgramReviewCard.scss";
import "../Home.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ReactComponent as BigQuote } from "../../svg/big_quote.svg";
import { ReactComponent as LikeIcon } from "../../svg/like_icon.svg";
import { ReactComponent as EditIcon } from "../../svg/edit.svg";
import { TutorStatus } from "../admins/pages/users/Review";
import queryString from "query-string";
import { GlobalContext } from "../../globals/components/ComponentsWrapper";
import { useUser } from "../../globals/user";
import { CustomAvatarWithLocation } from "../customs/CustomAvatar";
import { ReactComponent as CameraIcon } from "../../svg/camera.svg";
import CloseIcon from "@mui/icons-material/Close";
import { postImageFile } from "../../hooks/useImages";

interface ContentProps {
  programImage: string;
  programHeader: string;
  programName: string;
  reviewDate: string;
  tutorNames: string[];
  reviewedUserName: string;
  review: string;
  votes: number;
  handleVote: () => Promise<void>;
  voted: boolean;
  tutorIds: number[];
  tutorStatuses: TutorStatus[];
  index?: number;
  userId: number;
  userImage: string;
  reviewImage: string;
  reviewId: number;
}

export function Content({
  programImage,
  programHeader,
  programName,
  reviewDate,
  tutorNames,
  reviewedUserName,
  review,
  votes,
  handleVote,
  voted,
  tutorIds,
  tutorStatuses,
  index,
  userImage,
  reviewImage,
  userId,
  reviewId,
}: ContentProps) {
  const user = useUser();
  const { backgroundColor, shadowColor } = getBackgroundShadowColors(
    index ?? -1
  );
  const { toggleSigninDialog } = useContext(GlobalContext);
  const history = useHistory();
  const [edit, setEdit] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | null | undefined>(
    undefined
  );
  const [imageSrc, setImageSrc] = useState<string>("");
  const [uploadRef, setUploadRef] = useState<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [form, setForm] = useState<ContentProps>({
    programImage,
    programHeader,
    programName,
    reviewDate,
    tutorNames,
    reviewedUserName,
    review,
    votes,
    handleVote,
    voted,
    tutorIds,
    tutorStatuses,
    index,
    userImage,
    reviewImage,
    userId,
    reviewId,
  });

  useEffect(() => {
    if (imageFile) {
      setImageSrc(URL.createObjectURL(imageFile));
    } else if (imageFile === null) {
      setImageSrc("");
    } else {
      setImageSrc(reviewImage);
    }
  }, [imageFile, reviewImage]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    setImageFile(files[0]);
  };

  const handleUpdate = () => {
    setLoading(true);
    if (imageFile) {
      postImageFile(imageFile).then((image) =>
        fetchDefaultWithCredential(`/program/review/${reviewId}`, "PUT", {
          review: form.review,
          image_id: image.id,
        })
          .then((res) => {
            if (!res.ok) {
              return res.json().then(({ error }) => {
                throw new Error(error);
              });
            }
            return res.json();
          })
          .then(() => setLoading(false))
          .then(() => setEdit(false))
      );
    }
    fetchDefaultWithCredential(`/program/review/${reviewId}`, "PUT", {
      review: form.review,
      image_id: imageFile === null ? 0 : undefined,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then(() => setLoading(false))
      .then(() => setEdit(false));
  };

  return (
    <Fragment>
      <Hidden smDown>
        <div
          style={{
            width: widthPercentage(68.125),
            height: widthPercentage(50),
            color: textColor,
            textAlign: "center",
            userSelect: "none",
          }}
        >
          <div className="d-flex" style={{ height: "100%" }}>
            <div
              style={{
                width: widthPercentage(20),
                backgroundColor,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                padding: `0 ${widthPercentage(2)}px`,
              }}
            >
              <div>
                <div
                  style={{
                    marginTop: widthPercentage(5),
                    fontSize: widthPercentage(0.9375),
                  }}
                >
                  {programHeader}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(0.5),
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: widthPercentage(2),
                      height: widthPercentage(0.125),
                    }}
                  ></div>
                </div>
                <div
                  className="font-noto-medium"
                  style={{
                    marginTop: widthPercentage(0.5),
                    fontSize: widthPercentage(1.75),
                  }}
                >
                  {programName}
                </div>
              </div>
              <div
                style={{
                  marginTop: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: widthPercentage(9.375),
                      height: widthPercentage(0.0625),
                      opacity: 0.6,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(1.25),
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {tutorIds.map((tutorId, index) => (
                    <div
                      key={index}
                      style={{
                        padding: `0 ${widthPercentage(0.4375)}px`,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (tutorStatuses[index] !== "active") return;
                        history.push(`/tutor/${tutorId}`);
                      }}
                    >
                      <div
                        style={{
                          fontSize: widthPercentage(0.875),
                        }}
                      >
                        {tutorNames[index]}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: widthPercentage(1),
                          height: widthPercentage(1),
                        }}
                      >
                        <KeyboardArrowRightIcon
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(2),
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: widthPercentage(2.5),
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: widthPercentage(22.625),
                      backgroundImage: `url(${programImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="p-relative" style={{ height: "100%" }}>
                      <div
                        className="p-absolute"
                        style={{
                          left: "100%",
                          borderLeft: `${
                            isMobile()
                              ? widthPercentage(40)
                              : widthPercentage(11.3125)
                          }px solid ${shadowColor}`,
                          borderTop: `${
                            isMobile()
                              ? widthPercentage(40)
                              : widthPercentage(11.3125)
                          }px solid transparent`,
                          borderBottom: `${
                            isMobile()
                              ? widthPercentage(40)
                              : widthPercentage(11.3125)
                          }px solid ${shadowColor}`,
                        }}
                      ></div>
                      <div
                        className="p-absolute"
                        style={{
                          top: "100%",
                          borderLeft: `${
                            isMobile()
                              ? widthPercentage(40)
                              : widthPercentage(22.625)
                          }px solid transparent`,
                          borderTop: `${
                            isMobile()
                              ? widthPercentage(40)
                              : widthPercentage(22.625)
                          }px solid ${shadowColor}`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flexGrow: 1,
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: widthPercentage(2.5),
                  paddingBottom: 0,
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    fontSize: widthPercentage(0.75),
                    color: "#767676",
                  }}
                >
                  {formatDateTemplate(reviewDate)}
                </div>
                <div style={{ position: "relative" }}>
                  <div style={{ position: "absolute" }}>
                    {(!user || user.id !== userId) && (
                      <Fragment>
                        <div
                          style={{
                            marginTop: widthPercentage(0.125),
                            color: "#505050",
                            fontSize: widthPercentage(1),
                          }}
                        >
                          {reviewedUserName}님의 후기
                        </div>
                        <div
                          style={{
                            marginTop: widthPercentage(0.625),
                            width: widthPercentage(3),
                            height: widthPercentage(3),
                          }}
                        >
                          <CustomAvatarWithLocation location={userImage} />
                        </div>
                      </Fragment>
                    )}
                    {user && user.id === userId && (
                      <div style={{ position: "relative" }}>
                        <HomeButton
                          width={widthPercentage(5)}
                          height={widthPercentage(2)}
                          outlined
                          noColor
                          handleClick={() => {
                            if (!edit) {
                              setEdit(true);
                            } else {
                              handleUpdate();
                            }
                          }}
                          disabled={
                            loading || (edit && form.review.length < 20)
                          }
                        >
                          {!edit && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ display: "flex" }}>
                                <EditIcon
                                  style={{
                                    width: widthPercentage(0.75),
                                    height: widthPercentage(0.75),
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: widthPercentage(0.25),
                                  color: "#767676",
                                }}
                              >
                                수정
                              </div>
                            </div>
                          )}
                          {edit && (
                            <div
                              style={{
                                color: "#767676",
                              }}
                            >
                              작성 완료
                            </div>
                          )}
                        </HomeButton>
                        {edit && (
                          <Fragment>
                            <div style={{ position: "absolute" }}>
                              <Box
                                style={{
                                  marginTop: widthPercentage(0.5),
                                  display: "inline-flex",
                                  cursor: "pointer",
                                }}
                                sx={{
                                  "& path": {
                                    stroke: "#999999",
                                  },
                                }}
                                onClick={() => uploadRef && uploadRef.click()}
                              >
                                <CameraIcon width={widthPercentage(1.375)} />
                              </Box>
                            </div>
                            <input
                              ref={setUploadRef}
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: widthPercentage(6),
                  position: "relative",
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    width: widthPercentage(36.5625),
                    right: 0,
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "100%",
                        right: "100%",
                        width: widthPercentage(6.625),
                        height: widthPercentage(5),
                      }}
                    >
                      <BigQuote
                        style={{
                          marginTop: widthPercentage(1.3125),
                          marginLeft: widthPercentage(4.4375),
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 96.35%)`,
                        height: widthPercentage(2.5),
                        width: widthPercentage(25),
                        zIndex: 3,
                      }}
                    ></div>
                    <div
                      style={{
                        overflow: "auto",
                      }}
                    >
                      {(() => {
                        const style: React.CSSProperties = {
                          width: widthPercentage(25),
                          height: widthPercentage(32.9375),
                          whiteSpace: "pre-line",
                          zIndex: 1,
                          position: "relative",
                          userSelect: "text",
                        };
                        return (
                          <div style={style}>
                            <Input
                              readOnly={!edit}
                              fullWidth
                              multiline
                              disableUnderline
                              className="font-noto-regular"
                              style={{
                                fontSize: widthPercentage(1),
                                padding: 0,
                                lineHeight: "180%",
                              }}
                              onChange={(e) =>
                                setForm({ ...form, review: e.target.value })
                              }
                              defaultValue={review}
                            />
                            {imageSrc !== "" && (
                              <div
                                style={{
                                  marginTop: widthPercentage(2.5),
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={imageSrc}
                                  alt=""
                                  draggable={false}
                                  style={{ width: "100%" }}
                                />
                                {edit && (
                                  <div
                                    onClick={() => setImageFile(null)}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      display: "flex",
                                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <CloseIcon
                                      style={{
                                        width: isMobile()
                                          ? undefined
                                          : widthPercentage(1.75),
                                        height: isMobile()
                                          ? undefined
                                          : widthPercentage(1.75),
                                        color: "white",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })()}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    paddingTop: widthPercentage(0.5),
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <HomeButton
                      width={widthPercentage(11.625)}
                      outlined
                      selected={form.voted}
                      handleClick={() => {
                        if (!user) {
                          toggleSigninDialog();
                          return;
                        }
                        handleVote().then(() => {
                          if (form.voted) {
                            form.votes = form.votes - 1;
                          } else {
                            form.votes = form.votes + 1;
                          }
                          form.voted = !form.voted;
                          setForm({
                            ...form,
                          });
                        });
                      }}
                    >
                      <div className="d-flex">
                        <div
                          style={{
                            width: widthPercentage(1.5),
                            height: widthPercentage(1.5),
                          }}
                        >
                          <LikeIcon
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: widthPercentage(0.25),
                          }}
                        >
                          {form.voted
                            ? "도움이 되었어요"
                            : "후기가 도움됐나요?"}
                        </div>
                      </div>
                    </HomeButton>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(0.5),
                    color: "#999999",
                    textAlign: "center",
                    fontSize: widthPercentage(0.875),
                    marginBottom: widthPercentage(2.5),
                  }}
                >
                  {form.votes}명이 이 후기가 도움이 되었다고 답했어요!
                </div>
              </div>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div
          style={{
            color: textColor,
            textAlign: "center",
            userSelect: "none",
          }}
        >
          <div
            style={{
              backgroundColor,
              overflow: "hidden",
              height: widthPercentage(125),
              position: "relative",
            }}
          >
            <HomeContainer>
              <div
                style={{
                  marginTop: widthPercentage(19.5),
                  fontSize: widthPercentage(3.75),
                }}
              >
                {programHeader}
              </div>
              <div
                style={{
                  marginTop: widthPercentage(2.5),
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: widthPercentage(8),
                    height: widthPercentage(0.5),
                  }}
                ></div>
              </div>
              <div
                className="font-noto-medium"
                style={{
                  marginTop: widthPercentage(2.5),
                  fontSize: widthPercentage(5),
                }}
              >
                {programName}
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: -widthPercentage(17),
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: widthPercentage(37.5),
                      height: widthPercentage(0.25),
                      opacity: 0.6,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(4),
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {tutorIds.map((tutorId, index) => (
                    <div
                      key={index}
                      style={{
                        padding: `0 ${widthPercentage(2.25)}px`,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (tutorStatuses[index] !== "active") return;
                        history.push(`/tutor/${tutorId}`);
                      }}
                    >
                      <div
                        style={{
                          fontSize: widthPercentage(3.5),
                        }}
                      >
                        {tutorNames[index]}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: widthPercentage(4),
                          height: widthPercentage(4),
                        }}
                      >
                        <KeyboardArrowRightIcon
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(4),
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: widthPercentage(55),
                      height: widthPercentage(77.25),
                      backgroundImage: `url(${programImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="p-relative" style={{ height: "100%" }}>
                      <div
                        className="p-absolute"
                        style={{
                          left: "100%",
                          borderLeft: `${widthPercentage(
                            40
                          )}px solid ${shadowColor}`,
                          borderTop: `${widthPercentage(
                            40
                          )}px solid transparent`,
                          borderBottom: `${widthPercentage(
                            40
                          )}px solid ${shadowColor}`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </HomeContainer>
          </div>
          <HomeContainer>
            <div
              style={{
                textAlign: "start",
                padding: widthPercentage(6.5),
                paddingBottom: widthPercentage(22),
              }}
            >
              <div
                style={{
                  fontSize: widthPercentage(3.5),
                  color: "#767676",
                }}
              >
                {formatDateTemplate(reviewDate)}
              </div>
              {(!user || user.id !== userId) && (
                <Fragment>
                  <div
                    style={{
                      color: "#505050",
                      fontSize: widthPercentage(3.75),
                    }}
                  >
                    {reviewedUserName}님의 후기
                  </div>
                  <div
                    style={{
                      marginTop: widthPercentage(2.5),
                      width: widthPercentage(15.5),
                      height: widthPercentage(15.5),
                    }}
                  >
                    <CustomAvatarWithLocation location={userImage} />
                  </div>
                </Fragment>
              )}
              {user && user.id === userId && (
                <div style={{ display: "flex" }}>
                  <div>
                    <HomeButton
                      width={widthPercentage(20)}
                      height={widthPercentage(8)}
                      outlined
                      noColor
                      handleClick={() => {
                        if (!edit) {
                          setEdit(true);
                        } else {
                          handleUpdate();
                        }
                      }}
                      disabled={loading || (edit && form.review.length < 20)}
                    >
                      {!edit && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ display: "flex" }}>
                            <EditIcon
                              style={{
                                width: widthPercentage(3),
                                height: widthPercentage(3),
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginLeft: widthPercentage(1),
                              color: "#767676",
                            }}
                          >
                            수정
                          </div>
                        </div>
                      )}
                      {edit && (
                        <div
                          style={{
                            color: "#767676",
                          }}
                        >
                          작성 완료
                        </div>
                      )}
                    </HomeButton>
                  </div>
                  {edit && (
                    <Fragment>
                      <div style={{ marginLeft: widthPercentage(2) }}>
                        <Box
                          style={{
                            marginTop: widthPercentage(2),
                            display: "inline-flex",
                            cursor: "pointer",
                          }}
                          sx={{
                            "& path": {
                              stroke: "#999999",
                            },
                          }}
                          onClick={() => uploadRef && uploadRef.click()}
                        >
                          <CameraIcon width={widthPercentage(5.5)} />
                        </Box>
                      </div>
                      <input
                        ref={setUploadRef}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </Fragment>
                  )}
                </div>
              )}
              <div
                style={{
                  marginTop: widthPercentage(15),
                }}
              >
                <div
                  style={{
                    margin: `0 ${widthPercentage(4.25)}px`,
                    whiteSpace: "pre-line",
                    zIndex: 1,
                    position: "relative",
                    lineHeight: "180%",
                    fontSize: widthPercentage(4),
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      bottom: "100%",
                      right: "100%",
                      width: widthPercentage(20),
                      height: widthPercentage(15),
                      zIndex: -1,
                    }}
                  >
                    <BigQuote
                      style={{
                        marginTop: widthPercentage(5),
                        marginLeft: widthPercentage(15.75),
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  {(() => {
                    const style: React.CSSProperties = {
                      whiteSpace: "pre-line",
                      zIndex: 1,
                      position: "relative",
                      userSelect: "text",
                    };
                    return (
                      <div style={style}>
                        <Input
                          readOnly={!edit}
                          fullWidth
                          multiline
                          disableUnderline
                          className="font-noto-regular"
                          style={{
                            fontSize: widthPercentage(4),
                            padding: 0,
                            lineHeight: "180%",
                          }}
                          onChange={(e) =>
                            setForm({ ...form, review: e.target.value })
                          }
                          defaultValue={review}
                        />
                        {imageSrc !== "" && (
                          <div
                            style={{
                              marginTop: widthPercentage(10),
                              position: "relative",
                            }}
                          >
                            <img
                              src={imageSrc}
                              alt=""
                              draggable={false}
                              style={{ width: "100%" }}
                            />
                            {edit && (
                              <div
                                onClick={() => setImageFile(null)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  display: "flex",
                                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                                  cursor: "pointer",
                                }}
                              >
                                <CloseIcon
                                  style={{
                                    width: isMobile()
                                      ? undefined
                                      : widthPercentage(7),
                                    height: isMobile()
                                      ? undefined
                                      : widthPercentage(7),
                                    color: "white",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })()}
                </div>
              </div>
              <div
                style={{
                  paddingTop: widthPercentage(14.75),
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <HomeButton
                  width={widthPercentage(53.5)}
                  height={widthPercentage(11.5)}
                  outlined
                  selected={form.voted}
                  handleClick={() => {
                    if (!user) {
                      toggleSigninDialog();
                      return;
                    }
                    handleVote().then(() => {
                      if (form.voted) {
                        form.votes = form.votes - 1;
                      } else {
                        form.votes = form.votes + 1;
                      }
                      form.voted = !form.voted;
                      setForm({
                        ...form,
                      });
                    });
                  }}
                >
                  <div className="d-flex">
                    <div
                      style={{
                        width: widthPercentage(6),
                        height: widthPercentage(6),
                      }}
                    >
                      <LikeIcon
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: widthPercentage(1),
                      }}
                    >
                      {form.voted ? "도움이 되었어요" : "후기가 도움됐나요?"}
                    </div>
                  </div>
                </HomeButton>
              </div>
              <div
                style={{
                  marginTop: widthPercentage(1.75),
                  color: "#999999",
                  textAlign: "center",
                  fontSize: widthPercentage(3.5),
                }}
              >
                {form.votes}명이 이 후기가 도움이 되었다고 답했어요!
              </div>
            </div>
          </HomeContainer>
        </div>
      </Hidden>
    </Fragment>
  );
}

interface ProgramReviewCardReviewProps {
  id: number;
  reviewDate: string;
  reviewedUserName: string;
  review: string;
  votes: number;
  handleVote: () => void;
  voted: boolean;
}

export interface ProgramReviewCardProgramProps {
  id: number;
  programImage: string;
  programHeader: string;
  programName: string;
  tutorNames: string[];
  tutorIds: number[];
  tutorStatuses: TutorStatus[];
}

const WIDTH_HEIGHT_RATIO = Math.sqrt(2);

export interface ProgramReviewCardProps {
  program: ProgramReviewCardProgramProps;
  review?: ProgramReviewCardReviewProps;
}

function ProgramReviewCard({ program, review }: ProgramReviewCardProps) {
  const [divContainer, setDivContainer] = useState<HTMLDivElement | null>(null);
  const divContainerWidth = divContainer?.clientWidth ?? 0;
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  return (
    <div
      className={formClass([
        "program-review-card-container",
        review ? "review" : null,
        "p-relative",
        "width-100-percentage",
        "text-align-start",
        "cursor-pointer",
      ])}
      style={{
        padding: divContainerWidth / 8,
        height: divContainerWidth * WIDTH_HEIGHT_RATIO,
        boxSizing: "border-box",
        zIndex: 1,
      }}
      ref={setDivContainer}
    >
      {!review ? (
        <Fragment>
          <img
            draggable={false}
            src={program.programImage}
            alt=""
            className="p-absolute width-100-percentage height-100-percentage no-inset image-opacity"
          />
          <div
            className="text-white font-bold"
            style={{
              fontSize: divContainerWidth / 12,
            }}
          >
            {program.programHeader}
          </div>
          <div
            className="text-white font-bold mt-5"
            style={{
              fontSize: divContainerWidth / 15,
            }}
          >
            {program.programName}
          </div>
          <div
            style={{
              fontSize: divContainerWidth / 20,
              marginTop: divContainerWidth / 10,
            }}
            className="text-align-center text-white"
          >
            당신의 이야기를 들려주세요!
          </div>
          <Link to="/community" className="text-decoration-none">
            <CustomButton
              height={divContainerWidth / 10}
              style={{
                position: "absolute",
                bottom: divContainerWidth / 10,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <div
                className="text-white"
                style={{
                  fontSize: divContainerWidth / 25,
                }}
              >
                후기 쓰러 가기
              </div>
            </CustomButton>
          </Link>
          <div
            className="p-absolute-center"
            style={{
              width: divContainerWidth * 0.5,
            }}
          >
            <img
              draggable={false}
              src="assets/empty-box.svg"
              className="width-100-percentage height-100-percentage"
              alt=""
            />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <img
            draggable={false}
            src={program.programImage}
            alt=""
            className="p-absolute width-100-percentage height-100-percentage no-inset image-opacity z-back"
          />
          <Grid
            container
            direction="column"
            style={{ height: "100%" }}
            onClick={() => {
              parsed.review_id = review.id.toString();
              parsed.index = (-1).toString();
              location.search = queryString.stringify(parsed);
              history.replace({ search: location.search });
            }}
          >
            <Grid item>
              <div
                className="text-white font-bold"
                style={{
                  fontSize: divContainerWidth / 12,
                }}
              >
                {program.programHeader}
              </div>
              <div
                className="text-white font-bold mt-5"
                style={{
                  fontSize: divContainerWidth / 15,
                }}
              >
                {program.programName}
              </div>
            </Grid>
            <Grid item xs className="overflow-hidden whitespace-preline">
              <div
                style={{
                  fontSize: divContainerWidth / 20,
                  marginTop: divContainerWidth / 10,
                }}
                className="text-white"
              >
                {review.review}
              </div>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </div>
  );
}

export default ProgramReviewCard;
