import { Hidden } from "@mui/material";
import React, { Fragment } from "react";
import { Theme, useTheme } from "./admins/pages/edits/Theme";

interface ThemeComponentProps {
  theme: Theme;
}

function ThemeComponent({ theme }: ThemeComponentProps) {
  return (
    <div className="d-flex p-relative">
      <img
        src={theme.image}
        alt=""
        className="width-100-percentage"
        draggable={false}
      />
      {theme.buttons.map((button) => (
        <div
          key={button.id}
          className="p-absolute"
          onClick={() => window.open(button.link, "_blank")}
          style={{
            transform: "translate(-50%, -50%)",
            top: `${button.top_percentage}%`,
            left: `${button.left_percentage}%`,
            cursor: "pointer",
            zIndex: 1,
            width: `${button.width_percentage || 0}%`,
          }}
        >
          <img
            src={button.image}
            alt=""
            draggable={false}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </div>
  );
}

function PhilousTheme() {
  const { theme } = useTheme();
  if (!theme) return null;
  return (
    <Fragment>
      <Hidden smDown>
        <ThemeComponent theme={theme} />
      </Hidden>
      <Hidden smUp>
        <ThemeComponent theme={theme.mobile} />
      </Hidden>
    </Fragment>
  );
}

export default PhilousTheme;
