import { useEffect, useState } from "react";
import { SERVER } from "../config";
import { Image } from "../interfaces";
import {
  fetchDefaultFileWithCredential,
  fetchDefaultWithCredential,
  fetchWithCredential,
} from "../utils";
import { postImageToken, putImageBlobWithToken } from "./useImageTokens";

export function useImage(image_id: number) {
  const [image, setImage] = useState<Image | null>(null);

  useEffect(() => {
    getImage(image_id).then(setImage);
  }, [image_id]);

  return image;
}

export function getImage(image_id: number) {
  return fetchWithCredential(`${SERVER}/image/${image_id}`, "GET").then(
    (res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json();
    }
  );
}

export function postImageFile(file: File) {
  return fetchDefaultWithCredential(`/image`, "POST", {
    filename: file.name,
  }).then((res) => {
    if (!res.ok) {
      return res.text().then((data) => {
        throw new Error(data);
      });
    }
    return res.json().then((image) => {
      return fetchDefaultWithCredential(`/image/token`, "POST", {
        image_id: image.id,
      }).then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.text().then((token) => {
          const formData = new FormData();
          formData.append("image", file);
          return fetchDefaultFileWithCredential(
            `/image/token/${token}`,
            "PUT",
            formData
          ).then((res) => {
            if (!res.ok) {
              return res.text().then((data) => {
                throw new Error(data);
              });
            }
            return res.json();
          });
        });
      });
    });
  });
}

export function postImage(filename: string) {
  return fetchWithCredential(`${SERVER}/image`, "POST", {
    filename,
  }).then((res) => {
    if (!res.ok) {
      return res.text().then((data) => {
        throw new Error(data);
      });
    }
    return res.json();
  });
}

export function putImageCropped(image_id: number, blob: Blob) {
  return postImageToken(image_id).then((imageToken) =>
    putImageBlobWithToken(imageToken, blob)
  );
}

export function deleteImage(image_id: number) {
  return fetchWithCredential(`${SERVER}/image/${image_id}`, "DELETE").then(
    (res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
    }
  );
}
