import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomText from "../../../customs/CustomText";
import CustomAvatar from "../../../customs/CustomAvatar";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  formatTimeTemplate,
} from "../../../../utils";
import UserList from "../helpers/UserList";
import CustomCheckbox from "../../../customs/CustomCheckbox";
import SortComponent from "../helpers/SortComponent";
import { ProgramVisualizeInnerComponent } from "../Transactions";
import { GeneralCoupon, UserCoupon } from "../Coupons";
import { ProgramStatus } from "../../../Program";
import CustomButton from "../../../customs/CustomButton";
import CustomDialog from "../../../customs/CustomDialog";
import { useOptions } from "../Options";
import { User } from "../../../../globals/user";
import { SnackbarContext } from "../../../../globals/components/ComponentsWrapper";

export interface ProgramInfo {
  id: number;
  program_image: string;
  program_header: string;
  program_name: string;
  dates: string[];
  price: number;
  max_people: number;
  status: ProgramStatus;
}

interface UserInfo {
  user: User;
  points: number;
  cart_programs: ProgramInfo[];
  registered_programs: ProgramInfo[];
  total_paid: number;
  owned_coupons: UserCoupon[];
  used_coupons: GeneralCoupon[];
}

interface UserInfoComponentProps {
  userInfo: UserInfo | null;
  handleDelete: (user_id: number) => void;
  handleDeactivate: (user_id: number) => void;
  handleFirstLogin: (user_id: number) => void;
}

function UserInfoComponent({
  userInfo,
  handleDelete,
  handleDeactivate,
  handleFirstLogin,
}: UserInfoComponentProps) {
  const [firstLoginOpen, setFirstLoginOpen] = useState<boolean>(false);
  const [deactivateOpen, setDeactivateOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { options } = useOptions("personal_goal");
  if (!userInfo || !options) return null;
  return (
    <Grid container columnSpacing={2} key={userInfo.user.id}>
      <Grid item xs>
        <div className="p-15 font-15 font-bold">
          가입일: {formatDateTemplate(userInfo.user.created_at!)}
        </div>
        <div className="p-15 font-15 font-bold">
          적립금: {userInfo.points.toLocaleString()}원
        </div>
        <div className="p-15 font-15 font-bold">
          누적금액: {userInfo.total_paid.toLocaleString()}원
        </div>
        {userInfo.user.last_signed_in && (
          <div className="p-15 font-15 font-bold">
            마지막 로그인: {formatDateTemplate(userInfo.user.last_signed_in)}{" "}
            {formatTimeTemplate(userInfo.user.last_signed_in)}
          </div>
        )}
        <Grid
          className="mt-10"
          container
          direction="column"
          alignItems="center"
        >
          <Grid item>
            <div className="width-100 height-100">
              <CustomAvatar user={userInfo.user} />
            </div>
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="나만의 목표"
              name="personal_goal"
              value={(() => {
                const option = options.find(
                  (option) =>
                    option.id === userInfo.user.meta.personal_goal_option_id
                );
                if (!option) return "";
                return option.label;
              })()}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              multiline
              label="자기소개"
              name="intro"
              height="100px"
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="이메일"
              name="email"
              defaultValue={userInfo.user.email}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="이름"
              name="name"
              defaultValue={userInfo.user.name}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="성별"
              name="gender"
              defaultValue={(() => {
                const map: any = {
                  male: "남자",
                  female: "여자",
                  unwilling: "밝히지 않음",
                };
                return map[userInfo.user.gender];
              })()}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="생년월일"
              name="date_of_birth"
              defaultValue={userInfo.user.date_of_birth}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="휴대전화"
              name="phone"
              defaultValue={userInfo.user.phone}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="직업"
              name="job"
              defaultValue={userInfo.user.job}
              readOnly
            />
          </Grid>
          {userInfo.user.job === "기타 (직접 입력)" && (
            <Grid item className="mt-10">
              <CustomText
                type="text"
                label="*직업 직접 입력"
                name="job_detail"
                defaultValue={userInfo.user.meta.job_detail}
                readOnly
              />
            </Grid>
          )}
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="오시게 된 경로"
              name="how_philous"
              defaultValue={userInfo.user.meta.how_philous}
              readOnly
            />
          </Grid>
          {userInfo.user.meta.how_philous === "지인 추천" && (
            <Grid item className="mt-10">
              <CustomText
                type="text"
                label={(() => {
                  if (!userInfo.user.meta.recommend.verified) {
                    return "추천인 (인증 안됨)";
                  }
                  return "추천인 (인증됨)";
                })()}
                name="how_philous_detail"
                defaultValue={(() => {
                  if (!userInfo.user.meta.recommend.verified) {
                    return userInfo.user.meta.how_philous_detail;
                  }
                  return `${userInfo.user.meta.recommend.recommendee_id}. ${userInfo.user.meta.recommend.recommendee_name}`;
                })()}
                readOnly
              />
            </Grid>
          )}
          {userInfo.user.meta.how_philous === "기타 (직접 입력)" && (
            <Grid item className="mt-10">
              <CustomText
                type="text"
                label="*오시게 된 경로 직접 입력"
                name="how_philous_detail"
                defaultValue={userInfo.user.meta.how_philous_detail}
                readOnly
              />
            </Grid>
          )}
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="개인정보 수집 동의"
              name="private_info"
              defaultValue={userInfo.user.meta.private_info}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomText
              type="text"
              label="마케팅 수신정보 동의"
              name="marketing_info"
              defaultValue={userInfo.user.meta.marketing_info}
              readOnly
            />
          </Grid>
          <Grid item className="mt-10">
            <CustomButton width={360} onClick={() => setFirstLoginOpen(true)}>
              로그인 초기화
            </CustomButton>
          </Grid>
          <Grid item className="mt-10">
            <CustomButton width={360} onClick={() => setDeactivateOpen(true)}>
              휴면 {userInfo.user.deactivated ? "해제" : "등록"}
            </CustomButton>
          </Grid>
          <Grid item className="mt-10" onClick={() => setDeleteOpen(true)}>
            <CustomButton width={360}>회원 탈퇴</CustomButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <div className="cart-program-component">
          <SortComponent
            currentDataObject={null}
            setCurrentDataObject={() => null}
            label="장바구니"
            data={userInfo.cart_programs}
            listItemComponent={(program: ProgramInfo) => (
              <ProgramVisualizeInnerComponent {...program} />
            )}
            abcSortAttributeFunc={(program) => program.program_name}
            defaultSortType="recent"
            showMenu={false}
          />
        </div>
        <div className="mt-10 registered-program-component">
          <SortComponent
            currentDataObject={null}
            setCurrentDataObject={() => null}
            label="등록된 프로그램"
            data={userInfo.registered_programs}
            listItemComponent={(program: ProgramInfo) => (
              <ProgramVisualizeInnerComponent {...program} />
            )}
            abcSortAttributeFunc={(program) => program.program_name}
            defaultSortType="recent"
            showMenu={false}
          />
        </div>
        <div className="mt-10">
          <SortComponent
            currentDataObject={null}
            setCurrentDataObject={() => null}
            label="보유 쿠폰"
            data={userInfo.owned_coupons}
            listItemComponent={(coupon: UserCoupon) => (
              <div className="p-10 width-100-percentage">
                <div className="d-flex justify-content-space-between align-items-center">
                  <div>
                    <div className="font-12 font-bold">{coupon.title}</div>
                    <div className="mt-5 font-10 font-bold">
                      {coupon.value.toLocaleString()}
                      {coupon.unit === "percentage" ? "%" : "원"}
                    </div>
                  </div>
                  <div className="font-10 font-bold">
                    {formatDateTemplate(coupon.expires_at)}까지
                  </div>
                </div>
              </div>
            )}
            abcSortAttributeFunc={(coupon) => coupon.title}
            defaultSortType="recent"
          />
        </div>
        <div className="mt-10">
          <SortComponent
            currentDataObject={null}
            setCurrentDataObject={() => null}
            label="사용 쿠폰 | 만료된 쿠폰"
            data={userInfo.used_coupons}
            listItemComponent={(coupon: GeneralCoupon) => (
              <div className="p-10 width-100-percentage">
                <div className="d-flex justify-content-space-between align-items-center">
                  <div>
                    <div className="font-12 font-bold">{coupon.title}</div>
                    <div className="mt-5 font-10 font-bold">
                      {coupon.value.toLocaleString()}원
                    </div>
                  </div>

                  <div>
                    <div
                      className="p-5 text-white text-align-center"
                      style={{
                        backgroundColor: coupon.used ? "black" : "violet",
                      }}
                    >
                      {coupon.used ? "사용함" : "만료됨"}
                    </div>
                    <div className="mt-5 font-10 font-bold">
                      {formatDateTemplate(coupon.expires_at)}까지
                    </div>
                  </div>
                </div>
              </div>
            )}
            abcSortAttributeFunc={(coupon) => coupon.title}
          />
        </div>
      </Grid>
      <CustomDialog open={firstLoginOpen}>
        <p className="font-header">로그인을 첫 로그인화 시키겠습니까</p>
        <div className="d-flex mt-30 justify-content-flex-end">
          <CustomButton
            outlined
            onClick={() => handleFirstLogin(userInfo.user.id as number)}
          >
            확인
          </CustomButton>
          <CustomButton
            outlined
            onClick={() => setFirstLoginOpen(false)}
            className="ml-10"
          >
            닫기
          </CustomButton>
        </div>
      </CustomDialog>
      <CustomDialog open={deactivateOpen}>
        <p className="font-header">
          휴면 {userInfo.user.deactivated ? "해제" : "등록"} 하시겠습니까
        </p>
        <div className="d-flex mt-30 justify-content-flex-end">
          <CustomButton
            outlined
            onClick={() => handleDeactivate(userInfo.user.id as number)}
          >
            {userInfo.user.deactivated ? "해제" : "등록"}
          </CustomButton>
          <CustomButton
            outlined
            onClick={() => setDeactivateOpen(false)}
            className="ml-10"
          >
            닫기
          </CustomButton>
        </div>
      </CustomDialog>
      <CustomDialog open={deleteOpen}>
        <p className="font-header">유저를 삭제 하시겠습니까</p>
        <div className="d-flex mt-30 justify-content-flex-end">
          <CustomButton
            outlined
            onClick={() => handleDelete(userInfo.user.id as number)}
          >
            삭제
          </CustomButton>
          <CustomButton
            outlined
            onClick={() => setDeleteOpen(false)}
            className="ml-10"
          >
            닫기
          </CustomButton>
        </div>
      </CustomDialog>
    </Grid>
  );
}

function AdminPageUsers() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserInfo, setCurrentUserInfo] = useState<UserInfo | null>(null);
  const [deactivatedExclude, setDeactivatedExclude] = useState<boolean>(false);
  const [marketing, setMarketing] = useState<boolean>(false);
  const [onlyDeactivated, setOnlyDeactivated] = useState<boolean>(false);
  const [render, setRender] = useState<number>(0);
  const { showWarningSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (currentUser) {
      fetchDefaultWithCredential(`/user/${currentUser.id}/admin`, "GET").then(
        (res) => {
          if (!res.ok) {
            return res.json().then(({ message }) => {
              throw new Error(message);
            });
          }
          return res.json().then(setCurrentUserInfo);
        }
      );
    }
  }, [currentUser]);

  const handleDelete = (user_id: number) => {
    fetchDefaultWithCredential(`/user/${user_id}`, "DELETE").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          showWarningSnackbar(error);
        });
      }
      return res.json().then(() => {
        setCurrentUserInfo(null);
        setRender(render + 1);
      });
    });
  };

  const handleDeactivate = (user_id: number) => {
    fetchDefaultWithCredential(`/user/${user_id}/deactivate`, "PATCH").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            showWarningSnackbar(error);
          });
        }
        return res.json().then(() => {
          setCurrentUserInfo(null);
          setRender(render + 1);
        });
      }
    );
  };
  const handleFirstLogin = (user_id: number) => {
    fetchDefaultWithCredential(`/user/${user_id}/first_signing`, "PATCH").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            showWarningSnackbar(error);
          });
        }
        return res.json().then(() => {
          setCurrentUserInfo(null);
          setRender(render + 1);
        });
      }
    );
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={4} xs={12} className="p-15">
        <div className="filter-options">
          <CustomCheckbox
            checked={deactivatedExclude}
            handleChange={(checked) => {
              setDeactivatedExclude(checked);
            }}
            border={false}
          >
            휴면 미포함
          </CustomCheckbox>
          <CustomCheckbox
            checked={marketing}
            handleChange={(checked) => {
              setMarketing(checked);
            }}
            border={false}
          >
            마케팅 정보 포함
          </CustomCheckbox>
          <CustomCheckbox
            checked={onlyDeactivated}
            handleChange={(checked) => {
              setOnlyDeactivated(checked);
            }}
            border={false}
          >
            휴면만 포함
          </CustomCheckbox>
        </div>
        <UserList
          key={render}
          showCSV
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          filter={(user) => {
            let condition = true;
            if (deactivatedExclude) {
              condition = condition && user.deactivated === null;
            }
            if (marketing) {
              condition = condition && user.meta.marketing_info;
            }
            if (onlyDeactivated) {
              condition = condition && user.deactivated !== null;
            }
            return condition;
          }}
        />
      </Grid>
      <Grid item sm={8} xs={12}>
        <UserInfoComponent
          key={render}
          userInfo={currentUserInfo}
          handleDelete={handleDelete}
          handleDeactivate={handleDeactivate}
          handleFirstLogin={handleFirstLogin}
        />
      </Grid>
    </Grid>
  );
}

export default AdminPageUsers;
