import React from "react";
import { Alert, Snackbar } from "@mui/material";

export type SnackbarType = "success" | "error" | "warning";

interface SnackbarComponentProps {
  open: boolean;
  handleClose: () => void;
  text: string;
  type: SnackbarType;
}

function SnackbarComponent({
  open,
  handleClose,
  text,
  type,
}: SnackbarComponentProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert variant="filled" onClose={handleClose} severity={type}>
        {text}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarComponent;
