import { RouteInfo } from "../interfaces";
// import PrevPrograms from "../components/programs/PrevPrograms";
import TutorProfile from "../components/tutors/TutorProfile";
import TutorDetail from "../components/tutors/TutorDetail";
import Community from "../components/Community";
import CompleteBuy from "../components/CompleteBuy";
import FAQ from "../components/FAQ";
import Policies from "../components/Policies";
import Program from "../components/Program";
import Signin from "../components/layouts/Signin";
// import Introduction from "../components/Introduction";
// import UsePhilous from "../components/UsePhilous";
import Feedbacks from "../components/Feedbacks";
import ProgramDetail from "../components/ProgramDetail";
import Suggestions from "../components/Suggestions";
import Redirection from "../components/Redirection";
import Home from "../components/Home";
import UserPopupComponent from "../components/UserPopupComponent";
import Brand from "../components/Brand";
import MyPage from "../components/MyPage";
import PhiloTrip from "../components/PhiloTrip";
import PhilousTheme from "../components/Theme";

const Defaults: RouteInfo[] = [
  {
    exact: true,
    path: "/",
    component: Home,
  },
  {
    exact: false,
    path: "/mypage",
    component: MyPage,
  },
  {
    exact: true,
    path: "/signin",
    component: Signin,
  },
  {
    exact: true,
    path: "/popups/template/:template_id",
    component: UserPopupComponent,
  },
  // {
  //   exact: true,
  //   path: "/prev-programs",
  //   component: PrevPrograms,
  // },
  {
    exact: true,
    path: "/tutor/profile",
    component: TutorProfile,
  },
  {
    exact: true,
    path: "/tutor/:id",
    component: TutorDetail,
  },
  {
    exact: true,
    path: "/community",
    component: Community,
  },
  {
    exact: true,
    path: "/complete/buy",
    component: CompleteBuy,
  },
  {
    exact: true,
    path: "/faq",
    component: FAQ,
  },
  {
    exact: true,
    path: "/feedbacks",
    component: Feedbacks,
  },
  {
    exact: true,
    path: "/policies",
    component: Policies,
  },
  {
    exact: true,
    path: "/suggestions",
    component: Suggestions,
  },
  {
    exact: true,
    path: "/philotrip",
    component: PhiloTrip,
  },
  {
    exact: true,
    path: "/program",
    component: Program,
  },
  {
    exact: true,
    path: "/program/:id",
    component: ProgramDetail,
  },
  {
    exact: true,
    path: "/redirection/:type",
    component: Redirection,
  },
  {
    exact: true,
    path: "/brand",
    component: Brand,
  },
  {
    exact: true,
    path: "/theme",
    component: PhilousTheme,
  },
  // {
  //   exact: true,
  //   path: "/introduction",
  //   component: Introduction,
  // },
  // {
  //   exact: true,
  //   path: "/use-philous",
  //   component: UsePhilous,
  // },
  // {
  //   exact: true,
  //   path: "/philo-preceptorial-hidden",
  //   component: PhiloPreceptorialHidden,
  // },
];

export default Defaults;
