import React from "react";
import { useNotifications } from "../../../globals/notification";
import { User, useUser } from "../../../globals/user";
import TutorParticipations from "../../tutors/pages/Participations";
import TutorPrograms from "../../tutors/pages/Programs";
import TutorReports from "../../tutors/pages/Reports";
import MenuView, { getPathMetas, PathMeta } from "./MenuView";

const initPathMetas: PathMeta[] = [
  // skip for 0
  {
    title: "",
    metas: {
      prefix: "",
      subFields: [],
    },
  },
  {
    title: "프로그램 관리",
    metas: {
      prefix: "/programs",
      subFields: [
        {
          title: "진행한 프로그램",
          endpoint: "/programs",
          component: TutorPrograms,
        },
        {
          title: "출석체크 / 오프닝 퀘스쳔",
          endpoint: "/participations",
          component: TutorParticipations,
        },
        {
          title: "독서 성장 리포트",
          endpoint: "/reports",
          component: TutorReports,
          notification: "reports",
        },
      ],
    },
    showCondition: (user: User) => {
      if (user.tutor_id) return true;
      return false;
    },
  },
];

function useTutorPathMetas() {
  const user = useUser();
  const pathMetas = getPathMetas(
    initPathMetas.filter(
      (pathMeta) => !pathMeta.showCondition || pathMeta.showCondition(user)
    )
  );
  if (!user) return [];
  for (const pathMeta of pathMetas) {
    for (const subField of pathMeta.metas.subFields) {
      pathMeta.show = true;
      subField.show = true;
    }
  }
  return pathMetas;
}

function TutorView() {
  const pathMetas = useTutorPathMetas();
  const notifications = useNotifications();
  return (
    <MenuView
      label="튜터 페이지"
      prefix="/tutor/management"
      notifications={notifications.tutor || {}}
      pathMetas={pathMetas}
    />
  );
}

export default TutorView;
