import { RouteInfo } from "../interfaces";
import Cart from "../components/Cart";
import Order from "../components/Order";
import UserSatisfactionComponent from "../components/UserSatisfactionComponent";

const Client: RouteInfo[] = [
  {
    exact: true,
    path: "/cart",
    component: Cart,
  },
  {
    exact: true,
    path: "/order",
    component: Order,
  },
  {
    exact: true,
    path: "/user/satisfaction/:user_satisfaction_id_str",
    component: UserSatisfactionComponent,
  },
];

export default Client;
