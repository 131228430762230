import React, { ReactNode } from "react";
import { isMobile } from "../../utils";
import { widthPercentage } from "../Home";
import { ProgressBar } from "../UserPopupComponent";

interface CountProps {
  label: string;
  element: ReactNode;
}

interface InfoBarProps {
  header?: string;
  description: ReactNode;
  percentage: number;
  count?: CountProps;
}

function InfoBar({ header, description, percentage, count }: InfoBarProps) {
  return (
    <div
      style={{
        border: `${widthPercentage(
          isMobile() ? 0.25 : 0.0625
        )}px solid #E5E5EC`,
        padding: widthPercentage(isMobile() ? 5.5 : 2),
        fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
        position: "relative",
        ...(isMobile()
          ? {}
          : {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }),
      }}
    >
      <div>
        {header && <div className="font-noto-medium">{header}</div>}
        <div
          style={{
            color: "#505050",
          }}
        >
          {description}
        </div>
      </div>
      {count && (
        <div
          style={{
            ...(isMobile()
              ? {
                  marginTop: widthPercentage(6.75),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }
              : {}),
          }}
        >
          <div
            style={{
              color: "#999999",
              fontSize: widthPercentage(isMobile() ? 3 : 0.75),
              textAlign: isMobile() ? "start" : "end",
            }}
          >
            {count.label}
          </div>
          <div
            className="d-flex"
            style={{
              marginTop: widthPercentage(isMobile() ? 0 : 0.0625),
              color: "#4A148C",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            {count.element}
          </div>
        </div>
      )}
      <div
        className="p-absolute"
        style={{
          zIndex: 1,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <ProgressBar percentage={percentage} noNumber />
      </div>
    </div>
  );
}

export default InfoBar;
