import { Box, Hidden } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { CustomStandardSelect } from "./customs/CustomSelect";
import "swiper/swiper-bundle.min.css";
import {
  fetchDefaultWithCredential,
  formatProgramDate,
  isMobile,
} from "../utils";
import "./Community.scss";
import { AdminUserReview } from "./admins/pages/users/Review";
import {
  HomeButton,
  HomeContainer,
  HomeDialog,
  ProgramCard,
  ProgramCardImageComponent,
  widthPercentage,
} from "./Home";
import { SubjectiveInput } from "./UserPopupComponent";
import CustomPagination from "./customs/CustomPagination";
import HomeSwiper from "./customs/HomeSwiper";
import { ReactComponent as CommunityPopupImage } from "../svg/community_popup_image.svg";
import { ReactComponent as CameraIcon } from "../svg/camera.svg";
import { GlobalContext } from "../globals/components/ComponentsWrapper";
import { useUser } from "../globals/user";
import { GenericBannerComponent } from "./HeaderBanner";
import CloseIcon from "@mui/icons-material/Close";
import { postImageFile } from "../hooks/useImages";

interface SearchForm {
  program_type: number;
  program_period: string;
  order_by: string;
}

const initForm = {
  program_type: 0,
  program_period: "",
  order_by: "",
};

function SearchComponent() {
  const [form, setForm] = useState<SearchForm>(initForm);
  const { communityData, setSubmittingForm, setPage, page } =
    useContext(CommunityContext);

  const handleSearch = () => {
    setPage(1);
    setSubmittingForm({ ...form });
  };

  while (communityData.user_reviews.length < 8) {
    communityData.user_reviews.push(null as any);
  }

  return (
    <HomeContainer>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 22 : 7.5),
          marginBottom: widthPercentage(isMobile() ? 22 : 11),
        }}
      >
        <div
          className="font-noto-medium"
          style={{
            fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
          }}
        >
          같은 책,
          <br />
          다른 멤버들은
          <br />
          어떤 경험을 했을까요?
        </div>
        <div
          style={{
            marginTop: widthPercentage(isMobile() ? 11 : 2.5),
          }}
        >
          <div className="d-flex justify-content-space-between align-items-flex-end">
            <div style={{ width: widthPercentage(isMobile() ? 29.5 : 20) }}>
              <CustomStandardSelect<number>
                label="프로그램 유형"
                fullWidth
                value={form.program_type}
                items={[
                  {
                    label: "선택 안함",
                    value: 0,
                  },
                  ...communityData.program_types.map((program_type) => ({
                    label: program_type.label,
                    value: program_type.id,
                  })),
                ]}
                handleChange={(value) =>
                  setForm({
                    ...form,
                    program_type: value,
                  })
                }
              />
            </div>
            <div style={{ width: widthPercentage(isMobile() ? 29.5 : 20) }}>
              <CustomStandardSelect<string>
                label="원데이/먼슬리"
                fullWidth
                value={form.program_period}
                items={[
                  {
                    label: "선택 안함",
                    value: "",
                  },
                  {
                    label: "원데이",
                    value: "oneday",
                  },
                  {
                    label: "먼슬리",
                    value: "monthly",
                  },
                ]}
                handleChange={(value) =>
                  setForm({
                    ...form,
                    program_period: value,
                  })
                }
              />
            </div>
            <div style={{ width: widthPercentage(isMobile() ? 29.5 : 20) }}>
              <CustomStandardSelect<string>
                label="정렬순"
                fullWidth
                value={form.order_by}
                items={[
                  {
                    label: "선택 안함",
                    value: "",
                  },
                  {
                    label: "공감순",
                    value: "vote",
                  },
                  {
                    label: "최신순",
                    value: "latest",
                  },
                ]}
                handleChange={(value) =>
                  setForm({
                    ...form,
                    order_by: value,
                  })
                }
              />
            </div>
            {!isMobile() && (
              <div style={{ width: widthPercentage(20) }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <HomeButton
                    width={widthPercentage(7.5)}
                    outlined
                    handleClick={handleSearch}
                  >
                    검색하기
                  </HomeButton>
                </div>
              </div>
            )}
          </div>
        </div>
        {isMobile() && (
          <div style={{ marginTop: widthPercentage(5) }}>
            <HomeButton
              height={widthPercentage(11)}
              outlined
              handleClick={handleSearch}
            >
              검색하기
            </HomeButton>
          </div>
        )}
        {(() => {
          const colorIndex = [0, 1, 3, 2];
          let divisible = 4;
          let width = 20;
          if (isMobile()) {
            divisible = 2;
            width = 45;
          }
          const res = [];
          for (let i = 0; i < 8; i += divisible) {
            res.push(
              <div
                key={i}
                style={{
                  marginTop: widthPercentage(isMobile() ? 9 : 2.5),
                }}
              >
                <div className="d-flex justify-content-space-between align-items-flex-end">
                  {communityData.user_reviews
                    .slice(i, i + divisible)
                    .map((userReview, index) => (
                      <div key={index}>
                        {userReview === null ? (
                          <div
                            style={{
                              width: widthPercentage(width),
                            }}
                          ></div>
                        ) : (
                          <ProgramCard
                            program={{
                              id: userReview.program_id,
                              image: userReview.program_image,
                              program_name: userReview.program_name,
                              program_description:
                                userReview.program_description,
                            }}
                            index={
                              colorIndex[
                                Math.floor(i / 2) + Math.floor(index / 2)
                              ]
                            }
                            width={widthPercentage(width)}
                            review={{
                              ...userReview,
                              tutor_ids: userReview.program_tutor_ids,
                              image: userReview.program_image,
                            }}
                          />
                        )}
                      </div>
                    ))}
                </div>
              </div>
            );
          }
          return res;
        })()}
        <div
          style={{
            marginTop: widthPercentage(isMobile() ? 10 : 2.5),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomPagination
            count={communityData.total_pages}
            page={page}
            handleChange={setPage}
          />
        </div>
      </div>
    </HomeContainer>
  );
}

export function ProgramReviewWriteHeader() {
  return (
    <div
      className="font-noto-medium"
      style={{
        fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
      }}
    >
      이제
      <br />
      당신의 이야기를
      <br />
      들려주세요!
    </div>
  );
}

interface PointInfo {
  new_point: number;
  total_point: number;
}

export interface ProgramReviewWriteBodyProgram {
  id: number;
  program_header: string;
  program_name: string;
  dates: string[];
  tutor_names: string[];
  program_image: string;
}

interface ProgramReviewWriteBodyProps {
  programs: ProgramReviewWriteBodyProgram[];
  refresh: () => void;
  selectWidth?: number;
  margin?: number;
  programId: number;
  setProgramId: (programId: number) => void;
}

export function postProgramReview(
  programId: number,
  review: string,
  image_id?: number
) {
  return fetchDefaultWithCredential(`/program/${programId}/review`, "POST", {
    review,
    image_id,
  }).then((res) => {
    if (!res.ok) {
      return res.json().then(({ error }) => {
        throw new Error(error);
      });
    }
    return res.json();
  });
}

export function ProgramReviewWriteBody({
  programs,
  refresh,
  selectWidth,
  margin,
  programId,
  setProgramId,
}: ProgramReviewWriteBodyProps) {
  const user = useUser();
  const [programReview, setProgramReview] = useState<string>("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const selectedProgram = programs.find((program) => program.id === programId);
  const [openComplete, setOpenComplete] = useState<boolean>(false);
  const [pointInfo, setPointInfo] = useState<PointInfo>({
    new_point: 0,
    total_point: 0,
  });
  const { toggleSigninDialog } = useContext(GlobalContext);
  const [uploadRef, setUploadRef] = useState<HTMLInputElement | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    // check if image file is available
    // if available, do image operation first
    setSubmitting(true);
    if (!imageFile) {
      return postProgramReview(programId, programReview)
        .then(({ new_point, total_point }) => {
          setPointInfo({ new_point, total_point });
          setOpenComplete(true);
        })
        .then(() => {
          // reset
          refresh();
          setProgramId(0);
          setProgramReview("");
        })
        .then(() => setSubmitting(false));
    }
    return postImageFile(imageFile).then((image) =>
      postProgramReview(programId, programReview, image.id)
        .then(({ new_point, total_point }) => {
          setPointInfo({ new_point, total_point });
          setOpenComplete(true);
        })
        .then(() => {
          // reset
          refresh();
          setProgramId(0);
          setProgramReview("");
        })
        .then(() => setSubmitting(false))
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    setImageFile(files[0]);
  };

  return (
    <Fragment>
      <div
        style={{
          boxShadow: `${widthPercentage(
            isMobile() ? 5 : 1.25
          )}px ${widthPercentage(isMobile() ? 5 : 1.25)}px ${widthPercentage(
            isMobile() ? 15 : 3.75
          )}px rgba(74, 20, 140, 0.16)`,
          padding: isMobile()
            ? `${widthPercentage(9)}px ${widthPercentage(4)}px`
            : widthPercentage(3.75),
          cursor: !user ? "pointer" : "inherit",
        }}
        onClick={toggleSigninDialog}
      >
        <div
          className={isMobile() ? undefined : "d-flex p-relative"}
          style={{
            pointerEvents: !user ? "none" : undefined,
          }}
        >
          <div>
            <CustomStandardSelect<number>
              value={programId}
              items={[
                {
                  label: "내 프로그램 선택하기",
                  value: 0,
                  disabled: true,
                },
                ...programs.map((program) => ({
                  label: `${program.program_header} - ${program.program_name}`,
                  value: program.id,
                })),
              ]}
              handleChange={setProgramId}
              formStyle={{
                width: isMobile() ? "100%" : selectWidth || widthPercentage(20),
              }}
            />
            {selectedProgram && (
              <Fragment>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 3 : 1.5),
                    fontSize: widthPercentage(isMobile() ? 3.5 : 0.75),
                  }}
                >
                  <span
                    style={{
                      color: "#505050",
                    }}
                  >
                    {formatProgramDate(selectedProgram.dates)}
                  </span>
                  <span
                    style={{
                      marginLeft: widthPercentage(isMobile() ? 2 : 0.5),
                      color: "#999999",
                    }}
                  >
                    {selectedProgram.tutor_names.join(", ")}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 1 : 0.5),
                  }}
                >
                  <ProgramCardImageComponent
                    index={-1}
                    program_image={selectedProgram.program_image}
                  />
                </div>
              </Fragment>
            )}
          </div>
          <div
            style={{
              paddingTop: widthPercentage(isMobile() ? 9 : 0.45),
              marginLeft: isMobile() ? 0 : margin || widthPercentage(3.75),
              flexGrow: 1,
            }}
          >
            <div
              style={{
                height: isMobile()
                  ? undefined
                  : programId !== 0
                  ? widthPercentage(14)
                  : undefined,
                overflow: isMobile() ? undefined : "auto",
              }}
            >
              <SubjectiveInput
                key={programId}
                colorIcon={programId !== 0}
                showUnderline={isMobile() ? undefined : programId === 0}
                handleChange={setProgramReview}
                placeholder="시간이 지나면 기억은 사라져요! 세미나의 열기가 가시기 전 나의 느낌을 담아보세요."
                disabled={programId === 0}
              />
            </div>
            {programId !== 0 && (
              <div
                style={{
                  marginTop: widthPercentage(isMobile() ? 40 : 0.75),
                }}
              >
                <div
                  style={{
                    ...(isMobile()
                      ? {}
                      : {
                          display: "flex",
                          justifyContent: "flex-end",
                        }),
                  }}
                >
                  {imageFile && (
                    <div
                      style={{
                        position: "relative",
                        marginRight: isMobile()
                          ? undefined
                          : widthPercentage(1.25),
                        marginBottom: isMobile()
                          ? widthPercentage(5)
                          : undefined,
                      }}
                    >
                      <img
                        src={URL.createObjectURL(imageFile)}
                        alt=""
                        draggable={false}
                        style={{
                          height: isMobile() ? undefined : widthPercentage(2.5),
                          width: isMobile() ? "100%" : "auto",
                        }}
                      />
                      <div
                        onClick={() => setImageFile(null)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          display: "flex",
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon
                          style={{
                            width: isMobile()
                              ? undefined
                              : widthPercentage(0.875),
                            height: isMobile()
                              ? undefined
                              : widthPercentage(0.875),
                            color: "white",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <HomeButton
                      width={isMobile() ? undefined : widthPercentage(20)}
                      height={isMobile() ? widthPercentage(11) : undefined}
                      outlined
                      noColor={imageFile !== null}
                      disabled={imageFile !== null}
                      handleClick={() => uploadRef && uploadRef.click()}
                    >
                      {!imageFile && (
                        <Fragment>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Box
                              style={{ display: "flex" }}
                              sx={{
                                "& path": {
                                  stroke: "#4a148c",
                                },
                              }}
                            >
                              <CameraIcon
                                width={
                                  isMobile()
                                    ? widthPercentage(5.75)
                                    : widthPercentage(1.4375)
                                }
                                height={
                                  isMobile()
                                    ? widthPercentage(5.25)
                                    : widthPercentage(1.3125)
                                }
                              />
                            </Box>
                            <div
                              style={{
                                marginLeft: isMobile()
                                  ? widthPercentage(2.5)
                                  : widthPercentage(0.625),
                              }}
                            >
                              사진을 첨부하여 기록을 남겨보세요!
                            </div>
                          </div>
                          <input
                            ref={setUploadRef}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </Fragment>
                      )}
                      {imageFile && "사진 첨부 완료"}
                    </HomeButton>
                  </div>
                  <div
                    style={{
                      marginLeft: isMobile()
                        ? undefined
                        : widthPercentage(1.25),
                      marginTop: isMobile() ? widthPercentage(5) : undefined,
                    }}
                  >
                    <HomeButton
                      width={isMobile() ? undefined : widthPercentage(7.5)}
                      height={isMobile() ? widthPercentage(11) : undefined}
                      outlined
                      noColor={programReview.length < 20}
                      disabled={programReview.length < 20 || submitting}
                      handleClick={handleSubmit}
                    >
                      작성완료
                    </HomeButton>
                  </div>
                </div>
              </div>
            )}
          </div>
          {programId === 0 && (
            <div
              style={{
                marginTop: widthPercentage(isMobile() ? 5 : 0),
                marginLeft: isMobile() ? 0 : margin || widthPercentage(2.5),
              }}
            >
              <HomeButton
                width={isMobile() ? undefined : widthPercentage(7.5)}
                height={isMobile() ? widthPercentage(11) : undefined}
                disabled
                noColor
                outlined
              >
                작성하기
              </HomeButton>
            </div>
          )}
        </div>
      </div>
      <HomeDialog
        open={openComplete}
        handleClose={() => setOpenComplete(false)}
      >
        <Hidden smDown>
          <div
            style={{
              width: widthPercentage(68.125),
              userSelect: "none",
              WebkitUserSelect: "none",
              color: "#111111",
              textAlign: "center",
              padding: `${widthPercentage(10)}px 0`,
            }}
            className="font-noto-medium"
          >
            <div style={{ fontSize: widthPercentage(2) }}>후기작성 완료!</div>
            <div
              style={{
                marginTop: widthPercentage(2.5),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CommunityPopupImage
                style={{
                  width: widthPercentage(11.25),
                  height: widthPercentage(11.25),
                }}
              />
            </div>
            <div
              style={{
                marginTop: widthPercentage(3),
                color: "#767676",
                fontSize: widthPercentage(0.9375),
                whiteSpace: "pre-line",
              }}
            >
              소중한 경험을 남겨주셔서 감사합니다.
              <br />
              <span style={{ color: "#4A148C" }}>
                {pointInfo.new_point.toLocaleString()}원
              </span>
              이 리워드로 적립되었습니다 :)
            </div>
            <div
              style={{
                marginTop: widthPercentage(1.1875),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="font-noto-medium"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#F1F1FA",
                  width: widthPercentage(21.5625),
                  padding: `${widthPercentage(0.3125)}px ${widthPercentage(
                    2.3125
                  )}px`,
                  fontSize: widthPercentage(0.9375),
                }}
              >
                <div>내 적립금</div>
                <div style={{ color: "#4A148C" }}>
                  {pointInfo.total_point.toLocaleString()}원
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: widthPercentage(2.4375),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <HomeButton
                width={widthPercentage(10)}
                handleClick={() => setOpenComplete(false)}
              >
                확인
              </HomeButton>
            </div>
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              color: "#111111",
              textAlign: "center",
              padding: `0 ${widthPercentage(9)}px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            className="font-noto-medium"
          >
            <div>
              <div
                style={{
                  marginTop: widthPercentage(1),
                  fontSize: widthPercentage(5),
                }}
              >
                후기작성 완료!
              </div>
              <div
                style={{
                  marginTop: widthPercentage(9),
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CommunityPopupImage
                  style={{
                    width: widthPercentage(40),
                    height: widthPercentage(40),
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: widthPercentage(9),
                  color: "#767676",
                  fontSize: widthPercentage(3.75),
                  whiteSpace: "pre-line",
                }}
              >
                소중한 경험을 남겨주셔서 감사합니다.
                <br />
                <span style={{ color: "#4A148C" }}>
                  {pointInfo.new_point.toLocaleString()}원
                </span>
                이 리워드로 적립되었습니다 :)
              </div>
              <div
                style={{
                  marginTop: widthPercentage(3.5625),
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="font-noto-medium"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#F1F1FA",
                    width: widthPercentage(86.25),
                    padding: `${widthPercentage(1.25)}px ${widthPercentage(
                      9.25
                    )}px`,
                    fontSize: widthPercentage(3.75),
                  }}
                >
                  <div>내 적립금</div>
                  <div style={{ color: "#4A148C" }}>
                    {pointInfo.total_point.toLocaleString()}원
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: widthPercentage(7.3125),
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <HomeButton
                  width={widthPercentage(40)}
                  height={widthPercentage(12)}
                  handleClick={() => setOpenComplete(false)}
                >
                  확인
                </HomeButton>
              </div>
            </div>
          </div>
        </Hidden>
      </HomeDialog>
    </Fragment>
  );
}

function ProgramReviewWriteComponent() {
  const { communityData, refresh } = useContext(CommunityContext);
  const [programId, setProgramId] = useState<number>(0);
  return (
    <Fragment>
      <div
        style={{
          backgroundColor: "#4A148C",
          height: widthPercentage(isMobile() ? 86.75 : 21.6875),
        }}
      >
        <HomeContainer>
          <div
            style={{
              paddingTop: widthPercentage(isMobile() ? 10 : 5),
              color: "white",
            }}
          >
            <ProgramReviewWriteHeader />
          </div>
        </HomeContainer>
      </div>
      <HomeContainer>
        <div
          style={{
            marginTop: -widthPercentage(isMobile() ? 37.5 : 4.6875),
            backgroundColor: "white",
          }}
        >
          <ProgramReviewWriteBody
            programs={communityData.community_programs}
            refresh={refresh}
            programId={programId}
            setProgramId={setProgramId}
          />
        </div>
      </HomeContainer>
    </Fragment>
  );
}

function BestReviewComponent() {
  const { communityData } = useContext(CommunityContext);
  return (
    <HomeContainer>
      <div
        className="font-noto-medium"
        style={{
          paddingTop: widthPercentage(isMobile() ? 21.5 : 5),
          fontSize: widthPercentage(isMobile() ? 6.75 : 2.25),
        }}
      >
        {isMobile() ? (
          <Fragment>
            많은 분들에게
            <br />
            도움이 된 <span style={{ color: "#4A148C" }}>베스트 후기</span>를
            <br />
            만나보세요!
          </Fragment>
        ) : (
          <Fragment>
            많은 분들에게 도움이 된{" "}
            <span style={{ color: "#4A148C" }}>베스트 후기</span>를 만나보세요!
          </Fragment>
        )}
      </div>
      <div
        style={{
          marginTop: widthPercentage(isMobile() ? 11 : 2.5),
          marginBottom: widthPercentage(isMobile() ? 22 : 7.375),
        }}
      >
        <Hidden smDown>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {communityData.best_reviews.map((best_review) => (
              <div key={best_review.program_review_id}>
                <ProgramCard
                  program={{
                    id: best_review.program_id,
                    image: best_review.program_image,
                    program_name: best_review.program_name,
                    program_description: best_review.program_description,
                  }}
                  index={-1}
                  width={widthPercentage(26.875)}
                  review={{
                    ...best_review,
                    tutor_ids: best_review.program_tutor_ids,
                    image: best_review.program_image,
                  }}
                  badge="BEST"
                />
              </div>
            ))}
          </div>
        </Hidden>
        <Hidden smUp>
          <HomeSwiper
            slides={1}
            childWidth={widthPercentage(92)}
            buttonWidth={widthPercentage(8)}
            buttonHeight={widthPercentage(12)}
            offsetTop={widthPercentage(15.375)}
          >
            {communityData.best_reviews.map((best_review) => (
              <div key={best_review.program_review_id}>
                <ProgramCard
                  program={{
                    id: best_review.program_id,
                    image: best_review.program_image,
                    program_name: best_review.program_name,
                    program_description: best_review.program_description,
                  }}
                  index={-1}
                  width={widthPercentage(92)}
                  review={{
                    ...best_review,
                    tutor_ids: best_review.program_tutor_ids,
                    image: best_review.program_image,
                  }}
                  badge="BEST"
                />
              </div>
            ))}
          </HomeSwiper>
        </Hidden>
      </div>
    </HomeContainer>
  );
}

interface CommunityContextProps {
  refresh: () => void;
  communityData: CommunityData;
  setSubmittingForm: (searchForm: SearchForm) => void;
  setPage: (page: number) => void;
  page: number;
}

interface ProgramType {
  id: number;
  label: string;
}

const CommunityContext = React.createContext({} as CommunityContextProps);

interface CommunityProgram {
  id: number;
  program_header: string;
  program_name: string;
  program_image: string;
  dates: string[];
  tutor_names: string[];
}

interface CommunityData {
  best_reviews: AdminUserReview[];
  program_types: ProgramType[];
  user_reviews: AdminUserReview[];
  total_pages: number;
  community_programs: CommunityProgram[];
}

function CommunityBanner() {
  return <GenericBannerComponent bannerType="community" />;
}

function Community() {
  const [render, setRender] = useState<number>(0);
  const [communityData, setCommunityData] = useState<CommunityData | null>(
    null
  );
  const [submittingForm, setSubmittingForm] = useState<SearchForm>(initForm);
  const [page, setPage] = useState<number>(1);
  const pageAmount = 8;

  useEffect(() => {
    fetchDefaultWithCredential(
      `/community?program_type=${submittingForm.program_type}&program_period=${submittingForm.program_period}&order_by=${submittingForm.order_by}&page=${page}&amount=${pageAmount}`,
      "GET"
    )
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setCommunityData);
  }, [render, submittingForm, page]);
  if (!communityData) return null;
  return (
    <div style={{ userSelect: "none" }}>
      <CommunityContext.Provider
        value={{
          refresh: () => setRender(render + 1),
          communityData,
          setSubmittingForm,
          setPage,
          page,
        }}
      >
        <CommunityBanner />
        <BestReviewComponent />
        <ProgramReviewWriteComponent />
        <SearchComponent />
      </CommunityContext.Provider>
    </div>
  );
}

export default Community;
