import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchDefaultWithCredential, formatProgramDate } from "../../../utils";
import { CustomAvatarWithLocation } from "../../customs/CustomAvatar";
import CustomSelect from "../../customs/CustomSelect";
import { ProgramStatus, ProgramStatusLabel } from "../../Program";
import SortComponent from "./helpers/SortComponent";

interface UserInfo {
  id: number;
  created_at: string;
  name: string;
  image: string;
  email: string;
  new: boolean;
  registered: boolean;
  registered_at: string;
  cart: boolean;
  carted_at: string;
  waiting: boolean;
  capture_agreement: boolean;
  marketing_info: boolean;
}

interface ProgramInfo {
  id: number;
  program_image: string;
  program_header: string;
  program_name: string;
  dates: string[];
  price: number;
  registered: number;
  max_people: number;
  status: ProgramStatus;
}

interface ProgramStatusMappedUser {
  [programStatus: string]: ProgramInfo[];
}

interface ProgramVisualizeComponentProps {
  programs: ProgramInfo[];
  selectedProgram: ProgramInfo | null;
  setSelectedProgram: (selectedProgram: ProgramInfo | null) => void;
}

interface ProgramVisualizeProps {
  program: ProgramInfo;
}

interface ProgramVisualizeInnerComponentProps {
  program_image: string;
  program_header: string;
  program_name: string;
  dates: string[];
  price: number;
  registered?: number;
  status: ProgramStatus;
  max_people: number;
}

export function ProgramVisualizeInnerComponent({
  program_image,
  program_header,
  program_name,
  dates,
  price,
  registered,
  status,
  max_people,
}: ProgramVisualizeInnerComponentProps) {
  return (
    <Grid container>
      <Grid item xs className="d-flex">
        <img
          src={program_image}
          alt=""
          draggable={false}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid
        item
        xs
        className="p-5 text-align-center font-10"
        style={{ backgroundColor: "rgb(241, 241, 250)" }}
      >
        <div className="font-13 font-bold">{program_header}</div>
        <div className="mt-5 font-11 font-bold">{program_name}</div>
        <div className="mt-5">{formatProgramDate(dates)}</div>
        <div className="mt-5">{price.toLocaleString()}원</div>
        <div className="mt-5">정원: {max_people}명</div>
        {registered !== undefined && (
          <div className="mt-5 text-red">
            {registered} / {max_people} 모집됨
          </div>
        )}
        <div className="mt-5">{ProgramStatusLabel(status)}</div>
      </Grid>
    </Grid>
  );
}

function ProgramVisualize({ program }: ProgramVisualizeProps) {
  return <ProgramVisualizeInnerComponent {...program} />;
}

function ProgramVisualizeComponent({
  programs,
  selectedProgram,
  setSelectedProgram,
}: ProgramVisualizeComponentProps) {
  return (
    <SortComponent
      currentDataObject={selectedProgram}
      setCurrentDataObject={setSelectedProgram}
      label="프로그램"
      data={programs}
      listItemComponent={(program) => <ProgramVisualize program={program} />}
      abcSortAttributeFunc={(program) => ""}
      showMenu={false}
    />
  );
}

interface UserVisualizeProps {
  user: UserInfo;
  capture_agreement?: boolean;
}

function UserVisualize({
  user,
  capture_agreement = false,
}: UserVisualizeProps) {
  return (
    <Grid container className="text-align-center">
      <Grid item xs>
        <div className="d-flex justify-content-center">
          <div className="width-50 height-50">
            <CustomAvatarWithLocation location={user.image} />
          </div>
        </div>
        <div className="font-14">
          <div className="mt-10">{user.name}</div>
        </div>
      </Grid>
      <Grid item xs>
        {user.new && <span className="p-5 background-red text-white">NEW</span>}
        {capture_agreement && (
          <p className="font-10">
            {user.capture_agreement ? "얼굴 촬영 (O)" : "얼굴 촬영 (X)"}
          </p>
        )}
        <p className="font-10">
          마케팅 동의
          {user.marketing_info ? " (O)" : " (X)"}
        </p>
        {user.registered && <p className="font-12">{user.registered_at}</p>}
        {user.cart && <p className="font-12">{user.carted_at}</p>}
      </Grid>
    </Grid>
  );
}

interface UsersProps {
  users: UserInfo[];
}

function RegisteredUserComponent({ users }: UsersProps) {
  const newCount = users.filter((user) => user.new).length;
  return (
    <SortComponent
      currentDataObject={null}
      setCurrentDataObject={() => null}
      label={`구매 인원 NEW (${newCount}) / 총`}
      data={users}
      listItemComponent={(user) => (
        <UserVisualize user={user} capture_agreement />
      )}
      abcSortAttributeFunc={(user) => user.name}
    />
  );
}

function CartUserComponent({ users }: UsersProps) {
  return (
    <SortComponent
      currentDataObject={null}
      setCurrentDataObject={() => null}
      label="장바구니 인원"
      data={users}
      listItemComponent={(user) => <UserVisualize user={user} />}
      abcSortAttributeFunc={(user) => user.name}
    />
  );
}

function WaitingUserComponent({ users }: UsersProps) {
  return (
    <SortComponent
      currentDataObject={null}
      setCurrentDataObject={() => null}
      label="대기신청 인원"
      data={users}
      listItemComponent={(user) => <UserVisualize user={user} />}
      abcSortAttributeFunc={(user) => user.name}
    />
  );
}

interface UserComponentProps {
  programId: number;
}

function UserComponent({ programId }: UserComponentProps) {
  const [users, setUsers] = useState<UserInfo[] | null>(null);
  useEffect(() => {
    fetchDefaultWithCredential(`/program/${programId}/user/infos`, "GET").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            if (res.status !== 400) throw new Error(error);
            return;
          });
        }
        return res.json().then(setUsers);
      }
    );
  }, [programId]);
  if (!users) return null;
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs>
        <RegisteredUserComponent
          users={users.filter((user) => user.registered)}
        />
      </Grid>
      <Grid item xs>
        <CartUserComponent users={users.filter((user) => user.cart)} />
      </Grid>
      <Grid item xs>
        <WaitingUserComponent users={users.filter((user) => user.waiting)} />
      </Grid>
    </Grid>
  );
}

function AdminPageTransactions() {
  const [programStatusMappedUser, setProgramStatusMappedUser] =
    useState<ProgramStatusMappedUser | null>(null);
  const [programStatus, setProgramStatus] = useState<ProgramStatus>("all");
  const [selectedProgram, setSelectedProgram] = useState<ProgramInfo | null>(
    null
  );
  useEffect(() => {
    fetchDefaultWithCredential(`/program/statuses`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setProgramStatusMappedUser);
  }, []);

  if (!programStatusMappedUser) return null;
  return (
    <div className="p-30">
      <Grid container columnSpacing={2}>
        <Grid item sm={3} xs={12}>
          <CustomSelect
            fullWidth
            label="프로그램 진행상황"
            name="program_status"
            value={programStatus}
            items={(
              ["all", "progress", "almost", "done"] as ProgramStatus[]
            ).map((programStatus) => ({
              label: ProgramStatusLabel(programStatus),
              value: programStatus,
            }))}
            handleChange={(value) => setProgramStatus(value as ProgramStatus)}
          />
          <div className="mt-10">
            <ProgramVisualizeComponent
              programs={programStatusMappedUser[programStatus]}
              selectedProgram={selectedProgram}
              setSelectedProgram={setSelectedProgram}
            />
          </div>
        </Grid>
        <Grid item sm={9} xs={12}>
          {selectedProgram && <UserComponent programId={selectedProgram.id} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminPageTransactions;
