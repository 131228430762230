import React, { useEffect, useState } from "react";
import { fetchDefaultWithCredential } from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomDialog from "../../customs/CustomDialog";
import CustomText from "../../customs/CustomText";
import { usePrivacyAgreement } from "../../Policies";

interface FormProps {
  agreement: string;
  privacy: string;
}

function AdminPageAgreementPrivacies() {
  const privacyAgreement = usePrivacyAgreement();
  const [form, setForm] = useState<FormProps>({
    agreement: "",
    privacy: "",
  });
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const target = e.target as HTMLInputElement;
    setForm({
      ...form,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e: any) => {
    fetchDefaultWithCredential("/privacy-agreement", "PUT", form)
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
      })
      .then(() => setOpen(false));
  };

  useEffect(() => {
    if (!privacyAgreement) return;
    setForm({
      agreement: privacyAgreement.agreement.content,
      privacy: privacyAgreement.privacy.content,
    });
  }, [privacyAgreement]);

  if (!privacyAgreement) return null;
  return (
    <div className="p-30">
      <form onBlur={handleChange}>
        <div>
          <CustomText
            label="이용약관"
            name="agreement"
            type="text"
            width="100%"
            height="200px"
            defaultValue={privacyAgreement.agreement.content}
            multiline
          />
        </div>
        <div className="mt-30">
          <CustomText
            label="개인정보처리방침"
            name="privacy"
            type="text"
            width="100%"
            height="200px"
            defaultValue={privacyAgreement.privacy.content}
            multiline
          />
        </div>
        <div className="mt-10 d-flex justify-content-flex-end">
          <CustomButton onClick={() => setOpen(true)}>등록</CustomButton>
        </div>
        <CustomDialog open={open}>
          <p className="font-header">수정하시겠습니까?</p>
          <div className="d-flex justify-content-flex-end">
            <div>
              <CustomButton onClick={handleSubmit}>확인</CustomButton>
            </div>
            <div className="ml-10">
              <CustomButton onClick={() => setOpen(false)}>닫기</CustomButton>
            </div>
          </div>
        </CustomDialog>
      </form>
    </div>
  );
}

export default AdminPageAgreementPrivacies;
