import React from "react";
import Registered from "../../programs/Registered";

function TutorPrograms() {
  return (
    <div className="margin-center" style={{ width: "80%" }}>
      <Registered tutor />
    </div>
  );
}

export default TutorPrograms;
