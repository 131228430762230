import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { postImageFile } from "../../../../hooks/useImages";
import { fetchDefaultWithCredential } from "../../../../utils";
import CustomButton from "../../../customs/CustomButton";
import CustomImageUploadButton from "../../../customs/CustomImageUploadButton";
import CustomText from "../../../customs/CustomText";

interface BrandButton {
  id: string;
  image_id: number;
  image: string;
  top_percentage: number;
  left_percentage: number;
  link: string;
  width_percentage: number;
}

export interface Brand {
  image_id: number;
  image: string;
  buttons: BrandButton[];
  mobile: Brand;
}

export function useBrand() {
  const [brand, setBrand] = useState<Brand | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(`/brand`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setBrand);
    });
  }, [render]);

  return { brand, refresh: () => setRender(render + 1) };
}

interface BrandEditComponentProps {
  label: string;
  brand: Brand;
  editBrand: () => void;
}

function BrandEditComponent({
  label,
  brand,
  editBrand,
}: BrandEditComponentProps) {
  return (
    <div>
      <p className="font-header">{label}</p>
      <div>
        <div>
          <CustomImageUploadButton
            fileChangeCallback={(file) => {
              postImageFile(file).then((image) => {
                brand.image_id = image.id;
                editBrand();
              });
            }}
          >
            브랜드 페이지 이미지 선택
          </CustomImageUploadButton>
        </div>
        <div className="mt-30">
          <div className="width-300">
            <img
              src={brand.image}
              alt=""
              className="width-100-percentage"
              draggable={false}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <CustomButton
          onClick={() => {
            brand.buttons.push({
              id: v4(),
              image_id: 0,
              image: "",
              left_percentage: 0,
              link: "",
              top_percentage: 0,
              width_percentage: 0,
            });
            editBrand();
          }}
        >
          버튼 추가
        </CustomButton>
      </div>
      <div className="mt-10">
        {brand.buttons.map((button) => (
          <div key={button.id} className="pt-10">
            <div>
              <CustomButton
                onClick={() => {
                  brand.buttons = brand.buttons.filter(
                    (iterButton) => iterButton.id !== button.id
                  );
                  editBrand();
                }}
              >
                삭제
              </CustomButton>
            </div>
            <div className="mt-10">
              <div>
                <CustomImageUploadButton
                  fileChangeCallback={(file) => {
                    postImageFile(file).then((image) => {
                      button.image_id = image.id;
                      editBrand();
                    });
                  }}
                >
                  버튼 이미지 선택
                </CustomImageUploadButton>
              </div>
              <div className="mt-10">
                <div className="width-300">
                  <img
                    src={button.image}
                    alt=""
                    className="width-100-percentage"
                    draggable={false}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <CustomText
                label="가로 길이 %"
                name="width_percentage"
                type="number"
                width="100%"
                defaultValue={button.width_percentage || 0}
                handleBlur={(e) => {
                  button.width_percentage = parseFloat(e.target.value) || 0;
                  editBrand();
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="위쪽으로부터의 위치 %"
                name="top_percentage"
                type="number"
                width="100%"
                defaultValue={button.top_percentage || 0}
                handleBlur={(e) => {
                  button.top_percentage = parseFloat(e.target.value) || 0;
                  editBrand();
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="왼쪽으로부터의 위치 %"
                name="left_percentage"
                type="number"
                width="100%"
                defaultValue={button.left_percentage || 0}
                handleBlur={(e) => {
                  button.left_percentage = parseFloat(e.target.value) || 0;
                  editBrand();
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="링크"
                name="link"
                type="text"
                width="100%"
                defaultValue={button.link || ""}
                handleBlur={(e) => {
                  button.link = e.target.value;
                  editBrand();
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function AdminPagesEditsBrand() {
  const { brand, refresh } = useBrand();
  if (!brand) return null;

  const editBrand = () => {
    return fetchDefaultWithCredential(`/brand`, "PUT", brand)
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then(refresh);
  };

  return (
    <div>
      <Grid container columnSpacing={2}>
        <Grid item xs>
          <BrandEditComponent
            label="데스크탑"
            brand={brand}
            editBrand={editBrand}
          />
        </Grid>
        <Grid item xs>
          <BrandEditComponent
            label="모바일"
            brand={brand.mobile}
            editBrand={editBrand}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminPagesEditsBrand;
