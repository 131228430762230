import { Pagination, PaginationItem } from "@mui/material";
import React from "react";
import { isMobile } from "../../utils";
import { widthPercentage } from "../Home";

interface CustomPaginationProps {
  count: number;
  page: number;
  handleChange: (value: number) => void;
}

function CustomPagination({
  count,
  page,
  handleChange,
}: CustomPaginationProps) {
  return (
    <Pagination
      count={count}
      page={page}
      onChange={(_, value) => handleChange(value)}
      color="standard"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          sx={{
            fontSize: widthPercentage(isMobile() ? 3.5 : 0.875),
            borderRadius: "50%",
            padding: 0,
            margin: 0,
          }}
        />
      )}
    />
  );
}

export default CustomPagination;
