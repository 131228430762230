import { Hidden } from "@mui/material";
import React, { Fragment } from "react";
import { Brand as BrandProps, useBrand } from "./admins/pages/edits/Brand";

interface BrandComponentProps {
  brand: BrandProps;
}

function BrandComponent({ brand }: BrandComponentProps) {
  return (
    <div className="d-flex p-relative">
      <img
        src={brand.image}
        alt=""
        className="width-100-percentage"
        draggable={false}
      />
      {brand.buttons.map((button) => (
        <div
          key={button.id}
          className="p-absolute"
          onClick={() => window.open(button.link, "_blank")}
          style={{
            transform: "translate(-50%, -50%)",
            top: `${button.top_percentage}%`,
            left: `${button.left_percentage}%`,
            cursor: "pointer",
            zIndex: 1,
            width: `${button.width_percentage || 0}%`,
          }}
        >
          <img
            src={button.image}
            alt=""
            draggable={false}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </div>
  );
}

function Brand() {
  const { brand } = useBrand();
  if (!brand) return null;
  return (
    <Fragment>
      <Hidden smDown>
        <BrandComponent brand={brand} />
      </Hidden>
      <Hidden smUp>
        <BrandComponent brand={brand.mobile} />
      </Hidden>
    </Fragment>
  );
}

export default Brand;
