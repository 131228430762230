import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { fetchDefaultWithCredential, formatDashedDate } from "../../../utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/styles";
import CustomButton from "../../customs/CustomButton";
import CustomDialog from "../../customs/CustomDialog";
import CustomText from "../../customs/CustomText";
import CustomCheck from "../../customs/CustomCheck";
import RadioForm from "../../customs/RadioForm";
import { SnackbarContext } from "../../../globals/components/ComponentsWrapper";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

interface Transaction {
  id: number;
  created_at: string;
  type: "payment" | "refund";
  user_id: number;
  user_name: string;
  name: string;
  merchant_uid: string;
  amount: number;
  method: "card";
  coupon_point_info?: {
    coupons: {
      title: string;
      value: number;
      type: "default" | "code" | "package" | "select";
      unit: "value" | "percentage";
    }[];
    point: number;
  };
}

interface RefundProgramInfo {
  id: number;
  program_name: string;
  price: number;
  start_date: string;
}

interface TableRowComponentProps {
  transaction: Transaction;
  refresh: () => void;
}

interface Preview {
  total: number;
  point: number;
  coupons_to_reuse: number[];
  point_to_recall: number;
}

function TableRowComponent({ transaction, refresh }: TableRowComponentProps) {
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [method, setMethod] = useState<"auto" | "manual">("auto");
  const [manualRefund, setManualRefund] = useState<number>(0);
  const [requestDate, setRequestDate] = useState<string>(
    formatDashedDate(new Date())
  );
  const [preview, setPreview] = useState<Preview>({
    total: 0,
    point: 0,
    coupons_to_reuse: [],
    point_to_recall: 0,
  });
  const [toRefundPrograms, setToRefundPrograms] = useState<RefundProgramInfo[]>(
    []
  );
  const [programFormIds, setProgramFormIds] = useState<number[]>([]);
  const { showWarningSnackbar } = useContext(SnackbarContext);

  const requestRefund = (
    payment_id: number,
    request_date: string,
    reason: string
  ) => {
    fetchDefaultWithCredential(`/refund`, "POST", {
      ...preview,
      payment_id,
      request_date,
      reason,
      program_ids: programFormIds,
      method,
      manual_refund: manualRefund,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            showWarningSnackbar(message);
          });
        }
        return res.json();
      })
      .then(() => {
        setOpenRefund(false);
        refresh();
      });
  };

  useEffect(() => {
    if (openRefund) {
      fetchDefaultWithCredential(
        `/refund/preview/programs?paymentId=${transaction.id}`,
        "GET"
      )
        .then((res) => {
          if (!res.ok) {
            return res.json().then(({ message }) => {
              throw new Error(message);
            });
          }
          return res.json();
        })
        .then(setToRefundPrograms)
        .then(() => setLoaded(true));
    }
  }, [transaction, openRefund]);

  useEffect(() => {
    if (loaded) {
      fetchDefaultWithCredential(`/refund/preview`, "POST", {
        paymentId: transaction.id,
        requestDate,
        programFormIds,
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then(({ message }) => {
              throw new Error(message);
            });
          }
          return res.json();
        })
        .then(setPreview);
    }
  }, [programFormIds, requestDate, transaction, loaded]);

  return (
    <Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="center">
          {transaction.type === "payment" && (
            <CustomButton onClick={() => setOpenRefund(true)}>
              환불신청
            </CustomButton>
          )}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {new Date(transaction.created_at).toLocaleString()}
        </TableCell>
        <TableCell align="center">
          <span
            style={{
              color: transaction.type === "payment" ? "inherit" : "red",
            }}
          >
            {transaction.type}
          </span>
        </TableCell>
        <TableCell align="center">
          {transaction.user_id}. {transaction.user_name}
        </TableCell>
        <TableCell align="center">{transaction.name}</TableCell>
        <TableCell align="center">{transaction.merchant_uid}</TableCell>
        <TableCell align="center">
          <span
            style={{
              color: transaction.type === "payment" ? "inherit" : "red",
            }}
          >
            {transaction.type === "payment" ? "" : "-"}
            {transaction.amount.toLocaleString()}원
          </span>
        </TableCell>
        <TableCell align="center">{transaction.method}</TableCell>
        <TableCell
          align="center"
          style={{ textAlign: "start", width: "200px" }}
        >
          {(() => {
            const coupon_point_info = transaction.coupon_point_info;
            if (!coupon_point_info) return null;
            const res = [];
            for (let i = 0; i < coupon_point_info.coupons.length; i++) {
              const obj = coupon_point_info.coupons[i];
              res.push(
                <div key={i}>
                  <div>쿠폰명: {obj.title}</div>
                  <div>
                    쿠폰종류:{" "}
                    {(() => {
                      const type = obj.type;
                      if (type === "default") {
                        return "등록";
                      }
                      if (type === "code") {
                        return "코드";
                      }
                      if (type === "package") {
                        return "패키지";
                      }
                      if (type === "select") {
                        return "얼리버드";
                      }
                      return "";
                    })()}
                  </div>
                  <div>
                    할인:{" "}
                    {(() => {
                      const value = obj.value;
                      let unit = "원";
                      if (obj.unit === "percentage") {
                        unit = "%";
                      }
                      return `${value.toLocaleString()}${unit}`;
                    })()}
                  </div>
                  <div>------------------------</div>
                </div>
              );
            }
            res.push(
              <div key={-1}>
                적립금: {coupon_point_info.point.toLocaleString()}원
              </div>
            );
            return res;
          })()}
        </TableCell>
      </TableRow>
      <CustomDialog open={openRefund} maxWidth="sm" fullWidth>
        <div className="d-flex justify-content-space-between">
          <p className="font-18 font-bold">
            {method === "auto"
              ? preview.total.toLocaleString()
              : manualRefund.toLocaleString()}
            원 환불 예정
          </p>
          <p className="font-18 font-bold">
            적립금 {preview.point.toLocaleString()}원 환불 예정
          </p>
        </div>
        <div className="d-flex justify-content-flex-end">
          <p className="font-18 font-bold">
            적립금 {preview.point_to_recall.toLocaleString()}원 차감 예정
          </p>
        </div>
        <CustomCheck
          label=""
          checkItems={toRefundPrograms.map((toRefundProgram) => ({
            id: toRefundProgram.id,
            label: `${
              toRefundProgram.program_name
            } (${toRefundProgram.price.toLocaleString()}원) (${new Date(
              toRefundProgram.start_date
            ).toLocaleString()} 시작)`,
            checked: programFormIds.includes(toRefundProgram.id),
          }))}
          handleChange={(id: number, checked: boolean) => {
            if (checked) {
              setProgramFormIds([...programFormIds, id]);
            } else {
              setProgramFormIds(
                programFormIds.filter((programFormId) => programFormId !== id)
              );
            }
          }}
        />
        <div>
          <RadioForm
            name="method"
            label="환불 방법"
            radios={[
              {
                label: "자동",
                value: "auto",
              },
              {
                label: "수동",
                value: "manual",
              },
            ]}
            value={method}
            handleChange={(e) => {
              setMethod(e.target.value as "auto" | "manual");
            }}
            row
          />
        </div>
        {method === "manual" && (
          <CustomText
            label="환불 금액"
            name="amount"
            type="number"
            width="100%"
            handleBlur={(e) => setManualRefund(parseInt(e.target.value) || 0)}
          />
        )}
        <div>
          <p className="font-14 font-bold">환불 신청 날짜</p>
          <input
            type="date"
            value={requestDate}
            onChange={(e) => setRequestDate(e.target.value)}
            className="width-100-percentage"
          />
        </div>
        <CustomText
          label="환불사유"
          name="reason"
          type="text"
          width="100%"
          multiline
          height="100px"
          handleBlur={(e) => setReason(e.target.value)}
        />
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CustomButton
              onClick={() => requestRefund(transaction.id, requestDate, reason)}
              disabled={
                method === "auto"
                  ? preview.total === 0 && preview.point === 0
                  : manualRefund === 0
              }
            >
              환불하기
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton onClick={() => setOpenRefund(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
    </Fragment>
  );
}

function AdminPageTransactionDetails() {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [render, setRender] = useState(0);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactions.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchDefaultWithCredential(`/transactions`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json();
      })
      .then(setTransactions);
  }, [render]);

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">결제일시</TableCell>
              <TableCell align="center">거래상태</TableCell>
              <TableCell align="center">회원ID. 회원명</TableCell>
              <TableCell align="center">상품명</TableCell>
              <TableCell align="center">상품 주문 번호</TableCell>
              <TableCell align="center">결제/환불금액</TableCell>
              <TableCell align="center">결제/환불수단</TableCell>
              <TableCell align="center">쿠폰/포인트 사용</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? transactions.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : transactions
            ).map((transaction, index) => (
              <TableRowComponent
                key={index}
                transaction={transaction}
                refresh={() => setRender(render + 1)}
              />
            ))}
          </TableBody>
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell align="center" colSpan={8} />
            </TableRow>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={8}
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
}

export default AdminPageTransactionDetails;
