import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { putObjectFromList, removeObjectFromList } from "../../../hooks";
import {
  fetchDefaultWithCredential,
  formatDateTemplate,
  formatTimeTemplate,
} from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomCheckbox from "../../customs/CustomCheckbox";
import CustomDialog from "../../customs/CustomDialog";
import CustomText from "../../customs/CustomText";
import { Policy } from "../../Policies";

function usePolicies() {
  const [policies, setPolicies] = useState<Policy[]>([]);

  useEffect(() => {
    fetchDefaultWithCredential("/policies", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setPolicies);
    });
  }, []);

  const deletePolicy = (policyId: number) => {
    return fetchDefaultWithCredential(`/policies/${policyId}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res
          .json()
          .then((data) => setPolicies(removeObjectFromList(policies, data.id)));
      }
    );
  };

  const patchPolicyRead = (policyId: number, read: boolean) => {
    return fetchDefaultWithCredential(`/policies/${policyId}/read`, "PATCH", {
      read,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((data) => setPolicies(putObjectFromList(policies, data)));
  };

  return { policies, deletePolicy, patchPolicyRead };
}

function AdminPagePolicies() {
  const { policies, deletePolicy, patchPolicyRead } = usePolicies();
  const [deleteId, setDeleteId] = useState<number>(0);

  return (
    <div className="p-30">
      {policies.map((policy, index) => (
        <Card key={index} style={{ marginBottom: "50px", padding: "15px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <p className="mt-10 mb-10 font-sub">
                {`${formatDateTemplate(
                  policy.created_at!
                )} ${formatTimeTemplate(policy.created_at!)}`}
              </p>
            </Grid>
            <Grid item>
              <div className="d-flex align-items-center">
                <CustomCheckbox
                  checked={policy.read}
                  handleChange={(checked) => {
                    patchPolicyRead(policy.id!, checked);
                  }}
                >
                  읽음표시
                </CustomCheckbox>
                <div className="ml-10">
                  <CustomButton onClick={() => setDeleteId(policy.id!)}>
                    삭제
                  </CustomButton>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CustomText
                style={{ backgroundColor: "white" }}
                label="회사(기관)명"
                name="company_name"
                type="text"
                width="100%"
                readOnly
                defaultValue={policy.company_name}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomText
                style={{ backgroundColor: "white" }}
                label="성함/직책"
                name="name_position"
                type="text"
                width="100%"
                readOnly
                defaultValue={policy.name_position}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomText
                style={{ backgroundColor: "white" }}
                label="연락처"
                name="phone"
                type="text"
                width="100%"
                readOnly
                defaultValue={policy.phone}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomText
                style={{ backgroundColor: "white" }}
                label="이메일"
                name="email"
                type="email"
                width="100%"
                readOnly
                defaultValue={policy.email}
              />
            </Grid>
            <Grid item xs={12} className="mt-30">
              <CustomText
                style={{ backgroundColor: "white" }}
                label="내용"
                width="100%"
                height="200px"
                type="text"
                name="content"
                multiline
                readOnly
                defaultValue={policy.content}
              />
            </Grid>
            {policy.file && (
              <Grid item className="mt-10" style={{ marginLeft: "auto" }}>
                <CustomButton>
                  <a
                    href={policy.file.location}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    첨부파일
                  </a>
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Card>
      ))}
      <CustomDialog open={deleteId !== 0} handleClose={() => setDeleteId(0)}>
        <p className="font-header">정말로 삭제하시겠습니까?</p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton
              onClick={() => {
                deletePolicy(deleteId);
                setDeleteId(0);
              }}
            >
              삭제
            </CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setDeleteId(0)}>닫기</CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
    </div>
  );
}

export default AdminPagePolicies;
