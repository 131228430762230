import { Collapse } from "@mui/material";
import React, { Fragment, useState, ReactNode } from "react";
import CustomButton from "./customs/CustomButton";
import { formClass } from "../utils";
import "./faq.scss";
import { useQuestionaires } from "./admins/pages/Questionaires";
interface VisualComponentProps {
  header: string;
  subheader: string;
  link?: string;
}

export function VisualComponent({
  header,
  subheader,
  link,
}: VisualComponentProps) {
  return (
    <Fragment>
      <p className="font-header">{header}</p>
      <p
        className="mt-30 font-sub whitespace-preline outline-with-border p-30"
        style={{ maxHeight: "500px", overflow: "scroll" }}
      >
        {subheader}
      </p>
    </Fragment>
  );
}

interface ClickableCollapseProps {
  question: string;
  children: ReactNode;
}

function ClickableCollapse({ question, children }: ClickableCollapseProps) {
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <div className="question-container" onClick={() => setToggle(!toggle)}>
      <p
        className={formClass([
          "font-sub",
          "question",
          toggle ? "toggle" : null,
        ])}
      >
        {question}
      </p>
      <Collapse in={toggle}>
        <p className="font-sub whitespace-preline">{children}</p>
      </Collapse>
    </div>
  );
}

function FAQ() {
  const { questionaires } = useQuestionaires();

  return (
    <div className="p-50">
      <div className="d-flex justify-content-space-between align-items-center m-block">
        <p className="font-header">
          실시간 답변이 필요하신 경우, 문의하기 버튼을 클릭해주세요.
        </p>
        <div className="m-center">
          <a
            className="text-decoration-none"
            href="http://pf.kakao.com/_xnsACT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomButton outlined style={{ marginLeft: "auto" }}>
              문의하기
            </CustomButton>
          </a>
        </div>
      </div>
      <div className="mt-50">
        <p className="font-header">자주 찾는 질문 FAQ</p>
        {questionaires
          .filter(
            (questionaire) =>
              questionaire.type === "faq" && questionaire.order_by !== 0
          )
          .map((questionaire, index) => (
            <div className="mt-30" key={index}>
              <ClickableCollapse question={questionaire.question}>
                {questionaire.answer}
              </ClickableCollapse>
            </div>
          ))}
      </div>
      <div className="mt-50">
        <p className="font-header">환불 정책</p>
        {questionaires
          .filter(
            (questionaire) =>
              questionaire.type === "refund" && questionaire.order_by !== 0
          )
          .map((questionaire, index) => (
            <div className="mt-30" key={index}>
              <ClickableCollapse question={questionaire.question}>
                {questionaire.answer}
              </ClickableCollapse>
            </div>
          ))}
      </div>
    </div>
  );
}

export default FAQ;
