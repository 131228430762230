import React, { Fragment, useState } from "react";
import { UserPopupProgress, useUser } from "../user";
import { ReactComponent as PopupImage } from "../../svg/popup_image.svg";
import { useHistory, useLocation } from "react-router-dom";
import { HomeButton, HomeDialog, widthPercentage } from "../../components/Home";
import { Hidden } from "@mui/material";
import { fetchDefaultWithCredential } from "../../utils";

interface UserPopupDialogProps {
  popup_progress: UserPopupProgress;
}

function UserPopupDialog({ popup_progress }: UserPopupDialogProps) {
  const user = useUser();
  const [open, setOpen] = useState<boolean>(true);
  const history = useHistory();

  const handleClose = () => {
    fetchDefaultWithCredential(
      `/popups/template/${popup_progress.template.id}/closed`,
      "PATCH",
      {
        program_id: popup_progress.program_id,
      }
    ).then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(() => setOpen(false));
    });
  };
  if (!user) return null;
  return (
    <HomeDialog open={open} handleClose={handleClose}>
      <Hidden smDown>
        <div
          style={{
            width: widthPercentage(68.125),
            userSelect: "none",
            WebkitUserSelect: "none",
            color: "#111111",
            textAlign: "center",
            padding: `${widthPercentage(10)}px 0`,
          }}
          className="font-noto-medium"
        >
          <div style={{ fontSize: widthPercentage(1.125) }}>
            {popup_progress.condition === "first_login" && `${user.name}님`}
            {popup_progress.condition === "program_satisfaction" &&
              `${popup_progress.program!.program_header} - ${
                popup_progress.program!.program_name
              }`}
          </div>
          <div style={{ fontSize: widthPercentage(2) }}>
            {popup_progress.template.title}
          </div>
          <div
            style={{
              marginTop: widthPercentage(2.5),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PopupImage
              style={{
                width: widthPercentage(11.25),
                height: widthPercentage(11.25),
              }}
            />
          </div>
          <div
            style={{
              marginTop: widthPercentage(3),
              color: "#767676",
              fontSize: widthPercentage(0.9375),
              whiteSpace: "pre-line",
            }}
          >
            {popup_progress.template.description}
          </div>
          {popup_progress.template.benefit_type !== "none" &&
            (() => {
              const benefitType = popup_progress.template.benefit_type;
              let visualName = "";
              if (benefitType === "coupon") {
                if (
                  popup_progress.template.coupon_id !== 0 &&
                  popup_progress.template.coupon_name
                )
                  visualName = popup_progress.template.coupon_name;
              }
              if (benefitType === "point") {
                visualName =
                  popup_progress.template.point.toLocaleString() + "원 적립금";
              }
              if (visualName === "") return null;
              return (
                <div
                  style={{
                    marginTop: widthPercentage(1.25),
                    color: "#767676",
                    fontSize: widthPercentage(0.9375),
                  }}
                >
                  <span style={{ color: "#4A148C" }}>{visualName}</span>도 즉시
                  드릴게요 :)
                </div>
              );
            })()}
          <div
            style={{
              marginTop: widthPercentage(3),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HomeButton
              width={widthPercentage(10)}
              handleClick={() =>
                history.push(
                  `/popups/template/${popup_progress.template.id}${
                    popup_progress.program_id
                      ? `?program_id=${popup_progress.program_id}`
                      : ""
                  }`
                )
              }
            >
              시작하기
            </HomeButton>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            color: "#111111",
            textAlign: "center",
            padding: `0 ${widthPercentage(9)}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          className="font-noto-medium"
        >
          <div>
            <div style={{ fontSize: widthPercentage(3.75) }}>
              {popup_progress.condition === "first_login" && `${user.name}님`}
            </div>
            <div
              style={{
                marginTop: widthPercentage(1),
                fontSize: widthPercentage(5),
              }}
            >
              {popup_progress.template.title}
            </div>
            <div
              style={{
                marginTop: widthPercentage(9),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PopupImage
                style={{
                  width: widthPercentage(40),
                  height: widthPercentage(40),
                }}
              />
            </div>
            <div
              style={{
                marginTop: widthPercentage(9),
                color: "#767676",
                fontSize: widthPercentage(3.75),
                whiteSpace: "pre-line",
              }}
            >
              {popup_progress.template.description}
            </div>
            {popup_progress.template.benefit_type !== "none" &&
              (() => {
                const benefitType = popup_progress.template.benefit_type;
                let visualName = "";
                if (benefitType === "coupon") {
                  if (
                    popup_progress.template.coupon_id !== 0 &&
                    popup_progress.template.coupon_name
                  )
                    visualName = popup_progress.template.coupon_name;
                }
                if (benefitType === "point") {
                  visualName =
                    popup_progress.template.point.toLocaleString() +
                    "원 적립금";
                }
                if (visualName === "") return null;
                return (
                  <div
                    style={{
                      marginTop: widthPercentage(3),
                      color: "#767676",
                      fontSize: widthPercentage(3.5),
                    }}
                  >
                    <span style={{ color: "#4A148C" }}>{visualName}</span>도
                    즉시 드릴게요 :)
                  </div>
                );
              })()}
            <div
              style={{
                marginTop: widthPercentage(8),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <HomeButton
                width={widthPercentage(40)}
                height={widthPercentage(12)}
                handleClick={() =>
                  history.push(
                    `/popups/template/${popup_progress.template.id}${
                      popup_progress.program_id
                        ? `?program_id=${popup_progress.program_id}`
                        : ""
                    }`
                  )
                }
              >
                시작하기
              </HomeButton>
            </div>
          </div>
        </div>
      </Hidden>
    </HomeDialog>
  );
}

function UserPopupDialogComponent() {
  const user = useUser();
  const location = useLocation();
  if (!user || location.pathname.startsWith("/popups/template/")) return null;
  return (
    <Fragment>
      {user.popup_progresses.reverse().map((popup_progress, index) => (
        <UserPopupDialog key={index} popup_progress={popup_progress} />
      ))}
    </Fragment>
  );
}

export default UserPopupDialogComponent;
