import {
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { Fragment } from "react";
import CustomAvatar from "../../../customs/CustomAvatar";
import { useUsers } from "../../../../hooks";
import SortComponent from "./SortComponent";
import { CSVLink } from "react-csv";
import { day, formatDashedDate } from "../../../../utils";
import { User } from "../../../../globals/user";

export function timeDiff(a: Date, b: Date) {
  return a.getTime() - b.getTime();
}

interface UserListViewProps {
  user: User;
  showNew?: boolean;
}

export function UserListView({ user, showNew = true }: UserListViewProps) {
  return (
    <Fragment>
      <ListItemAvatar>
        <div className="width-40 height-40">
          <CustomAvatar user={user} />
        </div>
      </ListItemAvatar>
      <ListItemText
        primary={`${user.id}. ${user.name}`}
        secondary={user.email}
      />
      {showNew &&
        timeDiff(new Date(), new Date(user.created_at!)) <= 30 * day && (
          <ListItemSecondaryAction>
            <span
              style={{
                backgroundColor: "#ff5a6e",
                padding: "10px",
                color: "white",
                userSelect: "none",
              }}
            >
              N
            </span>
          </ListItemSecondaryAction>
        )}
    </Fragment>
  );
}

interface PropType {
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
  filter?: (user: User) => boolean;
  showCSV?: boolean;
}

function UserList({
  currentUser,
  setCurrentUser,
  filter,
  showCSV = false,
}: PropType) {
  let { users } = useUsers();
  if (filter) {
    users = users.filter(filter);
  }

  const csvLabelKeyHeader = [
    { label: "아이디", key: "id" },
    { label: "생성일", key: "created_at" },
    { label: "이메일", key: "email" },
    { label: "이름", key: "name" },
    { label: "성별", key: "gender" },
    { label: "생년월일", key: "date_of_birth" },
    { label: "직업", key: "job" },
    { label: "전화번호", key: "phone" },
    { label: "마지막 로그인 날짜", key: "last_signed_in" },
    { label: "역할", key: "role" },
    { label: "비활성화 날짜", key: "deactivated" },
    { label: "가입 경로", key: "meta.how_philous" },
    { label: "개인정보 활용 동의", key: "meta.private_info" },
    { label: "마케팅정보 활용 동의", key: "meta.marketing_info" },
  ];

  return (
    <div className="user-list-container">
      {showCSV && (
        <div className="download-export d-flex justify-content-flex-end">
          <CSVLink
            headers={csvLabelKeyHeader}
            data={users}
            target="_blank"
            filename={`userlist-${formatDashedDate(new Date())}.csv`}
          >
            <img src="/assets/excel.png" alt="" style={{ width: "50px" }} />
          </CSVLink>
        </div>
      )}
      <div className="user-list-component">
        <SortComponent
          currentDataObject={currentUser}
          setCurrentDataObject={setCurrentUser}
          label="고객명단"
          data={users}
          listItemComponent={(user) => <UserListView user={user} showNew />}
          abcSortAttributeFunc={(user) => user.name}
        />
      </div>
    </div>
  );
}

export default UserList;
