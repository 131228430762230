import { Card, Grid } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { fetchDefaultWithCredential } from "../../../../utils";
import CustomButton from "../../../customs/CustomButton";
import CustomText from "../../../customs/CustomText";
import { Coupon, CouponList, useCouponGroups } from "../Coupons";

type RecommendType = "recommender" | "recommendee" | "tutor_recommendee";

interface RegistrationComponentProps {
  title: string;
  type: RecommendType;
}

interface InfoComponentProps {
  info: RecommendDetailedInfo;
}

function InfoComponent({ info }: InfoComponentProps) {
  const { coupons } = useContext(RecommendContext);
  return (
    <Fragment>
      {info.coupon_id !== undefined && (
        <div className="font-bold">
          쿠폰 적용:{" "}
          {(() => {
            const couponFound = coupons.find(
              (coupon) => coupon.id === info.coupon_id
            );
            if (!couponFound) return "";
            return couponFound.title;
          })()}
        </div>
      )}
      {info.point !== undefined && (
        <div className="font-bold">적립금 적용: {info.point}원</div>
      )}
    </Fragment>
  );
}

function RegistrationComponent({ title, type }: RegistrationComponentProps) {
  const { coupon_id, point, refresh, recommendInfo } =
    useContext(RecommendContext);

  const handleRegister = (register?: string) => {
    fetchDefaultWithCredential(`/recommend`, "POST", {
      type,
      register,
      coupon_id,
      point,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(refresh);
    });
  };

  return (
    <Card elevation={0} className="p-10" variant="outlined">
      <div className="font-bold">{title}</div>
      <div className="mt-10 d-flex justify-content-space-between">
        <CustomButton onClick={() => handleRegister("coupon")}>
          쿠폰 등록
        </CustomButton>
        <CustomButton onClick={() => handleRegister("point")}>
          적립금 등록
        </CustomButton>
        <CustomButton onClick={() => handleRegister()}>해제</CustomButton>
      </div>
      <div className="mt-10">
        <InfoComponent info={recommendInfo[type]} />
      </div>
    </Card>
  );
}

interface RecommendDetailedInfo {
  coupon_id?: number;
  point?: number;
}

interface RecommendInfo {
  [key: string]: RecommendDetailedInfo;
}

interface RecommendContextProps {
  coupon_id: number;
  point: number;
  refresh: () => void;
  recommendInfo: RecommendInfo;
  coupons: Coupon[];
}

const RecommendContext = React.createContext({} as RecommendContextProps);

function AdminPagesRecommender() {
  const { couponGroups } = useCouponGroups();
  const [currentCoupon, setCurrentCoupon] = useState<Coupon | null>(null);
  const [point, setPoint] = useState<number>(0);
  const [render, setRender] = useState<number>(0);
  const [recommendInfo, setRecommendInfo] = useState<RecommendInfo | null>(
    null
  );

  useEffect(() => {
    fetchDefaultWithCredential(`/recommend`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(setRecommendInfo);
    });
  }, [render]);

  if (!couponGroups || !recommendInfo) return null;
  const nonExpiredCoupons = couponGroups.defaults.filter(
    (coupon: Coupon) => !coupon.expired
  );
  return (
    <RecommendContext.Provider
      value={{
        coupon_id: currentCoupon?.id ?? 0,
        point,
        recommendInfo,
        refresh: () => setRender(render + 1),
        coupons: nonExpiredCoupons,
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs>
          <RegistrationComponent title="신규 가입자" type="recommender" />
        </Grid>
        <Grid item xs>
          <RegistrationComponent
            title="추천인 고객 대상자"
            type="recommendee"
          />
        </Grid>
        <Grid item xs>
          <RegistrationComponent
            title="추천인 튜터 대상자"
            type="tutor_recommendee"
          />
        </Grid>
        <Grid item xs>
          <div className="font-bold">등록 쿠폰 리스트 / 적립금</div>
          <div className="mt-10">
            <CouponList
              coupons={nonExpiredCoupons}
              currentCoupon={currentCoupon}
              setCurrentCoupon={setCurrentCoupon}
            />
          </div>
          <div className="mt-10">
            <CustomText
              label="적립금"
              name="point"
              type="number"
              width="100%"
              defaultValue={0}
              style={{ backgroundColor: "white" }}
              handleBlur={(e) => setPoint(parseInt(e.target.value) || 0)}
            />
          </div>
        </Grid>
      </Grid>
    </RecommendContext.Provider>
  );
}

export default AdminPagesRecommender;
