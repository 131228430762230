import { Avatar, Grid, ListItemAvatar, ListItemText } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useTutors } from "../../../hooks";
import { postImageFile } from "../../../hooks/useImages";
import { Tutor } from "../../../interfaces";
import { fetchDefaultWithCredential } from "../../../utils";
import { CustomAvatarWithLocation } from "../../customs/CustomAvatar";
import CustomButton from "../../customs/CustomButton";
import CustomCheckbox from "../../customs/CustomCheckbox";
import CustomDialog from "../../customs/CustomDialog";
import CustomImageUploadButton from "../../customs/CustomImageUploadButton";
import CustomText from "../../customs/CustomText";
import SortComponent from "./helpers/SortComponent";

function TutorInfoComponent() {
  interface TutorInterview {
    id: string;
    question: string;
    answer: string;
  }

  interface TutorHashtag {
    id: string;
    label: string;
  }

  interface TutorInfo {
    id: string;
    image: string;
    tutor_id: number;
    title: string;
    description: string;
    tutor_name: string;
    image1_id: number;
    image1: string;
    image2_id: number;
    image2: string;
    interview_date: string;
    interviews: TutorInterview[];
    hashtags: TutorHashtag[];
  }

  const [tutors, setTutors] = useState<TutorInfo[]>([]);
  const [render, setRender] = useState<number>(0);
  const { tutors: tutorUsers } = useTutors();

  useEffect(() => {
    fetchDefaultWithCredential(`/home/tutors`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(setTutors);
    });
  }, [render]);

  const handleAdd = () => {
    return fetchDefaultWithCredential(`/home/tutors`, "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const handleDelete = (index: number) => {
    return fetchDefaultWithCredential(`/home/tutors/${index}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => setRender(render + 1));
      }
    );
  };

  const handleEdit = (index: number, tutorInfo: TutorInfo) => {
    return fetchDefaultWithCredential(
      `/home/tutors/${index}`,
      "PUT",
      tutorInfo
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const TutorInputComponent = ({
    tutor,
    index,
  }: {
    tutor: TutorInfo;
    index: number;
  }) => {
    const [tutorOpen, setTutorOpen] = useState<boolean>(false);
    const [currentTutor, setCurrentTutor] = useState<Tutor | null>(null);
    return (
      <Fragment>
        <div className="d-flex justify-content-space-between height-100 align-items-flex-end">
          <div className="height-100-percentage d-flex align-items-flex-end">
            <CustomAvatarWithLocation location={tutor.image} />
            <div className="ml-10 font-14 font-bold">{tutor.tutor_name}</div>
          </div>
          <div className="font-14">
            <CustomButton onClick={() => setTutorOpen(true)}>
              튜터 등록
            </CustomButton>
          </div>
        </div>
        <div className="mt-10">
          <Grid container columnSpacing={1}>
            <Grid item xs>
              <img
                src={tutor.image1}
                alt=""
                className="height-100"
                draggable={false}
              />
            </Grid>
            <Grid item>
              <div>
                <CustomImageUploadButton
                  fileChangeCallback={(file) => {
                    postImageFile(file).then((image) => {
                      tutor.image1_id = image.id;
                      handleEdit(index, tutor);
                    });
                  }}
                >
                  표지사진 선택
                </CustomImageUploadButton>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mt-10">
          <Grid container columnSpacing={1}>
            <Grid item xs>
              <img
                src={tutor.image2}
                alt=""
                className="height-100"
                draggable={false}
              />
            </Grid>
            <Grid item>
              <div>
                <CustomImageUploadButton
                  fileChangeCallback={(file) => {
                    postImageFile(file).then((image) => {
                      tutor.image2_id = image.id;
                      handleEdit(index, tutor);
                    });
                  }}
                >
                  팝업사진 선택
                </CustomImageUploadButton>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mt-10">
          <p className="font-14 font-bold">인터뷰 날짜</p>
          <input
            type="date"
            value={tutor.interview_date}
            onChange={(e) => {
              const date = e.target.value;
              tutor.interview_date = date;
              handleEdit(index, tutor);
            }}
            className="width-100-percentage"
          />
        </div>
        <div className="mt-10">
          <CustomText
            label="제목"
            name="title"
            type="text"
            width="100%"
            defaultValue={tutor.title}
            multiline
            height="100px"
            handleBlur={(e) => {
              tutor.title = e.target.value;
              handleEdit(index, tutor);
            }}
          />
        </div>
        <div className="mt-10">
          <CustomText
            label="설명"
            name="description"
            type="text"
            width="100%"
            defaultValue={tutor.description}
            multiline
            height="100px"
            handleBlur={(e) => {
              tutor.description = e.target.value;
              handleEdit(index, tutor);
            }}
          />
        </div>
        <div className="mt-10">
          <CustomButton
            onClick={() => {
              tutor.hashtags.push({
                id: v4(),
                label: "",
              });
              handleEdit(index, tutor);
            }}
          >
            해시태그 추가
          </CustomButton>
          {tutor.hashtags.map((hashtag, hashtagIndex) => (
            <div className="mt-10" key={hashtag.id}>
              <div className="d-flex justify-content-flex-end">
                <CustomButton
                  onClick={() => {
                    tutor.hashtags.splice(hashtagIndex, 1);
                    handleEdit(index, tutor);
                  }}
                >
                  삭제
                </CustomButton>
              </div>
              <div className="mt-10">
                <CustomText
                  label={`해시태그 ${hashtagIndex + 1}`}
                  name="hashtag"
                  type="text"
                  width="100%"
                  defaultValue={hashtag.label}
                  handleBlur={(e) => {
                    hashtag.label = e.target.value;
                    handleEdit(index, tutor);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10">
          <CustomButton
            onClick={() => {
              tutor.interviews.push({
                id: v4(),
                question: "",
                answer: "",
              });
              handleEdit(index, tutor);
            }}
          >
            인터뷰 추가
          </CustomButton>
          {tutor.interviews.map((interview, interviewIndex) => (
            <div className="mt-10" key={interview.id}>
              <div className="d-flex justify-content-flex-end">
                <CustomButton
                  onClick={() => {
                    tutor.interviews.splice(interviewIndex, 1);
                    handleEdit(index, tutor);
                  }}
                >
                  삭제
                </CustomButton>
              </div>
              <div className="mt-10">
                <CustomText
                  label={`질문 ${interviewIndex + 1}`}
                  name="question"
                  type="text"
                  width="100%"
                  defaultValue={interview.question}
                  multiline
                  height="100px"
                  handleBlur={(e) => {
                    interview.question = e.target.value;
                    handleEdit(index, tutor);
                  }}
                />
              </div>
              <div className="mt-10">
                <CustomText
                  label={`답변 ${interviewIndex + 1}`}
                  name="answer"
                  type="text"
                  width="100%"
                  defaultValue={interview.answer}
                  multiline
                  height="100px"
                  handleBlur={(e) => {
                    interview.answer = e.target.value;
                    handleEdit(index, tutor);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <CustomDialog open={tutorOpen}>
          <SortComponent
            currentDataObject={currentTutor}
            setCurrentDataObject={setCurrentTutor}
            label="튜터 명단"
            data={tutorUsers}
            listItemComponent={(tutor: Tutor) => (
              <Fragment>
                <ListItemAvatar>
                  <Avatar>
                    <CustomAvatarWithLocation
                      location={tutor.tutor_avatar ?? ""}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={tutor.tutor_name} />
              </Fragment>
            )}
            abcSortAttributeFunc={(tutor: Tutor) => tutor.tutor_name}
          />
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            className="mt-10"
          >
            <Grid item>
              <CustomButton
                onClick={() => {
                  if (currentTutor) {
                    tutor.tutor_id = currentTutor.id!;
                    handleEdit(index, tutor);
                    setTutorOpen(false);
                  }
                }}
                disabled={!currentTutor}
              >
                선택
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton onClick={() => setTutorOpen(false)}>
                닫기
              </CustomButton>
            </Grid>
          </Grid>
        </CustomDialog>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <p className="font-header">튜터</p>
      <CustomButton onClick={handleAdd}>튜터 추가</CustomButton>
      {tutors.map((tutor, index) => (
        <div className="mt-10" key={tutor.id}>
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDelete(index)}>
              삭제
            </CustomButton>
          </div>
          <div className="mt-10">
            <TutorInputComponent tutor={tutor} index={index} />
          </div>
        </div>
      ))}
    </Fragment>
  );
}

function YoutubeComponent() {
  interface Youtube {
    id: string;
    title1: string;
    title2: string;
    link: string;
  }

  const [youtubes, setYoutubes] = useState<Youtube[]>([]);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(`/home/youtubes`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(setYoutubes);
    });
  }, [render]);

  const handleAdd = () => {
    return fetchDefaultWithCredential(`/home/youtubes`, "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const handleDelete = (index: number) => {
    return fetchDefaultWithCredential(`/home/youtubes/${index}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => setRender(render + 1));
      }
    );
  };

  const handleEdit = (index: number, youtube: Youtube) => {
    return fetchDefaultWithCredential(
      `/home/youtubes/${index}`,
      "PUT",
      youtube
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  return (
    <Fragment>
      <p className="font-header">유튜브</p>
      <CustomButton onClick={handleAdd}>유튜브추가</CustomButton>
      {youtubes.map((youtube, index) => (
        <div className="mt-10" key={youtube.id}>
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDelete(index)}>
              삭제
            </CustomButton>
          </div>
          <div className="mt-10">
            <CustomText
              label="제목 1"
              name="title1"
              type="text"
              width="100%"
              defaultValue={youtube.title1}
              handleBlur={(e) => {
                youtube.title1 = e.target.value;
                handleEdit(index, youtube);
              }}
            />
          </div>
          <div className="mt-10">
            <CustomText
              label="제목 2"
              name="title2"
              type="text"
              width="100%"
              defaultValue={youtube.title2}
              handleBlur={(e) => {
                youtube.title2 = e.target.value;
                handleEdit(index, youtube);
              }}
            />
          </div>
          <div className="mt-10">
            <CustomText
              label="링크"
              name="link"
              type="text"
              width="100%"
              defaultValue={youtube.link}
              handleBlur={(e) => {
                youtube.link = e.target.value;
                handleEdit(index, youtube);
              }}
            />
          </div>
        </div>
      ))}
    </Fragment>
  );
}

function GuideComponent() {
  interface Guide {
    id: string;
    image: string;
    image_id: number;
    content: string;
    link: string;
  }

  const [guides, setGuides] = useState<Guide[]>([]);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(`/home/guides`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(setGuides);
    });
  }, [render]);

  const handleAdd = () => {
    return fetchDefaultWithCredential(`/home/guides`, "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const handleDelete = (index: number) => {
    return fetchDefaultWithCredential(`/home/guides/${index}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => setRender(render + 1));
      }
    );
  };

  const handleEdit = (
    index: number,
    image_id: number,
    content: string,
    link: string
  ) => {
    return fetchDefaultWithCredential(`/home/guides/${index}`, "PUT", {
      image_id,
      content,
      link,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  return (
    <Fragment>
      <p className="font-header">가이드</p>
      <CustomButton onClick={handleAdd}>가이드추가</CustomButton>
      {guides.map((guide, index) => (
        <div className="mt-10" key={guide.id}>
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDelete(index)}>
              삭제
            </CustomButton>
          </div>
          <div className="mt-10">
            <Grid container columnSpacing={1}>
              <Grid item xs>
                <img
                  src={guide.image}
                  alt=""
                  className="width-100-percentage"
                  draggable={false}
                />
              </Grid>
              <Grid item>
                <div>
                  <CustomImageUploadButton
                    fileChangeCallback={(file) => {
                      postImageFile(file).then((image) => {
                        handleEdit(index, image.id, guide.content, guide.link);
                      });
                    }}
                  >
                    이미지 선택
                  </CustomImageUploadButton>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="mt-10">
            <CustomText
              label="컨텐츠 명"
              name="content"
              type="text"
              width="100%"
              defaultValue={guide.content}
              handleBlur={(e) => {
                guide.content = e.target.value;
                handleEdit(index, guide.image_id, guide.content, guide.link);
              }}
            />
          </div>
          <div className="mt-10">
            <CustomText
              label="링크"
              name="link"
              type="text"
              width="100%"
              defaultValue={guide.link}
              handleBlur={(e) => {
                guide.link = e.target.value;
                handleEdit(index, guide.image_id, guide.content, guide.link);
              }}
            />
          </div>
        </div>
      ))}
    </Fragment>
  );
}

function BannerComponent() {
  interface Banner {
    id: string;
    image: string;
    image_id: number;
    mobile_image: string;
    mobile_image_id: number;
    visualize: string | null;
    link: string;
  }

  const [banners, setBanners] = useState<Banner[]>([]);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(`/home/banners`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(setBanners);
    });
  }, [render]);

  const handleAdd = () => {
    return fetchDefaultWithCredential(`/home/banners`, "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const handleDelete = (index: number) => {
    return fetchDefaultWithCredential(`/home/banners/${index}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => setRender(render + 1));
      }
    );
  };

  const handleEdit = (index: number, banner: Banner) => {
    return fetchDefaultWithCredential(
      `/home/banners/${index}`,
      "PUT",
      banner
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const sortedBanner = [...banners].sort((a: Banner, b: Banner) => {
    if (!a.visualize) return 1;
    if (!b.visualize) return -1;
    return new Date(a.visualize).getTime() - new Date(b.visualize).getTime();
  });

  return (
    <Fragment>
      <p className="font-header">배너</p>
      <CustomButton onClick={handleAdd}>배너추가</CustomButton>
      {banners.map((banner, index) => (
        <div className="mt-10" key={banner.id}>
          <div className="d-flex justify-content-space-between align-items-center">
            <div>
              <CustomCheckbox
                checked={banner.visualize !== null}
                handleChange={(checked) => {
                  if (checked) {
                    banner.visualize = new Date().toISOString();
                  } else {
                    banner.visualize = null;
                  }
                  handleEdit(index, banner);
                }}
                border={false}
              >
                보여지기
                {(() => {
                  if (!banner.visualize) return "";
                  const index = sortedBanner.findIndex(
                    (sorted) => sorted.id === banner.id
                  );
                  return `(${index + 1})`;
                })()}
              </CustomCheckbox>
            </div>
            <div>
              <CustomButton onClick={() => handleDelete(index)}>
                삭제
              </CustomButton>
            </div>
          </div>
          <div className="mt-10">
            <p className="font-bold font-14">데스크탑 이미지</p>
            <Grid container columnSpacing={1}>
              <Grid item xs>
                <img
                  src={banner.image}
                  alt=""
                  className="width-100-percentage"
                  draggable={false}
                />
              </Grid>
              <Grid item>
                <div>
                  <CustomImageUploadButton
                    fileChangeCallback={(file) => {
                      postImageFile(file).then((image) => {
                        banner.image_id = image.id;
                        handleEdit(index, banner);
                      });
                    }}
                  >
                    이미지 선택
                  </CustomImageUploadButton>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="mt-10">
            <p className="font-bold font-14">모바일 이미지</p>
            <Grid container columnSpacing={1}>
              <Grid item xs>
                <img
                  src={banner.mobile_image}
                  alt=""
                  className="width-100-percentage"
                  draggable={false}
                />
              </Grid>
              <Grid item>
                <div>
                  <CustomImageUploadButton
                    fileChangeCallback={(file) => {
                      postImageFile(file).then((image) => {
                        banner.mobile_image_id = image.id;
                        handleEdit(index, banner);
                      });
                    }}
                  >
                    이미지 선택
                  </CustomImageUploadButton>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="mt-10">
            <CustomText
              label="배너 링크"
              name="content"
              type="text"
              width="100%"
              defaultValue={banner.link}
              handleBlur={(e) => {
                banner.link = e.target.value;
                handleEdit(index, banner);
              }}
            />
          </div>
        </div>
      ))}
    </Fragment>
  );
}

function AdminPageHome() {
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    fetchDefaultWithCredential(`/home/check`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(() => setLoaded(true));
    });
  }, []);

  if (!loaded) return null;

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm xs={12}>
        <BannerComponent />
      </Grid>
      <Grid item sm xs={12}>
        <div>
          <GuideComponent />
        </div>
        <div className="mt-10">
          <YoutubeComponent />
        </div>
      </Grid>
      <Grid item sm xs={12}>
        <TutorInfoComponent />
      </Grid>
    </Grid>
  );
}

export default AdminPageHome;
