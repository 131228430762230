import { Fade, Grid, Popper, Typography, Hidden } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment } from "react";
import { StatusProps } from "../../interfaces";

interface PropType {
  feedback?: StatusProps;
  anchorEl: HTMLInputElement | HTMLTextAreaElement | null;
}

const useStyles = makeStyles({
  messageContainer: {
    marginLeft: "15px",
    minWidth: "200px",
    height: "50px",
    backgroundColor: "#f0142f",
    borderRadius: "4px",
  },
  message: {
    textAlign: "center",
    fontSize: "14px",
    color: "#ffffff",
  },
  mobileMessageContainer: {
    height: "28px",
    backgroundColor: "#f0142f",
    borderRadius: "15px",
    marginTop: " 110px",
    marginLeft: "27px",
  },
  mobileMessage: {
    textAlign: "center",
    fontSize: "12px",
    color: "#ffffff",
  },
});

function CustomPopper({ feedback, anchorEl }: PropType) {
  const classes = useStyles();
  return feedback && feedback.message ? (
    <Fragment>
      <Hidden smDown>
        <Popper
          open={feedback && feedback.message !== undefined}
          anchorEl={anchorEl}
          placement="right"
          transition
          style={{ zIndex: 10000 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Grid
                container
                className={classes.messageContainer}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography className={classes.message}>
                    {feedback.message}
                  </Typography>
                </Grid>
              </Grid>
            </Fade>
          )}
        </Popper>
      </Hidden>
    </Fragment>
  ) : null;
}

export default CustomPopper;
