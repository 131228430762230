import React, { Fragment, useState } from "react";
import { Grid, Hidden, Divider, Collapse } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InstagramIcon from "../../icons/InstagramIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import { Link } from "react-router-dom";
import KakaoTalkPlusIcon from "../../icons/KakaoTalkPlusIcon";
import YoutubeIcon from "../../icons/YoutubeIcon";
import NaverBlogIcon from "../../icons/NaverBlogIcon";
import "./Footer.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles({
  footer1: {
    padding: "25px",
    backgroundColor: "#e6e6e6",
    height: "200px",
  },
  footer2: {
    height: "160px",
    padding: "25px 50px",
    backgroundColor: "#292929",
  },
  whiteText: {
    color: "#ffffff",
    fontSize: "10px",
  },
  icon: {
    width: "auto",
    height: "15px",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
});

export function KakaoTalkPlus() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://pf.kakao.com/_xnsACT"
    >
      <KakaoTalkPlusIcon className="social-media-icon" />
    </a>
  );
}

export function Youtube() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.youtube.com/channel/UC_fAHwK-M2n8eBggTnKaTog"
    >
      <YoutubeIcon className="social-media-icon" />
    </a>
  );
}

export function Instagram() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/philous_official/"
    >
      <InstagramIcon className="social-media-icon" />
    </a>
  );
}

export function NaverBlog() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://blog.naver.com/philousmanager"
    >
      <NaverBlogIcon className="social-media-icon" />
    </a>
  );
}

export function Facebook() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.facebook.com/philousofficial/"
    >
      <FacebookIcon className="social-media-icon" />
    </a>
  );
}

function Footer() {
  const classes = useStyles();
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <Fragment>
      <Hidden smDown>
        <div className="p-20" style={{ backgroundColor: "#e6e6e6" }}>
          <div className="d-flex">
            <div className="flex-one">
              <div className="font-14 font-bold">
                필로어스에 대해 더 알아보기
              </div>
              <div className="mt-10 font-14">
                당신을 위한 필로어스의 이야기가 준비되어 있어요!
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <div>
                  <div className="font-13 font-bold">고객 문의</div>
                </div>
                <div className="mt-10">
                  <Link to="/faq" className={classes.link}>
                    <div className="font-13 text-dark">문의하기</div>
                  </Link>
                </div>
                <div className="mt-10">
                  <Link to="/faq" className={classes.link}>
                    <div className="font-13 text-dark">자주 찾는 질문</div>
                  </Link>
                </div>
                <div className="mt-10">
                  <Link to="/feedbacks" className={classes.link}>
                    <div className="font-13 text-dark">피드백 보내기</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="ml-40 ">
              <div className="d-flex flex-column">
                <div>
                  <div className="font-13 font-bold">커뮤니티</div>
                </div>
                <div className="mt-10">
                  <Link to="/community" className={classes.link}>
                    <div className="font-13 text-dark">프로그램 후기</div>
                  </Link>
                </div>
                {/* <div className="mt-10">
                  <Link to="/community?section=event" className={classes.link}>
                    <div className="font-13 text-dark">이벤트</div>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="ml-40">
              <div className="d-flex flex-column">
                <div>
                  <div className="font-13 font-bold">필로어스 이야기</div>
                </div>
                <div className="mt-10">
                  <a
                    href="https://www.notion.so/philous/aafe8ebb69ae496abcb4152459d4d627?pvs=4"
                    className={classes.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="font-13 text-dark">필로어스 소개</div>
                  </a>
                </div>
                <div className="mt-10">
                  <a
                    href="https://www.notion.so/philous/e70c39c4960740af8220ef130d8ee902"
                    className={classes.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="font-13 text-dark">필로어스 이용방법</div>
                  </a>
                </div>
                <div className="mt-10">
                  <Link to="/tutor/profile" className={classes.link}>
                    <div className="font-13 text-dark">튜터 프로필</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-20" style={{ backgroundColor: "#292929" }}>
          <div className="d-flex justify-content-space-between">
            <div>
              <div
                className="font-13 text-white mt-10 align-items-center cursor-pointer"
                onClick={() => setToggle(!toggle)}
                style={{
                  display: "inline-flex",
                }}
              >
                <div className="d-flex">필로어스 사업자 정보</div>
                <div className="d-flex">
                  {toggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </div>
              </div>
              <Collapse in={toggle}>
                <div className="font-13 text-white mt-10">
                  경기도 성남시 분당구 운중로 124 마크시티블루 804호 |{" "}
                  <span className="font-13" style={{ color: "#959595" }}>
                    대표
                  </span>{" "}
                  오은서 |{" "}
                  <span className="font-13" style={{ color: "#959595" }}>
                    사업자등록번호
                  </span>{" "}
                  337-24-00908 |{" "}
                  <span className="font-13" style={{ color: "#959595" }}>
                    통신판매신고번호
                  </span>{" "}
                  제 2022-서울강남-00595호 |{" "}
                  <span className="font-13" style={{ color: "#959595" }}>
                    이메일
                  </span>{" "}
                  philousmanager@naver.com
                </div>
              </Collapse>
            </div>
            <div>
              <Grid item container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <KakaoTalkPlus />
                </Grid>
                <Grid item>
                  <Youtube />
                </Grid>
                <Grid item>
                  <Instagram />
                </Grid>
                <Grid item>
                  <NaverBlog />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="mt-20 d-flex justify-content-space-between align-items-center">
            <div className="font-13 text-white">{CopyRight()}</div>
            <div>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <Link to="/policies" className={classes.link}>
                      <div className="font-13 text-white">이용약관</div>
                    </Link>
                  </div>
                </Grid>
                <Grid item>
                  <Link to="/policies" className={classes.link}>
                    <div className="font-13 text-white">개인정보 처리방침</div>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/suggestions" className={classes.link}>
                    <div className="font-13 text-white">제휴·제안</div>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/suggestions" className={classes.link}>
                    <div className="font-13 text-white">
                      단체 / 기업 교육 문의
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="p-30" style={{ backgroundColor: "#e6e6e6" }}>
          <div>
            <p className="font-13 font-bold text-dark">고객 문의</p>
          </div>
          <div>
            <Link to="/faq" className={classes.link}>
              <p className="font-13 text-dark">문의하기</p>
            </Link>
          </div>
          <div>
            <Link to="/faq" className={classes.link}>
              <p className="font-13 text-dark">자주 찾는 질문</p>
            </Link>
          </div>
          <div>
            <Link to="/feedbacks" className={classes.link}>
              <p className="font-13 text-dark">피드백 보내기</p>
            </Link>
          </div>
          <div>
            <Divider />
          </div>
          <div>
            <p className="font-13 font-bold text-dark">커뮤니티</p>
          </div>
          <div>
            <Link to="/community" className={classes.link}>
              <p className="font-13 text-dark">프로그램 후기</p>
            </Link>
          </div>
          {/* <div>
            <Link to="/community?section=event" className={classes.link}>
              <p className="font-13 text-dark">이벤트</p>
            </Link>
          </div> */}
          <div>
            <Divider />
          </div>
          <div>
            <p className="font-13 font-bold">필로어스 이야기</p>
          </div>
          <div>
            <a
              href="https://www.notion.so/philous/aafe8ebb69ae496abcb4152459d4d627?pvs=4"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              <p className="font-13 text-dark">필로어스 소개</p>
            </a>
          </div>
          <div>
            <a
              href="https://www.notion.so/philous/e70c39c4960740af8220ef130d8ee902"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              <p className="font-13 text-dark">필로어스 이용방법</p>
            </a>
          </div>
          <div>
            <Link to="/tutor/profile" className={classes.link}>
              <p className="font-13 text-dark">튜터 프로필</p>
            </Link>
          </div>
        </div>
        <div className="p-30" style={{ backgroundColor: "#292929" }}>
          <div className="d-flex justify-content-space-between align-items-center">
            <div>
              <Grid container spacing={2}>
                <Grid item>
                  <KakaoTalkPlus />
                </Grid>
                <Grid item>
                  <Youtube />
                </Grid>
                <Grid item>
                  <Instagram />
                </Grid>
                <Grid item>
                  <NaverBlog />
                </Grid>
              </Grid>
            </div>
          </div>
          <div>
            <Link to="/policies" className={classes.link}>
              <p className="text-white font-13">이용약관</p>
            </Link>
          </div>
          <div>
            <Link to="/policies" className={classes.link}>
              <p className="text-white font-13">개인정보 처리방침</p>
            </Link>
          </div>
          <div>
            <Link to="/suggestions" className={classes.link}>
              <p className="text-white font-13">제휴·제안</p>
            </Link>
          </div>
          <div>
            <Link to="/suggestions" className={classes.link}>
              <p className="text-white font-13">단체 / 기업 교육 문의</p>
            </Link>
          </div>
          <div>
            <Divider style={{ backgroundColor: "#707070" }} />
          </div>
          <div
            className="font-13 text-white mt-10 align-items-center cursor-pointer"
            onClick={() => setToggle(!toggle)}
            style={{
              display: "inline-flex",
            }}
          >
            <div className="d-flex">필로어스 사업자 정보</div>
            <div className="d-flex">
              {toggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
          </div>
          <Collapse in={toggle}>
            <p className="font-13 text-white mt-10">
              경기도 성남시 분당구 운중로 124 마크시티블루 804호 |{" "}
              <span className="font-13" style={{ color: "#959595" }}>
                대표
              </span>{" "}
              오은서 |{" "}
              <span className="font-13" style={{ color: "#959595" }}>
                사업자등록번호
              </span>{" "}
              337-24-00908 |{" "}
              <span className="font-13" style={{ color: "#959595" }}>
                통신판매신고번호
              </span>{" "}
              제 2022-서울강남-00595호 |{" "}
              <span className="font-13" style={{ color: "#959595" }}>
                이메일
              </span>{" "}
              philousmanager@naver.com
            </p>
            <p className="text-white font-13">{CopyRight()}</p>
          </Collapse>
        </div>
      </Hidden>
    </Fragment>
  );
}

export default Footer;

export function CopyRight() {
  return `© ${new Date().getFullYear()}. PhiloUs All Rights reserved`;
}
