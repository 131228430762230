import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SERVER } from "../../../config";
import { defaultSet } from "../../../interfaces";
import { fetchWithCredential } from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomText from "../../customs/CustomText";

export interface Option extends defaultSet {
  type: string;
  label: string;
}

export function useOptions(type: string) {
  const [options, setOptions] = useState<Option[] | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchWithCredential(`${SERVER}/options/list?type=${type}`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setOptions);
  }, [type, render]);

  const refresh = () => setRender(render + 1);

  function postOption() {
    return fetchWithCredential(`${SERVER}/options`, "POST", { type })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(refresh);
  }

  function putOption(option_id: number, label: string) {
    return fetchWithCredential(`${SERVER}/options/${option_id}`, "PUT", {
      label,
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(refresh);
  }

  function deleteOption(option_id: number) {
    return fetchWithCredential(`${SERVER}/options/${option_id}`, "DELETE")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(refresh);
  }

  return { options, postOption, putOption, deleteOption };
}

interface RawOptionComponentProps {
  label: string;
  options: Option[] | null;
  postOption: () => Promise<void>;
  putOption: (option_id: number, label: string) => Promise<void>;
  deleteOption: (option_id: number) => Promise<void>;
}

export function RawOptionComponent({
  label,
  options,
  postOption,
  putOption,
  deleteOption,
}: RawOptionComponentProps) {
  if (!options) return null;
  return (
    <div>
      <div>
        <div className="font-14 font-bold">{label}</div>
      </div>
      <div className="mt-10">
        <CustomButton width={100} height={30} onClick={postOption}>
          추가
        </CustomButton>
      </div>
      {options.map((option, index) => (
        <Grid container className="mt-10" key={option.id} alignItems="center">
          <Grid item xs>
            <CustomText
              type="text"
              label={`옵션 ${index + 1}`}
              name="option"
              defaultValue={option.label}
              handleBlur={(e) => putOption(option.id!, e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item className="ml-10">
            <CustomButton
              width={100}
              height={30}
              onClick={() => deleteOption(option.id!)}
            >
              제거
            </CustomButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

interface OptionComponentProps {
  label: string;
  type: string;
}

export function OptionComponent({ label, type }: OptionComponentProps) {
  const optionArgs = useOptions(type);
  return <RawOptionComponent {...optionArgs} label={label} />;
}

function AdminPageOptions() {
  return (
    <Grid container sx={{ padding: "30px" }} spacing={2}>
      <Grid item sm={6} xs={12}>
        <OptionComponent label="프로그램 유형" type="program_type" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <OptionComponent label="프로그램 장르" type="program_genre" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <OptionComponent label="프로그램 세부유형" type="program_type_detail" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <OptionComponent label="프로그램 라벨링" type="program_label" />
      </Grid>
    </Grid>
  );
}

export default AdminPageOptions;
