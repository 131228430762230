import { Button, Collapse, Grid, Hidden } from "@mui/material";
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchDefaultWithCredential,
  formatDashedMonthDate,
  formatProgramDate,
  formatProgramDetailDates,
  formatProgramTime,
  formClass,
  isMobile,
  isSizeLessThan,
} from "../utils";
import { Template } from "./admins/pages/edits/programs/Templates";
import CustomButton from "./customs/CustomButton";
import { ProgramProps, ProgramComponent, ProgramStatus } from "./Program";
import { ReactComponent as Cart } from "../svg/cart.svg";
import { ReactComponent as EmptyCart } from "../svg/empty_cart.svg";
import { ReactComponent as ShoppingCartEmpty } from "../svg/shopping_cart_empty.svg";
import { ReactComponent as ShoppingCartFilled } from "../svg/shopping_cart_filled.svg";
import CustomSwiper from "./customs/CustomSwiper";
import CustomStepper from "./customs/CustomStepper";
import {
  AdminUserReview,
  mapAdminUserReviewToCardProps,
} from "./admins/pages/users/Review";
import ProgramReviewCard from "./programs/ProgramReviewCard";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import Looks6Icon from "@mui/icons-material/Looks6";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ForumIcon from "@mui/icons-material/Forum";
import LocalPlayIcon from "@mui/icons-material/LocalPlay";
import CustomDialog from "./customs/CustomDialog";
import { responsivePercentage, textColor } from "./Home";
import { useUser } from "../globals/user";
import {
  GlobalContext,
  SnackbarContext,
} from "../globals/components/ComponentsWrapper";
import { CustomAvatarWithLocation } from "./customs/CustomAvatar";
interface FAQComponentProps {
  faq_templates: Template[];
}

interface FAQDescriptionComponentProps {
  faq_template: Template;
}

function FAQDescriptionComponent({
  faq_template,
}: FAQDescriptionComponentProps) {
  return (
    <div className="mt-50">
      <p className="font-16 font-bold">{faq_template.meta.question}</p>
      <div className="mt-15">
        <p className="font-14 whitespace-preline">{faq_template.meta.answer}</p>
      </div>
    </div>
  );
}

function FAQComponent({ faq_templates }: FAQComponentProps) {
  return (
    <Fragment>
      {faq_templates.length > 0 && <div className="font-header mb-15">FAQ</div>}
      <CustomStepper
        steps={faq_templates.map((faq_template) => faq_template.meta.title)}
        width="100%"
        contents={faq_templates.map((faq_template, index) => (
          <FAQDescriptionComponent key={index} faq_template={faq_template} />
        ))}
        disabled={false}
      />
    </Fragment>
  );
}

interface RefundComponentProps {
  refund_templates: Template[];
}

function RefundComponent({ refund_templates }: RefundComponentProps) {
  return (
    <Fragment>
      {refund_templates.length > 0 && (
        <div className="font-header mb-15">환불 규정</div>
      )}
      {refund_templates.map((refund_template, index) => (
        <div className="mt-30" key={index}>
          <div className="font-16 font-bold">{refund_template.meta.title}</div>
          <div className="mt-5 font-14 whitespace-preline">
            {refund_template.meta.description}
          </div>
        </div>
      ))}
    </Fragment>
  );
}

interface ProgramReviewComponentProps {
  program_reviews: AdminUserReview[];
  refresh: () => void;
  tutor: TutorInfo;
}

function ProgramReviewComponent({
  tutor,
  program_reviews,
  refresh,
}: ProgramReviewComponentProps) {
  return (
    <Fragment>
      {program_reviews.length > 0 && (
        <div className="font-header mb-30">
          {tutor.name} 튜터님과 함께한 멤버 후기
        </div>
      )}
      <CustomSwiper slides={isSizeLessThan(1600) ? 1 : 2}>
        {program_reviews.map((program_review, index) => (
          <div key={index}>
            <div className="width-200 margin-center">
              <ProgramReviewCard
                {...mapAdminUserReviewToCardProps(program_review, refresh)}
              />
            </div>
          </div>
        ))}
      </CustomSwiper>
    </Fragment>
  );
}

interface ProgramBenefitComponentProps {
  benefit_template?: Template;
}

function ProgramBenefitComponent({
  benefit_template,
}: ProgramBenefitComponentProps) {
  if (!benefit_template) return null;

  return (
    <div>
      <div className="font-header mb-30">다양한 혜택</div>
      {(benefit_template.meta.benefits as any[]).map((benefit, index) => (
        <div key={index} className="mt-30">
          <div className="font-16 font-bold">{benefit.title}</div>
          <div className="mt-5 font-16">{benefit.description}</div>
          <div className="d-flex justify-content-center mt-5">
            <img
              src={benefit.image}
              alt=""
              className="width-75-percentage"
              draggable={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

interface ProgramEvent {
  title: string;
  image: string;
  content: string;
}

interface ProgramEventsComponentProps {
  events: ProgramEvent[];
}

function ProgramEventsComponent({ events }: ProgramEventsComponentProps) {
  return (
    <div className="pt-10">
      {events.length > 0 && <div className="font-header mb-15">이벤트</div>}
      {events.map((event, index) => (
        <div className="text-align-center mt-15" key={index}>
          <div className="font-20 font-bold whitespace-preline">
            {event.title}
          </div>
          <div className="mt-5 width-50-percentage margin-center">
            <img
              src={event.image}
              alt=""
              className="width-100-percentage"
              draggable={false}
            />
          </div>
          <div className="mt-5 font-16 whitespace-preline">{event.content}</div>
        </div>
      ))}
    </div>
  );
}

interface OtherProgramsComponentProps {
  other_programs: ProgramProps[];
  refresh: () => void;
}

function OtherProgramsComponent({
  other_programs,
  refresh,
}: OtherProgramsComponentProps) {
  return (
    <Fragment>
      <div className="font-header mb-15">어떤 다른 프로그램이 있을까요?</div>
      <div className="mt-50">
        <CustomSwiper slides={isSizeLessThan(1600) ? 1 : 2}>
          {other_programs.map((other_program, index) => (
            <div key={index}>
              <div className="width-200 margin-center">
                <ProgramComponent
                  program={other_program}
                  refreshGroupedPrograms={refresh}
                />
              </div>
            </div>
          ))}
        </CustomSwiper>
      </div>
    </Fragment>
  );
}

interface DiscountTemplate extends Template {
  package_coupon_id?: number;
  select_coupon_id?: number;
}

interface DiscountComponentProps {
  discount_templates: DiscountTemplate[];
}

function DiscountComponent({ discount_templates }: DiscountComponentProps) {
  const user = useUser();
  const { toggleSigninDialog, refreshUser } = useContext(GlobalContext);
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const downloadCoupon = (couponId: number) => {
    if (!user) {
      return toggleSigninDialog();
    }
    return fetchDefaultWithCredential(`/coupons/${couponId}/download`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            showWarningSnackbar(error);
          });
        }
        return res.json().then(({ message }) => {
          showSuccessSnackbar(message);
        });
      })
      .then(refreshUser);
  };

  return (
    <Fragment>
      {discount_templates.length > 0 && (
        <div className="font-header mb-15">할인 정보</div>
      )}
      <CustomSwiper slides={isSizeLessThan(1600) ? 1 : 2}>
        {discount_templates.map((discount_template, index) => (
          <div key={index}>
            <div className="width-200 text-align-center margin-center">
              <div
                className="outline-with-border p-30"
                style={{ maxWidth: "225px", boxSizing: "border-box" }}
              >
                <p className="font-sub">{discount_template.meta.title}</p>
                <div className="font-20 font-bold">
                  {discount_template.meta.amount}
                </div>
                <p className="mt-15 font-sub">
                  {discount_template.meta.description}
                </p>
                {discount_template.package_coupon_id && (
                  <Fragment>
                    <hr />
                    <div className="mt-10">
                      <CustomButton
                        onClick={() =>
                          downloadCoupon(discount_template.package_coupon_id!)
                        }
                      >
                        패키지 쿠폰 받기
                      </CustomButton>
                    </div>
                  </Fragment>
                )}
                {discount_template.select_coupon_id && (
                  <Fragment>
                    <hr />
                    <div className="mt-10">
                      <CustomButton
                        onClick={() =>
                          downloadCoupon(discount_template.select_coupon_id!)
                        }
                      >
                        지정 쿠폰 받기
                      </CustomButton>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        ))}
      </CustomSwiper>
    </Fragment>
  );
}

interface ProgramGenreShowProps {
  genres: string[];
}

function ProgramGenreShow({ genres }: ProgramGenreShowProps) {
  if (genres.length === 0) return null;
  const mapIcon = (genre: string) => {
    if (genre.includes("영화") || genre.includes("영상")) {
      return LocalMoviesIcon;
    }
    if (genre.includes("수학") || genre.includes("과학")) {
      return PsychologyIcon;
    }
    if (genre.includes("예술")) {
      return ColorLensIcon;
    } else {
      return AutoStoriesIcon;
    }
  };

  // put icons into set
  const iconSet = new Set();
  for (const genre of genres) {
    iconSet.add(mapIcon(genre));
  }

  const icons: any[] = [];
  for (const Icon of Array.from(iconSet) as any) {
    icons.push(<Icon fontSize="large" />);
  }

  return <ShowComponent icons={icons} strings={genres} />;
}

interface ProgramTypeDetailShowProps {
  program_type_details: string[];
}

function ProgramTypeDetailShow({
  program_type_details,
}: ProgramTypeDetailShowProps) {
  if (program_type_details.length === 0) return null;
  const mapIcon = (program_type_detail: string) => {
    if (program_type_detail.includes("입문자")) return LooksOneIcon;
    if (program_type_detail.includes("초심자")) return LooksTwoIcon;
    if (program_type_detail.includes("중하")) return Looks3Icon;
    if (program_type_detail.includes("중")) return Looks4Icon;
    if (program_type_detail.includes("중상")) return Looks5Icon;
    if (program_type_detail.includes("상")) return Looks6Icon;
    if (program_type_detail.includes("챌린지")) return EmojiEventsIcon;
    if (program_type_detail.includes("글쓰기")) return BorderColorIcon;
    if (program_type_detail.includes("세미나")) return ForumIcon;
    if (program_type_detail.includes("체험형")) return LocalPlayIcon;
    return Fragment;
  };
  // put icons into set
  const iconSet = new Set();
  for (const program_type_detail of program_type_details) {
    iconSet.add(mapIcon(program_type_detail));
  }

  const icons: any[] = [];
  for (const Icon of Array.from(iconSet) as any) {
    icons.push(<Icon fontSize="large" />);
  }

  return <ShowComponent icons={icons} strings={program_type_details} />;
}

interface ProgramLocationShowProps {
  location: string;
}

function ProgramLocationShow({ location }: ProgramLocationShowProps) {
  if (location === "") return null;
  let icon: JSX.Element = <Fragment />;
  if (location.includes("온라인")) {
    icon = <ConnectWithoutContactIcon fontSize="large" />;
  } else if (
    location.includes("온") &&
    location.includes("오프") &&
    location.includes("라인")
  ) {
    icon = (
      <Fragment>
        <Grid container columnSpacing={1}>
          <Grid item>
            <ConnectWithoutContactIcon fontSize="large" />
          </Grid>
          <Grid item>
            <GroupsIcon fontSize="large" />
          </Grid>
        </Grid>
      </Fragment>
    );
  } else {
    icon = <GroupsIcon fontSize="large" />;
  }
  const lineIndex = location.indexOf("라인");
  const newLocation =
    location.substring(0, lineIndex + 2) +
    "\n" +
    location.substring(lineIndex + 2);
  return <ShowComponent icons={[icon]} strings={[newLocation]} />;
}

interface ShowComponentProps {
  icons: ReactNode[];
  strings: string[];
}

function ShowComponent({ icons, strings }: ShowComponentProps) {
  const combinedString = strings.join("/");
  const combinedIcon = (
    <Grid container columnSpacing={1} justifyContent="center">
      {icons.map((icon, index) => (
        <Grid item key={index}>
          {icon}
        </Grid>
      ))}
    </Grid>
  );
  return (
    <div className="width-75">
      <div className="width-100-percentage">{combinedIcon}</div>
      <div className="font-16 text-align-center preline-whitespace">
        {combinedString}
      </div>
    </div>
  );
}

interface ProgramDetailComponentProps {
  programDetail: ProgramDetailProps;
  refresh: () => void;
}

function ProgramDetailComponent({
  programDetail,
  refresh,
}: ProgramDetailComponentProps) {
  const history = useHistory();
  const [bookIndex, setBookIndex] = useState<number>(0);
  const [mediaIndex, setMediaIndex] = useState<number>(0);
  const [experimentalIndex, setExperimentalIndex] = useState<number>(0);
  return (
    <div className="p-30">
      <div className="width-75-percentage margin-center">
        <img
          draggable={false}
          src={programDetail.program_image}
          alt=""
          className="width-100-percentage"
        />
      </div>
      <Grid container spacing={2} justifyContent="center" className="mt-15">
        <Grid item>
          <ProgramGenreShow genres={programDetail.program_genres} />
        </Grid>
        <Grid item>
          <ProgramTypeDetailShow
            program_type_details={programDetail.program_type_details}
          />
        </Grid>
        <Grid item>
          <ProgramLocationShow location={programDetail.location} />
        </Grid>
      </Grid>
      <p className="pt-30 font-header">프로그램 소개</p>
      <p className="font-14 whitespace-preline">
        {programDetail.program_description_internal}
      </p>
      {programDetail.books.length > 0 && (
        <div
          className="pt-30 font-22 font-bold mt-50"
          style={{ color: "#5E10AC" }}
        >
          어떤 책을 함께 읽나요?
        </div>
      )}
      <CustomSwiper slides={1} onSlideChange={setBookIndex}>
        {programDetail.books.map((book, index) => (
          <div key={index}>
            <div
              className="pt-50 pl-50 pr-50 pb-20 text-align-center"
              style={{ margin: "0 auto" }}
            >
              <img
                draggable={false}
                src={book.book_image}
                alt=""
                className={formClass([
                  "width-200",
                  book.book_link === "" ? null : "cursor-pointer",
                ])}
                onClick={() =>
                  book.book_link !== "" && window.open(book.book_link, "_blank")
                }
              />
            </div>
          </div>
        ))}
      </CustomSwiper>
      {programDetail.books.length > 0 && programDetail.books[bookIndex] && (
        <div className="text-align-center">
          <div className="font-16 font-bold">
            {programDetail.books[bookIndex].book_name} (
            {programDetail.books[bookIndex].book_publisher})
          </div>
          <div className="mt-15 whitespace-preline font-14">
            {programDetail.books[bookIndex].book_detail}
          </div>
        </div>
      )}
      {programDetail.medias.length > 0 && (
        <div
          className="pt-10 font-22 font-bold mt-50"
          style={{ color: "#5E10AC" }}
        >
          어떤 매체를 함께 보나요?
        </div>
      )}
      <CustomSwiper slides={1} onSlideChange={setMediaIndex}>
        {programDetail.medias.map((media, index) => (
          <div key={index}>
            <div
              className="pt-50 pl-50 pr-50 pb-20 text-align-center"
              style={{ margin: "0 auto" }}
            >
              <img
                draggable={false}
                src={media.image}
                alt=""
                className={formClass([
                  "width-75-percentage",
                  media.link === "" ? null : "cursor-pointer",
                ])}
                onClick={() =>
                  media.link !== "" && window.open(media.link, "_blank")
                }
              />
            </div>
          </div>
        ))}
      </CustomSwiper>
      {programDetail.medias.length > 0 && programDetail.medias[mediaIndex] && (
        <div className="text-align-center">
          <div className="font-16 font-bold">
            {programDetail.medias[mediaIndex].name}
          </div>
          <div className="mt-15 whitespace-preline font-14">
            {programDetail.medias[mediaIndex].detail}
          </div>
        </div>
      )}
      {programDetail.experimentals.length > 0 && (
        <div
          className="pt-10 font-22 font-bold mt-50"
          style={{ color: "#5E10AC" }}
        >
          어떤 활동을 함께 하나요?
        </div>
      )}
      <CustomSwiper slides={1} onSlideChange={setExperimentalIndex}>
        {programDetail.experimentals.map((experimental, index) => (
          <div key={index}>
            <div
              className="pt-50 pl-50 pr-50 pb-20 text-align-center"
              style={{ margin: "0 auto" }}
            >
              <img
                draggable={false}
                src={experimental.image}
                alt=""
                className={formClass([
                  "width-75-percentage",
                  experimental.link === "" ? null : "cursor-pointer",
                ])}
                onClick={() =>
                  experimental.link !== "" &&
                  window.open(experimental.link, "_blank")
                }
              />
            </div>
          </div>
        ))}
      </CustomSwiper>
      {programDetail.experimentals.length > 0 &&
        programDetail.experimentals[experimentalIndex] && (
          <div className="text-align-center">
            <div className="font-16 font-bold">
              {programDetail.experimentals[experimentalIndex].name}
            </div>
            <div className="mt-15 whitespace-preline font-14">
              {programDetail.experimentals[experimentalIndex].detail}
            </div>
          </div>
        )}
      <div
        className="pt-10 font-22 font-bold mt-50"
        style={{ color: "#5E10AC" }}
      >
        진행순서는 어떻게 되나요?
      </div>
      <div className="mt-30">
        {programDetail.program_progress_programs.map(
          (program_progress_program, index) => (
            <p className="font-14" key={index}>
              {programDetail.program_progress_programs.length > 1
                ? `${index + 1}회차: `
                : "원데이: "}
              {program_progress_program}
            </p>
          )
        )}
      </div>
      <div className="mt-30">
        {programDetail.program_progresses.map((program_progress, index) => (
          <p className="font-14" key={index}>
            * {program_progress}
          </p>
        ))}
      </div>
      <div
        className="pt-10 font-22 font-bold mt-50"
        style={{ color: "#5E10AC" }}
      >
        무엇을 배울 수 있나요?
      </div>
      <p className="font-14 whitespace-preline">
        {programDetail.program_detail_learn}
      </p>
      <div className="font-header d-flex align-items-center mt-50">
        <span className="mr-5 d-flex">
          <ErrorIcon fontSize="large" />
        </span>
        상세 정보
      </div>
      <div className="mt-10 font-16 font-bold">
        날짜 |{" "}
        <span className="font-14 font-normal">
          {(() => {
            // check if customized date or not
            if (programDetail.custom_date) {
              return `${formatDashedMonthDate(
                programDetail.custom_date_start
              )} - ${formatDashedMonthDate(programDetail.custom_date_end)}`;
            }
            return formatProgramDetailDates(programDetail.dates.sort());
          })()}
        </span>
      </div>
      <div className="mt-10 font-16 font-bold">
        시간 |{" "}
        <span className="font-14 font-normal">
          {formatProgramTime(
            programDetail.starting_hour,
            programDetail.starting_minute,
            programDetail.minutes
          )}
        </span>
      </div>
      <div className="mt-10 font-16 font-bold">
        장소 |{" "}
        <span className="font-14 font-normal">{programDetail.location}</span>
      </div>
      <div className="mt-10 font-16 font-bold">
        인원 |{" "}
        <span className="font-14 font-normal">
          최소 {programDetail.min_people}명, 최대 {programDetail.max_people}명
        </span>
      </div>
      <div className="mt-10 font-16 font-bold">
        도서 |<br />
        <span className="font-14 font-normal">
          {programDetail.books.map((book, index) => {
            return (
              <div className="mt-5" key={index}>
                ꔷ {book.book_name} ({book.book_publisher})
                <br />
              </div>
            );
          })}
        </span>
      </div>
      <div className="mt-10 font-16 font-bold">
        튜터 |{" "}
        <span>
          {programDetail.tutors.map((tutor) => tutor.name).join(", ")}
        </span>
      </div>
      <div className="mt-10 font-16 font-bold">
        가격 |{" "}
        <span className="font-14 font-normal">
          {programDetail.prev_price !== 0 && (
            <Fragment>
              <span
                style={{
                  color: programDetail.prev_price !== 0 ? "#5d5d5d" : "inherit",
                  textDecoration:
                    programDetail.prev_price !== 0 ? "line-through" : "inherit",
                }}
              >
                {programDetail.prev_price.toLocaleString()}원
              </span>
              <span> → </span>
            </Fragment>
          )}
          <span className="font-15 font-bold">
            {programDetail.price.toLocaleString()}원
          </span>
          {programDetail.price_description !== "" && (
            <span
              className="font-15 font-bold ml-5"
              style={{
                color: "#5d5d5d",
              }}
            >
              ({programDetail.price_description})
            </span>
          )}
        </span>
      </div>
      {programDetail.guidance !== "" && (
        <div className="mt-10 font-16 font-bold whitespace-preline">
          별도 안내 |{" "}
          <span className="font-14 font-normal whitespace-preline">
            {programDetail.guidance}
          </span>
        </div>
      )}
      {programDetail.discount_templates.length > 0 && (
        <div className="mt-50 pt-10">
          <DiscountComponent
            discount_templates={programDetail.discount_templates}
          />
        </div>
      )}
      {programDetail.tutor_speech !== "" && programDetail.tutors.length > 0 && (
        <div className="mt-50 font-header font-bold font-noto-medium">
          튜터 {programDetail.tutors[0].name}님의 한마디
          <div className="mt-30 width-100 height-100">
            <CustomAvatarWithLocation
              location={programDetail.tutors[0].image}
            />
          </div>
          <div className="mt-30 font-14 font-normal whitespace-preline">
            {programDetail.tutor_speech}
          </div>
          <span
            className="mt-30 font-14 font-bold"
            style={{
              color: "#767676",
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => history.push("/tutor/" + programDetail.tutors[0].id)}
          >
            → 튜터 프로필 보러가기
          </span>
        </div>
      )}
      {programDetail.tutors.length > 0 &&
        programDetail.program_reviews.length > 0 && (
          <div className="mt-50 pt-10">
            <ProgramReviewComponent
              program_reviews={programDetail.program_reviews}
              tutor={programDetail.tutors[0]}
              refresh={refresh}
            />
          </div>
        )}
      {programDetail.program_detail_which_members !== "" && (
        <Fragment>
          <div
            className="pt-10 font-22 font-bold mt-50"
            style={{ color: "#5E10AC" }}
          >
            어떤 멤버들과 함께 하나요?
          </div>
          <p className="font-14 whitespace-preline">
            {programDetail.program_detail_which_members}
          </p>
        </Fragment>
      )}
      <div className="mt-50 pt-10">
        <ProgramBenefitComponent
          benefit_template={programDetail.benefit_template}
        />
      </div>
      {programDetail.events.length > 0 && (
        <div className="mt-50 pt-10">
          <ProgramEventsComponent events={programDetail.events} />
        </div>
      )}

      {programDetail.refund_templates.length > 0 && (
        <div className="mt-50 pt-10">
          <RefundComponent refund_templates={programDetail.refund_templates} />
        </div>
      )}
      {programDetail.faq_templates.length > 0 && (
        <div className="mt-50 pt-10">
          <FAQComponent faq_templates={programDetail.faq_templates} />
        </div>
      )}
      {programDetail.other_programs.length > 0 && (
        <div className="mt-50 pt-10">
          <OtherProgramsComponent
            other_programs={programDetail.other_programs}
            refresh={refresh}
          />
        </div>
      )}
    </div>
  );
}

interface TutorInfo {
  id: number;
  name: string;
  image: string;
}

interface BookInfo {
  book_image: string;
  book_name: string;
  book_publisher: string;
  book_detail: string;
  book_link: string;
}

interface MediaInfo {
  image: string;
  name: string;
  detail: string;
  link: string;
}

interface ExperimentalInfo {
  image: string;
  name: string;
  detail: string;
  link: string;
}

interface ProgramDetailProps {
  id: number;
  program_header: string;
  program_name: string;
  program_image: string;
  program_description: string;
  program_description_internal: string;
  program_detail_book_preview: string;
  program_detail_learn: string;
  program_detail_which_members: string;
  program_progress_programs: string[];
  program_progresses: string[];
  combined_program_type_name: string;
  books: BookInfo[];
  medias: MediaInfo[];
  experimentals: ExperimentalInfo[];
  dates: string[];
  location: string;
  min_people: number;
  max_people: number;
  price: number;
  prev_price: number;
  price_description: string;
  tutors: TutorInfo[];
  tutor_speech: string;
  discount_templates: Template[];
  refund_templates: Template[];
  faq_templates: Template[];
  program_status: ProgramStatus;
  cart: boolean;
  starting_hour: number;
  starting_minute: number;
  minutes: number;
  bought: boolean;
  program_reviews: AdminUserReview[];
  other_programs: ProgramProps[];
  guidance: string;
  benefit_template?: Template;
  events: ProgramEvent[];
  program_type_details: string[];
  program_genres: string[];
  program_label: string;
  waiting_available: boolean;
  waiting: boolean;
  available_seats?: number;
  custom_date: boolean;
  custom_date_start: string;
  custom_date_end: string;
}

interface FixedComponentProps {
  programDetail: ProgramDetailProps;
  signinWrapper: (callbackFunc: () => any) => any;
  patchProgramCart: () => void;
  patchProgramWait: () => void;
}

interface LabelingButtonProps {
  programDetail: ProgramDetailProps;
  patchProgramWait: () => void;
}

function LabelingButton({
  programDetail,
  patchProgramWait,
}: LabelingButtonProps) {
  const history = useHistory();
  const { signinWrapper } = useContext(GlobalContext);
  return (
    <Fragment>
      {(() => {
        let backgroundColor = "#5E10AC";
        let text = "신청하기";
        let disabled = false;
        let handleClick = () =>
          history.push({
            pathname: "/order",
            state: [programDetail.id],
          });

        if (programDetail.program_status !== "done") {
          // check if bought
          if (programDetail.bought) {
            text = "신청완료";
            disabled = true;
            backgroundColor = "#111111";
          }
          // check if available seats is in map
          if (
            programDetail.available_seats &&
            programDetail.available_seats <= 3
          ) {
            text = `마감까지 ${programDetail.available_seats}자리`;
          }
        } else {
          // need to check if status is done..
          if (programDetail.waiting_available) {
            if (!programDetail.waiting) {
              backgroundColor = "#310566";
              text = "마감 | 대기신청 가능";
            } else {
              backgroundColor = "#111111";
              text = "대기신청 취소";
            }
            handleClick = patchProgramWait;
          } else {
            backgroundColor = "#111111";
            text = "마감";
            disabled = true;
          }
        }
        return (
          <CustomButton
            className="font-16"
            fullWidth
            disabled={disabled}
            style={{
              backgroundColor,
              color: "white",
            }}
            onClick={() => signinWrapper(handleClick)}
          >
            {text}
          </CustomButton>
        );
      })()}
    </Fragment>
  );
}

function FixedComponent({
  programDetail,
  signinWrapper,
  patchProgramCart,
  patchProgramWait,
}: FixedComponentProps) {
  const [hovered, setHovered] = useState<boolean>(false);
  const disableCondition =
    programDetail.program_status === "done" ||
    isMobile() ||
    programDetail.bought;
  return (
    <div
      className="font-noto-medium font-14"
      style={{ padding: "10%", fontWeight: 700, userSelect: "none" }}
    >
      <div className="d-flex justify-content-center">
        <div
          className="d-flex p-relative"
          style={{
            width: "50%",
            cursor: disableCondition ? "default" : "pointer",

            borderRadius: "10px",
            overflow: "hidden",
          }}
          onMouseEnter={() => {
            if (disableCondition) return;
            setHovered(true);
          }}
          onMouseLeave={() => {
            if (disableCondition) return;
            setHovered(false);
          }}
          onClick={() => {
            if (disableCondition) return;
            return signinWrapper(patchProgramCart);
          }}
        >
          <img
            src={programDetail.program_image}
            alt=""
            style={{ width: "100%" }}
            draggable={false}
          />
          {hovered && (
            <div
              className="p-absolute"
              style={{
                inset: 0,
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <div
                className="p-relative"
                style={{ width: "100%", height: "100%" }}
              >
                <div className="p-absolute-center" style={{ width: "50%" }}>
                  {!programDetail.cart && (
                    <ShoppingCartEmpty style={{ width: "100%" }} />
                  )}
                  {programDetail.cart && (
                    <ShoppingCartFilled style={{ width: "100%" }} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="whitespace-preline mt-10 font-20">
        {programDetail.program_name}
      </div>
      <div className="whitespace-preline mt-10" style={{ color: "#767676" }}>
        {programDetail.program_header}
      </div>
      <div className="mt-15" style={{ color: "#6939CD" }}>
        {programDetail.location}
      </div>
      <div className="mt-5" style={{ color: "#6939CD" }}>
        {(() => {
          let dates = programDetail.dates;
          // check if we should add more onto dates or not
          if (programDetail.custom_date) {
            dates = [
              ...dates,
              programDetail.custom_date_start,
              programDetail.custom_date_end,
            ];
          }
          return formatProgramDate(dates);
        })()}
        <br />
        {formatProgramTime(
          programDetail.starting_hour,
          programDetail.starting_minute,
          programDetail.minutes
        )}
      </div>
      <div className="mt-30 font-13" style={{ color: "#767676" }}>
        {(() => {
          const dates = programDetail.dates;
          const price = programDetail.price;
          const distinctMonths = new Set(
            dates.map((date) => new Date(date).getMonth())
          );
          let label = "";
          if (dates.length === 1) {
            label = "원데이";
          } else if (
            programDetail.combined_program_type_name === "글쓰기 챌린지"
          ) {
            label = "위대한 질문 글쓰기 챌린지";
          } else {
            const dividedPrice = Math.floor(price / dates.length);
            label = `[${distinctMonths.size}개월${
              distinctMonths.size > 1 ? " 무이자" : ""
            }] ${dividedPrice.toLocaleString()}원 x ${dates.length}회`;
          }
          return (
            <Fragment>
              {label} |{" "}
              <span style={{ color: textColor }}>
                {price.toLocaleString()}원
              </span>
            </Fragment>
          );
        })()}
      </div>
      <div className="mt-15">
        <LabelingButton
          programDetail={programDetail}
          patchProgramWait={patchProgramWait}
        />
      </div>
    </div>
  );
}

export function useProgramWait() {
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const patchProgramWait = useCallback((programId: number) => {
    return fetchDefaultWithCredential(
      `/program/${programId}/wait`,
      "PATCH"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(({ waiting }) => {
        if (waiting) {
          setOpen(true);
        }
      });
    });
  }, []);

  const WaitDialog = useCallback(() => {
    return (
      <CustomDialog open={open}>
        <div className="text-align-center">
          <p className="font-30 font-bold">대기 신청이 완료되었습니다</p>
          <p className="font-sub2 text-secondary" style={{ lineHeight: 2.5 }}>
            자리가 생기면 대기 신청을 하신분들께 '이메일'로 알림 메세지가 전송될
            예정입니다.
            <br />
            마이페이지에서 '마케팅 수신동의'에 체크해 주신분들께 여석 연락을
            보내드릴 수 있습니다.
          </p>
          <div className="mt-10">
            <Grid container columnSpacing={1} justifyContent="center">
              <Grid item>
                <CustomButton
                  outlined
                  onClick={() => history.push("/mypage?section=profile")}
                >
                  마케팅 수신동의 하러가기
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton outlined onClick={() => setOpen(false)}>
                  닫기
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </CustomDialog>
    );
  }, [open, history]);

  return {
    patchProgramWait,
    WaitDialog,
  };
}

function ProgramDetail() {
  const location = useLocation();
  const splittedLocation = location.pathname.split("/");
  const programId = parseInt(splittedLocation[splittedLocation.length - 1]);
  const [programDetail, setProgramDetail] = useState<ProgramDetailProps | null>(
    null
  );
  const [refresh, setRefresh] = useState<number>(0);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [toggleFixed, setToggleFixed] = useState<boolean>(false);

  const { signinWrapper } = useContext(GlobalContext);
  const { showSuccessSnackbar, showWarningSnackbar } =
    useContext(SnackbarContext);
  const { WaitDialog, patchProgramWait } = useProgramWait();

  useEffect(() => {
    window.scroll(0, 0);
  }, [programId]);

  useEffect(() => {
    setProgramDetail(null);
    fetchDefaultWithCredential(`/program/${programId}`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setProgramDetail);
    });
  }, [programId]);

  const patchProgramCart = () => {
    if (programDetail) {
      fetchDefaultWithCredential(
        `/program/${programDetail.id}/cart`,
        "PATCH"
      ).then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => {
          if (!programDetail.cart) {
            showSuccessSnackbar("장바구니에 담겼습니다");
          } else {
            showWarningSnackbar("장바구니에서 제거되었습니다");
          }
          setRefresh(refresh + 1);
        });
      });
    }
  };

  if (!programDetail) return null;
  return (
    <div
      className="p-30 margin-center"
      style={{
        width: responsivePercentage(75),
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={7} ref={setRef}>
          <ProgramDetailComponent
            programDetail={programDetail}
            refresh={() => setRefresh(refresh + 1)}
          />
        </Grid>
        <Hidden smDown>
          <Grid item sm={5} id="fixed-component-container" className="p-50">
            <div
              className="text-align-center"
              style={{
                borderRadius: "25px",
                boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.16)",
                position: "fixed",
                left: "65%",
                top: (ref?.offsetTop ?? -30) + 30,
                width: "20%",
                backgroundColor: "white",
              }}
            >
              <FixedComponent
                programDetail={programDetail}
                patchProgramCart={patchProgramCart}
                signinWrapper={signinWrapper}
                patchProgramWait={() => {
                  patchProgramWait(programDetail.id).then(() =>
                    setRefresh(refresh + 1)
                  );
                }}
              />
            </div>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <div
            style={{
              borderRadius: "25px 25px 0 0",
              boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.16)",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "white",
              textAlign: "center",
              zIndex: 1,
            }}
          >
            <Button onClick={() => setToggleFixed(!toggleFixed)}>
              {toggleFixed ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </Button>
            {!programDetail.bought &&
              !toggleFixed &&
              programDetail.program_status !== "done" && (
                <Grid
                  container
                  justifyContent="center"
                  columnSpacing={2}
                  style={{
                    padding: `0 15% 5%`,
                  }}
                >
                  <Grid item>
                    <CustomButton
                      outlined
                      onClick={() => signinWrapper(patchProgramCart)}
                    >
                      <div style={{ height: "15px" }}>
                        {programDetail.cart ? (
                          <Cart style={{ width: "auto", height: "100%" }} />
                        ) : (
                          <EmptyCart
                            style={{ width: "auto", height: "100%" }}
                          />
                        )}
                      </div>
                    </CustomButton>
                  </Grid>
                  <Grid item xs>
                    <LabelingButton
                      programDetail={programDetail}
                      patchProgramWait={() =>
                        patchProgramWait(programDetail.id).then(() =>
                          setRefresh(refresh + 1)
                        )
                      }
                    />
                  </Grid>
                </Grid>
              )}
            {!programDetail.bought &&
              !toggleFixed &&
              programDetail.waiting_available && (
                <Grid container justifyContent="center" className="mb-15">
                  <Grid item>
                    <CustomButton
                      style={{
                        backgroundColor: programDetail.waiting
                          ? "inherit"
                          : "#6300EB",
                      }}
                      onClick={() =>
                        signinWrapper(() => {
                          patchProgramWait(programDetail.id).then(() =>
                            setRefresh(refresh + 1)
                          );
                        })
                      }
                    >
                      {programDetail.waiting ? "대기신청 취소" : "대기신청"}
                    </CustomButton>
                  </Grid>
                </Grid>
              )}
            <Collapse in={toggleFixed}>
              <FixedComponent
                programDetail={programDetail}
                patchProgramCart={patchProgramCart}
                signinWrapper={signinWrapper}
                patchProgramWait={() => {
                  patchProgramWait(programDetail.id).then(() =>
                    setRefresh(refresh + 1)
                  );
                }}
              />
            </Collapse>
          </div>
        </Hidden>
      </Grid>
      <WaitDialog />
    </div>
  );
}

export default ProgramDetail;
