import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../globals/components/ComponentsWrapper";
import { Feedbacks } from "../../../interfaces";
import { fetchDefaultWithCredential, isMobile } from "../../../utils";
import CustomText from "../../customs/CustomText";
import { Button } from "@mui/material";

interface PasswordForm {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
}

function Password() {
  const [form, setForm] = useState<PasswordForm>({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [feedbacks, setFeedbacks] = useState<Feedbacks>({});
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const handleSubmit = () => {
    fetchDefaultWithCredential(`/user/?type=password`, "PUT", form).then(
      (res) => {
        if (!res.ok) {
          res.json().then((data) => {
            let tempFeed = {};
            for (const [field, message] of Object.entries(data)) {
              if (message) {
                tempFeed = {
                  ...tempFeed,
                  [field]: {
                    stauts: false,
                    message: message,
                  },
                };
              }
            }
            setFeedbacks(tempFeed);
            return;
          });
        } else {
          showSuccessSnackbar("비밀번호가 변경되었습니다");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    );
  };

  const handlePasswordCheck = () => {
    fetchDefaultWithCredential(`/user/password/verify`, "POST", {
      current_password: form.current_password,
    }).then((res) => {
      if (!res.ok) {
        if (res.status === 400) {
          showWarningSnackbar("잘못된 비밀번호입니다");
        }
        return;
      }
      return res.json().then(() => {
        showSuccessSnackbar("인증 되었습니다");
        setConfirmed(true);
      });
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", inset: 0, zIndex: -1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ height: "300px", backgroundColor: "#313131" }}></div>
          <div style={{ flex: 1, backgroundColor: "#F2F2F2" }}></div>
        </div>
      </div>
      <div
        style={{
          paddingTop: isMobile() ? 50 : 100,
          fontSize: 20,
          color: "white",
          fontWeight: 700,
          width: isMobile() ? "75%" : "100%",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        로그인 시 사용하는 비밀번호를 변경할 수 있습니다.
      </div>
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          padding: isMobile() ? "25px 0" : "50px 0",
        }}
      >
        <div
          style={{
            borderRadius: isMobile() ? 20 : "70px 70px 30px 30px",
            backgroundColor: "white",
            padding: isMobile() ? 25 : 50,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              columnGap: 15,
              alignItems: "flex-end",
            }}
          >
            <div style={{ flex: 1 }}>
              <CustomText
                width="100%"
                type="password"
                label="현재 비밀번호 입력"
                name="password"
                autocomplete="off"
                handleBlur={(e) => {
                  form.current_password = e.target.value;
                  setForm({
                    ...form,
                  });
                }}
                disabled={confirmed}
              />
            </div>
            <Button
              style={{
                fontSize: isMobile() ? 16 : 18,
                color: "white",
                backgroundColor: confirmed ? "#767676" : "#6939CD",
                height: isMobile() ? 37.5 : 53,
                borderRadius: 13,
                padding: "5px 20px",
              }}
              onClick={handlePasswordCheck}
              disabled={confirmed}
            >
              인증하기
            </Button>
          </div>
          <div style={{ marginTop: isMobile() ? 15 : 30 }}>
            <CustomText
              width="100%"
              type="password"
              label="*새 비밀번호"
              name="new_password"
              readOnly={!confirmed}
              autocomplete="off"
              placeholder="영문 대/소문자, 숫자, 특수문자 포함 8~16자리"
              feedback={feedbacks.new_password}
              handleBlur={(e) => {
                form.new_password = e.target.value;
                setForm({
                  ...form,
                });
              }}
            />
          </div>
          <div style={{ marginTop: isMobile() ? 15 : 30 }}>
            <CustomText
              width="100%"
              type="password"
              label="*새 비밀번호 재확인"
              name="new_confirm_password"
              readOnly={!confirmed}
              autocomplete="off"
              feedback={feedbacks.confirm_new_password}
              handleBlur={(e) => {
                form.confirm_new_password = e.target.value;
                setForm({
                  ...form,
                });
              }}
            />
          </div>
          <div style={{ marginTop: isMobile() ? 15 : 30, textAlign: "center" }}>
            <Button
              style={{
                borderRadius: 36,
                fontSize: isMobile() ? 16 : 18,
                padding: isMobile() ? "10px 30px" : "15px 50px",
                color: "white",
                backgroundColor: !confirmed ? "#767676" : "#6939CD",
              }}
              onClick={handleSubmit}
              disabled={!confirmed}
            >
              정보 수정 완료
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
