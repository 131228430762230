import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { IMP_IDENTIFIER, SERVER } from "../config";
import {
  GlobalContext,
  SnackbarContext,
} from "../globals/components/ComponentsWrapper";
import { useUser } from "../globals/user";
import { fetchDefaultWithCredential, fetchWithCredential } from "../utils";
import { CouponApplyProps, OrderInfo } from "./Order";

let INITIALIZED = false;

export function getImp() {
  const IMP = (window as any).IMP;
  if (!INITIALIZED) {
    IMP.init(IMP_IDENTIFIER);
    INITIALIZED = true;
  }
  return IMP;
}

export function useCertification() {
  const IMP = getImp();
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  const handleCertify = (
    type: string,
    onSuccess: (data: any) => void,
    onFailure: () => void
  ) => {
    // IMP.certification(param, callback) 호출
    return IMP.certification(
      {
        merchant_uid: "merchant_" + new Date().getTime(), //상점에서 관리하시는 고유 주문번호를 전달
        m_redirect_url: `${window.location.origin}/redirection/${type}`, // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
        popup: false, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
      },
      (res: any) => {
        // callback
        if (!res.success) {
          showWarningSnackbar(res.error_msg);
          onFailure();
        } else {
          return fetchDefaultWithCredential(`/certification`, "POST", {
            imp_uid: res.imp_uid,
          }).then((res) => {
            if (!res.ok) {
              return res.json().then(({ error }) => {
                showWarningSnackbar(error);
                onFailure();
              });
            }
            return res.json().then((data) => {
              showSuccessSnackbar("인증에 성공하셨습니다");
              onSuccess(data);
            });
          });
        }
      }
    ) as Promise<Response>;
  };
  return { handleCertify };
}

export function usePayment() {
  const user = useUser();
  const IMP = getImp();
  const history = useHistory();
  const { environment } = useContext(GlobalContext);
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  const defaultPaymentInfo = {
    pg: environment === "production" ? "danal_tpay" : "kakaopay",
    pay_method: "card", //card(신용카드), trans(실시간계좌이체), vbank(가상계좌), phone(휴대폰소액결제)
    ...(user && {
      buyer_email: user.email,
      buyer_name: user.name,
      buyer_tel: user.phone,
      buyer_addr: user.meta.address ?? "",
      buyer_postcode: user.meta.postcode ?? "",
    }),
    display: {},
  };

  const paymentComplete = (body: any) => {
    return fetchDefaultWithCredential(
      `/program/payment/complete`,
      "POST",
      body
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          showWarningSnackbar(message);
        });
      }
      history.push("/mypage/program/registered?newBought=true");
      showSuccessSnackbar("결제가 완료되었습니다.");
    });
  };

  const paymentFailed = (merchant_uid: string) => {
    return fetchDefaultWithCredential(`/program/payment/failed`, "PUT", {
      merchant_uid,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          showWarningSnackbar(message);
        });
      }
    });
  };

  const requestPay = (data: any) => {
    // IMP.request_pay(param, callback) 호출
    return IMP.request_pay(data, (res: any) => {
      // callback
      if (res.success) {
        return paymentComplete({
          ...data,
          imp_uid: res.imp_uid,
          merchant_uid: res.merchant_uid,
        });
      } else {
        return paymentFailed(res.merchant_uid).then(() => {
          showWarningSnackbar(
            `결제에 실패하였습니다. 에러 내용: ${res.error_msg}`
          );
        });
      }
    });
  };

  const handleBuy = (
    programIds: number[],
    toApply: CouponApplyProps,
    meta: any
  ) => {
    return fetchDefaultWithCredential("/order", "POST", {
      program_ids: programIds,
      coupon_point_info: toApply,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then((data: OrderInfo) => {
        const paymentInfo = {
          ...defaultPaymentInfo,
          ...meta,
          name: `${data.programs
            .map((program) => program.program_name)
            .join(", ")}`,
          amount: data.price_info.total,
          merchant_uid: "merchant_" + new Date().getTime(), //상점에서 관리하시는 고유 주문번호를 전달
          program_ids: programIds,
          coupon_point_info: toApply,
          price_info: data.price_info,
        };
        return fetchDefaultWithCredential(
          `/program/payment/info`,
          "POST",
          paymentInfo
        ).then((res) => {
          if (!res.ok) {
            return res.json().then(({ message }) => {
              showWarningSnackbar(message);
            });
          }
          return res.json().then((data) => {
            if (paymentInfo.amount === 0) {
              return paymentComplete(data.meta);
            }
            // data is new object created from server, including merchant_uid
            return requestPay({
              ...data.meta,
              // set m_redirect_url
              m_redirect_url: `${SERVER}/program/payment/complete/mobile`,
            });
          });
        });
      });
    });
  };

  const handleRefund = () => {
    fetchWithCredential(`${SERVER}/payment/refund`, "POST", {
      merchant_uid: "62e7340c-6db8-4e71-bed8-7a299448f72f",
      cancel_request_amount: 100,
      reason: "테스트 결제 환불", // 환불사유
    })
      .then((res) => res.json())
      .then(({ status, message }) => {
        if (!status) throw new Error(message);
        console.log("환불 성공");
      })
      .catch((err) => console.log(err));
  };

  return {
    handleBuy,
    handleRefund,
  };
}
