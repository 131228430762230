import React from "react";
import { handleSignout } from "../../components/layouts/Navbar";
import CustomButton from "../../components/customs/CustomButton";
import { responsivePixel } from "../../components/Home";

export default function InactiveComponent() {
  return (
    <div className="text-align-center">
      <p
        className="font-bold"
        style={{
          fontSize: responsivePixel(30),
        }}
      >
        귀하는 이전에 계정을 비활성화 시키셨습니다. <br />
        계정을 다시 활성화 시키시겠습니까?
      </p>
      <p className="font-sub2 text-secondary" style={{ lineHeight: 2.5 }}>
        계정이 활성화 되면 프로그램 신청, 독서 성장 리포트, 튜터의 피드백 등의
        기록 확인을 포함한
        <br />
        필로어스의 모든 기능 이용이 가능합니다.
        <br />
        계정을 다시 활성화시키기 위해서는 '문의하기' 버튼을 누르신 후 채팅창에
        '활성화 문의'를 입력해주세요.
      </p>
      <div className="mt-10">
        <CustomButton
          width={100}
          height={40}
          outlined
          onClick={() => {
            window.open("http://pf.kakao.com/_xnsACT", "_blank");
          }}
        >
          문의하기
        </CustomButton>
        <CustomButton
          width={100}
          height={40}
          outlined
          onClick={handleSignout}
          style={{ marginLeft: "10px" }}
        >
          로그아웃
        </CustomButton>
      </div>
    </div>
  );
}
