import React from "react";

function KakaoTalkPlusIcon(props: any) {
  return (
    <svg
      height={512}
      viewBox="0 0 24 24"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.462 9.306l-.692 1.951h1.383z" />
      <path d="M12 1C5.373 1 0 5.208 0 10.399c0 3.356 2.246 6.301 5.625 7.963-1.299 4.45-1.333 4.47-1.113 4.599.276.161.634-.005 5.357-3.311.692.097 1.404.148 2.131.148 6.627 0 12-4.208 12-9.399S18.627 1 12 1zM6.058 13.023c0 .362-.311.657-.692.657s-.692-.295-.692-.657V8.937h-1.08c-.375 0-.679-.302-.679-.673s.303-.674.678-.674h3.545c.375 0 .679.302.679.673s-.305.674-.679.674h-1.08zm5.378.648c-.72 0-.587-.565-.919-1.195H8.406c-.329.625-.2 1.195-.919 1.195-.693.001-.815-.421-.604-1.071l1.656-4.33c.117-.33.471-.669.922-.679.452.01.807.349.923.679 1.093 3.39 2.654 5.402 1.052 5.401zm3.939-.092h-2.221c-1.159 0-.454-1.565-.663-5.301 0-.379.317-.688.707-.688s.707.308.707.688v4.04h1.471c.366 0 .663.283.663.63-.001.348-.298.631-.664.631zm5.419-.518a.684.684 0 01-.269.454c-.955.721-1.661-1.381-2.593-2.271l-.24.239v1.5a.69.69 0 01-.693.688.69.69 0 01-.692-.688V8.278c0-.379.31-.688.692-.688s.692.308.692.688v1.478c1.277-.958 1.985-2.67 2.792-1.869.792.786-.848 1.474-1.527 2.422 1.604 2.212 1.909 2.267 1.838 2.752z" />
    </svg>
  );
}

export default KakaoTalkPlusIcon;
