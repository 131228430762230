import { Checkbox, Grid, Input } from "@mui/material";
import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { fetchDefaultWithCredential, isMobile } from "../utils";
import { PopupTemplateQuestion } from "./admins/pages/Popup";
import { HomeButton, widthPercentage } from "./Home";
import { ReactComponent as EditIcon } from "../svg/edit_icon.svg";
import { postProgramReview } from "./Community";
import { useUser } from "../globals/user";
import { GlobalContext } from "../globals/components/ComponentsWrapper";
import queryString from "query-string";

interface SubjectiveInputProps {
  defaultValue?: string;
  handleChange: (value: string) => void;
  showUnderline?: boolean;
  placeholder: string;
  width?: number;
  disabled?: boolean;
  colorIcon?: boolean;
}

export function SubjectiveInput({
  defaultValue,
  handleChange,
  showUnderline,
  placeholder,
  width,
  disabled,
  colorIcon,
}: SubjectiveInputProps) {
  const [editIconRef, setEditIconRef] = useState<SVGElement | null>(null);

  if (editIconRef) {
    for (const child of (editIconRef as any).children) {
      if (colorIcon) {
        child.style.stroke = "#4A148C";
      } else {
        child.style.stroke = "#999999";
      }
    }
  }

  return (
    <div
      className="d-flex"
      style={{
        width: width ?? "100%",
        paddingBottom: widthPercentage(0.375),
        pointerEvents: disabled ? "none" : "inherit",
        ...(showUnderline
          ? {
              borderBottom: `1px solid #949494`,
            }
          : {}),
      }}
    >
      <div
        style={{
          width: isMobile() ? widthPercentage(6) : widthPercentage(1.5),
          height: isMobile() ? widthPercentage(6) : widthPercentage(1.5),
        }}
      >
        <EditIcon
          ref={setEditIconRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div
        style={{
          marginLeft: isMobile() ? widthPercentage(1) : widthPercentage(0.25),
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Input
          placeholder={placeholder}
          fullWidth
          multiline
          disableUnderline
          className="font-noto-regular"
          style={{
            fontSize: isMobile() ? widthPercentage(4) : widthPercentage(1),
            padding: 0,
            lineHeight: "180%",
          }}
          onChange={(e) => handleChange(e.target.value)}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
}

interface ProgressBarProps {
  percentage: number;
  noNumber?: boolean;
}

export function ProgressBar({ percentage, noNumber }: ProgressBarProps) {
  return (
    <Grid container alignItems="center" style={{ userSelect: "none" }}>
      <Grid
        item
        xs
        style={{
          height: isMobile() ? widthPercentage(1) : widthPercentage(0.25),
          position: "relative",
          backgroundColor: "#E5E5EC",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: `${percentage}%`,
            backgroundColor: "#4A148C",
          }}
        ></div>
      </Grid>
      {!noNumber && (
        <Grid
          item
          style={{
            marginLeft: isMobile()
              ? widthPercentage(1)
              : widthPercentage(0.875),
            fontSize: isMobile()
              ? widthPercentage(3.75)
              : widthPercentage(0.9375),
            color: "#999999",
          }}
        >
          {percentage}%
        </Grid>
      )}
    </Grid>
  );
}

interface PopupQuestionComponentProps {
  question: PopupTemplateQuestion;
  defaultOptionIds: string[];
  defaultEtc: string;
  defaultSubjective: string;
  prevAvailable: boolean;
  prevLabel: string;
  nextLabel: string;
  handlePrev: () => void;
  handleNext: (any: {
    optionIds: string[];
    etc: string;
    subjective: string;
  }) => void;
  subjectivePlaceholder?: string;
  nextLoading: boolean;
  buttonContainerStyle?: React.CSSProperties;
}

export function PopupQuestionComponent({
  question,
  defaultOptionIds,
  defaultEtc,
  defaultSubjective,
  prevAvailable,
  prevLabel,
  nextLabel,
  handlePrev,
  handleNext,
  subjectivePlaceholder = "자유롭게 피드백해주세요.",
  nextLoading,
  buttonContainerStyle,
}: PopupQuestionComponentProps) {
  const [optionIds, setOptionIds] = useState<string[]>(defaultOptionIds);
  const [etc, setEtc] = useState<string>(defaultEtc);
  const [subjective, setSubjective] = useState<string>(defaultSubjective);

  interface CheckboxComponentProps {
    value: string;
    handleChange: (checked: boolean) => void;
    checked: boolean;
  }

  const CheckboxComponent = ({
    value,
    handleChange,
    checked,
  }: CheckboxComponentProps) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: isMobile()
          ? `${widthPercentage(2.25)}px ${widthPercentage(3)}px`
          : `${widthPercentage(0.5625)}px ${widthPercentage(0.75)}px`,
      }}
    >
      <Checkbox
        color="secondary"
        sx={{
          "&": {
            padding: 0,
          },
          "& svg": {
            display: "flex",
          },
          "& path": {
            width: isMobile() ? widthPercentage(4.5) : widthPercentage(1.125),
            height: isMobile() ? widthPercentage(4.5) : widthPercentage(1.125),
          },
        }}
        onChange={(e) => handleChange(e.target.checked)}
        checked={checked}
      />
      <div
        className="font-noto-regular"
        style={{
          marginLeft: isMobile() ? widthPercentage(3) : widthPercentage(0.75),
          fontSize: isMobile() ? widthPercentage(4) : widthPercentage(1),
        }}
      >
        {value}
      </div>
    </div>
  );

  let availableCondition = !nextLoading;
  if (question.type === "multiple") {
    availableCondition =
      availableCondition &&
      optionIds.length >= question.min_options &&
      optionIds.length <= question.max_options;
    if (optionIds.findIndex((optionId) => optionId === "etc") !== -1) {
      availableCondition = availableCondition && etc.length >= question.etc;
    }
  } else if (question.type === "subjective") {
    availableCondition =
      availableCondition && subjective.length >= question.min_subjective_length;
  }

  return (
    <Fragment>
      <div
        style={{
          fontSize: isMobile() ? widthPercentage(4.5) : widthPercentage(1.125),
        }}
      >
        {question.question}
      </div>
      {question.type === "multiple" && (
        <div
          style={{
            marginTop: isMobile() ? widthPercentage(1) : widthPercentage(0.25),
            fontSize: isMobile()
              ? widthPercentage(4.5)
              : widthPercentage(1.125),
            color: "#999999",
          }}
        >
          {question.min_options === question.max_options &&
            `${question.max_options}개를 선택해주세요.`}
          {question.min_options !== question.max_options &&
            `최대 ${question.max_options}개 선택 가능합니다.`}
        </div>
      )}
      <div
        style={{
          marginTop: isMobile() ? widthPercentage(3.5) : widthPercentage(1.25),
          padding: isMobile()
            ? `${widthPercentage(8.75)}px ${widthPercentage(4)}px`
            : `${widthPercentage(2.5)}px ${widthPercentage(3)}px`,
          border: `${
            isMobile() ? widthPercentage(0.25) : widthPercentage(0.0625)
          }px solid #E5E5EC`,
        }}
      >
        {question.type === "multiple" && (
          <Fragment>
            {question.options.map((option, index) => (
              <CheckboxComponent
                key={index}
                value={option.value}
                handleChange={(checked) => {
                  if (!checked) {
                    setOptionIds(
                      optionIds.filter((optionId) => optionId !== option.id)
                    );
                  } else {
                    if (optionIds.length === question.max_options) return;
                    setOptionIds([...optionIds, option.id]);
                  }
                }}
                checked={
                  optionIds.findIndex((optionId) => optionId === option.id) !==
                  -1
                }
              />
            ))}
            {question.etc > 0 && (
              <Fragment>
                <CheckboxComponent
                  value="기타"
                  handleChange={(checked) => {
                    if (!checked) {
                      setOptionIds(
                        optionIds.filter((optionId) => optionId !== "etc")
                      );
                    } else {
                      if (optionIds.length === question.max_options) return;
                      setOptionIds([...optionIds, "etc"]);
                    }
                  }}
                  checked={
                    optionIds.findIndex((optionId) => optionId === "etc") !== -1
                  }
                />
                {optionIds.findIndex((optionId) => optionId === "etc") !==
                  -1 && (
                  <div
                    style={{
                      padding: isMobile()
                        ? `0 ${widthPercentage(4.5) + widthPercentage(3)}px`
                        : `0 ${
                            widthPercentage(2.625) + widthPercentage(0.75)
                          }px`,
                    }}
                  >
                    <Input
                      placeholder="직접 입력하세요."
                      fullWidth
                      multiline
                      className="font-noto-regular"
                      style={{
                        fontSize: isMobile()
                          ? widthPercentage(4)
                          : widthPercentage(1),
                        paddingBottom: isMobile()
                          ? widthPercentage(1.5)
                          : widthPercentage(0.4375),
                        lineHeight: "180%",
                      }}
                      onChange={(e) => setEtc(e.target.value)}
                      defaultValue={etc}
                    />
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
        {question.type === "subjective" && (
          <SubjectiveInput
            defaultValue={subjective}
            handleChange={setSubjective}
            placeholder={subjectivePlaceholder}
            colorIcon={availableCondition}
          />
        )}
      </div>
      <div
        className="d-flex"
        style={
          buttonContainerStyle ?? {
            marginTop: isMobile() ? widthPercentage(6.5) : widthPercentage(2.5),
          }
        }
      >
        {prevAvailable && (
          <div
            style={{
              marginLeft: isMobile() ? 0 : "auto",
              marginRight: isMobile() ? "auto" : widthPercentage(1),
            }}
          >
            <HomeButton
              width={isMobile() ? widthPercentage(31) : widthPercentage(7.75)}
              height={isMobile() ? widthPercentage(11) : undefined}
              handleClick={handlePrev}
              outlined
            >
              {prevLabel}
            </HomeButton>
          </div>
        )}
        <div
          style={{
            marginLeft: !prevAvailable ? "auto" : 0,
          }}
        >
          <HomeButton
            width={isMobile() ? widthPercentage(31) : widthPercentage(7.75)}
            height={isMobile() ? widthPercentage(11) : undefined}
            handleClick={() =>
              handleNext({
                optionIds,
                etc,
                subjective,
              })
            }
            noColor={!availableCondition}
            disabled={!availableCondition}
          >
            {nextLabel}
          </HomeButton>
        </div>
      </div>
    </Fragment>
  );
}

function UserPopupComponent() {
  const user = useUser();
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const splittedLocation = location.pathname.split("/");
  const templateId = splittedLocation[splittedLocation.length - 1];
  const { program_id } = queryString.parse(location.search);
  const currentProgress = user?.popup_progresses.find((popup_progress) => {
    const templateCondition = popup_progress.template.id === templateId;
    if (!templateCondition) return false;
    if (popup_progress.program_id)
      return popup_progress.program_id === parseInt(program_id as string);
    return true;
  });
  const [lastStepNeeded, setLastStepNeeded] = useState<boolean>(false);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const history = useHistory();
  const { refreshUser } = useContext(GlobalContext);

  useEffect(() => {
    if (currentProgress) {
      setCurrentIndex((currentIndex) => {
        if (currentIndex === -1) {
          return currentProgress.questions.length;
        } else {
          return currentIndex + 1;
        }
      });
      setNextLoading(false);
      // judge if review section is needed
      if (currentProgress.reviewed === false) {
        setLastStepNeeded(true);
      }
    }
  }, [currentProgress]);

  if (
    !user ||
    currentIndex === -1 ||
    !currentProgress ||
    !(currentProgress.template.questions.length > 0)
  )
    return null;

  const TemplateComponent = ({ children }: { children: ReactNode }) => (
    <Fragment>
      <div
        style={{
          fontSize: isMobile() ? widthPercentage(3.75) : widthPercentage(1.125),
        }}
      >
        {currentProgress.condition === "first_login" && `${user.name}님`}
        {currentProgress.condition === "program_satisfaction" &&
          `${currentProgress.program!.program_header} - ${
            currentProgress.program!.program_name
          }`}
      </div>
      <div
        style={{
          fontSize: isMobile() ? widthPercentage(5) : widthPercentage(2),
          marginTop: isMobile() ? 1 : 0,
        }}
      >
        {currentProgress.template.title}
      </div>
      <div
        style={{
          marginTop: isMobile()
            ? widthPercentage(8.25)
            : widthPercentage(1.9375),
        }}
      >
        <ProgressBar
          percentage={Math.min(
            100,
            Math.round(
              ((currentIndex + 1) / currentProgress.template.questions.length) *
                100
            )
          )}
        />
      </div>
      <div
        style={{
          marginTop: isMobile()
            ? widthPercentage(12.75)
            : widthPercentage(2.9375),
        }}
      >
        {!nextLoading && children}
      </div>
    </Fragment>
  );

  return (
    <div
      className="margin-center font-noto-medium"
      style={{
        width: isMobile() ? undefined : widthPercentage(81.875),
        padding: isMobile()
          ? `${widthPercentage(22)}px ${widthPercentage(4)}px`
          : `${widthPercentage(10)}px 0`,
        color: "#111111",
        userSelect: "none",
      }}
    >
      {currentIndex < currentProgress.template.questions.length && (
        <TemplateComponent>
          {(() => {
            const currentQuestion =
              currentProgress.template.questions[currentIndex];
            const userAnsweredQuestion = currentProgress.questions.find(
              (question) => question.question_id === currentQuestion.id
            );
            let optionIds = [] as string[];
            let etc = "";
            let subjective = "";
            if (userAnsweredQuestion) {
              optionIds = userAnsweredQuestion.answers.map(
                (answer) => answer.option_id
              );
              const etcObject = userAnsweredQuestion.answers.find(
                (answer) => answer.option_id === "etc"
              );
              if (etcObject && etcObject.etc) {
                etc = etcObject.etc;
              }
              const subjectiveObject = userAnsweredQuestion.answers.find(
                (answer) => answer.subjective
              );
              if (subjectiveObject && subjectiveObject.subjective) {
                subjective = subjectiveObject.subjective;
              }
            }
            return (
              <PopupQuestionComponent
                key={currentQuestion.id}
                question={currentQuestion}
                defaultOptionIds={optionIds}
                defaultEtc={etc}
                defaultSubjective={subjective}
                prevAvailable={currentIndex > 0}
                prevLabel="이전"
                nextLabel="다음"
                nextLoading={nextLoading}
                handlePrev={() => setCurrentIndex(currentIndex - 1)}
                handleNext={({ optionIds, etc, subjective }) => {
                  setNextLoading(true);
                  fetchDefaultWithCredential(
                    `/popups/template/${templateId}/question/${currentQuestion.id}`,
                    "PUT",
                    {
                      option_ids: optionIds,
                      etc,
                      subjective,
                      program_id: currentProgress.program_id,
                    }
                  ).then((res) => {
                    if (!res.ok) {
                      return res.text().then((data) => {
                        throw new Error(data);
                      });
                    }
                    return res.json().then(() => {
                      if (
                        currentIndex ===
                        currentProgress.template.questions.length - 1
                      ) {
                        setCurrentIndex(currentIndex + 1);
                        setNextLoading(false);
                      } else {
                        refreshUser();
                      }
                    });
                  });
                }}
              />
            );
          })()}
        </TemplateComponent>
      )}
      {lastStepNeeded &&
        currentIndex === currentProgress.template.questions.length && (
          <TemplateComponent>
            <PopupQuestionComponent
              question={{
                id: "",
                question:
                  "이번 세미나 여정은 어떠셨나요? 나의 세미나 일지를 작성해보세요. (리워드: 적립금 1,000원)",
                type: "subjective",
                min_subjective_length: 30,
                etc: 0,
                min_options: 0,
                max_options: 0,
                options: [],
              }}
              defaultOptionIds={[]}
              defaultEtc=""
              defaultSubjective=""
              prevAvailable
              prevLabel="건너뛰기"
              nextLabel="다음"
              handlePrev={() => setLastStepNeeded(false)}
              handleNext={({ subjective }) =>
                postProgramReview(
                  currentProgress.program_id!,
                  subjective
                ).finally(() => setLastStepNeeded(false))
              }
              subjectivePlaceholder="무엇을 경험했고, 어떤 것들을 알게 되셨는지 공유해주세요. 세미나 일지는 ‘마이페이지’에서도 작성하실 수 있습니다."
              nextLoading={nextLoading}
            />
          </TemplateComponent>
        )}
      {!lastStepNeeded &&
        currentIndex === currentProgress.template.questions.length && (
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                fontSize: isMobile() ? widthPercentage(5) : widthPercentage(2),
              }}
            >
              소중한 의견을 들려주셔서 감사합니다!
            </div>
            {currentProgress.template.benefit_type === "coupon" &&
              currentProgress.template.coupon_id !== 0 &&
              currentProgress.template.coupon_name && (
                <div
                  style={{
                    marginTop: isMobile()
                      ? widthPercentage(2.5)
                      : widthPercentage(1.25),
                    fontSize: isMobile()
                      ? widthPercentage(3.5)
                      : widthPercentage(0.9375),
                    color: "#505050",
                  }}
                >
                  <span style={{ color: "#4A148C" }}>
                    {currentProgress.template.coupon_name}
                  </span>
                  이 지급되었습니다.
                  <br />
                  쿠폰/적립금은 프로그램 구매시 결제단계에서 사용하실 수
                  있습니다.
                </div>
              )}
            <div
              style={{
                marginTop: isMobile()
                  ? widthPercentage(6.5)
                  : widthPercentage(2.5),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <HomeButton
                width={isMobile() ? widthPercentage(50) : widthPercentage(12.5)}
                height={isMobile() ? widthPercentage(11) : undefined}
                handleClick={() => refreshUser().then(() => history.push("/"))}
              >
                홈으로 이동하기
              </HomeButton>
            </div>
          </div>
        )}
    </div>
  );
}

export default UserPopupComponent;
