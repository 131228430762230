import {
  Grid,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { useState, Fragment } from "react";
import { postTutor, useTutors, useUsers } from "../../../hooks";
import { Tutor } from "../../../interfaces";
import {
  fetchDefaultWithCredential,
  month,
  putImageWithToken,
} from "../../../utils";
import CustomAvatar, {
  CustomAvatarWithLocation,
} from "../../customs/CustomAvatar";
import CustomButton from "../../customs/CustomButton";
import SortComponent from "./helpers/SortComponent";
import CustomText from "../../customs/CustomText";
import CustomCheck from "../../customs/CustomCheck";
import { TutorType, useTutorTypes } from "./TutorTypes";
import CustomDialog from "../../customs/CustomDialog";
import { User } from "../../../globals/user";

// function TutorAttendedPrograms({ tutor }: TutorAttendedProgramsProps) {
//   const [page, setPage] = useState<number>(1);
//   const { tutorProgramReviews } = useTutorProgramReviews(tutor.id!, page);
//   const [currentProgramReview, setCurrentProgramReview] =
//     useState<ProgramReview | null>(null);

//   if (!tutorProgramReviews) return null;
//   return (
//     <Grid container>
//       <Grid item style={{ width: "300px" }}>
//         <SortComponent
//           currentDataObject={currentProgramReview}
//           setCurrentDataObject={setCurrentProgramReview}
//           label="후기"
//           data={tutorProgramReviews.program_reviews}
//           listItemComponent={(programReview: ProgramReview) => (
//             <Grid container alignItems="center">
//               <Grid item xs={9}>
//                 <p className="font-16 text-ellipsis">{programReview.detail}</p>
//                 <p className="font-12 text-secondary">
//                   {programReview.program.meta.program_name}
//                 </p>
//               </Grid>
//               <Grid item xs={3}>
//                 <p className="font-bold text-align-center">
//                   {programReview.user.name}
//                 </p>
//               </Grid>
//             </Grid>
//           )}
//           abcSortAttributeFunc={(programReview) => programReview.detail}
//           defaultSortType="recent"
//         />
//         <div className="mt-10 d-flex justify-content-center">
//           <Pagination
//             page={page}
//             count={tutorProgramReviews.total_pages}
//             color="standard"
//             onChange={(event, value) => setPage(value)}
//           />
//         </div>
//       </Grid>
//       {currentProgramReview && (
//         <Grid item xs className="ml-30">
//           <ProgramReviewComponent programReview={currentProgramReview} />
//         </Grid>
//       )}
//     </Grid>
//   );
// }

interface CurrentTutorProfileComponentProps {
  tutor: Tutor;
  patchTutorStatus: (tutorId: number) => Promise<void>;
  deleteTutor: (tutorId: number) => Promise<void>;
  refreshTutors: () => void;
  tutorTypes: TutorType[];
}

interface TutorForm {
  tutor_avatar: string;
  tutor_name: string;
  introduction: string;
  tutor_type_ids: number[];
  discount_percentage: number;
}

function CurrentTutorProfileComponent({
  tutor,
  patchTutorStatus,
  deleteTutor,
  refreshTutors,
  tutorTypes,
}: CurrentTutorProfileComponentProps) {
  const [tutorForm, setTutorForm] = useState<TutorForm>({
    tutor_avatar: tutor.tutor_avatar ?? "",
    tutor_name: tutor.tutor_name,
    introduction: tutor.introduction,
    tutor_type_ids: [tutor.tutor_type1, tutor.tutor_type2].filter(
      (tutorType) => tutorType
    ) as number[],
    discount_percentage: tutor.discount_percentage,
  });
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [tutorAvatarInputRef, setTutorAvatarInputRef] =
    useState<HTMLInputElement | null>(null);

  const handleEdit = () => {
    fetchDefaultWithCredential(`/tutor/${tutor.id!}`, "PUT", tutorForm)
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then(() => {
        refreshTutors();
        setOpenEdit(false);
      });
  };
  const handleDelete = () => {
    deleteTutor(tutor.id!);
  };

  const handleTutorAvatarChange = (e: any) => {
    const files = e.target.files;
    if (files.length === 0) return;
    const file = e.target.files[0];
    fetchDefaultWithCredential(`/tutor/${tutor.id!}/avatar`, "PUT", {
      filename: file.name,
    }).then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(({ token }) => {
        putImageWithToken(token, file).then((image) => {
          tutorForm.tutor_avatar = image.location;
          setTutorForm({ ...tutorForm });
        });
      });
    });
  };

  const handleTutorAvatarDelete = (e: any) => {
    fetchDefaultWithCredential(`/tutor/${tutor.id!}/avatar`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json().then(() => {
          tutorForm.tutor_avatar = "";
          setTutorForm({ ...tutorForm });
        });
      }
    );
  };

  return (
    <Grid container>
      <Grid item>
        <div className="d-flex justify-content-space-between">
          <CustomCheck
            label=""
            checkItems={[
              {
                label: "튜터 활성화",
                checked: tutor.status === "active",
                id: 0,
              },
            ]}
            handleChange={() => setOpenStatus(true)}
          />
          {new Date().getTime() - new Date(tutor.created_at!).getTime() <=
            3 * month && (
            <CustomButton className="background-red">New</CustomButton>
          )}
        </div>
        <div className="d-flex justify-content-center mt-10">
          <div className="width-100 height-100">
            <CustomAvatarWithLocation location={tutorForm.tutor_avatar} />
          </div>
        </div>
        <div className="mt-10 d-flex justify-content-space-evenly">
          <CustomButton
            onClick={() => {
              if (tutorAvatarInputRef) {
                tutorAvatarInputRef.click();
              }
            }}
          >
            프로필 사진 바꾸기
          </CustomButton>
          <input
            className="d-none"
            type="file"
            name="tutor_avatar"
            ref={setTutorAvatarInputRef}
            onChange={handleTutorAvatarChange}
            accept="image/*"
          />
          <CustomButton onClick={handleTutorAvatarDelete}>X</CustomButton>
        </div>
        <div className="mt-10">
          <p className="font-bold">회원 명: {tutor.name}</p>
        </div>
        <div className="mt-10">
          <p className="font-bold">
            튜터 등록 날짜:{" "}
            {new Date(tutor.created_at!).toLocaleDateString("ko-KR")}
          </p>
        </div>
        <div className="mt-10">
          <p className="font-bold">개월 수: {tutor.months}개월</p>
        </div>
        <div className="mt-10">
          <p className="font-bold">누적 횟수: {tutor.counts}회</p>
        </div>
        <form
          onBlur={(e) => {
            const target = e.target;
            if (target.type === "number") {
              tutorForm[target.name as "discount_percentage"] = parseInt(
                target.value
              );
            } else {
              tutorForm[target.name as "tutor_name" | "introduction"] =
                target.value;
            }
            setTutorForm({ ...tutorForm });
          }}
        >
          <div className="mt-10">
            <CustomText
              width="100%"
              type="text"
              label="튜터 명"
              name="tutor_name"
              defaultValue={tutorForm.tutor_name}
            />
          </div>
          <div className="mt-10">
            <CustomText
              width="100%"
              type="text"
              multiline
              label="자기소개"
              name="introduction"
              height="100px"
              defaultValue={tutorForm.introduction}
            />
          </div>
          <div className="mt-10">
            <CustomText
              width="100%"
              type="number"
              label="기본 할인률(%)"
              name="discount_percentage"
              defaultValue={tutorForm.discount_percentage}
            />
          </div>
        </form>
        <div className="mt-10">
          <CustomCheck
            label="튜터 유형 등록"
            checkItems={tutorTypes.map((tutorType) => ({
              id: tutorType.id!,
              label: tutorType.label,
              checked: tutorForm.tutor_type_ids.includes(tutorType.id!),
            }))}
            handleChange={(tutorTypeId, checked) => {
              if (checked && tutorForm.tutor_type_ids.length === 2) {
                return;
              }
              if (!checked) {
                setTutorForm({
                  ...tutorForm,
                  tutor_type_ids: tutorForm.tutor_type_ids.filter(
                    (tutor_type_id) => tutor_type_id !== tutorTypeId
                  ),
                });
              } else {
                setTutorForm({
                  ...tutorForm,
                  tutor_type_ids: [...tutorForm.tutor_type_ids, tutorTypeId],
                });
              }
            }}
          />
        </div>
        <div className="mt-10 d-flex justify-content-space-evenly">
          <CustomButton onClick={() => setOpenEdit(true)}>
            수정하기
          </CustomButton>
          <CustomButton onClick={() => setOpenDelete(true)}>
            삭제하기
          </CustomButton>
        </div>
      </Grid>
      <CustomDialog open={openStatus}>
        <p className="font-header">
          정말로 {tutor.status === "pending" ? "활성화" : "비활성화"}{" "}
          하시겠습니까?
        </p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton
              onClick={() => {
                if (tutor.status === "pending") {
                  tutor.status = "active";
                } else {
                  tutor.status = "pending";
                }
                patchTutorStatus(tutor.id!).then(() => setOpenStatus(false));
              }}
            >
              {tutor.status === "pending" ? "활성화" : "비활성화"}
            </CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setOpenStatus(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
      <CustomDialog open={openDelete}>
        <p className="font-header">정말로 삭제하시겠습니까?</p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton onClick={handleDelete}>삭제</CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setOpenDelete(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
      <CustomDialog open={openEdit}>
        <p className="font-header">정말로 수정하시겠습니까?</p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton onClick={handleEdit}>수정</CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setOpenEdit(false)}>닫기</CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
      <Grid item xs className="ml-30">
        {/* <TutorAttendedPrograms tutor={tutor} /> */}
      </Grid>
    </Grid>
  );
}

function AdminPageTutors() {
  const { users } = useUsers();
  const { tutors, refreshTutors, patchTutorStatus, deleteTutor } = useTutors();
  const tutorUserIds = tutors.map((tutor) => tutor.user_id);
  const [currentTutorUser, setCurrentTutorUser] = useState<User | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const currentTutor = tutors.find(
    (tutor) => tutor.user_id === currentTutorUser?.id!
  );

  const nonTutoredUsers = users.filter(
    (user) => !tutorUserIds.includes(user.id!)
  );
  const tutoredUsers = users.filter((user) => tutorUserIds.includes(user.id!));
  const { tutorTypes } = useTutorTypes();

  return (
    <div className="p-30">
      <div className="mt-10">
        <Grid container columnSpacing={4}>
          <Grid item sm={4} xs={12}>
            <div>
              <SortComponent
                currentDataObject={currentTutorUser}
                setCurrentDataObject={setCurrentTutorUser}
                label="튜터 명단"
                data={tutoredUsers}
                listItemComponent={(user) => (
                  <Fragment>
                    <ListItemAvatar>
                      <div className="width-40 height-40">
                        <CustomAvatar user={user} />
                      </div>
                    </ListItemAvatar>
                    <ListItemText primary={user.name} secondary={user.email} />
                    <ListItemSecondaryAction>
                      {(() => {
                        const tutor = tutors.find(
                          (tutor) => tutor.user_id === user.id
                        )!;
                        return (
                          <CustomButton
                            width={50}
                            height={30}
                            onClick={() =>
                              deleteTutor(tutor.id!).then(refreshTutors)
                            }
                          >
                            튜터 삭제
                          </CustomButton>
                        );
                      })()}
                    </ListItemSecondaryAction>
                  </Fragment>
                )}
                abcSortAttributeFunc={(user) => user.name}
              />
            </div>
            <div className="mt-30">
              <SortComponent
                currentDataObject={currentUser}
                setCurrentDataObject={setCurrentUser}
                label="회원 명단"
                data={nonTutoredUsers}
                listItemComponent={(user) => (
                  <Fragment>
                    <ListItemAvatar>
                      <div className="width-40 height-40">
                        <CustomAvatar user={user} />
                      </div>
                    </ListItemAvatar>
                    <ListItemText primary={user.name} secondary={user.email} />
                    <ListItemSecondaryAction>
                      <CustomButton
                        width={50}
                        height={30}
                        onClick={() => postTutor(user.id!).then(refreshTutors)}
                      >
                        튜터 추가
                      </CustomButton>
                    </ListItemSecondaryAction>
                  </Fragment>
                )}
                abcSortAttributeFunc={(user) => user.name}
              />
            </div>
          </Grid>
          {currentTutor && (
            <Grid item sm={8} xs={12}>
              <CurrentTutorProfileComponent
                key={currentTutor.id}
                tutor={currentTutor}
                patchTutorStatus={patchTutorStatus}
                deleteTutor={deleteTutor}
                refreshTutors={refreshTutors}
                tutorTypes={tutorTypes}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default AdminPageTutors;
