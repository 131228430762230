import { Box } from "@mui/material";
import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { GlobalContext } from "../globals/components/ComponentsWrapper";
import { useUser } from "../globals/user";
import { fetchDefaultWithCredential, isMobile } from "../utils";
import { PopupTemplateQuestion } from "./admins/pages/Popup";
import { postProgramReview } from "./Community";
import { HomeButton, widthPercentage } from "./Home";
import { PopupQuestionComponent, ProgressBar } from "./UserPopupComponent";
import { ReactComponent as CameraIcon } from "../svg/camera.svg";
import CloseIcon from "@mui/icons-material/Close";
import { postImageFile } from "../hooks/useImages";

// question has id individually
// record in question_id with option id or subjective value

interface TemplateComponentProps {
  program_header: string;
  program_name: string;
  question: string;
  current: number;
  total: number;
  children: ReactNode;
}

function TemplateComponent({
  program_header,
  program_name,
  question,
  current,
  total,
  children,
}: TemplateComponentProps) {
  return (
    <Fragment>
      <div
        style={{
          fontSize: isMobile() ? widthPercentage(3.75) : widthPercentage(1.125),
        }}
      >
        {program_header} - {program_name}
      </div>
      <div
        style={{
          fontSize: isMobile() ? widthPercentage(5) : widthPercentage(2),
          marginTop: isMobile() ? 1 : 0,
        }}
      >
        {question}
      </div>
      <div
        style={{
          marginTop: isMobile()
            ? widthPercentage(8.25)
            : widthPercentage(1.9375),
        }}
      >
        <ProgressBar
          percentage={Math.min(100, Math.round(((current + 1) / total) * 100))}
        />
      </div>
      <div
        style={{
          marginTop: isMobile()
            ? widthPercentage(12.75)
            : widthPercentage(2.9375),
        }}
      >
        {children}
      </div>
    </Fragment>
  );
}

interface UserSatisfactionAnswerOption {
  type: "multiple" | "subjective";
  value: string;
  etc?: string;
}

export interface UserSatisfactionAnswer {
  [id: string]: UserSatisfactionAnswerOption[] | undefined;
}

interface UserSatisfactionProgram {
  id: number;
  program_header: string;
  program_name: string;
}

interface UserSatisfaction {
  program: UserSatisfactionProgram;
  // questions
  questions: PopupTemplateQuestion[];
  answers: UserSatisfactionAnswer;
  reviewed: boolean;
}

function UserSatisfactionComponent() {
  const user = useUser();
  const { user_satisfaction_id_str } = useParams<{
    user_satisfaction_id_str: string;
  }>();
  const user_satisfaction_id = parseInt(user_satisfaction_id_str);
  const history = useHistory();
  const [current, setCurrent] = useState<number>(-1);
  const { refreshUser } = useContext(GlobalContext);
  const [userSatisfaction, setUserSatisfaction] =
    useState<UserSatisfaction | null>(null);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [lastStepNeeded, setLastStepNeeded] = useState<boolean>(false);
  const [render, setRender] = useState<number>(0);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [uploadRef, setUploadRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    fetchDefaultWithCredential(
      `/user/satisfactions/${user_satisfaction_id}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setUserSatisfaction);
    });
  }, [user_satisfaction_id, render]);

  useEffect(() => {
    if (!userSatisfaction) return;
    setLastStepNeeded(!userSatisfaction.reviewed);
    for (let i = 0; i < userSatisfaction.questions.length; i++) {
      const question = userSatisfaction.questions[i];
      if (userSatisfaction.answers[question.id]) continue;
      setCurrent(i);
      return;
    }
    setCurrent(userSatisfaction.questions.length);
  }, [userSatisfaction]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    setImageFile(files[0]);
  };

  if (!user || !userSatisfaction || current === -1) return null;

  return (
    <div
      className="margin-center font-noto-medium"
      style={{
        width: isMobile() ? undefined : widthPercentage(81.875),
        padding: isMobile()
          ? `${widthPercentage(22)}px ${widthPercentage(4)}px`
          : `${widthPercentage(10)}px 0`,
        color: "#111111",
        userSelect: "none",
      }}
    >
      {current < userSatisfaction.questions.length && (
        <TemplateComponent
          program_header={userSatisfaction.program.program_header}
          program_name={userSatisfaction.program.program_name}
          current={current}
          total={userSatisfaction.questions.length}
          question={userSatisfaction.questions[current].question}
        >
          {(() => {
            const currentQuestion = userSatisfaction.questions[current];
            const currentAnswer = userSatisfaction.answers[currentQuestion.id];
            let optionIds = [] as string[];
            let etc = "";
            let subjective = "";
            if (currentAnswer) {
              optionIds = currentAnswer.map((answer) => answer.value);
              const etcObject = currentAnswer.find(
                (answer) => answer.value === "etc"
              );
              if (etcObject && etcObject.etc) {
                etc = etcObject.etc;
              }
              const subjectiveObject = currentAnswer.find(
                (answer) => answer.type === "subjective"
              );
              if (subjectiveObject) {
                subjective = subjectiveObject.value;
              }
            }
            return (
              <PopupQuestionComponent
                key={currentQuestion.id}
                question={currentQuestion}
                defaultOptionIds={optionIds}
                defaultEtc={etc}
                defaultSubjective={subjective}
                prevAvailable={current > 0}
                prevLabel="이전"
                nextLabel="다음"
                nextLoading={nextLoading}
                handlePrev={() => setCurrent(current - 1)}
                handleNext={({ optionIds, etc, subjective }) => {
                  setNextLoading(true);
                  fetchDefaultWithCredential(
                    `/user/satisfactions/${user_satisfaction_id}/${currentQuestion.id}`,
                    "PUT",
                    {
                      option_ids: optionIds,
                      etc,
                      subjective,
                    }
                  ).then((res) => {
                    if (!res.ok) {
                      return res.text().then((data) => {
                        throw new Error(data);
                      });
                    }
                    return res.json().then(() => {
                      setNextLoading(false);
                      setRender(render + 1);
                    });
                  });
                }}
              />
            );
          })()}
        </TemplateComponent>
      )}
      {lastStepNeeded && current === userSatisfaction.questions.length && (
        <div style={{ position: "relative" }}>
          <TemplateComponent
            program_header={userSatisfaction.program.program_header}
            program_name={userSatisfaction.program.program_name}
            current={current}
            total={userSatisfaction.questions.length}
            question=""
          >
            <PopupQuestionComponent
              question={{
                id: "",
                question:
                  "이번 세미나 여정은 어떠셨나요? 나의 세미나 일지를 작성해보세요. (리워드: 적립금 1,000원)",
                type: "subjective",
                min_subjective_length: 20,
                etc: 0,
                min_options: 0,
                max_options: 0,
                options: [],
              }}
              defaultOptionIds={[]}
              defaultEtc=""
              defaultSubjective=""
              prevAvailable
              prevLabel="건너뛰기"
              nextLabel="다음"
              handlePrev={() => setLastStepNeeded(false)}
              buttonContainerStyle={{
                marginTop: isMobile()
                  ? widthPercentage(20)
                  : widthPercentage(2.5),
              }}
              handleNext={({ subjective }) => {
                setNextLoading(true);
                if (!imageFile) {
                  return postProgramReview(
                    userSatisfaction.program.id,
                    subjective
                  )
                    .then(() => setNextLoading(false))
                    .finally(() => setLastStepNeeded(false));
                }
                return postImageFile(imageFile).then((image) =>
                  postProgramReview(
                    userSatisfaction.program.id,
                    subjective,
                    image.id
                  )
                    .then(() => setNextLoading(false))
                    .finally(() => setLastStepNeeded(false))
                );
              }}
              subjectivePlaceholder="무엇을 경험했고, 어떤 것들을 알게 되셨는지 공유해주세요. 세미나 일지는 ‘마이페이지’에서도 작성하실 수 있습니다."
              nextLoading={nextLoading}
            />
          </TemplateComponent>
          <div
            style={{
              position: "absolute",
              bottom: isMobile() ? widthPercentage(15) : 0,
              left: 0,
              display: "inline-flex",
            }}
          >
            <div>
              <HomeButton
                width={isMobile() ? widthPercentage(40) : widthPercentage(10)}
                height={isMobile() ? widthPercentage(11) : undefined}
                outlined
                noColor={imageFile !== null}
                disabled={imageFile !== null}
                handleClick={() => uploadRef && uploadRef.click()}
              >
                {!imageFile && (
                  <Fragment>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Box
                        style={{ display: "flex" }}
                        sx={{
                          "& path": {
                            stroke: "#4a148c",
                          },
                        }}
                      >
                        <CameraIcon
                          width={
                            isMobile()
                              ? widthPercentage(5.75)
                              : widthPercentage(1.4375)
                          }
                          height={
                            isMobile()
                              ? widthPercentage(5.25)
                              : widthPercentage(1.3125)
                          }
                        />
                      </Box>
                      <div
                        style={{
                          marginLeft: isMobile()
                            ? widthPercentage(2.5)
                            : widthPercentage(0.625),
                        }}
                      >
                        사진 첨부
                      </div>
                    </div>
                    <input
                      ref={setUploadRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Fragment>
                )}
                {imageFile && "사진 첨부 완료"}
              </HomeButton>
            </div>
            {imageFile && (
              <div
                style={{
                  marginLeft: widthPercentage(isMobile() ? 5 : 1.25),
                }}
              >
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt=""
                  draggable={false}
                  style={{
                    height: isMobile()
                      ? widthPercentage(11)
                      : widthPercentage(2.5),
                    width: "auto",
                  }}
                />
                <div
                  onClick={() => setImageFile(null)}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "flex",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon
                    style={{
                      width: isMobile() ? undefined : widthPercentage(0.875),
                      height: isMobile() ? undefined : widthPercentage(0.875),
                      color: "white",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!lastStepNeeded && current === userSatisfaction.questions.length && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              fontSize: isMobile() ? widthPercentage(5) : widthPercentage(2),
            }}
          >
            소중한 의견을 들려주셔서 감사합니다!
          </div>
          <div
            style={{
              marginTop: isMobile()
                ? widthPercentage(6.5)
                : widthPercentage(2.5),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HomeButton
              width={isMobile() ? widthPercentage(50) : widthPercentage(12.5)}
              height={isMobile() ? widthPercentage(11) : undefined}
              handleClick={() => refreshUser().then(() => history.push("/"))}
            >
              홈으로 이동하기
            </HomeButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserSatisfactionComponent;
