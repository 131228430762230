import { Grid } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../../../globals/components/ComponentsWrapper";
import { User } from "../../../../../globals/user";
import { fetchDefaultWithCredential } from "../../../../../utils";
import CustomButton from "../../../../customs/CustomButton";
import SortComponent from "../../helpers/SortComponent";
import { UserListView } from "../../helpers/UserList";
import { ProgramVisualizeInnerComponent } from "../../Transactions";
import { ProgramInfo } from "../../users";

interface UserRegistrationViewProps {
  program: ProgramInfo;
}

interface RegistrationInfo {
  registered: User[];
  unregistered: User[];
}

function UserRegistrationView({ program }: UserRegistrationViewProps) {
  const [registrationInfo, setRegistrationInfo] =
    useState<RegistrationInfo | null>(null);
  const [render, setRender] = useState<number>(0);
  const [currentRegisteredUser, setCurrentRegisteredUser] =
    useState<User | null>(null);
  const [currentUnregisteredUser, setCurrentUnregisteredUser] =
    useState<User | null>(null);
  const { showWarningSnackbar } = useContext(SnackbarContext);
  useEffect(() => {
    fetchDefaultWithCredential(
      `/program/${program.id}/registrations`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setRegistrationInfo);
    });
  }, [program, render]);

  const patchRegistration = (user: User | null) => {
    if (!user) return;
    fetchDefaultWithCredential(
      `/program/${program.id}/user/${user.id}/register`,
      "PATCH"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          showWarningSnackbar(error);
        });
      }
      return res.json().then(() => {
        setRender(render + 1);
      });
    });
  };

  if (!registrationInfo) return null;

  return (
    <Fragment>
      <div className="registered">
        <div>
          <CustomButton
            disabled={!currentRegisteredUser}
            onClick={() => patchRegistration(currentRegisteredUser)}
          >
            등록 취소
          </CustomButton>
        </div>
        <div className="mt-10">
          <SortComponent
            currentDataObject={currentRegisteredUser}
            setCurrentDataObject={setCurrentRegisteredUser}
            label="등록된 고객"
            data={registrationInfo.registered}
            listItemComponent={(user) => <UserListView user={user} />}
            abcSortAttributeFunc={(user) => user.name}
          />
        </div>
      </div>
      <div className="mt-30 unregistered">
        <div>
          <CustomButton
            disabled={!currentUnregisteredUser}
            onClick={() => patchRegistration(currentUnregisteredUser)}
          >
            등록 적용
          </CustomButton>
        </div>
        <div className="mt-10">
          <SortComponent
            currentDataObject={currentUnregisteredUser}
            setCurrentDataObject={setCurrentUnregisteredUser}
            label="등록 안된 고객"
            data={registrationInfo.unregistered}
            listItemComponent={(user) => <UserListView user={user} />}
            abcSortAttributeFunc={(user) => user.name}
          />
        </div>
      </div>
    </Fragment>
  );
}

export function useAdminProgramInfos() {
  const [programs, setPrograms] = useState<ProgramInfo[] | null>(null);
  const [render, setRender] = useState<number>(0);
  useEffect(() => {
    fetchDefaultWithCredential(`/program/list/all/admin`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setPrograms);
    });
  }, [render]);
  return { programs, refresh: () => setRender(render + 1) };
}

function AdminPageProgramCustomizedRegister() {
  const { programs } = useAdminProgramInfos();
  const [currentProgram, setCurrentProgram] = useState<ProgramInfo | null>(
    null
  );

  if (!programs) return null;

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm xs={12}>
        <SortComponent
          currentDataObject={currentProgram}
          setCurrentDataObject={setCurrentProgram}
          label="프로그램"
          data={programs}
          listItemComponent={(program: ProgramInfo) => (
            <ProgramVisualizeInnerComponent {...program} />
          )}
          abcSortAttributeFunc={(program) => program.program_name}
        />
      </Grid>
      <Grid item sm xs={12}>
        {currentProgram && <UserRegistrationView program={currentProgram} />}
      </Grid>
      <Grid item sm xs={12}></Grid>
    </Grid>
  );
}

export default AdminPageProgramCustomizedRegister;
