import { fetchDefaultWithCredential } from "../utils";

export type Environment = "development" | "staging" | "production";

export function getEnvironment() {
  return fetchDefaultWithCredential(`/environment`, "GET").then((res) => {
    if (!res.ok) {
      return res.json().then(({ message }) => {
        throw new Error(message);
      });
    }
    return res.json();
  });
}
