import { Dialog, IconButton } from "@mui/material";
import React, { ReactNode } from "react";
import CustomButton from "./CustomButton";
import CloseIcon from "@mui/icons-material/Close";

interface PrivateInfoCollectionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function PrivateInfoCollectionDialog({
  open,
  setOpen,
}: PrivateInfoCollectionDialogProps) {
  return (
    <CustomInstructDialog
      open={open}
      handleClose={() => setOpen(false)}
      header="개인정보 수집 및 이용 동의"
      maxWidth="md"
    >
      필로어스(PhiloUs)는 제휴를 희망하는 기업 및 단체, 기관을 대상으로 아래와
      같이 개인정보를 수집하고 있습니다.
      <br />
      1. 수집 및 이용 개인정보 항목 : [필수] 회사(기관)명, 제안자 성함, 직책,
      이메일, 전화번호
      <br />
      2. 개인정보의 수집 및 이용목적 : 제휴신청에 따른 본인확인 및 원활한
      의사소통 경로 확보
      <br />
      3. 개인정보의 보유 및 이용기간: 제휴제안 종료 처리 시점으로부터 3개월간
      보관 후 파기합니다.
      <br />
      4. 동의 거부권리 안내 추가: 위와 같은 개인정보 수집 동의를 거부할 수
      있습니다. 다만 동의를 거부하는 경우 제휴제안 신청이 제한될 수 있습니다. 그
      밖의 사항은 각 사별 개인정보처리방침을 준수합니다.
    </CustomInstructDialog>
  );
}

interface CustomInstructDialogProps extends PropType {
  header: string;
}

export function CustomInstructDialog({
  open,
  handleClose,
  children,
  maxWidth = "sm",
  fullWidth,
  header,
}: CustomInstructDialogProps) {
  return (
    <CustomDialog open={open} maxWidth={maxWidth} fullWidth={fullWidth}>
      <p className="font-header">{header}</p>
      {children && (
        <div className="mt-20 height-300 p-10 outline-with-border overflow-scroll">
          <p className="font-sub whitespace-preline">{children}</p>
        </div>
      )}
      <div className="mt-30 text-align-center">
        <CustomButton outlined onClick={handleClose}>
          닫기
        </CustomButton>
      </div>
    </CustomDialog>
  );
}

interface PropType {
  children?: ReactNode;
  open: boolean;
  handleClose?: () => void;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
  handleCloseIcon?: () => void;
}

function CustomDialog({
  open,
  handleClose,
  children,
  maxWidth = "sm",
  fullWidth,
  handleCloseIcon,
}: PropType) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <div className="p-15 overflow-auto">
        {handleCloseIcon && (
          <div className="d-flex justify-content-flex-end mb-5">
            <IconButton onClick={handleCloseIcon} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {children}
      </div>
    </Dialog>
  );
}

export default CustomDialog;
