import { Grid, ListItemText } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  fetchDefaultWithCredential,
  formatDashedDateTemplate,
} from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomDialog from "../../customs/CustomDialog";
import CustomText from "../../customs/CustomText";
import CustomSelect from "../../customs/CustomSelect";
import RadioForm from "../../customs/RadioForm";
import { Template, useTemplates } from "./edits/programs/Templates";
import SortComponent from "./helpers/SortComponent";
import { UserListView } from "./helpers/UserList";
import { User } from "../../../globals/user";
import { SnackbarContext } from "../../../globals/components/ComponentsWrapper";
import { useAdminProgramInfos } from "./edits/programs/CustomizedRegister";
import { ProgramInfo } from "./users";
import { ProgramVisualizeInnerComponent } from "./Transactions";

type CouponType = "default" | "code" | "package" | "select";

type YesNo = "yes" | "no";

export interface Coupon {
  id: number;
  created_at: string;
  title: string;
  unit: string;
  value: number;
  expires_at: string;
  used: boolean;
  at_least_to_apply: number;
  type: CouponType;
  expired: boolean;
  program_registered: YesNo;
  registered_program_id: number;
  current_program: YesNo;
  current_program_id: number;
}

interface CouponDialogProps {
  open: boolean;
  coupons: Coupon[];
  refresh: () => void;
  handleClose: () => void;
}

interface CouponFormComponentProps {
  coupon: GeneralCoupon;
  refresh: () => void;
  type?: CouponType;
  reset: () => void;
}

function CouponFormComponent({
  refresh,
  coupon,
  type = "default",
  reset,
}: CouponFormComponentProps) {
  const { showWarningSnackbar } = useContext(SnackbarContext);
  const { programs } = useContext(CouponContext);
  const [currentProgram, setCurrentProgram] = useState<ProgramInfo | null>(
    null
  );

  const handleUpdate = () => {
    fetchDefaultWithCredential(`/coupons/${coupon.id}`, "PUT", coupon).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            showWarningSnackbar(message);
          });
        }
        return res.json().then(refresh);
      }
    );
  };

  const handleBlur = (e: any) => {
    const target = e.target as HTMLInputElement;
    if (target.type === "number") {
      (coupon as any)[target.name] = parseInt(target.value) || 0;
      handleUpdate();
    } else if (target.type === "text") {
      (coupon as any)[target.name] = target.value;
      handleUpdate();
    }
  };

  const handleDelete = () => {
    fetchDefaultWithCredential(`/coupons/${coupon.id}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(refresh).then(reset);
      }
    );
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-flex-end">
        <CustomButton onClick={handleDelete}>삭제</CustomButton>
      </div>
      <div>
        <CustomText
          label="쿠폰명"
          name="title"
          type="text"
          width="100%"
          style={{ backgroundColor: "white" }}
          defaultValue={coupon.title}
          handleBlur={handleBlur}
        />
      </div>
      {type === "code" && (
        <div className="mt-10">
          <CustomText
            label="코드"
            name="code"
            type="text"
            width="100%"
            style={{ backgroundColor: "white" }}
            defaultValue={(coupon as CodeCoupon).code}
            handleBlur={handleBlur}
          />
        </div>
      )}
      {type !== "package" && type !== "select" && (
        <div className="mt-10">
          <RadioForm
            name="unit"
            label="단위"
            row
            value={coupon.unit}
            radios={[
              {
                label: "단가 (원)",
                value: "value",
              },
              {
                label: "퍼센트 (%)",
                value: "percentage",
              },
            ]}
            handleChange={(e) => {
              coupon.unit = e.target.value;
              if (coupon.unit === "percentage") {
                coupon.value = Math.min(100, coupon.value);
              }
              handleUpdate();
            }}
          />
        </div>
      )}
      <div className="mt-10" key={coupon.value}>
        <CustomText
          label="값"
          name="value"
          type="number"
          width="100%"
          style={{ backgroundColor: "white" }}
          defaultValue={coupon.value}
          handleBlur={handleBlur}
        />
      </div>
      {type !== "package" && (
        <div className="mt-10">
          <p className="font-14 font-bold">유효기간</p>
          <input
            type="date"
            value={coupon.expires_at}
            onChange={(e) => {
              coupon.expires_at = e.target.value;
              handleUpdate();
            }}
            className="width-100-percentage"
          />
        </div>
      )}
      {["default", "code"].includes(type) && (
        <div className="mt-10">
          <CustomText
            label="최소 금액 이상"
            name="at_least_to_apply"
            type="number"
            width="100%"
            style={{ backgroundColor: "white" }}
            defaultValue={(coupon as UserCoupon).at_least_to_apply}
            handleBlur={handleBlur}
          />
        </div>
      )}
      {["default", "code"].includes(type) && (
        <div className="mt-10">
          <RadioForm<YesNo>
            name="unit"
            label="프로그램 지정 할인 여부"
            row
            value={coupon.current_program}
            radios={[
              {
                label: "아니오",
                value: "no",
              },
              {
                label: "예",
                value: "yes",
              },
            ]}
            handleChange={(e) => {
              coupon.current_program = e.target.value as YesNo;
              handleUpdate();
            }}
          />
        </div>
      )}
      {["select"].includes(type) && (
        <div className="mt-10">
          <RadioForm<YesNo>
            name="unit"
            label="과거 프로그램 등록 할인 여부"
            row
            value={coupon.program_registered}
            radios={[
              {
                label: "아니오",
                value: "no",
              },
              {
                label: "예",
                value: "yes",
              },
            ]}
            handleChange={(e) => {
              coupon.program_registered = e.target.value as YesNo;
              handleUpdate();
            }}
          />
        </div>
      )}
      {coupon.current_program === "yes" && (
        <Fragment>
          <div className="mt-10 font-14">
            현재 등록되어 있는 프로그램:{" "}
            {(() => {
              const registeredProgram = programs.find(
                (program) => program.id === coupon.current_program_id
              );
              return registeredProgram?.program_name ?? "없음";
            })()}
          </div>
          <div className="mt-10">
            <SortComponent<ProgramInfo>
              currentDataObject={currentProgram}
              setCurrentDataObject={setCurrentProgram}
              label="프로그램"
              data={programs}
              listItemComponent={(program) => (
                <ProgramVisualizeInnerComponent {...program} />
              )}
              abcSortAttributeFunc={(program) => program.program_name}
            />
          </div>
          <div className="mt-10">
            <CustomButton
              onClick={() => {
                if (currentProgram) {
                  coupon.current_program_id = currentProgram.id;
                  handleUpdate();
                }
              }}
            >
              쿠폰 프로그램 등록
            </CustomButton>
          </div>
          <div className="mt-10">
            <CustomButton
              onClick={() => {
                coupon.current_program_id = 0;
                handleUpdate();
              }}
            >
              쿠폰 프로그램 등록 해제
            </CustomButton>
          </div>
        </Fragment>
      )}
      {coupon.program_registered === "yes" && (
        <Fragment>
          <div className="mt-10 font-14">
            현재 등록되어 있는 프로그램:{" "}
            {(() => {
              const registeredProgram = programs.find(
                (program) => program.id === coupon.registered_program_id
              );
              return registeredProgram?.program_name ?? "없음";
            })()}
          </div>
          <div className="mt-10">
            <SortComponent<ProgramInfo>
              currentDataObject={currentProgram}
              setCurrentDataObject={setCurrentProgram}
              label="프로그램"
              data={programs}
              listItemComponent={(program) => (
                <ProgramVisualizeInnerComponent {...program} />
              )}
              abcSortAttributeFunc={(program) => program.program_name}
            />
          </div>
          <div className="mt-10">
            <CustomButton
              onClick={() => {
                if (currentProgram) {
                  coupon.registered_program_id = currentProgram.id;
                  handleUpdate();
                }
              }}
            >
              쿠폰 프로그램 등록
            </CustomButton>
          </div>
          <div className="mt-10">
            <CustomButton
              onClick={() => {
                coupon.registered_program_id = 0;
                handleUpdate();
              }}
            >
              쿠폰 프로그램 등록 해제
            </CustomButton>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export type GeneralCoupon = Coupon | UserCoupon | CodeCoupon | PackageCoupon;

interface CouponListProps {
  coupons: GeneralCoupon[];
  currentCoupon: GeneralCoupon | null;
  setCurrentCoupon: (coupon: GeneralCoupon | null) => void;
}

export function CouponList({
  coupons,
  currentCoupon,
  setCurrentCoupon,
}: CouponListProps) {
  const disableCondition = (coupon: UserCoupon) => {
    return coupon.used || coupon.expired;
  };
  const couponTypeMap = (coupon: Coupon) => {
    switch (coupon.type) {
      case "code":
        return "코드";
      case "default":
        return "등록";
      case "package":
        return "패키지";
      case "select":
        return "지정";
      default:
        return "";
    }
  };
  const couponWordMap = (coupon: UserCoupon) => {
    if (coupon.used) {
      return "사용함";
    }
    if (coupon.expired) {
      return "만료됨";
    }
    return "";
  };
  return (
    <SortComponent<GeneralCoupon>
      mutable
      currentDataObject={currentCoupon}
      setCurrentDataObject={setCurrentCoupon}
      label="쿠폰"
      data={coupons}
      listItemComponent={(coupon) => (
        <div
          className="user-select-none width-100-percentage"
          style={{
            opacity: disableCondition(coupon) ? 0.5 : 1,
          }}
        >
          <Grid
            container
            alignItems="center"
            spacing={2}
            style={{ flexWrap: "wrap" }}
          >
            <Grid item xs className="font-bold font-sub2">
              {coupon.title}
              <div className="mt-5 font-12">
                {couponTypeMap(coupon)}
                {"code" in coupon ? ` (${coupon.code})` : ""}
              </div>
              {disableCondition(coupon) && (
                <div className="mt-5 font-8">{couponWordMap(coupon)}</div>
              )}
            </Grid>
            <Grid item style={{ textAlign: "end" }}>
              <ListItemText
                primary={
                  <div className="font-bold font-sub2">
                    {coupon.value}
                    {coupon.unit === "value" ? "원" : "%"}
                  </div>
                }
                secondary={
                  ["default", "select"].includes(coupon.type) &&
                  formatDashedDateTemplate(coupon.expires_at) + "까지"
                }
              />
            </Grid>
          </Grid>
        </div>
      )}
      abcSortAttributeFunc={(coupon) => coupon.title}
      defaultSortType="recent"
    />
  );
}

interface DiscountTemplateListProps {
  templates: Template[];
  currentTemplate: Template | null;
  setCurrentTemplate: (coupon: Template | null) => void;
}

function DiscountTemplateList({
  templates,
  currentTemplate,
  setCurrentTemplate,
}: DiscountTemplateListProps) {
  return (
    <SortComponent
      currentDataObject={currentTemplate}
      setCurrentDataObject={setCurrentTemplate}
      label="할인 정보 템플릿"
      data={templates}
      listItemComponent={(template) => (
        <div>
          <p className="font-15 font-bold">{template.meta.title}</p>
          <p className="font-12 text-secondary">{template.meta.description}</p>
        </div>
      )}
      abcSortAttributeFunc={(template) => template.meta.title}
      defaultSortType="recent"
    />
  );
}

function PackageCouponDialog({
  open,
  coupons,
  refresh,
  handleClose,
}: CouponDialogProps) {
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const { templates } = useTemplates();
  const handleAddCoupon = () => {
    fetchDefaultWithCredential(`/coupons`, "POST", { type: "package" }).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(refresh);
      }
    );
  };

  useEffect(() => {
    if (selectedCoupon) {
      const template = templates.find(
        (template) =>
          template.id === (selectedCoupon as PackageCoupon).template_id
      );
      setCurrentTemplate(template ?? null);
    }
  }, [selectedCoupon, templates]);

  const discountTemplates = templates.filter(
    (template) => template.meta.type === "discount"
  );

  const patchCouponTemplate = () => {
    if (selectedCoupon && currentTemplate) {
      fetchDefaultWithCredential(
        `/coupons/${selectedCoupon.id}/template/${currentTemplate.id}`,
        "PATCH"
      ).then((res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            showWarningSnackbar(message);
          });
        }
        return res
          .json()
          .then(refresh)
          .then(() => {
            showSuccessSnackbar("적용 되었습니다");
          });
      });
    }
  };

  const removeCouponTemplate = () => {
    if (selectedCoupon && currentTemplate) {
      fetchDefaultWithCredential(
        `/coupons/${selectedCoupon.id}/template`,
        "DELETE"
      ).then((res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            showWarningSnackbar(message);
          });
        }
        return res.json().then(() => {
          refresh();
          showSuccessSnackbar("해제 되었습니다");
          setCurrentTemplate(null);
        });
      });
    }
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md">
      <div className="d-flex justify-content-flex-end">
        <CustomButton onClick={handleAddCoupon}>추가</CustomButton>
      </div>
      <Grid container spacing={2} className="mt-10">
        <Grid item xs>
          <CouponList
            currentCoupon={selectedCoupon}
            setCurrentCoupon={setSelectedCoupon}
            coupons={coupons}
          />
        </Grid>
        <Grid item xs>
          {selectedCoupon && (
            <CouponFormComponent
              coupon={selectedCoupon}
              refresh={refresh}
              key={selectedCoupon.id}
              reset={() => setSelectedCoupon(null)}
              type="package"
            />
          )}
        </Grid>
        <Grid item xs>
          {selectedCoupon && (
            <Fragment>
              <DiscountTemplateList
                currentTemplate={currentTemplate}
                setCurrentTemplate={setCurrentTemplate}
                templates={discountTemplates}
              />
              <div className="mt-10 d-flex justify-content-flex-end">
                <div>
                  <CustomButton onClick={removeCouponTemplate}>
                    패키지 템플릿 해제
                  </CustomButton>
                </div>
                <div className="ml-10">
                  <CustomButton
                    disabled={!currentTemplate}
                    onClick={patchCouponTemplate}
                  >
                    패키지 템플릿 적용
                  </CustomButton>
                </div>
              </div>
            </Fragment>
          )}
        </Grid>
      </Grid>
      <div className="mt-10 d-flex justify-content-flex-end">
        <CustomButton onClick={handleClose}>닫기</CustomButton>
      </div>
    </CustomDialog>
  );
}

function SelectCouponDialog({
  open,
  coupons,
  refresh,
  handleClose,
}: CouponDialogProps) {
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const { templates } = useTemplates();
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const handleAddCoupon = () => {
    fetchDefaultWithCredential(`/coupons`, "POST", { type: "select" }).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(refresh);
      }
    );
  };

  useEffect(() => {
    if (selectedCoupon) {
      const template = templates.find(
        (template) =>
          template.id === (selectedCoupon as PackageCoupon).template_id
      );
      setCurrentTemplate(template ?? null);
    }
  }, [selectedCoupon, templates]);

  const discountTemplates = templates.filter(
    (template) => template.meta.type === "discount"
  );

  const patchCouponTemplate = () => {
    if (selectedCoupon && currentTemplate) {
      fetchDefaultWithCredential(
        `/coupons/${selectedCoupon.id}/template/${currentTemplate.id}`,
        "PATCH"
      ).then((res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            showWarningSnackbar(message);
          });
        }
        return res.json().then(() => {
          refresh();
          showSuccessSnackbar("지정 되었습니다");
        });
      });
    }
  };

  const removeCouponTemplate = () => {
    if (selectedCoupon && currentTemplate) {
      fetchDefaultWithCredential(
        `/coupons/${selectedCoupon.id}/template`,
        "DELETE"
      ).then((res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            showWarningSnackbar(message);
          });
        }
        return res
          .json()
          .then(refresh)
          .then(() => {
            refresh();
            showSuccessSnackbar("해제 되었습니다");
            setCurrentTemplate(null);
          });
      });
    }
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md">
      <div className="d-flex justify-content-flex-end">
        <CustomButton onClick={handleAddCoupon}>추가</CustomButton>
      </div>
      <Grid container spacing={2} className="mt-10">
        <Grid item xs>
          <CouponList
            currentCoupon={selectedCoupon}
            setCurrentCoupon={setSelectedCoupon}
            coupons={coupons}
          />
        </Grid>
        <Grid item xs>
          {selectedCoupon && (
            <CouponFormComponent
              coupon={selectedCoupon}
              refresh={refresh}
              key={selectedCoupon.id}
              reset={() => setSelectedCoupon(null)}
              type="select"
            />
          )}
        </Grid>
        <Grid item xs>
          {selectedCoupon && (
            <Fragment>
              <DiscountTemplateList
                currentTemplate={currentTemplate}
                setCurrentTemplate={setCurrentTemplate}
                templates={discountTemplates}
              />
              <div className="mt-10 d-flex justify-content-flex-end">
                <div>
                  <CustomButton onClick={removeCouponTemplate}>
                    지정 템플릿 해제
                  </CustomButton>
                </div>
                <div className="ml-10">
                  <CustomButton
                    disabled={!currentTemplate}
                    onClick={patchCouponTemplate}
                  >
                    지정 템플릿 적용
                  </CustomButton>
                </div>
              </div>
            </Fragment>
          )}
        </Grid>
      </Grid>
      <div className="mt-10 d-flex justify-content-flex-end">
        <CustomButton onClick={handleClose}>닫기</CustomButton>
      </div>
    </CustomDialog>
  );
}

function CouponDialog({
  open,
  coupons,
  refresh,
  handleClose,
}: CouponDialogProps) {
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);
  const handleAddCoupon = () => {
    fetchDefaultWithCredential(`/coupons`, "POST", { type: "default" }).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(refresh);
      }
    );
  };

  return (
    <CustomDialog open={open} fullWidth>
      <div className="d-flex justify-content-flex-end">
        <CustomButton onClick={handleAddCoupon}>추가</CustomButton>
      </div>
      <Grid container spacing={2} className="mt-10">
        <Grid item xs>
          <CouponList
            currentCoupon={selectedCoupon}
            setCurrentCoupon={setSelectedCoupon}
            coupons={coupons}
          />
        </Grid>
        <Grid item xs>
          {selectedCoupon && (
            <CouponFormComponent
              coupon={selectedCoupon}
              refresh={refresh}
              key={selectedCoupon.id}
              reset={() => setSelectedCoupon(null)}
            />
          )}
        </Grid>
      </Grid>
      <div className="mt-10 d-flex justify-content-flex-end">
        <CustomButton onClick={handleClose}>닫기</CustomButton>
      </div>
    </CustomDialog>
  );
}

function CodeCouponDialog({
  open,
  coupons,
  refresh,
  handleClose,
}: CouponDialogProps) {
  const [selectedCoupon, setSelectedCoupon] = useState<CodeCoupon | null>(null);
  const handleAddCoupon = () => {
    fetchDefaultWithCredential(`/coupons`, "POST", { type: "code" }).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(refresh);
      }
    );
  };

  return (
    <CustomDialog open={open} fullWidth>
      <div className="d-flex justify-content-flex-end">
        <CustomButton onClick={handleAddCoupon}>추가</CustomButton>
      </div>
      <Grid container spacing={2} className="mt-10">
        <Grid item xs>
          <CouponList
            currentCoupon={selectedCoupon}
            setCurrentCoupon={setSelectedCoupon as any}
            coupons={coupons}
          />
        </Grid>
        <Grid item xs>
          {selectedCoupon && (
            <CouponFormComponent
              coupon={selectedCoupon}
              refresh={refresh}
              type="code"
              key={selectedCoupon.id}
              reset={() => setSelectedCoupon(null)}
            />
          )}
        </Grid>
      </Grid>
      <div className="mt-10 d-flex justify-content-flex-end">
        <CustomButton onClick={handleClose}>닫기</CustomButton>
      </div>
    </CustomDialog>
  );
}

interface UserCouponHistoryComponentProps {
  coupon: Coupon;
  user: User;
}

export interface UserCoupon extends Coupon {
  used: boolean;
  expired: boolean;
  at_least_to_apply: number;
}

function UserCouponHistoryComponent({
  coupon,
  user,
}: UserCouponHistoryComponentProps) {
  const [userCoupons, setUserCoupons] = useState<UserCoupon[]>([]);
  const [render, setRender] = useState<number>(0);
  const [currentUserCoupon, setCurrentUserCoupon] = useState<UserCoupon | null>(
    null
  );

  useEffect(() => {
    fetchDefaultWithCredential(`/coupons/user/${user.id}`, "GET").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(setUserCoupons);
      }
    );
  }, [user, render]);

  const handleSendCoupon = () => {
    fetchDefaultWithCredential(`/coupons/send`, "POST", {
      user_id: user.id,
      coupon_id: coupon.id,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  const handleRetrieveCoupon = (userCouponId: number) => {
    fetchDefaultWithCredential(
      `/coupons/retrieve/${userCouponId}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-space-between">
        {coupon.type === "default" && (
          <CustomButton onClick={handleSendCoupon}>쿠폰 지급</CustomButton>
        )}
        {currentUserCoupon && !currentUserCoupon.used && (
          <CustomButton
            onClick={() => handleRetrieveCoupon(currentUserCoupon.id)}
          >
            쿠폰 회수
          </CustomButton>
        )}
      </div>
      <CouponList
        coupons={userCoupons}
        currentCoupon={currentUserCoupon}
        setCurrentCoupon={setCurrentUserCoupon as any}
      />
    </Fragment>
  );
}

interface UserShowComponentProps {
  users: User[];
  title: string;
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
}

function UserShowComponent({
  users,
  title,
  currentUser,
  setCurrentUser,
}: UserShowComponentProps) {
  return (
    <SortComponent<User>
      currentDataObject={currentUser}
      setCurrentDataObject={setCurrentUser}
      label={title}
      data={users}
      listItemComponent={(user) => <UserListView user={user} showNew={false} />}
      abcSortAttributeFunc={(user) => user.name}
      defaultSortType="recent"
    />
  );
}

interface UserCouponComponentProps {
  coupon: Coupon;
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
}

function UserCouponComponent({
  coupon,
  currentUser,
  setCurrentUser,
}: UserCouponComponentProps) {
  interface UserCouponInfo {
    yes: User[];
    no: User[];
  }

  const [userCouponInfo, setUserCouponInfo] = useState<UserCouponInfo | null>(
    null
  );
  useEffect(() => {
    fetchDefaultWithCredential(`/coupons/${coupon.id}/users`, "GET").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(setUserCouponInfo);
      }
    );
  }, [coupon]);

  if (!userCouponInfo) return null;

  return (
    <Fragment>
      <div>
        <UserShowComponent
          users={userCouponInfo.yes}
          title="보유 유저"
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        />
      </div>
      <div className="mt-15">
        <UserShowComponent
          users={userCouponInfo.no}
          title="비보유 유저"
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        />
      </div>
    </Fragment>
  );
}

interface CodeCoupon extends Coupon {
  code: string;
}

interface PackageCoupon extends Coupon {
  template_id: number;
}

interface SelectCoupon extends Coupon {}

interface CouponGroups {
  defaults: Coupon[];
  packages: PackageCoupon[];
  codes: CodeCoupon[];
  selects: SelectCoupon[];
}

export function useCouponGroups() {
  const [couponGroups, setCouponGroups] = useState<CouponGroups | null>(null);
  const [render, setRender] = useState<number>(0);
  useEffect(() => {
    fetchDefaultWithCredential(`/coupons`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(setCouponGroups);
    });
  }, [render]);
  return {
    couponGroups,
    refresh: () => setRender(render + 1),
  };
}

interface CouponContextProps {
  programs: ProgramInfo[];
}

const CouponContext = React.createContext({} as CouponContextProps);

function AdminPageCoupons() {
  const { couponGroups, refresh } = useCouponGroups();
  const [currentCoupon, setCurrentCoupon] = useState<Coupon | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [codeOpen, setCodeOpen] = useState<boolean>(false);
  const [packageOpen, setPackageOpen] = useState<boolean>(false);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<CouponType | "all">("all");
  const { programs } = useAdminProgramInfos();
  if (!couponGroups || !programs) return null;
  return (
    <CouponContext.Provider value={{ programs }}>
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton onClick={() => setCodeOpen(true)}>
            코드 쿠폰 관리
          </CustomButton>
          <CustomButton onClick={() => setOpen(true)} className="ml-10">
            고객 등록 쿠폰 관리
          </CustomButton>
          <CustomButton onClick={() => setSelectOpen(true)} className="ml-10">
            지정 쿠폰 관리
          </CustomButton>
          <CustomButton onClick={() => setPackageOpen(true)} className="ml-10">
            패키지 등록 쿠폰 관리
          </CustomButton>
        </div>
        <Grid container columnSpacing={2} className="mt-10">
          <Grid item xs>
            <div>
              <CustomSelect
                name="type"
                label="쿠폰 유형"
                items={[
                  {
                    label: "전체",
                    value: "all",
                  },
                  {
                    label: "코드",
                    value: "codes",
                  },
                  {
                    label: "등록",
                    value: "defaults",
                  },
                  {
                    label: "패키지",
                    value: "packages",
                  },
                  {
                    label: "지정",
                    value: "selects",
                  },
                ]}
                value={type}
                handleChange={(value) => setType(value as CouponType)}
              />
            </div>
            <div className="mt-10">
              <p className="font-20 font-bold">액티브 쿠폰</p>
              <CouponList
                coupons={(couponGroups as any)[type].filter(
                  (coupon: Coupon) => !coupon.expired
                )}
                currentCoupon={currentCoupon}
                setCurrentCoupon={setCurrentCoupon}
              />
            </div>
            <div className="mt-10">
              <p className="font-20 font-bold">만료된 쿠폰</p>
              <CouponList
                coupons={(couponGroups as any)[type].filter(
                  (coupon: Coupon) => coupon.expired
                )}
                currentCoupon={currentCoupon}
                setCurrentCoupon={setCurrentCoupon}
              />
            </div>
          </Grid>
          <Grid item xs>
            {currentCoupon && (
              <UserCouponComponent
                coupon={currentCoupon}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
              />
            )}
          </Grid>
          <Grid item xs>
            {currentUser && currentCoupon && (
              <UserCouponHistoryComponent
                user={currentUser}
                coupon={currentCoupon}
              />
            )}
          </Grid>
        </Grid>
        <CouponDialog
          open={open}
          coupons={couponGroups.defaults}
          refresh={refresh}
          handleClose={() => setOpen(false)}
        />
        <CodeCouponDialog
          open={codeOpen}
          coupons={couponGroups.codes}
          refresh={refresh}
          handleClose={() => setCodeOpen(false)}
        />
        <PackageCouponDialog
          open={packageOpen}
          coupons={couponGroups.packages}
          refresh={refresh}
          handleClose={() => setPackageOpen(false)}
        />
        <SelectCouponDialog
          open={selectOpen}
          coupons={couponGroups.selects}
          refresh={refresh}
          handleClose={() => setSelectOpen(false)}
        />
      </div>
    </CouponContext.Provider>
  );
}

export default AdminPageCoupons;
