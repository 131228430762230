import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/lab";

interface PropType {
  label: string;
  value: Date;
  handleChange: (date: Date) => void;
  fullWidth?: boolean;
}

function CustomDatePicker({ label, value, handleChange, fullWidth }: PropType) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={(date) => date && handleChange(date)}
        renderInput={(params) => (
          <TextField {...params} fullWidth={fullWidth} />
        )}
      />
    </LocalizationProvider>
  );
}

export function CustomDateTimePicker({
  label,
  value,
  handleChange,
  fullWidth,
}: PropType) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={() => {}}
        onAccept={(date) => date && handleChange(date)}
        renderInput={(params) => (
          <TextField {...params} fullWidth={fullWidth} />
        )}
        views={["year", "month", "day", "hours", "minutes"]}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
