import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { putObjectFromList } from "../../../hooks";
import { defaultSet } from "../../../interfaces";
import { fetchDefaultWithCredential } from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomCheckbox from "../../customs/CustomCheckbox";
import CustomText from "../../customs/CustomText";
import CustomDialog from "../../customs/CustomDialog";

interface Questionaire extends defaultSet {
  question: string;
  answer: string;
  type: QuestionaireType;
  order_by: number;
}

type QuestionaireType = "faq" | "refund";

export function useQuestionaires() {
  const [questionaires, setQuestionaires] = useState<Questionaire[]>([]);

  useEffect(() => {
    fetchDefaultWithCredential(`/questionaires`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setQuestionaires);
  }, []);

  function insert(type: QuestionaireType) {
    return fetchDefaultWithCredential(`/questionaires`, "POST", { type })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then((data) => setQuestionaires([...questionaires, data]));
  }

  function update(questionaire: Questionaire) {
    return fetchDefaultWithCredential(
      `/questionaires/${questionaire.id!}`,
      "PUT",
      questionaire
    )
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then((data) => setQuestionaires(putObjectFromList(questionaires, data)));
  }

  function remove(id: number) {
    return fetchDefaultWithCredential(`/questionaires/${id}`, "DELETE")
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setQuestionaires);
  }

  function patchOrder(id: number, checked: boolean) {
    return fetchDefaultWithCredential(`/questionaires/${id}`, "PATCH", {
      checked,
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json();
      })
      .then(setQuestionaires);
  }

  return {
    questionaires,
    insert,
    update,
    remove,
    patchOrder,
  };
}

interface QuestionaireComponentProps {
  questionaire: Questionaire;
  update: (questionaire: Questionaire) => Promise<void>;
  remove: (id: number) => Promise<void>;
  patchOrder: (id: number, checked: boolean) => Promise<void>;
}

function QuestionaireComponent({
  questionaire,
  update,
  remove,
  patchOrder,
}: QuestionaireComponentProps) {
  const handleChange = (
    e: React.FocusEvent<HTMLFormElement>,
    questionaire: Questionaire
  ) => {
    const target = e.target as any;
    questionaire[target.name as "question" | "answer"] = target.value;
    update(questionaire);
  };

  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <div className="mt-30">
      <div className="d-flex justify-content-space-between">
        <CustomCheckbox
          handleChange={(checked) => patchOrder(questionaire.id!, checked)}
          border={false}
          checked={questionaire.order_by !== 0}
        >
          {questionaire.order_by === 0 ? "" : questionaire.order_by + "."}
        </CustomCheckbox>
        <CustomButton onClick={() => setOpenDelete(true)}>삭제</CustomButton>
      </div>
      <form onBlur={(e) => handleChange(e, questionaire)}>
        <div>
          <CustomText
            label="질문"
            name="question"
            type="text"
            width="100%"
            defaultValue={questionaire.question}
          />
        </div>
        <div className="mt-10">
          <CustomText
            label="답변"
            name="answer"
            type="text"
            width="100%"
            height="200px"
            defaultValue={questionaire.answer}
            multiline
          />
        </div>
      </form>
      <CustomDialog open={openDelete} handleClose={() => setOpenDelete(false)}>
        <p className="font-header">정말로 삭제하시겠습니까?</p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton
              onClick={() => {
                remove(questionaire.id!).then(() => setOpenDelete(false));
              }}
            >
              삭제
            </CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setOpenDelete(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
    </div>
  );
}

function AdminPageQuestionaires() {
  const { questionaires, insert, update, remove, patchOrder } =
    useQuestionaires();

  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    setRender((render) => render + 1);
  }, [questionaires]);

  return (
    <div className="p-30" key={render}>
      <p className="font-sub">
        *체크를 표시하면 웹사이트 회원들이 볼 수 있도록 등록이 됩니다. 수정시
        내용을 눌러 내용을 변경해주신 후 흰 바탕을 누르면 수정이 완료된 후
        회원들이 볼 수 있게 됩니다.
      </p>
      <div className="faq">
        <p className="font-header">자주 찾는 질문</p>
        <div>
          <CustomButton onClick={() => insert("faq")}>추가</CustomButton>
        </div>
        {questionaires
          .filter((questionaire) => questionaire.type === "faq")
          .map((questionaire, index) => (
            <QuestionaireComponent
              questionaire={questionaire}
              key={index}
              update={update}
              remove={remove}
              patchOrder={patchOrder}
            />
          ))}
      </div>
      <p className="font-header">환불정책</p>
      <div>
        <CustomButton onClick={() => insert("refund")}>추가</CustomButton>
      </div>
      {questionaires
        .filter((questionaire) => questionaire.type === "refund")
        .map((questionaire, index) => (
          <QuestionaireComponent
            questionaire={questionaire}
            key={index}
            update={update}
            remove={remove}
            patchOrder={patchOrder}
          />
        ))}
    </div>
  );
}

export default AdminPageQuestionaires;
